/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ErtekesitesRiportSzemely } from './ertekesitesRiportSzemely';
import { SzerzodesHierarchiaSzervezet } from './szerzodesHierarchiaSzervezet';

export interface ErtekesitesDetails { 
    beteltFogasiNaploLeadasDatum?: string;
    betoltott70Eletkor?: boolean;
    csakMasTagsag?: boolean;
    ertekesitoSzemelyLoginId?: string;
    ertekesitoSzemelyNev?: string;
    ertekesitoSzervezetNev?: string;
    ervenyessegiEv?: number;
    fogasiNaploLeadasDatum?: string;
    fogyatekkalElo?: boolean;
    gyermek?: boolean;
    horgaszAzonosito?: string;
    horgaszvizsgaDatum?: string;
    horgaszvizsgaSzam?: string;
    jutalek?: number;
    kosarId?: number;
    kulfoldiAllampolgar?: boolean;
    szakAzonosito?: string;
    szemely?: ErtekesitesRiportSzemely;
    szerzodesHierarchiaSzervezetIdList?: Array<number>;
    szerzodesHierarchiaSzervezetList?: Array<SzerzodesHierarchiaSzervezet>;
}