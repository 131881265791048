/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GyartasIgenylesLetrehozasRequest } from '../model/gyartasIgenylesLetrehozasRequest';
import { HorgaszkartyaIgenyles } from '../model/horgaszkartyaIgenyles';
import { HorgaszkartyaIgenylesKombinacio } from '../model/horgaszkartyaIgenylesKombinacio';
import { HorgaszkartyaIgenylesTortenet } from '../model/horgaszkartyaIgenylesTortenet';
import { SzemelyRequest } from '../model/szemelyRequest';
import { UjragyartasIgenylesLetrehozasRequest } from '../model/ujragyartasIgenylesLetrehozasRequest';
import { UjragyartasIgenylesModositasRequest } from '../model/ujragyartasIgenylesModositasRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SzemelyHorgaszkartyaIgenylesekMgmtControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * dokumentumFeltoltes
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dokumentumFeltoltesUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public dokumentumFeltoltesUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public dokumentumFeltoltesUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public dokumentumFeltoltesUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling dokumentumFeltoltesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenylesmgmt/dokumentum-feltoltes`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchHorgaszkartyaIgenylesTortenet
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchHorgaszkartyaIgenylesTortenetUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<HorgaszkartyaIgenylesTortenet>>;
    public fetchHorgaszkartyaIgenylesTortenetUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HorgaszkartyaIgenylesTortenet>>>;
    public fetchHorgaszkartyaIgenylesTortenetUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HorgaszkartyaIgenylesTortenet>>>;
    public fetchHorgaszkartyaIgenylesTortenetUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fetchHorgaszkartyaIgenylesTortenetUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<HorgaszkartyaIgenylesTortenet>>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenylesmgmt/fetch-horgaszkartyaigenyles-tortenet`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchLehetsegesHorgaszkartyaIgenylesKombinaciok
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchLehetsegesHorgaszkartyaIgenylesKombinaciokUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<HorgaszkartyaIgenylesKombinacio>>;
    public fetchLehetsegesHorgaszkartyaIgenylesKombinaciokUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HorgaszkartyaIgenylesKombinacio>>>;
    public fetchLehetsegesHorgaszkartyaIgenylesKombinaciokUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HorgaszkartyaIgenylesKombinacio>>>;
    public fetchLehetsegesHorgaszkartyaIgenylesKombinaciokUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fetchLehetsegesHorgaszkartyaIgenylesKombinaciokUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<HorgaszkartyaIgenylesKombinacio>>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenylesmgmt/fetch-horgaszkartyaigenyles-kombinaciok`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findFolyamatbanLevoIgenyles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findFolyamatbanLevoIgenylesUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenyles>;
    public findFolyamatbanLevoIgenylesUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenyles>>;
    public findFolyamatbanLevoIgenylesUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenyles>>;
    public findFolyamatbanLevoIgenylesUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findFolyamatbanLevoIgenylesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenyles>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenylesmgmt/find-folyamatbanlevoigenyles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * gyartasHorgaszkartyaIgenylesLetrehozasFizetesreKuldes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gyartasHorgaszkartyaIgenylesLetrehozasFizetesreKuldesUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenyles>;
    public gyartasHorgaszkartyaIgenylesLetrehozasFizetesreKuldesUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenyles>>;
    public gyartasHorgaszkartyaIgenylesLetrehozasFizetesreKuldesUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenyles>>;
    public gyartasHorgaszkartyaIgenylesLetrehozasFizetesreKuldesUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling gyartasHorgaszkartyaIgenylesLetrehozasFizetesreKuldesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenyles>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenylesmgmt/gyartas-horgaszkartyaigenyles-letrehozas-fizetesrekuldes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * gyartasHorgaszkartyaIgenylesLetrehozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gyartasHorgaszkartyaIgenylesLetrehozasUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenyles>;
    public gyartasHorgaszkartyaIgenylesLetrehozasUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenyles>>;
    public gyartasHorgaszkartyaIgenylesLetrehozasUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenyles>>;
    public gyartasHorgaszkartyaIgenylesLetrehozasUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling gyartasHorgaszkartyaIgenylesLetrehozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenyles>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenylesmgmt/gyartas-horgaszkartyaigenyles-letrehozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * horgaszkartyaIgenylesFizetesreKuldes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszkartyaIgenylesFizetesreKuldesUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenyles>;
    public horgaszkartyaIgenylesFizetesreKuldesUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenyles>>;
    public horgaszkartyaIgenylesFizetesreKuldesUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenyles>>;
    public horgaszkartyaIgenylesFizetesreKuldesUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgaszkartyaIgenylesFizetesreKuldesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenyles>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenylesmgmt/horgaszkartyaigenyles-fizetesrekuldes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * horgaszkartyaIgenylesTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszkartyaIgenylesTorlesUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public horgaszkartyaIgenylesTorlesUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public horgaszkartyaIgenylesTorlesUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public horgaszkartyaIgenylesTorlesUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgaszkartyaIgenylesTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenylesmgmt/horgaszkartyaigenyles-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * isIgenylesFunkcioEngedelyezve
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isIgenylesFunkcioEngedelyezveUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public isIgenylesFunkcioEngedelyezveUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public isIgenylesFunkcioEngedelyezveUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public isIgenylesFunkcioEngedelyezveUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling isIgenylesFunkcioEngedelyezveUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenylesmgmt/is-horgaszkartyaigenyles-funkcioengedelyezve`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ujragyartasHorgaszkartyaIgenylesLetrehozasFizetesreKuldes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ujragyartasHorgaszkartyaIgenylesLetrehozasFizetesreKuldesUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenyles>;
    public ujragyartasHorgaszkartyaIgenylesLetrehozasFizetesreKuldesUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenyles>>;
    public ujragyartasHorgaszkartyaIgenylesLetrehozasFizetesreKuldesUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenyles>>;
    public ujragyartasHorgaszkartyaIgenylesLetrehozasFizetesreKuldesUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ujragyartasHorgaszkartyaIgenylesLetrehozasFizetesreKuldesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenyles>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenylesmgmt/ujragyartas-horgaszkartyaigenyles-letrehozas-fizetesrekuldes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ujragyartasHorgaszkartyaIgenylesLetrehozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ujragyartasHorgaszkartyaIgenylesLetrehozasUsingPOST(body: UjragyartasIgenylesLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenyles>;
    public ujragyartasHorgaszkartyaIgenylesLetrehozasUsingPOST(body: UjragyartasIgenylesLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenyles>>;
    public ujragyartasHorgaszkartyaIgenylesLetrehozasUsingPOST(body: UjragyartasIgenylesLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenyles>>;
    public ujragyartasHorgaszkartyaIgenylesLetrehozasUsingPOST(body: UjragyartasIgenylesLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ujragyartasHorgaszkartyaIgenylesLetrehozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenyles>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenylesmgmt/ujragyartas-horgaszkartyaigenyles-letrehozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ujragyartasHorgaszkartyaIgenylesModositasFizetesreKuldes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ujragyartasHorgaszkartyaIgenylesModositasFizetesreKuldesUsingPOST(body: UjragyartasIgenylesModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenyles>;
    public ujragyartasHorgaszkartyaIgenylesModositasFizetesreKuldesUsingPOST(body: UjragyartasIgenylesModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenyles>>;
    public ujragyartasHorgaszkartyaIgenylesModositasFizetesreKuldesUsingPOST(body: UjragyartasIgenylesModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenyles>>;
    public ujragyartasHorgaszkartyaIgenylesModositasFizetesreKuldesUsingPOST(body: UjragyartasIgenylesModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ujragyartasHorgaszkartyaIgenylesModositasFizetesreKuldesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenyles>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenylesmgmt/ujragyartas-horgaszkartyaigenyles-modositas-fizetesrekuldes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ujragyartasHorgaszkartyaIgenylesModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ujragyartasHorgaszkartyaIgenylesModositasUsingPOST(body: UjragyartasIgenylesModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenyles>;
    public ujragyartasHorgaszkartyaIgenylesModositasUsingPOST(body: UjragyartasIgenylesModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenyles>>;
    public ujragyartasHorgaszkartyaIgenylesModositasUsingPOST(body: UjragyartasIgenylesModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenyles>>;
    public ujragyartasHorgaszkartyaIgenylesModositasUsingPOST(body: UjragyartasIgenylesModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ujragyartasHorgaszkartyaIgenylesModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenyles>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenylesmgmt/ujragyartas-horgaszkartyaigenyles-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
