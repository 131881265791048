/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddFogasRequest } from '../model/addFogasRequest';
import { AddFogasResponse } from '../model/addFogasResponse';
import { DeleteFogasRequest } from '../model/deleteFogasRequest';
import { FogasNyilvantartas } from '../model/fogasNyilvantartas';
import { FogasNyilvantartasEllenorzesRequest } from '../model/fogasNyilvantartasEllenorzesRequest';
import { FogasNyilvantartasEllenorzesResponse } from '../model/fogasNyilvantartasEllenorzesResponse';
import { FogasNyilvantartasRogzitesRequest } from '../model/fogasNyilvantartasRogzitesRequest';
import { FogasiNaploIdRequest } from '../model/fogasiNaploIdRequest';
import { UpdateFogasRequest } from '../model/updateFogasRequest';
import { UpdateFogasResponse } from '../model/updateFogasResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class FogasNyilvantartasControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * addFogas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addFogasUsingPOST(body: AddFogasRequest, observe?: 'body', reportProgress?: boolean): Observable<AddFogasResponse>;
    public addFogasUsingPOST(body: AddFogasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AddFogasResponse>>;
    public addFogasUsingPOST(body: AddFogasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AddFogasResponse>>;
    public addFogasUsingPOST(body: AddFogasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addFogasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AddFogasResponse>('post',`${this.basePath}/api/mohosz/fogasnyilvantartas/add-fogas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteFogas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFogasUsingPOST(body: DeleteFogasRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteFogasUsingPOST(body: DeleteFogasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteFogasUsingPOST(body: DeleteFogasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteFogasUsingPOST(body: DeleteFogasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling deleteFogasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/fogasnyilvantartas/delete-fogas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findByFogasiNaploId
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findByFogasiNaploIdUsingPOST(body: FogasiNaploIdRequest, observe?: 'body', reportProgress?: boolean): Observable<FogasNyilvantartas>;
    public findByFogasiNaploIdUsingPOST(body: FogasiNaploIdRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FogasNyilvantartas>>;
    public findByFogasiNaploIdUsingPOST(body: FogasiNaploIdRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FogasNyilvantartas>>;
    public findByFogasiNaploIdUsingPOST(body: FogasiNaploIdRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findByFogasiNaploIdUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<FogasNyilvantartas>('post',`${this.basePath}/api/mohosz/fogasnyilvantartas/find-by-fogasi-naplo-id`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fogasNyilvantartasEllenorzes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fogasNyilvantartasEllenorzesUsingPOST(body: FogasNyilvantartasEllenorzesRequest, observe?: 'body', reportProgress?: boolean): Observable<FogasNyilvantartasEllenorzesResponse>;
    public fogasNyilvantartasEllenorzesUsingPOST(body: FogasNyilvantartasEllenorzesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FogasNyilvantartasEllenorzesResponse>>;
    public fogasNyilvantartasEllenorzesUsingPOST(body: FogasNyilvantartasEllenorzesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FogasNyilvantartasEllenorzesResponse>>;
    public fogasNyilvantartasEllenorzesUsingPOST(body: FogasNyilvantartasEllenorzesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fogasNyilvantartasEllenorzesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<FogasNyilvantartasEllenorzesResponse>('post',`${this.basePath}/api/mohosz/fogasnyilvantartas/fogasnyilvantartas-ellenorzes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fogasNyilvantartasRogzites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fogasNyilvantartasRogzitesUsingPOST(body: FogasNyilvantartasRogzitesRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public fogasNyilvantartasRogzitesUsingPOST(body: FogasNyilvantartasRogzitesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public fogasNyilvantartasRogzitesUsingPOST(body: FogasNyilvantartasRogzitesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public fogasNyilvantartasRogzitesUsingPOST(body: FogasNyilvantartasRogzitesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fogasNyilvantartasRogzitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/fogasnyilvantartas/fogasnyilvantartas-rogzites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateFogas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFogasUsingPOST(body: UpdateFogasRequest, observe?: 'body', reportProgress?: boolean): Observable<UpdateFogasResponse>;
    public updateFogasUsingPOST(body: UpdateFogasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateFogasResponse>>;
    public updateFogasUsingPOST(body: UpdateFogasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateFogasResponse>>;
    public updateFogasUsingPOST(body: UpdateFogasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateFogasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UpdateFogasResponse>('post',`${this.basePath}/api/mohosz/fogasnyilvantartas/update-fogas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
