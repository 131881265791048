/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SzamlaReadModel { 
    externalSzamlaId?: string;
    tipus?: SzamlaReadModel.TipusEnum;
}
export namespace SzamlaReadModel {
    export type TipusEnum = 'NORMAL' | 'SZTORNO' | 'HELYESBITO';
    export const TipusEnum = {
        NORMAL: 'NORMAL' as TipusEnum,
        SZTORNO: 'SZTORNO' as TipusEnum,
        HELYESBITO: 'HELYESBITO' as TipusEnum
    };
}