/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface RendszerenKivuliBefizetesModositasRequest { 
    mahorElofizetesCiklusId?: number;
    mahorElofizetesId?: number;
    rendszerenKivuliBefizetesDatum?: string;
    rendszerenKivuliFizetesTipus?: RendszerenKivuliBefizetesModositasRequest.RendszerenKivuliFizetesTipusEnum;
}
export namespace RendszerenKivuliBefizetesModositasRequest {
    export type RendszerenKivuliFizetesTipusEnum = 'CSEKK' | 'ATUTALAS' | 'KESZPENZ' | 'NINCSPENZMOZGAS' | 'EGYEB';
    export const RendszerenKivuliFizetesTipusEnum = {
        CSEKK: 'CSEKK' as RendszerenKivuliFizetesTipusEnum,
        ATUTALAS: 'ATUTALAS' as RendszerenKivuliFizetesTipusEnum,
        KESZPENZ: 'KESZPENZ' as RendszerenKivuliFizetesTipusEnum,
        NINCSPENZMOZGAS: 'NINCSPENZMOZGAS' as RendszerenKivuliFizetesTipusEnum,
        EGYEB: 'EGYEB' as RendszerenKivuliFizetesTipusEnum
    };
}