/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EvesTagsag } from './evesTagsag';
import { SzemelyEltiltas } from './szemelyEltiltas';

export interface EgyesuletiTagsagRiportSor { 
    evesTagsagList?: Array<EvesTagsag>;
    horgaszAzonosito?: string;
    horgaszNev?: string;
    horgaszSzemelyId?: number;
    statusz?: EgyesuletiTagsagRiportSor.StatuszEnum;
    szemelyEltiltas?: SzemelyEltiltas;
    tagdijOsszeg?: number;
    tagsagBefizetesIdopont?: Date;
    tagsagKategoria?: EgyesuletiTagsagRiportSor.TagsagKategoriaEnum;
}
export namespace EgyesuletiTagsagRiportSor {
    export type StatuszEnum = 'ROGZITETT' | 'AKTIV' | 'TURELMI_IDOSZAK' | 'MEGSZUNT';
    export const StatuszEnum = {
        ROGZITETT: 'ROGZITETT' as StatuszEnum,
        AKTIV: 'AKTIV' as StatuszEnum,
        TURELMIIDOSZAK: 'TURELMI_IDOSZAK' as StatuszEnum,
        MEGSZUNT: 'MEGSZUNT' as StatuszEnum
    };
    export type TagsagKategoriaEnum = 'IFJUSAGI' | 'FELNOTT' | 'FELNOTT_KEDVEZMENYEZETT' | 'IFJUSAGI_IHJ' | 'FELNOTT_IHJ' | 'FELNOTT_JOGFENNTARTO_IHJ' | 'MOHOSZON_KIVULI';
    export const TagsagKategoriaEnum = {
        IFJUSAGI: 'IFJUSAGI' as TagsagKategoriaEnum,
        FELNOTT: 'FELNOTT' as TagsagKategoriaEnum,
        FELNOTTKEDVEZMENYEZETT: 'FELNOTT_KEDVEZMENYEZETT' as TagsagKategoriaEnum,
        IFJUSAGIIHJ: 'IFJUSAGI_IHJ' as TagsagKategoriaEnum,
        FELNOTTIHJ: 'FELNOTT_IHJ' as TagsagKategoriaEnum,
        FELNOTTJOGFENNTARTOIHJ: 'FELNOTT_JOGFENNTARTO_IHJ' as TagsagKategoriaEnum,
        MOHOSZONKIVULI: 'MOHOSZON_KIVULI' as TagsagKategoriaEnum
    };
}