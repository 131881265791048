/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HalgazdalkodoHozzarendelesRequest { 
    alhaszonberletKezdete?: string;
    alhaszonberletVege?: string;
    alhaszonberletiSzerzodesSzam?: string;
    szervezetId?: number;
    szervezetTipus?: HalgazdalkodoHozzarendelesRequest.SzervezetTipusEnum;
    vizteruletId?: number;
}
export namespace HalgazdalkodoHozzarendelesRequest {
    export type SzervezetTipusEnum = 'HALGAZDALKODASI_HASZNOSITO' | 'JEGYKIADASRA_JOGOSULT';
    export const SzervezetTipusEnum = {
        HALGAZDALKODASIHASZNOSITO: 'HALGAZDALKODASI_HASZNOSITO' as SzervezetTipusEnum,
        JEGYKIADASRAJOGOSULT: 'JEGYKIADASRA_JOGOSULT' as SzervezetTipusEnum
    };
}