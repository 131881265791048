/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { HorgaszkartyaIgenylesTortenet } from './horgaszkartyaIgenylesTortenet';

export interface HorgaszkartyaIgenylesTortenetBundle { 
    availableGyartasMuvelet?: HorgaszkartyaIgenylesTortenetBundle.AvailableGyartasMuveletEnum;
    availableIgenylesOkList?: Array<HorgaszkartyaIgenylesTortenetBundle.AvailableIgenylesOkListEnum>;
    ervenyessegKezdete?: string;
    ervenyessegVege?: string;
    tortenetList?: Array<HorgaszkartyaIgenylesTortenet>;
}
export namespace HorgaszkartyaIgenylesTortenetBundle {
    export type AvailableGyartasMuveletEnum = 'GYARTAS' | 'UJRAGYARTAS';
    export const AvailableGyartasMuveletEnum = {
        GYARTAS: 'GYARTAS' as AvailableGyartasMuveletEnum,
        UJRAGYARTAS: 'UJRAGYARTAS' as AvailableGyartasMuveletEnum
    };
    export type AvailableIgenylesOkListEnum = 'ELSO_GYARTAS' | 'TERMESZETES_MODON_MEGVALTOZO_ADAT' | 'KARTYA_HIANY' | 'IGAZOLT_KARTYA_HIANY' | 'ADATHIBA_A_KARTYAN_HORGASZ_HIBAJABOL' | 'ADATHIBA_A_KARTYAN_UGYFELSZOLGALAT_HIBAJABOL' | 'LEJART_KARTYA';
    export const AvailableIgenylesOkListEnum = {
        ELSOGYARTAS: 'ELSO_GYARTAS' as AvailableIgenylesOkListEnum,
        TERMESZETESMODONMEGVALTOZOADAT: 'TERMESZETES_MODON_MEGVALTOZO_ADAT' as AvailableIgenylesOkListEnum,
        KARTYAHIANY: 'KARTYA_HIANY' as AvailableIgenylesOkListEnum,
        IGAZOLTKARTYAHIANY: 'IGAZOLT_KARTYA_HIANY' as AvailableIgenylesOkListEnum,
        ADATHIBAAKARTYANHORGASZHIBAJABOL: 'ADATHIBA_A_KARTYAN_HORGASZ_HIBAJABOL' as AvailableIgenylesOkListEnum,
        ADATHIBAAKARTYANUGYFELSZOLGALATHIBAJABOL: 'ADATHIBA_A_KARTYAN_UGYFELSZOLGALAT_HIBAJABOL' as AvailableIgenylesOkListEnum,
        LEJARTKARTYA: 'LEJART_KARTYA' as AvailableIgenylesOkListEnum
    };
}