/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SzerzodesDetails } from './szerzodesDetails';

export interface Szerzodes { 
    aktiv?: boolean;
    availableCommands?: Array<Szerzodes.AvailableCommandsEnum>;
    details?: SzerzodesDetails;
    id?: number;
    jutalekMerteke?: number;
    megbizoSzereploId?: number;
    megbizottSzereploId?: number;
    modositva?: Date;
    szerzodesTipus?: Szerzodes.SzerzodesTipusEnum;
}
export namespace Szerzodes {
    export type AvailableCommandsEnum = 'TORLES' | 'LEFURAS';
    export const AvailableCommandsEnum = {
        TORLES: 'TORLES' as AvailableCommandsEnum,
        LEFURAS: 'LEFURAS' as AvailableCommandsEnum
    };
    export type SzerzodesTipusEnum = 'ALLAMI_JEGY_ERTEKESITES' | 'HORGASZVIZSGAZTATAS' | 'TERULETI_JEGY_VISZONTERTEKESITES' | 'TERULETI_JEGY_ERTEKESITES';
    export const SzerzodesTipusEnum = {
        ALLAMIJEGYERTEKESITES: 'ALLAMI_JEGY_ERTEKESITES' as SzerzodesTipusEnum,
        HORGASZVIZSGAZTATAS: 'HORGASZVIZSGAZTATAS' as SzerzodesTipusEnum,
        TERULETIJEGYVISZONTERTEKESITES: 'TERULETI_JEGY_VISZONTERTEKESITES' as SzerzodesTipusEnum,
        TERULETIJEGYERTEKESITES: 'TERULETI_JEGY_ERTEKESITES' as SzerzodesTipusEnum
    };
}