/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BatchSzemelyRequest } from '../model/batchSzemelyRequest';
import { SajatSzemelyAlapadatRequest } from '../model/sajatSzemelyAlapadatRequest';
import { Szemely } from '../model/szemely';
import { SzemelyAlapadatRequest } from '../model/szemelyAlapadatRequest';
import { SzemelyCimRequest } from '../model/szemelyCimRequest';
import { SzemelyProfilkepRequest } from '../model/szemelyProfilkepRequest';
import { SzemelyRequest } from '../model/szemelyRequest';
import { SzemelySearchRequest } from '../model/szemelySearchRequest';
import { SzervezetAdapter } from '../model/szervezetAdapter';
import { UgyintezoiSzemelyAlapadatRequest } from '../model/ugyintezoiSzemelyAlapadatRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SzemelyControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * alapadatModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public alapadatModositasUsingPOST(body: SzemelyAlapadatRequest, observe?: 'body', reportProgress?: boolean): Observable<Szemely>;
    public alapadatModositasUsingPOST(body: SzemelyAlapadatRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Szemely>>;
    public alapadatModositasUsingPOST(body: SzemelyAlapadatRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Szemely>>;
    public alapadatModositasUsingPOST(body: SzemelyAlapadatRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling alapadatModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Szemely>('post',`${this.basePath}/api/mohosz/szemely/alapadat-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * cimModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cimModositasUsingPOST(body: SzemelyCimRequest, observe?: 'body', reportProgress?: boolean): Observable<Szemely>;
    public cimModositasUsingPOST(body: SzemelyCimRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Szemely>>;
    public cimModositasUsingPOST(body: SzemelyCimRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Szemely>>;
    public cimModositasUsingPOST(body: SzemelyCimRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling cimModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Szemely>('post',`${this.basePath}/api/mohosz/szemely/cim-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetch
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<Szemely>;
    public fetchUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Szemely>>;
    public fetchUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Szemely>>;
    public fetchUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fetchUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Szemely>('post',`${this.basePath}/api/mohosz/szemely/fetch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fullTextSearch
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fullTextSearchUsingPOST(body: SzemelySearchRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<Szemely>>;
    public fullTextSearchUsingPOST(body: SzemelySearchRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Szemely>>>;
    public fullTextSearchUsingPOST(body: SzemelySearchRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Szemely>>>;
    public fullTextSearchUsingPOST(body: SzemelySearchRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fullTextSearchUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<Szemely>>('post',`${this.basePath}/api/mohosz/szemely/szemely-kereso`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * isSajatSzemelyAlapadatKitoltve
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isSajatSzemelyAlapadatKitoltveUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public isSajatSzemelyAlapadatKitoltveUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public isSajatSzemelyAlapadatKitoltveUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public isSajatSzemelyAlapadatKitoltveUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/mohosz/szemely/sajat-alapadat-kitoltve`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * sajatAlapadatModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sajatAlapadatModositasUsingPOST(body: SajatSzemelyAlapadatRequest, observe?: 'body', reportProgress?: boolean): Observable<Szemely>;
    public sajatAlapadatModositasUsingPOST(body: SajatSzemelyAlapadatRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Szemely>>;
    public sajatAlapadatModositasUsingPOST(body: SajatSzemelyAlapadatRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Szemely>>;
    public sajatAlapadatModositasUsingPOST(body: SajatSzemelyAlapadatRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling sajatAlapadatModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Szemely>('post',`${this.basePath}/api/mohosz/szemely/sajat-alapadat-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szemelyModositas
     * 
     * @param body szemely
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szemelyModositasUsingPOST(body: Szemely, observe?: 'body', reportProgress?: boolean): Observable<Szemely>;
    public szemelyModositasUsingPOST(body: Szemely, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Szemely>>;
    public szemelyModositasUsingPOST(body: Szemely, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Szemely>>;
    public szemelyModositasUsingPOST(body: Szemely, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szemelyModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Szemely>('post',`${this.basePath}/api/mohosz/szemely/szemely-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szemelyProfilkepMentes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szemelyProfilkepMentesUsingPOST(body: SzemelyProfilkepRequest, observe?: 'body', reportProgress?: boolean): Observable<Szemely>;
    public szemelyProfilkepMentesUsingPOST(body: SzemelyProfilkepRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Szemely>>;
    public szemelyProfilkepMentesUsingPOST(body: SzemelyProfilkepRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Szemely>>;
    public szemelyProfilkepMentesUsingPOST(body: SzemelyProfilkepRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szemelyProfilkepMentesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Szemely>('post',`${this.basePath}/api/mohosz/szemely/szemely-profilkep-mentes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szemelyProfilkepTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szemelyProfilkepTorlesUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<Szemely>;
    public szemelyProfilkepTorlesUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Szemely>>;
    public szemelyProfilkepTorlesUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Szemely>>;
    public szemelyProfilkepTorlesUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szemelyProfilkepTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Szemely>('post',`${this.basePath}/api/mohosz/szemely/szemely-profilkep-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szemelyProfilkepUpload
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szemelyProfilkepUploadUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public szemelyProfilkepUploadUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public szemelyProfilkepUploadUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public szemelyProfilkepUploadUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling szemelyProfilkepUploadUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/szemely/szemely-profilkep-upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szemelyTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szemelyTorlesUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public szemelyTorlesUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public szemelyTorlesUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public szemelyTorlesUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szemelyTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/szemely/szemely-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ugyintezoiAlapadatModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ugyintezoiAlapadatModositasUsingPOST(body: UgyintezoiSzemelyAlapadatRequest, observe?: 'body', reportProgress?: boolean): Observable<Szemely>;
    public ugyintezoiAlapadatModositasUsingPOST(body: UgyintezoiSzemelyAlapadatRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Szemely>>;
    public ugyintezoiAlapadatModositasUsingPOST(body: UgyintezoiSzemelyAlapadatRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Szemely>>;
    public ugyintezoiAlapadatModositasUsingPOST(body: UgyintezoiSzemelyAlapadatRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ugyintezoiAlapadatModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Szemely>('post',`${this.basePath}/api/mohosz/szemely/ugyintezoi-alapadat-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateSzemelyConsistency
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSzemelyConsistencyUsingPOST(body: BatchSzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateSzemelyConsistencyUsingPOST(body: BatchSzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateSzemelyConsistencyUsingPOST(body: BatchSzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateSzemelyConsistencyUsingPOST(body: BatchSzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateSzemelyConsistencyUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/szemely/update-consistency`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * valaszthatoSzervezetek
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public valaszthatoSzervezetekUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<SzervezetAdapter>>;
    public valaszthatoSzervezetekUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SzervezetAdapter>>>;
    public valaszthatoSzervezetekUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SzervezetAdapter>>>;
    public valaszthatoSzervezetekUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SzervezetAdapter>>('post',`${this.basePath}/api/mohosz/szemely/valaszthato-szervezetek`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
