/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Authority { 
    authority?: Authority.AuthorityEnum;
    hidden?: boolean;
    superAdmin?: boolean;
    szereploTipusSet?: Array<Authority.SzereploTipusSetEnum>;
}
export namespace Authority {
    export type AuthorityEnum = 'UGYFELSZOLGALATI_GODMODE' | 'SUPER_ADMIN' | 'ONLINE_HORGASZREGISZTRACIO' | 'UGYINTEZOI_HORGASZREGISZTRACIO' | 'UGYINTEZOI_JOVAHAGYAS' | 'UGYINTEZOI_FIZETESMGMT' | 'SZERVEZETI_JOGOSULTSAG_BEALLITAS' | 'UGYFELSZOLGALATI_JOVAHAGYAS' | 'UGYFELSZOLGALATI_FIZETESMGMT' | 'ADOKARTYA_UTOLAGOS_ELLENORZES' | 'UGYFELSZOLGALATI_JUTALEK' | 'UGYFELSZOLGALATI_HORGASZREGISZTRACIO' | 'UGYFELSZOLGALATI_TORZSADATMGMT' | 'UGYFELSZOLGALATI_USERMGMT' | 'UGYFELSZOLGALATI_ELTILTASMGMT' | 'ELTILTAS_FELTOLTES' | 'ERTEKESITESI_RIPORT' | 'FOGASNYILVATTARTAS_RIPORT' | 'FOGASINAPLOLEADAS_RIPORT' | 'EGYESULETITAGSAG_RIPORT' | 'TERULETIJEGY_RIPORT' | 'VIZSGABIZOTTSAG_RIPORT' | 'FORGALOM_RIPORT' | 'UZENET_MGMNT' | 'VIZTERULET_MGMT' | 'ELLENOR' | 'ELLENOR_MGMT' | 'ALLAMIJEGY_ARLISTA_MGMT' | 'ALLAMI_JEGY_ERTEKESITES' | 'ALLAMI_JEGY_ATADAS' | 'TAGDIJ_ARLISTA_MGMT' | 'TERULETI_JEGY_ERTEKESITES' | 'TERULETI_JEGY_TERMEK_MGMT' | 'VIZSGABIZOTTSAG_MGMT' | 'VIZSGABIZTOS' | 'SZERZODES_KOTES' | 'KESZLETEZO_MGMT' | 'FELADATSOR_MGMT' | 'HORGASZKARTYA_IGENYLES_MGMT' | 'DOKUMENTUMTAR_MGMT' | 'MAHOR_ELOFIZETES_MGMT' | 'VERSENY_MGMT' | 'PSYS_ADMIN' | 'TECHU' | 'SZOLGALTATASMGMT' | 'SZOLGALTATAS_FELUGYELO';
    export const AuthorityEnum = {
        UGYFELSZOLGALATIGODMODE: 'UGYFELSZOLGALATI_GODMODE' as AuthorityEnum,
        SUPERADMIN: 'SUPER_ADMIN' as AuthorityEnum,
        ONLINEHORGASZREGISZTRACIO: 'ONLINE_HORGASZREGISZTRACIO' as AuthorityEnum,
        UGYINTEZOIHORGASZREGISZTRACIO: 'UGYINTEZOI_HORGASZREGISZTRACIO' as AuthorityEnum,
        UGYINTEZOIJOVAHAGYAS: 'UGYINTEZOI_JOVAHAGYAS' as AuthorityEnum,
        UGYINTEZOIFIZETESMGMT: 'UGYINTEZOI_FIZETESMGMT' as AuthorityEnum,
        SZERVEZETIJOGOSULTSAGBEALLITAS: 'SZERVEZETI_JOGOSULTSAG_BEALLITAS' as AuthorityEnum,
        UGYFELSZOLGALATIJOVAHAGYAS: 'UGYFELSZOLGALATI_JOVAHAGYAS' as AuthorityEnum,
        UGYFELSZOLGALATIFIZETESMGMT: 'UGYFELSZOLGALATI_FIZETESMGMT' as AuthorityEnum,
        ADOKARTYAUTOLAGOSELLENORZES: 'ADOKARTYA_UTOLAGOS_ELLENORZES' as AuthorityEnum,
        UGYFELSZOLGALATIJUTALEK: 'UGYFELSZOLGALATI_JUTALEK' as AuthorityEnum,
        UGYFELSZOLGALATIHORGASZREGISZTRACIO: 'UGYFELSZOLGALATI_HORGASZREGISZTRACIO' as AuthorityEnum,
        UGYFELSZOLGALATITORZSADATMGMT: 'UGYFELSZOLGALATI_TORZSADATMGMT' as AuthorityEnum,
        UGYFELSZOLGALATIUSERMGMT: 'UGYFELSZOLGALATI_USERMGMT' as AuthorityEnum,
        UGYFELSZOLGALATIELTILTASMGMT: 'UGYFELSZOLGALATI_ELTILTASMGMT' as AuthorityEnum,
        ELTILTASFELTOLTES: 'ELTILTAS_FELTOLTES' as AuthorityEnum,
        ERTEKESITESIRIPORT: 'ERTEKESITESI_RIPORT' as AuthorityEnum,
        FOGASNYILVATTARTASRIPORT: 'FOGASNYILVATTARTAS_RIPORT' as AuthorityEnum,
        FOGASINAPLOLEADASRIPORT: 'FOGASINAPLOLEADAS_RIPORT' as AuthorityEnum,
        EGYESULETITAGSAGRIPORT: 'EGYESULETITAGSAG_RIPORT' as AuthorityEnum,
        TERULETIJEGYRIPORT: 'TERULETIJEGY_RIPORT' as AuthorityEnum,
        VIZSGABIZOTTSAGRIPORT: 'VIZSGABIZOTTSAG_RIPORT' as AuthorityEnum,
        FORGALOMRIPORT: 'FORGALOM_RIPORT' as AuthorityEnum,
        UZENETMGMNT: 'UZENET_MGMNT' as AuthorityEnum,
        VIZTERULETMGMT: 'VIZTERULET_MGMT' as AuthorityEnum,
        ELLENOR: 'ELLENOR' as AuthorityEnum,
        ELLENORMGMT: 'ELLENOR_MGMT' as AuthorityEnum,
        ALLAMIJEGYARLISTAMGMT: 'ALLAMIJEGY_ARLISTA_MGMT' as AuthorityEnum,
        ALLAMIJEGYERTEKESITES: 'ALLAMI_JEGY_ERTEKESITES' as AuthorityEnum,
        ALLAMIJEGYATADAS: 'ALLAMI_JEGY_ATADAS' as AuthorityEnum,
        TAGDIJARLISTAMGMT: 'TAGDIJ_ARLISTA_MGMT' as AuthorityEnum,
        TERULETIJEGYERTEKESITES: 'TERULETI_JEGY_ERTEKESITES' as AuthorityEnum,
        TERULETIJEGYTERMEKMGMT: 'TERULETI_JEGY_TERMEK_MGMT' as AuthorityEnum,
        VIZSGABIZOTTSAGMGMT: 'VIZSGABIZOTTSAG_MGMT' as AuthorityEnum,
        VIZSGABIZTOS: 'VIZSGABIZTOS' as AuthorityEnum,
        SZERZODESKOTES: 'SZERZODES_KOTES' as AuthorityEnum,
        KESZLETEZOMGMT: 'KESZLETEZO_MGMT' as AuthorityEnum,
        FELADATSORMGMT: 'FELADATSOR_MGMT' as AuthorityEnum,
        HORGASZKARTYAIGENYLESMGMT: 'HORGASZKARTYA_IGENYLES_MGMT' as AuthorityEnum,
        DOKUMENTUMTARMGMT: 'DOKUMENTUMTAR_MGMT' as AuthorityEnum,
        MAHORELOFIZETESMGMT: 'MAHOR_ELOFIZETES_MGMT' as AuthorityEnum,
        VERSENYMGMT: 'VERSENY_MGMT' as AuthorityEnum,
        PSYSADMIN: 'PSYS_ADMIN' as AuthorityEnum,
        TECHU: 'TECHU' as AuthorityEnum,
        SZOLGALTATASMGMT: 'SZOLGALTATASMGMT' as AuthorityEnum,
        SZOLGALTATASFELUGYELO: 'SZOLGALTATAS_FELUGYELO' as AuthorityEnum
    };
    export type SzereploTipusSetEnum = 'SZEMELY' | 'MOHOSZ' | 'TAGEGYESULET' | 'TAGSZOVETSEG' | 'BOLT' | 'HALGAZDALKODO' | 'ALTALANOS';
    export const SzereploTipusSetEnum = {
        SZEMELY: 'SZEMELY' as SzereploTipusSetEnum,
        MOHOSZ: 'MOHOSZ' as SzereploTipusSetEnum,
        TAGEGYESULET: 'TAGEGYESULET' as SzereploTipusSetEnum,
        TAGSZOVETSEG: 'TAGSZOVETSEG' as SzereploTipusSetEnum,
        BOLT: 'BOLT' as SzereploTipusSetEnum,
        HALGAZDALKODO: 'HALGAZDALKODO' as SzereploTipusSetEnum,
        ALTALANOS: 'ALTALANOS' as SzereploTipusSetEnum
    };
}