/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SzemelyEllenorzes } from './szemelyEllenorzes';
import { SzemelyJogszabalyiEltiltas } from './szemelyJogszabalyiEltiltas';
import { SzemelyVizteruletEltiltas } from './szemelyVizteruletEltiltas';

export interface EltiltasBundle { 
    ellenorzesiTevekenysegList?: Array<SzemelyEllenorzes>;
    jogszabalyiEltiltasList?: Array<SzemelyJogszabalyiEltiltas>;
    teruletiEltiltasList?: Array<SzemelyVizteruletEltiltas>;
}