/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SzemelyTulajdonsagok { 
    szemelyTulajdonsagList?: Array<SzemelyTulajdonsagok.SzemelyTulajdonsagListEnum>;
}
export namespace SzemelyTulajdonsagok {
    export type SzemelyTulajdonsagListEnum = 'HAS_FIZETES' | 'HAS_VASAROLTTERMEK' | 'HAS_TURSITAVIZSGA' | 'HAS_ALLAMIVIZSGA' | 'HAS_KARTYAGYARTASFOLYAMATBAN' | 'HAS_GONDVISELT' | 'HAS_MAHORELOFIZETES' | 'HAS_UGYINTEZOIMEGBIZAS' | 'HAS_SZABALYSERTES';
    export const SzemelyTulajdonsagListEnum = {
        FIZETES: 'HAS_FIZETES' as SzemelyTulajdonsagListEnum,
        VASAROLTTERMEK: 'HAS_VASAROLTTERMEK' as SzemelyTulajdonsagListEnum,
        TURSITAVIZSGA: 'HAS_TURSITAVIZSGA' as SzemelyTulajdonsagListEnum,
        ALLAMIVIZSGA: 'HAS_ALLAMIVIZSGA' as SzemelyTulajdonsagListEnum,
        KARTYAGYARTASFOLYAMATBAN: 'HAS_KARTYAGYARTASFOLYAMATBAN' as SzemelyTulajdonsagListEnum,
        GONDVISELT: 'HAS_GONDVISELT' as SzemelyTulajdonsagListEnum,
        MAHORELOFIZETES: 'HAS_MAHORELOFIZETES' as SzemelyTulajdonsagListEnum,
        UGYINTEZOIMEGBIZAS: 'HAS_UGYINTEZOIMEGBIZAS' as SzemelyTulajdonsagListEnum,
        SZABALYSERTES: 'HAS_SZABALYSERTES' as SzemelyTulajdonsagListEnum
    };
}