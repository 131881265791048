/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Versenyzo } from './versenyzo';

export interface VersenyEgyeniResztvevo { 
    availableCommands?: Array<VersenyEgyeniResztvevo.AvailableCommandsEnum>;
    versenyJelentkesStatusz?: VersenyEgyeniResztvevo.VersenyJelentkesStatuszEnum;
    versenyzo?: Versenyzo;
}
export namespace VersenyEgyeniResztvevo {
    export type AvailableCommandsEnum = 'TORLES' | 'FIZETES' | 'FIZETES_FOLYTATAS' | 'ATHELYEZES' | 'KAPCSOLATTARTAS_MODOSITAS' | 'MENTES';
    export const AvailableCommandsEnum = {
        TORLES: 'TORLES' as AvailableCommandsEnum,
        FIZETES: 'FIZETES' as AvailableCommandsEnum,
        FIZETESFOLYTATAS: 'FIZETES_FOLYTATAS' as AvailableCommandsEnum,
        ATHELYEZES: 'ATHELYEZES' as AvailableCommandsEnum,
        KAPCSOLATTARTASMODOSITAS: 'KAPCSOLATTARTAS_MODOSITAS' as AvailableCommandsEnum,
        MENTES: 'MENTES' as AvailableCommandsEnum
    };
    export type VersenyJelentkesStatuszEnum = 'FIZETESRE_VAR' | 'FIZETES_FOLYAMATBAN' | 'NEVEZETT' | 'BEFEJEZETT' | 'TOROLT';
    export const VersenyJelentkesStatuszEnum = {
        FIZETESREVAR: 'FIZETESRE_VAR' as VersenyJelentkesStatuszEnum,
        FIZETESFOLYAMATBAN: 'FIZETES_FOLYAMATBAN' as VersenyJelentkesStatuszEnum,
        NEVEZETT: 'NEVEZETT' as VersenyJelentkesStatuszEnum,
        BEFEJEZETT: 'BEFEJEZETT' as VersenyJelentkesStatuszEnum,
        TOROLT: 'TOROLT' as VersenyJelentkesStatuszEnum
    };
}