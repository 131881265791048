/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Cim } from './cim';

export interface PostaIntegracio { 
    exportDatum?: Date;
    exportUuid?: string;
    exportXlsId?: number;
    id?: number;
    importDatum?: Date;
    importXlsId?: number;
    letrehozasDatum?: Date;
    nev?: string;
    postaAzonosito?: string;
    postaStatusz?: PostaIntegracio.PostaStatuszEnum;
    szallitasiCim?: Cim;
    torolt?: boolean;
}
export namespace PostaIntegracio {
    export type PostaStatuszEnum = 'POSTARA_KULDENDO' | 'POSTARA_KULDVE' | 'POSTAN_BEDOLGOZOTT' | 'POSTAN_HIBAS' | 'POSTAN_TOROLVE';
    export const PostaStatuszEnum = {
        POSTARAKULDENDO: 'POSTARA_KULDENDO' as PostaStatuszEnum,
        POSTARAKULDVE: 'POSTARA_KULDVE' as PostaStatuszEnum,
        POSTANBEDOLGOZOTT: 'POSTAN_BEDOLGOZOTT' as PostaStatuszEnum,
        POSTANHIBAS: 'POSTAN_HIBAS' as PostaStatuszEnum,
        POSTANTOROLVE: 'POSTAN_TOROLVE' as PostaStatuszEnum
    };
}