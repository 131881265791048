/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Base64Content } from './base64Content';

export interface HorgaszturizmusPoiItem { 
    altalanosLeiras?: string;
    id?: number;
    kep?: Base64Content;
    megnevezes?: string;
    type?: HorgaszturizmusPoiItem.TypeEnum;
}
export namespace HorgaszturizmusPoiItem {
    export type TypeEnum = 'VIZTERULET' | 'BERBEADAS';
    export const TypeEnum = {
        VIZTERULET: 'VIZTERULET' as TypeEnum,
        BERBEADAS: 'BERBEADAS' as TypeEnum
    };
}