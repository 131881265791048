/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { SzervezetVizteruletCsoportRequest } from '../model/szervezetVizteruletCsoportRequest';
import { Vizterulet } from '../model/vizterulet';
import { VizteruletCsoport } from '../model/vizteruletCsoport';
import { VizteruletCsoportLeirasModositasRequest } from '../model/vizteruletCsoportLeirasModositasRequest';
import { VizteruletCsoportLetrehozasRequest } from '../model/vizteruletCsoportLetrehozasRequest';
import { VizteruletCsoportModositasRequest } from '../model/vizteruletCsoportModositasRequest';
import { VizteruletCsoportZarvatartasModositasRequest } from '../model/vizteruletCsoportZarvatartasModositasRequest';
import { VizteruletCsoportZarvatartasRogitesRequest } from '../model/vizteruletCsoportZarvatartasRogitesRequest';
import { VizteruletCsoportZarvatartasTorlesRequest } from '../model/vizteruletCsoportZarvatartasTorlesRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class VizteruletCsoportMgmtControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * kepUpload
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kepUploadUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public kepUploadUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public kepUploadUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public kepUploadUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling kepUploadUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/vizteruletcsoportmgmt/vizteruletcsoport-kep-upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * leirasFeltoltes
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leirasFeltoltesUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public leirasFeltoltesUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public leirasFeltoltesUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public leirasFeltoltesUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling leirasFeltoltesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/vizteruletcsoportmgmt/vizteruletcsoport-leiras-kepfeltoltes`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * leirasModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leirasModositasUsingPOST(body: VizteruletCsoportLeirasModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<VizteruletCsoport>;
    public leirasModositasUsingPOST(body: VizteruletCsoportLeirasModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizteruletCsoport>>;
    public leirasModositasUsingPOST(body: VizteruletCsoportLeirasModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizteruletCsoport>>;
    public leirasModositasUsingPOST(body: VizteruletCsoportLeirasModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling leirasModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizteruletCsoport>('post',`${this.basePath}/api/mohosz/vizteruletcsoportmgmt/vizteruletcsoport-leiras-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * letrehozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public letrehozasUsingPOST(body: VizteruletCsoportLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<VizteruletCsoport>;
    public letrehozasUsingPOST(body: VizteruletCsoportLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizteruletCsoport>>;
    public letrehozasUsingPOST(body: VizteruletCsoportLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizteruletCsoport>>;
    public letrehozasUsingPOST(body: VizteruletCsoportLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling letrehozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizteruletCsoport>('post',`${this.basePath}/api/mohosz/vizteruletcsoportmgmt/vizteruletcsoport-letrehozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listazas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listazasUsingPOST(body: SzervezetVizteruletCsoportRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<VizteruletCsoport>>;
    public listazasUsingPOST(body: SzervezetVizteruletCsoportRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VizteruletCsoport>>>;
    public listazasUsingPOST(body: SzervezetVizteruletCsoportRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VizteruletCsoport>>>;
    public listazasUsingPOST(body: SzervezetVizteruletCsoportRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling listazasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<VizteruletCsoport>>('post',`${this.basePath}/api/mohosz/vizteruletcsoportmgmt/vizteruletcsoport-listazas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * modositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modositasUsingPOST(body: VizteruletCsoportModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<VizteruletCsoport>;
    public modositasUsingPOST(body: VizteruletCsoportModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizteruletCsoport>>;
    public modositasUsingPOST(body: VizteruletCsoportModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizteruletCsoport>>;
    public modositasUsingPOST(body: VizteruletCsoportModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling modositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizteruletCsoport>('post',`${this.basePath}/api/mohosz/vizteruletcsoportmgmt/vizteruletcsoport-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * valaszthatoVizteruletek
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public valaszthatoVizteruletekUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<Vizterulet>>;
    public valaszthatoVizteruletekUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Vizterulet>>>;
    public valaszthatoVizteruletekUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Vizterulet>>>;
    public valaszthatoVizteruletekUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Vizterulet>>('post',`${this.basePath}/api/mohosz/vizteruletcsoportmgmt/vizteruletcsoport-valaszthato-vizteruletek`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * zarvatartasModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zarvatartasModositasUsingPOST(body: VizteruletCsoportZarvatartasModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<VizteruletCsoport>;
    public zarvatartasModositasUsingPOST(body: VizteruletCsoportZarvatartasModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizteruletCsoport>>;
    public zarvatartasModositasUsingPOST(body: VizteruletCsoportZarvatartasModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizteruletCsoport>>;
    public zarvatartasModositasUsingPOST(body: VizteruletCsoportZarvatartasModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling zarvatartasModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizteruletCsoport>('post',`${this.basePath}/api/mohosz/vizteruletcsoportmgmt/vizteruletcsoport-zarvatartas-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * zarvatartasRogzites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zarvatartasRogzitesUsingPOST(body: VizteruletCsoportZarvatartasRogitesRequest, observe?: 'body', reportProgress?: boolean): Observable<VizteruletCsoport>;
    public zarvatartasRogzitesUsingPOST(body: VizteruletCsoportZarvatartasRogitesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizteruletCsoport>>;
    public zarvatartasRogzitesUsingPOST(body: VizteruletCsoportZarvatartasRogitesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizteruletCsoport>>;
    public zarvatartasRogzitesUsingPOST(body: VizteruletCsoportZarvatartasRogitesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling zarvatartasRogzitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizteruletCsoport>('post',`${this.basePath}/api/mohosz/vizteruletcsoportmgmt/vizteruletcsoport-zarvatartas-rogzites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * zarvatartasTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zarvatartasTorlesUsingPOST(body: VizteruletCsoportZarvatartasTorlesRequest, observe?: 'body', reportProgress?: boolean): Observable<VizteruletCsoport>;
    public zarvatartasTorlesUsingPOST(body: VizteruletCsoportZarvatartasTorlesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizteruletCsoport>>;
    public zarvatartasTorlesUsingPOST(body: VizteruletCsoportZarvatartasTorlesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizteruletCsoport>>;
    public zarvatartasTorlesUsingPOST(body: VizteruletCsoportZarvatartasTorlesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling zarvatartasTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizteruletCsoport>('post',`${this.basePath}/api/mohosz/vizteruletcsoportmgmt/vizteruletcsoport-zarvatartas-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
