/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Horgasz { 
    allamiJegyErvenyessegKezdete?: string;
    allamiJegyErvenyessegVege?: string;
    allamiJegySorszam?: string;
    hazszam?: string;
    horgaszAzonosito?: string;
    iranyitoszam?: string;
    keresztnev?: string;
    kozteruletNev?: string;
    kozteruletTipus?: string;
    orszagKod?: string;
    telepules?: string;
    vezeteknev?: string;
}