/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CsapatAzonositoGeneralasReply } from '../model/csapatAzonositoGeneralasReply';
import { CsapatLetrehozasRequest } from '../model/csapatLetrehozasRequest';
import { CsapatTagHozzaadasRequest } from '../model/csapatTagHozzaadasRequest';
import { CsapatTagTorlesRequest } from '../model/csapatTagTorlesRequest';
import { JelentkezesEllenorzesReply } from '../model/jelentkezesEllenorzesReply';
import { JelentkezesEllenorzesRequest } from '../model/jelentkezesEllenorzesRequest';
import { KosarFizetes } from '../model/kosarFizetes';
import { OnlinePenztar } from '../model/onlinePenztar';
import { SzemelyRequest } from '../model/szemelyRequest';
import { SzemelyVerseny } from '../model/szemelyVerseny';
import { SzemelyVersenyRequest } from '../model/szemelyVersenyRequest';
import { Verseny } from '../model/verseny';
import { VersenyJelentkezesRequest } from '../model/versenyJelentkezesRequest';
import { VersenyKapcsolattartasModositasRequest } from '../model/versenyKapcsolattartasModositasRequest';
import { VersenyNevezesFilter } from '../model/versenyNevezesFilter';
import { VersenyNevezesFizetesInditasRequest } from '../model/versenyNevezesFizetesInditasRequest';
import { VersenyRequest } from '../model/versenyRequest';
import { VersenyUrlReply } from '../model/versenyUrlReply';
import { VersenyUrlRequest } from '../model/versenyUrlRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class VersenyNevezesControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * csapatLetrehozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public csapatLetrehozasUsingPOST(body: CsapatLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public csapatLetrehozasUsingPOST(body: CsapatLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public csapatLetrehozasUsingPOST(body: CsapatLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public csapatLetrehozasUsingPOST(body: CsapatLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling csapatLetrehozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenynevezes/csapat-letrehozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * csapatTagHozzaadas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public csapatTagHozzaadasUsingPOST(body: CsapatTagHozzaadasRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public csapatTagHozzaadasUsingPOST(body: CsapatTagHozzaadasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public csapatTagHozzaadasUsingPOST(body: CsapatTagHozzaadasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public csapatTagHozzaadasUsingPOST(body: CsapatTagHozzaadasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling csapatTagHozzaadasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenynevezes/csapat-tag-hozzaadas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * csapatTagTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public csapatTagTorlesUsingPOST(body: CsapatTagTorlesRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public csapatTagTorlesUsingPOST(body: CsapatTagTorlesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public csapatTagTorlesUsingPOST(body: CsapatTagTorlesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public csapatTagTorlesUsingPOST(body: CsapatTagTorlesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling csapatTagTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenynevezes/csapat-tag-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fizetesInditas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fizetesInditasUsingPOST(body: SzemelyVersenyRequest, observe?: 'body', reportProgress?: boolean): Observable<KosarFizetes>;
    public fizetesInditasUsingPOST(body: SzemelyVersenyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KosarFizetes>>;
    public fizetesInditasUsingPOST(body: SzemelyVersenyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KosarFizetes>>;
    public fizetesInditasUsingPOST(body: SzemelyVersenyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fizetesInditasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<KosarFizetes>('post',`${this.basePath}/api/mohosz/versenynevezes/fizetes-inditas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * jelentkezesEllenorzes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public jelentkezesEllenorzesUsingPOST(body: JelentkezesEllenorzesRequest, observe?: 'body', reportProgress?: boolean): Observable<JelentkezesEllenorzesReply>;
    public jelentkezesEllenorzesUsingPOST(body: JelentkezesEllenorzesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<JelentkezesEllenorzesReply>>;
    public jelentkezesEllenorzesUsingPOST(body: JelentkezesEllenorzesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<JelentkezesEllenorzesReply>>;
    public jelentkezesEllenorzesUsingPOST(body: JelentkezesEllenorzesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling jelentkezesEllenorzesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<JelentkezesEllenorzesReply>('post',`${this.basePath}/api/mohosz/versenynevezes/jelentkezes-ellenorzes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * jelentkezes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public jelentkezesUsingPOST(body: VersenyJelentkezesRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public jelentkezesUsingPOST(body: VersenyJelentkezesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public jelentkezesUsingPOST(body: VersenyJelentkezesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public jelentkezesUsingPOST(body: VersenyJelentkezesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling jelentkezesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenynevezes/jelentkezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kapcsolattartasModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kapcsolattartasModositasUsingPOST(body: VersenyKapcsolattartasModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public kapcsolattartasModositasUsingPOST(body: VersenyKapcsolattartasModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public kapcsolattartasModositasUsingPOST(body: VersenyKapcsolattartasModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public kapcsolattartasModositasUsingPOST(body: VersenyKapcsolattartasModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kapcsolattartasModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenynevezes/kapcsolattartas-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listazas
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listazasUsingPOST(body: VersenyNevezesFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<Verseny>>;
    public listazasUsingPOST(body: VersenyNevezesFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Verseny>>>;
    public listazasUsingPOST(body: VersenyNevezesFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Verseny>>>;
    public listazasUsingPOST(body: VersenyNevezesFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling listazasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<Verseny>>('post',`${this.basePath}/api/mohosz/versenynevezes/verseny-listazas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * nextAzonosito
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public nextAzonositoUsingPOST(body: VersenyRequest, observe?: 'body', reportProgress?: boolean): Observable<CsapatAzonositoGeneralasReply>;
    public nextAzonositoUsingPOST(body: VersenyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CsapatAzonositoGeneralasReply>>;
    public nextAzonositoUsingPOST(body: VersenyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CsapatAzonositoGeneralasReply>>;
    public nextAzonositoUsingPOST(body: VersenyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling nextAzonositoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CsapatAzonositoGeneralasReply>('post',`${this.basePath}/api/mohosz/versenynevezes/azonosito-generalas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * offlineFizetes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offlineFizetesUsingPOST(body: SzemelyVersenyRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public offlineFizetesUsingPOST(body: SzemelyVersenyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public offlineFizetesUsingPOST(body: SzemelyVersenyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public offlineFizetesUsingPOST(body: SzemelyVersenyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling offlineFizetesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenynevezes/offline-fizetes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * onlinePenztarVasarlasInditas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public onlinePenztarVasarlasInditasUsingPOST(body: VersenyNevezesFizetesInditasRequest, observe?: 'body', reportProgress?: boolean): Observable<OnlinePenztar>;
    public onlinePenztarVasarlasInditasUsingPOST(body: VersenyNevezesFizetesInditasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OnlinePenztar>>;
    public onlinePenztarVasarlasInditasUsingPOST(body: VersenyNevezesFizetesInditasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OnlinePenztar>>;
    public onlinePenztarVasarlasInditasUsingPOST(body: VersenyNevezesFizetesInditasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling onlinePenztarVasarlasInditasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OnlinePenztar>('post',`${this.basePath}/api/mohosz/versenynevezes/fizetes-onlinepenztar-inditas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szamossag
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szamossagUsingPOST(body: VersenyNevezesFilter, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public szamossagUsingPOST(body: VersenyNevezesFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public szamossagUsingPOST(body: VersenyNevezesFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public szamossagUsingPOST(body: VersenyNevezesFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szamossagUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/versenynevezes/verseny-szamossag`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szemelyVersenyJelentkezesek
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szemelyVersenyJelentkezesekUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<SzemelyVerseny>>;
    public szemelyVersenyJelentkezesekUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SzemelyVerseny>>>;
    public szemelyVersenyJelentkezesekUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SzemelyVerseny>>>;
    public szemelyVersenyJelentkezesekUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szemelyVersenyJelentkezesekUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<SzemelyVerseny>>('post',`${this.basePath}/api/mohosz/versenynevezes/szemely-jelentkezesek`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szemelyVerseny
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szemelyVersenyUsingPOST(body: SzemelyVersenyRequest, observe?: 'body', reportProgress?: boolean): Observable<SzemelyVerseny>;
    public szemelyVersenyUsingPOST(body: SzemelyVersenyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SzemelyVerseny>>;
    public szemelyVersenyUsingPOST(body: SzemelyVersenyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SzemelyVerseny>>;
    public szemelyVersenyUsingPOST(body: SzemelyVersenyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szemelyVersenyUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SzemelyVerseny>('post',`${this.basePath}/api/mohosz/versenynevezes/szemely-verseny`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * versenyUrlKereso
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public versenyUrlKeresoUsingPOST(body: VersenyUrlRequest, observe?: 'body', reportProgress?: boolean): Observable<VersenyUrlReply>;
    public versenyUrlKeresoUsingPOST(body: VersenyUrlRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersenyUrlReply>>;
    public versenyUrlKeresoUsingPOST(body: VersenyUrlRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersenyUrlReply>>;
    public versenyUrlKeresoUsingPOST(body: VersenyUrlRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling versenyUrlKeresoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersenyUrlReply>('post',`${this.basePath}/api/mohosz/versenynevezes/verseny-url-kereso`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
