/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { VersenyDetails } from './versenyDetails';

export interface Verseny { 
    availableCommands?: Array<Verseny.AvailableCommandsEnum>;
    azonosito?: string;
    details?: VersenyDetails;
    id?: number;
    jelentkezesKezdeteIdopont?: string;
    jelentkezesVegeIdopont?: string;
    kezdesIdopont?: Date;
    letszam?: number;
    maxLetszam?: number;
    nev?: string;
    statusz?: Verseny.StatuszEnum;
    vanUresHely?: boolean;
    vegeIdopont?: Date;
}
export namespace Verseny {
    export type AvailableCommandsEnum = 'MEGTEKINTES' | 'MODOSITAS' | 'TORLES' | 'JELENTKEZES' | 'DIJMODOSITAS';
    export const AvailableCommandsEnum = {
        MEGTEKINTES: 'MEGTEKINTES' as AvailableCommandsEnum,
        MODOSITAS: 'MODOSITAS' as AvailableCommandsEnum,
        TORLES: 'TORLES' as AvailableCommandsEnum,
        JELENTKEZES: 'JELENTKEZES' as AvailableCommandsEnum,
        DIJMODOSITAS: 'DIJMODOSITAS' as AvailableCommandsEnum
    };
    export type StatuszEnum = 'TERVEZETT' | 'JELENTKEZES_IDO_ALATT' | 'LEZART_JELENTKEZES' | 'LEZART_VERSENY';
    export const StatuszEnum = {
        TERVEZETT: 'TERVEZETT' as StatuszEnum,
        JELENTKEZESIDOALATT: 'JELENTKEZES_IDO_ALATT' as StatuszEnum,
        LEZARTJELENTKEZES: 'LEZART_JELENTKEZES' as StatuszEnum,
        LEZARTVERSENY: 'LEZART_VERSENY' as StatuszEnum
    };
}