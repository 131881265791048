/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Horgasz } from './horgasz';

export interface HorgaszInfoReply { 
    hibakodList?: Array<HorgaszInfoReply.HibakodListEnum>;
    horgasz?: Horgasz;
}
export namespace HorgaszInfoReply {
    export type HibakodListEnum = 'NEMLETEZO_HORGASZAZONOSITO' | 'NEMELO_HORGASZ' | 'NINCS_ERVENYES_ALLAMIJEGY' | 'NEMEGYEZO_ALLAMIJEGYSORSZAM' | 'ELTILTAS_ALATT';
    export const HibakodListEnum = {
        NEMLETEZOHORGASZAZONOSITO: 'NEMLETEZO_HORGASZAZONOSITO' as HibakodListEnum,
        NEMELOHORGASZ: 'NEMELO_HORGASZ' as HibakodListEnum,
        NINCSERVENYESALLAMIJEGY: 'NINCS_ERVENYES_ALLAMIJEGY' as HibakodListEnum,
        NEMEGYEZOALLAMIJEGYSORSZAM: 'NEMEGYEZO_ALLAMIJEGYSORSZAM' as HibakodListEnum,
        ELTILTASALATT: 'ELTILTAS_ALATT' as HibakodListEnum
    };
}