/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Cim } from './cim';
import { GPSKoordinata } from './gPSKoordinata';

export interface SzervezetModositasRequest { 
    adoszam?: string;
    alkalmazottakSzama?: number;
    birosagiNyilvantartasiSzam?: string;
    egyediKod?: string;
    id?: number;
    kapcsolattartoEmail?: string;
    kapcsolattartoNev?: string;
    kapcsolattartoTelefon?: string;
    nev?: string;
    postazasiCim?: Cim;
    postazasiCimSzekhelyCimMegegyezik?: boolean;
    szekhelyCim?: Cim;
    szekhelyGPSKoordinata?: GPSKoordinata;
    szervezetiForma?: SzervezetModositasRequest.SzervezetiFormaEnum;
    szolgaltatoOwnerSzemelyId?: number;
    tipus?: SzervezetModositasRequest.TipusEnum;
    torvenyesKepviseloBeosztas?: string;
    torvenyesKepviseloEmail?: string;
    torvenyesKepviseloNev?: string;
    torvenyesKepviseloTelefon?: string;
}
export namespace SzervezetModositasRequest {
    export type SzervezetiFormaEnum = 'KORLATOLTFELELOSSEGUTARSASAG' | 'RESZVENYTARSASAG' | 'BETETITARSASAG' | 'SZOVETKEZET' | 'NONPROFITGAZDASAGITARSASAG' | 'EGYEBJOGISZEMELYISEGUVALLALKOZAS' | 'EGYENICEG' | 'EGYENIVALLALKOZO' | 'ADOSZAMMALRENDELKEZOMAGANSZEMELY' | 'ALAPITVANY' | 'SZOVETSEG' | 'EGYESULET' | 'EGYEBNONPROFITSZERVEZET' | 'KOLTSEGVETESISZERV';
    export const SzervezetiFormaEnum = {
        KORLATOLTFELELOSSEGUTARSASAG: 'KORLATOLTFELELOSSEGUTARSASAG' as SzervezetiFormaEnum,
        RESZVENYTARSASAG: 'RESZVENYTARSASAG' as SzervezetiFormaEnum,
        BETETITARSASAG: 'BETETITARSASAG' as SzervezetiFormaEnum,
        SZOVETKEZET: 'SZOVETKEZET' as SzervezetiFormaEnum,
        NONPROFITGAZDASAGITARSASAG: 'NONPROFITGAZDASAGITARSASAG' as SzervezetiFormaEnum,
        EGYEBJOGISZEMELYISEGUVALLALKOZAS: 'EGYEBJOGISZEMELYISEGUVALLALKOZAS' as SzervezetiFormaEnum,
        EGYENICEG: 'EGYENICEG' as SzervezetiFormaEnum,
        EGYENIVALLALKOZO: 'EGYENIVALLALKOZO' as SzervezetiFormaEnum,
        ADOSZAMMALRENDELKEZOMAGANSZEMELY: 'ADOSZAMMALRENDELKEZOMAGANSZEMELY' as SzervezetiFormaEnum,
        ALAPITVANY: 'ALAPITVANY' as SzervezetiFormaEnum,
        SZOVETSEG: 'SZOVETSEG' as SzervezetiFormaEnum,
        EGYESULET: 'EGYESULET' as SzervezetiFormaEnum,
        EGYEBNONPROFITSZERVEZET: 'EGYEBNONPROFITSZERVEZET' as SzervezetiFormaEnum,
        KOLTSEGVETESISZERV: 'KOLTSEGVETESISZERV' as SzervezetiFormaEnum
    };
    export type TipusEnum = 'SZEMELY' | 'MOHOSZ' | 'TAGEGYESULET' | 'TAGSZOVETSEG' | 'BOLT' | 'HALGAZDALKODO' | 'ALTALANOS';
    export const TipusEnum = {
        SZEMELY: 'SZEMELY' as TipusEnum,
        MOHOSZ: 'MOHOSZ' as TipusEnum,
        TAGEGYESULET: 'TAGEGYESULET' as TipusEnum,
        TAGSZOVETSEG: 'TAGSZOVETSEG' as TipusEnum,
        BOLT: 'BOLT' as TipusEnum,
        HALGAZDALKODO: 'HALGAZDALKODO' as TipusEnum,
        ALTALANOS: 'ALTALANOS' as TipusEnum
    };
}