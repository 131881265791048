/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AllamiVizsga } from './allamiVizsga';
import { HorgaszvizsgaIgazoloDokumentum } from './horgaszvizsgaIgazoloDokumentum';
import { VizsgaElozmeny } from './vizsgaElozmeny';

export interface OfflineVizsgaOsszesito { 
    allamiVizsgaList?: Array<AllamiVizsga>;
    horgaszvizsgaIgazoloDokumentum?: HorgaszvizsgaIgazoloDokumentum;
    horgaszvizsgaIgazolva?: boolean;
    igazoltHorgaszvizsgaSzam?: string;
    offlineVizsgaElozmeny?: Array<VizsgaElozmeny>;
    sikeresAllamiVizsga?: VizsgaElozmeny;
    sikeresTuristaVizsgaList?: Array<VizsgaElozmeny>;
    szemelyId?: number;
    turistaVizsgaLimitElerve?: boolean;
    vizsgaElozmenyList?: Array<VizsgaElozmeny>;
}