/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ValaszOpcio } from './valaszOpcio';

export interface KerdesFlatBean { 
    checkBoxValasz?: Array<number>;
    datumValasz?: string;
    id?: number;
    igazHamisValasz?: boolean;
    kepId?: number;
    kerdesTipus?: KerdesFlatBean.KerdesTipusEnum;
    radioGombValasz?: number;
    szamValasz?: string;
    szoveg?: string;
    valaszOpciok?: Array<ValaszOpcio>;
}
export namespace KerdesFlatBean {
    export type KerdesTipusEnum = 'RADIOGOMB' | 'CHECKBOX' | 'DATUM' | 'SZAM' | 'IGAZHAMIS';
    export const KerdesTipusEnum = {
        RADIOGOMB: 'RADIOGOMB' as KerdesTipusEnum,
        CHECKBOX: 'CHECKBOX' as KerdesTipusEnum,
        DATUM: 'DATUM' as KerdesTipusEnum,
        SZAM: 'SZAM' as KerdesTipusEnum,
        IGAZHAMIS: 'IGAZHAMIS' as KerdesTipusEnum
    };
}