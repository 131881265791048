/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { KozvetlenErtekesitoArres } from './kozvetlenErtekesitoArres';

export interface TeruletiJegyKozvetlenJutalek { 
    defaultKozvetlenErtekesitoArres?: number;
    defaultKozvetlenErtekesitoArresMaximum?: number;
    defaultKozvetlenErtekesitoArresMinimum?: number;
    kozvetlenErtekesitoArresList?: Array<KozvetlenErtekesitoArres>;
    termekSzervezetId?: number;
}