/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TeruletiJegyOnlineSztornoDetails } from './teruletiJegyOnlineSztornoDetails';
import { TeruletiKiegeszitoJegy } from './teruletiKiegeszitoJegy';

export interface TeruletiJegy { 
    availableCommands?: Array<TeruletiJegy.AvailableCommandsEnum>;
    botokSzama?: number;
    ertekesitesEv?: number;
    ertekesitesTipus?: TeruletiJegy.ErtekesitesTipusEnum;
    ertekesitoSzervezetId?: number;
    ervenyes?: boolean;
    ervenyesStatusz?: boolean;
    ervenyessegKezdete?: Date;
    ervenyessegVege?: Date;
    fizetesTipus?: TeruletiJegy.FizetesTipusEnum;
    hghJegysorszam?: string;
    idoszak?: TeruletiJegy.IdoszakEnum;
    jogcim?: TeruletiJegy.JogcimEnum;
    jogcimCsoport?: TeruletiJegy.JogcimCsoportEnum;
    kiegeszitoJegyIdIndex?: number;
    megvasaroltStatusz?: boolean;
    onlineSztornozasList?: Array<TeruletiJegyOnlineSztornoDetails>;
    sorszam?: string;
    statusz?: TeruletiJegy.StatuszEnum;
    sztornozasDatuma?: Date;
    teruletiJegyTermekId?: number;
    teruletiJegyTermekNev?: string;
    teruletiJegyTermekSzervezetId?: number;
    teruletiKiegeszitoJegyList?: Array<TeruletiKiegeszitoJegy>;
    vasarlasDatuma?: Date;
    vizteruletCsoportId?: number;
}
export namespace TeruletiJegy {
    export type AvailableCommandsEnum = 'SZTORNO' | 'RKSZTORNO' | 'NYOMTATAS' | 'VISSZAVONASROLLBACK';
    export const AvailableCommandsEnum = {
        SZTORNO: 'SZTORNO' as AvailableCommandsEnum,
        RKSZTORNO: 'RKSZTORNO' as AvailableCommandsEnum,
        NYOMTATAS: 'NYOMTATAS' as AvailableCommandsEnum,
        VISSZAVONASROLLBACK: 'VISSZAVONASROLLBACK' as AvailableCommandsEnum
    };
    export type ErtekesitesTipusEnum = 'ONLINE' | 'SAJAT' | 'KOZVETLEN' | 'VISZONT';
    export const ErtekesitesTipusEnum = {
        ONLINE: 'ONLINE' as ErtekesitesTipusEnum,
        SAJAT: 'SAJAT' as ErtekesitesTipusEnum,
        KOZVETLEN: 'KOZVETLEN' as ErtekesitesTipusEnum,
        VISZONT: 'VISZONT' as ErtekesitesTipusEnum
    };
    export type FizetesTipusEnum = 'KH' | 'CSEKKES' | 'ERTEKESITESIPONT' | 'DIJMENTES' | 'SZEPKH' | 'RK' | 'OTPSZEP' | 'MKBSZEP';
    export const FizetesTipusEnum = {
        KH: 'KH' as FizetesTipusEnum,
        CSEKKES: 'CSEKKES' as FizetesTipusEnum,
        ERTEKESITESIPONT: 'ERTEKESITESIPONT' as FizetesTipusEnum,
        DIJMENTES: 'DIJMENTES' as FizetesTipusEnum,
        SZEPKH: 'SZEPKH' as FizetesTipusEnum,
        RK: 'RK' as FizetesTipusEnum,
        OTPSZEP: 'OTPSZEP' as FizetesTipusEnum,
        MKBSZEP: 'MKBSZEP' as FizetesTipusEnum
    };
    export type IdoszakEnum = 'EVES' | 'EVES_RESZLET1' | 'EVES_RESZLET2' | 'FELEVES' | 'NAP10' | 'NAP7' | 'ORA72' | 'NAP3' | 'ORA48' | 'NAP2' | 'ORA24' | 'NAPI' | 'EJSZAKAI';
    export const IdoszakEnum = {
        EVES: 'EVES' as IdoszakEnum,
        EVESRESZLET1: 'EVES_RESZLET1' as IdoszakEnum,
        EVESRESZLET2: 'EVES_RESZLET2' as IdoszakEnum,
        FELEVES: 'FELEVES' as IdoszakEnum,
        NAP10: 'NAP10' as IdoszakEnum,
        NAP7: 'NAP7' as IdoszakEnum,
        ORA72: 'ORA72' as IdoszakEnum,
        NAP3: 'NAP3' as IdoszakEnum,
        ORA48: 'ORA48' as IdoszakEnum,
        NAP2: 'NAP2' as IdoszakEnum,
        ORA24: 'ORA24' as IdoszakEnum,
        NAPI: 'NAPI' as IdoszakEnum,
        EJSZAKAI: 'EJSZAKAI' as IdoszakEnum
    };
    export type JogcimEnum = 'GYERMEK' | 'GYERMEK_USZOS' | 'IFJUSAGI' | 'IFJUSAGI_EMELTARAS' | 'IFJUSAGI_TURISTA' | 'IFJUSAGI_KULFOLDI' | 'FELNOTT' | 'FELNOTT_KEDVEZMENYEZETT' | 'FELNOTT_KORKEDVEZMENYEZETT' | 'FELNOTT_KEDVEZMENYEZETT_1BOTOS' | 'FELNOTT_EMELTARAS' | 'FELNOTT_TURISTA' | 'FELNOTT_KULFOLDI' | 'FELNOTT_MENTESITETT' | 'IFJUSAGI_IHJ' | 'FELNOTT_IHJ' | 'FELNOTT_JOGFENNTARTO_IHJ' | 'TISZTSEGVISELOI' | 'SZOLGALATI' | 'BONUSZ';
    export const JogcimEnum = {
        GYERMEK: 'GYERMEK' as JogcimEnum,
        GYERMEKUSZOS: 'GYERMEK_USZOS' as JogcimEnum,
        IFJUSAGI: 'IFJUSAGI' as JogcimEnum,
        IFJUSAGIEMELTARAS: 'IFJUSAGI_EMELTARAS' as JogcimEnum,
        IFJUSAGITURISTA: 'IFJUSAGI_TURISTA' as JogcimEnum,
        IFJUSAGIKULFOLDI: 'IFJUSAGI_KULFOLDI' as JogcimEnum,
        FELNOTT: 'FELNOTT' as JogcimEnum,
        FELNOTTKEDVEZMENYEZETT: 'FELNOTT_KEDVEZMENYEZETT' as JogcimEnum,
        FELNOTTKORKEDVEZMENYEZETT: 'FELNOTT_KORKEDVEZMENYEZETT' as JogcimEnum,
        FELNOTTKEDVEZMENYEZETT1BOTOS: 'FELNOTT_KEDVEZMENYEZETT_1BOTOS' as JogcimEnum,
        FELNOTTEMELTARAS: 'FELNOTT_EMELTARAS' as JogcimEnum,
        FELNOTTTURISTA: 'FELNOTT_TURISTA' as JogcimEnum,
        FELNOTTKULFOLDI: 'FELNOTT_KULFOLDI' as JogcimEnum,
        FELNOTTMENTESITETT: 'FELNOTT_MENTESITETT' as JogcimEnum,
        IFJUSAGIIHJ: 'IFJUSAGI_IHJ' as JogcimEnum,
        FELNOTTIHJ: 'FELNOTT_IHJ' as JogcimEnum,
        FELNOTTJOGFENNTARTOIHJ: 'FELNOTT_JOGFENNTARTO_IHJ' as JogcimEnum,
        TISZTSEGVISELOI: 'TISZTSEGVISELOI' as JogcimEnum,
        SZOLGALATI: 'SZOLGALATI' as JogcimEnum,
        BONUSZ: 'BONUSZ' as JogcimEnum
    };
    export type JogcimCsoportEnum = 'ALTALANOS' | 'PARTI' | 'BOJLIS' | 'SPORTCELU';
    export const JogcimCsoportEnum = {
        ALTALANOS: 'ALTALANOS' as JogcimCsoportEnum,
        PARTI: 'PARTI' as JogcimCsoportEnum,
        BOJLIS: 'BOJLIS' as JogcimCsoportEnum,
        SPORTCELU: 'SPORTCELU' as JogcimCsoportEnum
    };
    export type StatuszEnum = 'MEGVASAROLT' | 'ERVENYES' | 'LEJART' | 'VISSZAVONT' | 'SZTORNOZOTT' | 'RKSZTORNOZOTT';
    export const StatuszEnum = {
        MEGVASAROLT: 'MEGVASAROLT' as StatuszEnum,
        ERVENYES: 'ERVENYES' as StatuszEnum,
        LEJART: 'LEJART' as StatuszEnum,
        VISSZAVONT: 'VISSZAVONT' as StatuszEnum,
        SZTORNOZOTT: 'SZTORNOZOTT' as StatuszEnum,
        RKSZTORNOZOTT: 'RKSZTORNOZOTT' as StatuszEnum
    };
}