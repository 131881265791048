/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PostaExportRequest { 
    ev?: number;
    honap?: number;
    kezbesitesTipus?: PostaExportRequest.KezbesitesTipusEnum;
}
export namespace PostaExportRequest {
    export type KezbesitesTipusEnum = 'LISTAS' | 'CIMZETT';
    export const KezbesitesTipusEnum = {
        LISTAS: 'LISTAS' as KezbesitesTipusEnum,
        CIMZETT: 'CIMZETT' as KezbesitesTipusEnum
    };
}