/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SzemelyJegy } from './szemelyJegy';

export interface SzemelyDetails { 
    csekkesFizetesIdList?: Array<string>;
    egyesuletiTagsagIdList?: Array<number>;
    ellenorkentTevekenykedik?: boolean;
    eredetiHorgaszAzonosito?: string;
    horgaszAzonosito?: string;
    jegyList?: Array<SzemelyJegy>;
    kepesitesTipusList?: Array<SzemelyDetails.KepesitesTipusListEnum>;
    loginId?: string;
    szemelyContentIds?: Array<number>;
}
export namespace SzemelyDetails {
    export type KepesitesTipusListEnum = 'HALGAZDALKODASIFELSOFOKU' | 'HALORIVIZSGA';
    export const KepesitesTipusListEnum = {
        HALGAZDALKODASIFELSOFOKU: 'HALGAZDALKODASIFELSOFOKU' as KepesitesTipusListEnum,
        HALORIVIZSGA: 'HALORIVIZSGA' as KepesitesTipusListEnum
    };
}