/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FogasiNaploRiportDetails } from './fogasiNaploRiportDetails';

export interface FogasiNaploRiport { 
    allamiJegyTipus?: FogasiNaploRiport.AllamiJegyTipusEnum;
    details?: FogasiNaploRiportDetails;
    ervenyessegiEv?: number;
    horgaszSzemelyId?: number;
    leadva?: boolean;
    rogzitve?: boolean;
    sorszam?: string;
    statusz?: FogasiNaploRiport.StatuszEnum;
}
export namespace FogasiNaploRiport {
    export type AllamiJegyTipusEnum = 'TURISTA_ALLAMIJEGY' | 'ALLAMIJEGY';
    export const AllamiJegyTipusEnum = {
        TURISTAALLAMIJEGY: 'TURISTA_ALLAMIJEGY' as AllamiJegyTipusEnum,
        ALLAMIJEGY: 'ALLAMIJEGY' as AllamiJegyTipusEnum
    };
    export type StatuszEnum = 'KIADOTT' | 'IDOBEN_LEADVA' | 'KESON_LEADVA' | 'BETELT' | 'VISSZAVONT' | 'ELVESZETT' | 'MEGSEMMISULT';
    export const StatuszEnum = {
        KIADOTT: 'KIADOTT' as StatuszEnum,
        IDOBENLEADVA: 'IDOBEN_LEADVA' as StatuszEnum,
        KESONLEADVA: 'KESON_LEADVA' as StatuszEnum,
        BETELT: 'BETELT' as StatuszEnum,
        VISSZAVONT: 'VISSZAVONT' as StatuszEnum,
        ELVESZETT: 'ELVESZETT' as StatuszEnum,
        MEGSEMMISULT: 'MEGSEMMISULT' as StatuszEnum
    };
}