/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FogasiRekord } from './fogasiRekord';
import { Halallomany } from './halallomany';
import { HorgasztatasSzolgaltatasVegrehajtottLepes } from './horgasztatasSzolgaltatasVegrehajtottLepes';
import { HorgasztatasZarvatartas } from './horgasztatasZarvatartas';
import { SzolgaltatasRegisztracioBiralat } from './szolgaltatasRegisztracioBiralat';
import { VedettVizterulet } from './vedettVizterulet';
import { VizteruletCsoportLeiras } from './vizteruletCsoportLeiras';

export interface HorgasztatasSzolgaltatasRegisztracio { 
    aktualisLepes?: HorgasztatasSzolgaltatasRegisztracio.AktualisLepesEnum;
    altalanosLeiras?: string;
    egyediAzonosito?: string;
    ellenorTelefonszam?: string;
    elutasitasList?: Array<SzolgaltatasRegisztracioBiralat>;
    felszereltsegList?: Array<HorgasztatasSzolgaltatasRegisztracio.FelszereltsegListEnum>;
    fenntartoEmail?: string;
    fenntartoTelefonszam?: string;
    fogasiRekordList?: Array<FogasiRekord>;
    halallomanyList?: Array<Halallomany>;
    helyrajziSzam?: string;
    horgaszmodszerAltipusList?: Array<HorgasztatasSzolgaltatasRegisztracio.HorgaszmodszerAltipusListEnum>;
    horgaszrendContentId?: number;
    hosszusagiFok?: number;
    ismertNev?: string;
    jovahagyasList?: Array<SzolgaltatasRegisztracioBiralat>;
    kepIds?: Array<number>;
    kiegeszitoInformacio?: string;
    korlatozottVizteruletList?: Array<VedettVizterulet>;
    leirasList?: Array<VizteruletCsoportLeiras>;
    megkozelithetoseg?: string;
    megyeKod?: string;
    nev?: string;
    ownerSzereploId?: number;
    ownerSzereploNev?: string;
    szelessegiFok?: number;
    terulet?: string;
    tiltottVizteruletList?: Array<VedettVizterulet>;
    vegrehajtottLepesek?: Array<HorgasztatasSzolgaltatasVegrehajtottLepes>;
    vizmeret?: string;
    vizterkod?: string;
    vizteruletCsoportId?: number;
    vizteruletId?: number;
    vizteruletTipus?: HorgasztatasSzolgaltatasRegisztracio.VizteruletTipusEnum;
    zarvatartasIdSequence?: number;
    zarvatartasList?: Array<HorgasztatasZarvatartas>;
}
export namespace HorgasztatasSzolgaltatasRegisztracio {
    export type AktualisLepesEnum = 'ALAPADATOK' | 'ELHELYEZKEDES' | 'HORGASZREND' | 'FENYKEPEK' | 'JELLEMZOK' | 'HALALLOMANY' | 'OSSZEGZES';
    export const AktualisLepesEnum = {
        ALAPADATOK: 'ALAPADATOK' as AktualisLepesEnum,
        ELHELYEZKEDES: 'ELHELYEZKEDES' as AktualisLepesEnum,
        HORGASZREND: 'HORGASZREND' as AktualisLepesEnum,
        FENYKEPEK: 'FENYKEPEK' as AktualisLepesEnum,
        JELLEMZOK: 'JELLEMZOK' as AktualisLepesEnum,
        HALALLOMANY: 'HALALLOMANY' as AktualisLepesEnum,
        OSSZEGZES: 'OSSZEGZES' as AktualisLepesEnum
    };
    export type FelszereltsegListEnum = 'BERELHETO_HORGASZHELY' | 'HORGASZTANYA' | 'SOLYAPALYA';
    export const FelszereltsegListEnum = {
        BERELHETOHORGASZHELY: 'BERELHETO_HORGASZHELY' as FelszereltsegListEnum,
        HORGASZTANYA: 'HORGASZTANYA' as FelszereltsegListEnum,
        SOLYAPALYA: 'SOLYAPALYA' as FelszereltsegListEnum
    };
    export type HorgaszmodszerAltipusListEnum = 'KLASSZIKUS_FENEKEZES' | 'BOJLIS_HORGASZAT' | 'FEEDER_BOTOS_HORGASZAT' | 'RAGADOZO_HALAS_FENEKEZOS_HORGASZAT' | 'SPICCBOTOS_HORGASZAT' | 'BOLOGNAI_BOTOS_HORGASZAT' | 'RAKOS_BOTOS_HORGASZAT' | 'MATCH_BOTOS_HORGASZAT' | 'USZOS_RAGADOZO_HALAS_HORGASZAT' | 'WOBBLEREZES' | 'JIGELES' | 'DROPSHOT' | 'VERTIKALIS' | 'MARTOGATAS_TAPOGATAS' | 'KUTTYOGTATAS' | 'MORMISKAZAS';
    export const HorgaszmodszerAltipusListEnum = {
        KLASSZIKUSFENEKEZES: 'KLASSZIKUS_FENEKEZES' as HorgaszmodszerAltipusListEnum,
        BOJLISHORGASZAT: 'BOJLIS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        FEEDERBOTOSHORGASZAT: 'FEEDER_BOTOS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        RAGADOZOHALASFENEKEZOSHORGASZAT: 'RAGADOZO_HALAS_FENEKEZOS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        SPICCBOTOSHORGASZAT: 'SPICCBOTOS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        BOLOGNAIBOTOSHORGASZAT: 'BOLOGNAI_BOTOS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        RAKOSBOTOSHORGASZAT: 'RAKOS_BOTOS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        MATCHBOTOSHORGASZAT: 'MATCH_BOTOS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        USZOSRAGADOZOHALASHORGASZAT: 'USZOS_RAGADOZO_HALAS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        WOBBLEREZES: 'WOBBLEREZES' as HorgaszmodszerAltipusListEnum,
        JIGELES: 'JIGELES' as HorgaszmodszerAltipusListEnum,
        DROPSHOT: 'DROPSHOT' as HorgaszmodszerAltipusListEnum,
        VERTIKALIS: 'VERTIKALIS' as HorgaszmodszerAltipusListEnum,
        MARTOGATASTAPOGATAS: 'MARTOGATAS_TAPOGATAS' as HorgaszmodszerAltipusListEnum,
        KUTTYOGTATAS: 'KUTTYOGTATAS' as HorgaszmodszerAltipusListEnum,
        MORMISKAZAS: 'MORMISKAZAS' as HorgaszmodszerAltipusListEnum
    };
    export type VizteruletTipusEnum = 'FOLYO' | 'VIZTAROZO' | 'PATAK' | 'CSATORNA' | 'TERMESZETES_TO' | 'MESTERSEGES_TO' | 'HOLTAG';
    export const VizteruletTipusEnum = {
        FOLYO: 'FOLYO' as VizteruletTipusEnum,
        VIZTAROZO: 'VIZTAROZO' as VizteruletTipusEnum,
        PATAK: 'PATAK' as VizteruletTipusEnum,
        CSATORNA: 'CSATORNA' as VizteruletTipusEnum,
        TERMESZETESTO: 'TERMESZETES_TO' as VizteruletTipusEnum,
        MESTERSEGESTO: 'MESTERSEGES_TO' as VizteruletTipusEnum,
        HOLTAG: 'HOLTAG' as VizteruletTipusEnum
    };
}