/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MegbizoDetails } from './megbizoDetails';
import { PasswordReset } from './passwordReset';
import { SssUser } from './sssUser';
import { SssUserEmailValidation } from './sssUserEmailValidation';
import { SssUserRegistration } from './sssUserRegistration';

export interface SssUserInfo { 
    emailValidation?: SssUserEmailValidation;
    emailValidationLink?: string;
    hitelesitettEmail?: boolean;
    jelszoResetLink?: string;
    loginId?: string;
    megbizoDetailsList?: Array<MegbizoDetails>;
    mohoszHost?: string;
    passwordReset?: PasswordReset;
    regisztracioAktivalasLink?: string;
    sssUser?: SssUser;
    szemelyNev?: string;
    userRegistration?: SssUserRegistration;
}