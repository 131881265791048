/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ElkovetesLokacio } from './elkovetesLokacio';
import { SzabalysertesAllomany } from './szabalysertesAllomany';

export interface SzabalysertesRogzitesRequest { 
    allamiJegySorszama?: string;
    anyjaNeve?: string;
    elkovetesHelye?: ElkovetesLokacio;
    elkovetesIdeje?: Date;
    elkovetettCselekmeny?: string;
    elkovetoElismerte?: boolean;
    elkovetoIndoklasa?: string;
    ellenorSzemelyId?: number;
    ellenorzesId?: number;
    feljelentesSorszama?: string;
    feltoltottAllomanyok?: Array<SzabalysertesAllomany>;
    keresztnev?: string;
    lefoglaltTargyak?: string;
    megjegyzes?: string;
    okai?: Array<SzabalysertesRogzitesRequest.OkaiEnum>;
    sorszam?: string;
    szemelyId?: number;
    szervezetId?: number;
    szuletesiDatum?: string;
    szuletesiHely?: string;
    teruletiJegySorszama?: string;
    tipusai?: Array<SzabalysertesRogzitesRequest.TipusaiEnum>;
    validElkovetesIdeje?: boolean;
    validFeljelentesSorszama?: boolean;
    validSzervezetId?: boolean;
    vezeteknev?: string;
}
export namespace SzabalysertesRogzitesRequest {
    export type OkaiEnum = 'ALLAMI_JEGY_HIANYA' | 'TERULETI_JEGY_HIANYA' | 'HAROM_BOTTAL_HORGASZAS' | 'HELYI_HORGASZREND_MEGSERTESE' | 'KIJELOLT_ZONAN_KIVULI_HORGASZAT';
    export const OkaiEnum = {
        ALLAMIJEGYHIANYA: 'ALLAMI_JEGY_HIANYA' as OkaiEnum,
        TERULETIJEGYHIANYA: 'TERULETI_JEGY_HIANYA' as OkaiEnum,
        HAROMBOTTALHORGASZAS: 'HAROM_BOTTAL_HORGASZAS' as OkaiEnum,
        HELYIHORGASZRENDMEGSERTESE: 'HELYI_HORGASZREND_MEGSERTESE' as OkaiEnum,
        KIJELOLTZONANKIVULIHORGASZAT: 'KIJELOLT_ZONAN_KIVULI_HORGASZAT' as OkaiEnum
    };
    export type TipusaiEnum = 'HELYI_HORGASZRENDI' | 'JOGSZABALYI';
    export const TipusaiEnum = {
        HELYIHORGASZRENDI: 'HELYI_HORGASZRENDI' as TipusaiEnum,
        JOGSZABALYI: 'JOGSZABALYI' as TipusaiEnum
    };
}