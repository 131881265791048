/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { HorgasztatasSzolgaltatasRegisztracio } from './horgasztatasSzolgaltatasRegisztracio';
import { SzolgaltatasListItem } from './szolgaltatasListItem';

export interface HorgasztatasSzolgaltatasBundle { 
    availableCommands?: Array<HorgasztatasSzolgaltatasBundle.AvailableCommandsEnum>;
    ownerSzereploId?: number;
    regisztracio?: HorgasztatasSzolgaltatasRegisztracio;
    statusz?: HorgasztatasSzolgaltatasBundle.StatuszEnum;
    szolgaltatasId?: number;
    szolgaltatasListItem?: SzolgaltatasListItem;
}
export namespace HorgasztatasSzolgaltatasBundle {
    export type AvailableCommandsEnum = 'REGISZTRACIO_VEGLEGESITES' | 'RESZLETEK_MEGNYITAS' | 'ELONEZET_MEGNYITAS' | 'REGISZTRACIO_MEGNYITAS' | 'ELBIRALAS' | 'MODOSITAS';
    export const AvailableCommandsEnum = {
        REGISZTRACIOVEGLEGESITES: 'REGISZTRACIO_VEGLEGESITES' as AvailableCommandsEnum,
        RESZLETEKMEGNYITAS: 'RESZLETEK_MEGNYITAS' as AvailableCommandsEnum,
        ELONEZETMEGNYITAS: 'ELONEZET_MEGNYITAS' as AvailableCommandsEnum,
        REGISZTRACIOMEGNYITAS: 'REGISZTRACIO_MEGNYITAS' as AvailableCommandsEnum,
        ELBIRALAS: 'ELBIRALAS' as AvailableCommandsEnum,
        MODOSITAS: 'MODOSITAS' as AvailableCommandsEnum
    };
    export type StatuszEnum = 'ROGZITESALATT' | 'JOVAHAGYASRAVAR' | 'AKTIV' | 'INAKTIV';
    export const StatuszEnum = {
        ROGZITESALATT: 'ROGZITESALATT' as StatuszEnum,
        JOVAHAGYASRAVAR: 'JOVAHAGYASRAVAR' as StatuszEnum,
        AKTIV: 'AKTIV' as StatuszEnum,
        INAKTIV: 'INAKTIV' as StatuszEnum
    };
}