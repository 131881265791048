/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizteruletEltiltas { 
    eltiltasKezdete?: string;
    eltiltasVege?: string;
    feloldva?: boolean;
    id?: number;
    statusz?: VizteruletEltiltas.StatuszEnum;
}
export namespace VizteruletEltiltas {
    export type StatuszEnum = 'AKTIV' | 'INAKTIV' | 'FELOLDOTT';
    export const StatuszEnum = {
        AKTIV: 'AKTIV' as StatuszEnum,
        INAKTIV: 'INAKTIV' as StatuszEnum,
        FELOLDOTT: 'FELOLDOTT' as StatuszEnum
    };
}