/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AllamiVizsga { 
    availableCommands?: Array<AllamiVizsga.AvailableCommandsEnum>;
    idopont?: Date;
    statusz?: AllamiVizsga.StatuszEnum;
    vizsgabizottsagElerhetoseg?: string;
    vizsgabizottsagNev?: string;
    vizsgabizottsagVizsgaId?: number;
    vizsgahely?: string;
}
export namespace AllamiVizsga {
    export type AvailableCommandsEnum = 'VIZSGAINDITAS' | 'VIZSGAFOLYTATAS' | 'LEMONDAS' | 'KAPCSOLATTARTAS_MODOSITAS' | 'VBJELENTKEZTETES' | 'JEGYZOKONYVNYOMTATAS' | 'MEGHIUSITAS';
    export const AvailableCommandsEnum = {
        VIZSGAINDITAS: 'VIZSGAINDITAS' as AvailableCommandsEnum,
        VIZSGAFOLYTATAS: 'VIZSGAFOLYTATAS' as AvailableCommandsEnum,
        LEMONDAS: 'LEMONDAS' as AvailableCommandsEnum,
        KAPCSOLATTARTASMODOSITAS: 'KAPCSOLATTARTAS_MODOSITAS' as AvailableCommandsEnum,
        VBJELENTKEZTETES: 'VBJELENTKEZTETES' as AvailableCommandsEnum,
        JEGYZOKONYVNYOMTATAS: 'JEGYZOKONYVNYOMTATAS' as AvailableCommandsEnum,
        MEGHIUSITAS: 'MEGHIUSITAS' as AvailableCommandsEnum
    };
    export type StatuszEnum = 'FOLYAMATBAN' | 'INDITHATO' | 'TERVEZETT' | 'LEZARULT' | 'MEGHIUSULT';
    export const StatuszEnum = {
        FOLYAMATBAN: 'FOLYAMATBAN' as StatuszEnum,
        INDITHATO: 'INDITHATO' as StatuszEnum,
        TERVEZETT: 'TERVEZETT' as StatuszEnum,
        LEZARULT: 'LEZARULT' as StatuszEnum,
        MEGHIUSULT: 'MEGHIUSULT' as StatuszEnum
    };
}