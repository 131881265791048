/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AlapadatRequest { 
    adatvedelmiNyilatkozatElfogadva?: boolean;
    anyjaNeve?: string;
    bekertTelefonszam?: string;
    keresztnev?: string;
    kulfoldiAllampolgar?: boolean;
    nem?: AlapadatRequest.NemEnum;
    regisztracioTipus?: AlapadatRequest.RegisztracioTipusEnum;
    szemelyId?: number;
    szerzodesesFeltetelekElfogadva?: boolean;
    szuletesiDatum?: string;
    szuletesiHely?: string;
    titulus?: AlapadatRequest.TitulusEnum;
    validGyermekRegisztracio?: boolean;
    validSzemelyId?: boolean;
    vezeteknev?: string;
}
export namespace AlapadatRequest {
    export type NemEnum = 'NINCS_MEGADVA' | 'NO' | 'FERFI';
    export const NemEnum = {
        NINCSMEGADVA: 'NINCS_MEGADVA' as NemEnum,
        NO: 'NO' as NemEnum,
        FERFI: 'FERFI' as NemEnum
    };
    export type RegisztracioTipusEnum = 'SAJAT' | 'HOZZATARTOZOI' | 'UGYINTEZOI';
    export const RegisztracioTipusEnum = {
        SAJAT: 'SAJAT' as RegisztracioTipusEnum,
        HOZZATARTOZOI: 'HOZZATARTOZOI' as RegisztracioTipusEnum,
        UGYINTEZOI: 'UGYINTEZOI' as RegisztracioTipusEnum
    };
    export type TitulusEnum = 'dr' | 'id' | 'ifj' | 'özv' | 'prof';
    export const TitulusEnum = {
        Dr: 'dr' as TitulusEnum,
        Id: 'id' as TitulusEnum,
        Ifj: 'ifj' as TitulusEnum,
        Zv: 'özv' as TitulusEnum,
        Prof: 'prof' as TitulusEnum
    };
}