/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { SzereploRequest } from '../model/szereploRequest';
import { Vizsgabizottsag } from '../model/vizsgabizottsag';
import { VizsgabizottsagVizsga } from '../model/vizsgabizottsagVizsga';
import { VizsgabizottsagVizsgaFilterRequest } from '../model/vizsgabizottsagVizsgaFilterRequest';
import { VizsgabizottsagVizsgaJegyzokonyvUploadRequest } from '../model/vizsgabizottsagVizsgaJegyzokonyvUploadRequest';
import { VizsgabizottsagVizsgaRequest } from '../model/vizsgabizottsagVizsgaRequest';
import { VizsgabizottsagVizsgaTagJegyzokonyvKitoltesRequest } from '../model/vizsgabizottsagVizsgaTagJegyzokonyvKitoltesRequest';
import { VizsgaidopontJelentkezesRequest } from '../model/vizsgaidopontJelentkezesRequest';
import { VizsgaraJelentkezettJegyzokonyvKitoltesRequest } from '../model/vizsgaraJelentkezettJegyzokonyvKitoltesRequest';
import { VizsgaraJelentkezettRequest } from '../model/vizsgaraJelentkezettRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class VizsgabizottsagVizsgaMgmtControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * betoltes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public betoltesUsingPOST(body: VizsgabizottsagVizsgaRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgabizottsagVizsga>;
    public betoltesUsingPOST(body: VizsgabizottsagVizsgaRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgabizottsagVizsga>>;
    public betoltesUsingPOST(body: VizsgabizottsagVizsgaRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgabizottsagVizsga>>;
    public betoltesUsingPOST(body: VizsgabizottsagVizsgaRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling betoltesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgabizottsagVizsga>('post',`${this.basePath}/api/mohosz/vizsgabizottsagvizsgamgmt/vizsgabizottsagvizsga-betoltes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findByFilter
     * 
     * @param body filterRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findByFilterUsingPOST(body: VizsgabizottsagVizsgaFilterRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<VizsgabizottsagVizsga>>;
    public findByFilterUsingPOST(body: VizsgabizottsagVizsgaFilterRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VizsgabizottsagVizsga>>>;
    public findByFilterUsingPOST(body: VizsgabizottsagVizsgaFilterRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VizsgabizottsagVizsga>>>;
    public findByFilterUsingPOST(body: VizsgabizottsagVizsgaFilterRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findByFilterUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<VizsgabizottsagVizsga>>('post',`${this.basePath}/api/mohosz/vizsgabizottsagvizsgamgmt/findbyfilter`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * isAuthorized
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isAuthorizedUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public isAuthorizedUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public isAuthorizedUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public isAuthorizedUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/mohosz/vizsgabizottsagvizsgamgmt/isauthorized`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kapcsolattartasModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kapcsolattartasModositasUsingPOST(body: VizsgaidopontJelentkezesRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgabizottsagVizsga>;
    public kapcsolattartasModositasUsingPOST(body: VizsgaidopontJelentkezesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgabizottsagVizsga>>;
    public kapcsolattartasModositasUsingPOST(body: VizsgaidopontJelentkezesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgabizottsagVizsga>>;
    public kapcsolattartasModositasUsingPOST(body: VizsgaidopontJelentkezesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kapcsolattartasModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgabizottsagVizsga>('post',`${this.basePath}/api/mohosz/vizsgabizottsagvizsgamgmt/jelentkezett-kapcsolattartas-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * tagJegyzokonyvKitoltes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tagJegyzokonyvKitoltesUsingPOST(body: VizsgabizottsagVizsgaTagJegyzokonyvKitoltesRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgabizottsagVizsga>;
    public tagJegyzokonyvKitoltesUsingPOST(body: VizsgabizottsagVizsgaTagJegyzokonyvKitoltesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgabizottsagVizsga>>;
    public tagJegyzokonyvKitoltesUsingPOST(body: VizsgabizottsagVizsgaTagJegyzokonyvKitoltesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgabizottsagVizsga>>;
    public tagJegyzokonyvKitoltesUsingPOST(body: VizsgabizottsagVizsgaTagJegyzokonyvKitoltesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling tagJegyzokonyvKitoltesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgabizottsagVizsga>('post',`${this.basePath}/api/mohosz/vizsgabizottsagvizsgamgmt/tag-jegyzokonyv-kitoltes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * valaszthatoVizsgabizottsagok
     * 
     * @param body szereploRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public valaszthatoVizsgabizottsagokUsingPOST(body: SzereploRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<Vizsgabizottsag>>;
    public valaszthatoVizsgabizottsagokUsingPOST(body: SzereploRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Vizsgabizottsag>>>;
    public valaszthatoVizsgabizottsagokUsingPOST(body: SzereploRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Vizsgabizottsag>>>;
    public valaszthatoVizsgabizottsagokUsingPOST(body: SzereploRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling valaszthatoVizsgabizottsagokUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<Vizsgabizottsag>>('post',`${this.basePath}/api/mohosz/vizsgabizottsagvizsgamgmt/valaszthato-vizsgabizottsagok`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaInditas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaInditasUsingPOST(body: VizsgabizottsagVizsgaRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgabizottsagVizsga>;
    public vizsgaInditasUsingPOST(body: VizsgabizottsagVizsgaRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgabizottsagVizsga>>;
    public vizsgaInditasUsingPOST(body: VizsgabizottsagVizsgaRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgabizottsagVizsga>>;
    public vizsgaInditasUsingPOST(body: VizsgabizottsagVizsgaRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizsgaInditasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgabizottsagVizsga>('post',`${this.basePath}/api/mohosz/vizsgabizottsagvizsgamgmt/vizsga-inditas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaLezaras
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaLezarasUsingPOST(body: VizsgabizottsagVizsgaRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgabizottsagVizsga>;
    public vizsgaLezarasUsingPOST(body: VizsgabizottsagVizsgaRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgabizottsagVizsga>>;
    public vizsgaLezarasUsingPOST(body: VizsgabizottsagVizsgaRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgabizottsagVizsga>>;
    public vizsgaLezarasUsingPOST(body: VizsgabizottsagVizsgaRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizsgaLezarasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgabizottsagVizsga>('post',`${this.basePath}/api/mohosz/vizsgabizottsagvizsgamgmt/vizsga-lezaras`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaMeghiusitas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaMeghiusitasUsingPOST(body: VizsgabizottsagVizsgaRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgabizottsagVizsga>;
    public vizsgaMeghiusitasUsingPOST(body: VizsgabizottsagVizsgaRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgabizottsagVizsga>>;
    public vizsgaMeghiusitasUsingPOST(body: VizsgabizottsagVizsgaRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgabizottsagVizsga>>;
    public vizsgaMeghiusitasUsingPOST(body: VizsgabizottsagVizsgaRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizsgaMeghiusitasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgabizottsagVizsga>('post',`${this.basePath}/api/mohosz/vizsgabizottsagvizsgamgmt/vizsga-meghiusitas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaidopontraJelentkezes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaidopontraJelentkezesUsingPOST(body: VizsgaidopontJelentkezesRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgabizottsagVizsga>;
    public vizsgaidopontraJelentkezesUsingPOST(body: VizsgaidopontJelentkezesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgabizottsagVizsga>>;
    public vizsgaidopontraJelentkezesUsingPOST(body: VizsgaidopontJelentkezesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgabizottsagVizsga>>;
    public vizsgaidopontraJelentkezesUsingPOST(body: VizsgaidopontJelentkezesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizsgaidopontraJelentkezesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgabizottsagVizsga>('post',`${this.basePath}/api/mohosz/vizsgabizottsagvizsgamgmt/vizsgaidopontra-jelentkezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgajegyzokonyvFeltoltes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgajegyzokonyvFeltoltesUsingPOST(body: VizsgabizottsagVizsgaJegyzokonyvUploadRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgabizottsagVizsga>;
    public vizsgajegyzokonyvFeltoltesUsingPOST(body: VizsgabizottsagVizsgaJegyzokonyvUploadRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgabizottsagVizsga>>;
    public vizsgajegyzokonyvFeltoltesUsingPOST(body: VizsgabizottsagVizsgaJegyzokonyvUploadRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgabizottsagVizsga>>;
    public vizsgajegyzokonyvFeltoltesUsingPOST(body: VizsgabizottsagVizsgaJegyzokonyvUploadRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizsgajegyzokonyvFeltoltesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgabizottsagVizsga>('post',`${this.basePath}/api/mohosz/vizsgabizottsagvizsgamgmt/vizsgajegyzokonyv-feltoltes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgajegyzokonyvFileUpload
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgajegyzokonyvFileUploadUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public vizsgajegyzokonyvFileUploadUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public vizsgajegyzokonyvFileUploadUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public vizsgajegyzokonyvFileUploadUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling vizsgajegyzokonyvFileUploadUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/vizsgabizottsagvizsgamgmt/vizsgajegyzokonyv-file-upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaraJegyzokonyv
     * 
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaraJegyzokonyvUsingGET(request: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public vizsgaraJegyzokonyvUsingGET(request: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public vizsgaraJegyzokonyvUsingGET(request: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public vizsgaraJegyzokonyvUsingGET(request: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling vizsgaraJegyzokonyvUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (request !== undefined && request !== null) {
            queryParameters = queryParameters.set('request', <any>request);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/vizsgabizottsagvizsgamgmt/vizsgajegyzokonyv-nyomtatas`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaraJelentkezettJegyzokonyvKitoltes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaraJelentkezettJegyzokonyvKitoltesUsingPOST(body: VizsgaraJelentkezettJegyzokonyvKitoltesRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgabizottsagVizsga>;
    public vizsgaraJelentkezettJegyzokonyvKitoltesUsingPOST(body: VizsgaraJelentkezettJegyzokonyvKitoltesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgabizottsagVizsga>>;
    public vizsgaraJelentkezettJegyzokonyvKitoltesUsingPOST(body: VizsgaraJelentkezettJegyzokonyvKitoltesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgabizottsagVizsga>>;
    public vizsgaraJelentkezettJegyzokonyvKitoltesUsingPOST(body: VizsgaraJelentkezettJegyzokonyvKitoltesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizsgaraJelentkezettJegyzokonyvKitoltesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgabizottsagVizsga>('post',`${this.basePath}/api/mohosz/vizsgabizottsagvizsgamgmt/jelentkezett-jegyzokonyv-kitoltes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaraJelentkezettKizaras
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaraJelentkezettKizarasUsingPOST(body: VizsgaraJelentkezettRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgabizottsagVizsga>;
    public vizsgaraJelentkezettKizarasUsingPOST(body: VizsgaraJelentkezettRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgabizottsagVizsga>>;
    public vizsgaraJelentkezettKizarasUsingPOST(body: VizsgaraJelentkezettRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgabizottsagVizsga>>;
    public vizsgaraJelentkezettKizarasUsingPOST(body: VizsgaraJelentkezettRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizsgaraJelentkezettKizarasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgabizottsagVizsga>('post',`${this.basePath}/api/mohosz/vizsgabizottsagvizsgamgmt/jelentkezett-kizaras`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaraJelentkezettVizsgabizonyitvany
     * 
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaraJelentkezettVizsgabizonyitvanyUsingGET(request: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public vizsgaraJelentkezettVizsgabizonyitvanyUsingGET(request: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public vizsgaraJelentkezettVizsgabizonyitvanyUsingGET(request: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public vizsgaraJelentkezettVizsgabizonyitvanyUsingGET(request: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling vizsgaraJelentkezettVizsgabizonyitvanyUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (request !== undefined && request !== null) {
            queryParameters = queryParameters.set('request', <any>request);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/vizsgabizottsagvizsgamgmt/jelentkezett-vizsgabizonyitvany-nyomtatas`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
