import {Injectable} from '@angular/core';
import {BackgroundGeolocation, BackgroundGeolocationConfig, BackgroundGeolocationEvents, BackgroundGeolocationLocationProvider, BackgroundGeolocationResponse} from '@ionic-native/background-geolocation/ngx';
import {Geolocation, GeolocationOptions} from '@ionic-native/geolocation/ngx';
import {Network} from '@ionic-native/network/ngx';
import {AlertController, MenuController, Platform} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {Mutex} from 'async-mutex';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {AuthRestControllerService, EllenorLocationHistoryControllerService, EllenorLokacioRogzitesRequest, GPSKoordinata, JogosultsagControllerService, OnlineEllenorControllerService, SssUser} from 'src/api';
import {environment} from 'src/environments/environment';
import {AuthenticatedUser} from '../objects/authenticatedUser';
import {AppHelper} from './app-helper';
import {DatabaseService} from './database.service';
import {OfflineDataService} from './offline-data.service';


export const SSSUserName = 'SSSUserName';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends OnDestroyMixin {
  onlineStatus: BehaviorSubject<boolean> = new BehaviorSubject(true);
  sssUser = new BehaviorSubject<AuthenticatedUser>({});
  requestRepeat = new BehaviorSubject<boolean>(false);

  geolocationOptions = {
    timeout: 1000*20,
    enableHighAccuracy: true,
    maximumAge: 0
  } as GeolocationOptions;

  private lokacioMutex = new Mutex();
  private ellenorzesekMutex = new Mutex();

  private lastGeolocation: BehaviorSubject<BackgroundGeolocationResponse> = new BehaviorSubject<BackgroundGeolocationResponse>(null);
  private backgroundGeolocationSubs: Subscription;

  constructor(
    private platform: Platform,
    private network: Network,
    private geolocationProvider: Geolocation,
    private menuController: MenuController,
    private databaseService: DatabaseService,
    private backgroundGeolocation: BackgroundGeolocation,
    private jogosultsagControllerService: JogosultsagControllerService,
    private storage: Storage,
    private authRestControllerService: AuthRestControllerService,
    private alertCtrl: AlertController,
    private offlineDataService: OfflineDataService,
    private ellenorLocationHistoryControllerService: EllenorLocationHistoryControllerService,
    private ellenorControllerService: OnlineEllenorControllerService
  ) {
    super()

    //20 masodpercenkent ellenorizzuk hogy van-e adat
    interval(20000).pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.sendLokaciokToServer().then(() => {});
      this.sendEllenorzesekToServer().then(() => {});
    });

    this.platform.ready().then(() => {
      this.network.onDisconnect().pipe(untilComponentDestroyed(this)).subscribe(() => {
        console.log('network was disconnected :-(');
        this.onlineStatus.next(false);
      });
      this.network.onConnect().pipe(untilComponentDestroyed(this)).subscribe(() => {
        console.log('network was connected :-)');
        //ha elment offlineba
        if(this.isOfflineMukodes()) {
          this.offlineDataService.refreshDataFromServer();
        }
        this.onlineStatus.next(true);
      });
    });
  }

  async checkUser() {
    if (this.authRestControllerService) {
      const user = await this.authRestControllerService.currentPrincipalUsingPOST().toPromise();
      const authenticatedUser: AuthenticatedUser = AuthenticationService.authenticatedUserFromSssUser(user);
      if (authenticatedUser && authenticatedUser.authorities && authenticatedUser.authorities.indexOf('ELLENOR') > -1) {
        this.storage.set(SSSUserName, JSON.stringify(authenticatedUser)).then(() => {
          this.sssUser.next(authenticatedUser);
        });
      } else if (authenticatedUser && authenticatedUser.entrustingId !== null) {
        const alertMesage = await this.alertCtrl.create({
          header: `Nincs jogosultságod az applikáció használatához!`,
          message: '',
          buttons: ['OK']
        });
        await alertMesage.present();
        this.sssUser.next({});
      }
    }
  }

  async login(form) {
    try {
      const user = await this.authRestControllerService.loginUsingPOST(form.username, form.password).toPromise();
      const authenticatedUser: AuthenticatedUser = AuthenticationService.authenticatedUserFromSssUser(user);

      authenticatedUser.password = form.password;
      this.storage.set(SSSUserName, JSON.stringify(authenticatedUser)).then(() => {
        this.sssUser.next(authenticatedUser);
      });
    } catch (error) {
      const alertMesage = await this.alertCtrl.create({
        header: `Hiba`,
        message: 'Hibás felhasználónév/jelszó',
        buttons: ['OK']
      });
      await alertMesage.present();
      this.storage.remove(SSSUserName).then(() => {
        this.sssUser.next({});
      });
    }
  }

  async reLogin(){
    this.storage.get(SSSUserName).then(user => {
      if (user) {
        console.log("Background re-authentication...")
        const sssUser: AuthenticatedUser = JSON.parse(user);
        this.authRestControllerService.loginUsingPOST(sssUser.loginId, sssUser.password).subscribe(() => {
          this.jogosultsagControllerService.megbizoKivalasztasUsingPOST({ megbizoSzereploId: sssUser.entrustingId }).subscribe(
          () => {
            this.requestRepeat.next(true)
          },
          err => {
            console.log(err);
            this.logout();
          });
        });
      }
    });
  }

  async logout() {
    try {
      await this.authRestControllerService.logoutUsingPOST().toPromise();
    } catch (error) {
    } finally {
      this.sssUser.next({});
      await this.backgroundGeolocation.stop();
      await this.menuController.close();
      this.backgroundGeolocationSubs.unsubscribe();
    }
  }

  isAuthenticated() {
    return !!(this.sssUser.value && this.sssUser.value.trusteeId);
  }

  isFullyAuthenticated() {
    //vizterulet valasztast kovetoen van teljesen bejelentkezve
    return !!(this.sssUser.value && this.sssUser.value.selectedVizterulet);
  }

  isHasEntrustingId() {
    return !!(this.sssUser.value && this.sssUser.value.entrustingId);
  }

  private async saveLocation(user, location) {
    const lokacioReq: EllenorLokacioRogzitesRequest = {
      ellenorSzemelyId: user.trusteeId,
      idopont: AppHelper.serverDateNow(),
      szervezetId: user.entrustingId,
      koordinata: {
        hosszusagiFok: location.longitude,
        szelessegiFok: location.latitude
      }
    };
    await this.databaseService.insertLokacio(lokacioReq);
  }

  private static authenticatedUserFromSssUser(sssUser: SssUser): AuthenticatedUser {
    const authenticatedUser: AuthenticatedUser = new AuthenticatedUser();
    authenticatedUser.active = sssUser.active;
    authenticatedUser.authorities = sssUser.authorities;
    authenticatedUser.authorityChannel = sssUser.authorityChannel;
    authenticatedUser.email = sssUser.email;
    authenticatedUser.entrustingId = sssUser.entrustingId;
    authenticatedUser.lastLoggedIn = sssUser.lastLoggedIn;
    authenticatedUser.loginId = sssUser.loginId;
    authenticatedUser.trusteeId = sssUser.trusteeId;
    return authenticatedUser;
  }

  startTracking(user) {
    //vizterulet valtoztatasnal ismet keletkezik egy feliratkozas
    if (this.backgroundGeolocationSubs) {
      this.backgroundGeolocationSubs.unsubscribe();
    }

    //      HIGH = 0,
    //      MEDIUM = 10,
    //      LOW = 100,
    //      PASSIVE = 1000

    // Background Tracking
    const config: BackgroundGeolocationConfig = {
      desiredAccuracy: 0,
      distanceFilter: environment.geolocationConfig.distanceFilter,
      interval: environment.geolocationConfig.interval,
      startForeground: true,
      notificationTitle: 'Location tracking',
      stopOnTerminate: false,
      stopOnStillActivity: false,
      syncThreshold: 1,
      locationProvider: BackgroundGeolocationLocationProvider.RAW_PROVIDER
    };

    this.backgroundGeolocation.configure(config)
      .then(() => {
        this.backgroundGeolocationSubs = this.backgroundGeolocation.on(BackgroundGeolocationEvents.location)
            .pipe(untilComponentDestroyed(this))
            .subscribe((location: BackgroundGeolocationResponse) => {
              console.log("TRACKING: " + location.longitude + " - " + location.latitude);
              this.lastGeolocation.next(location);
              this.saveLocation(user, location);
              this.backgroundGeolocation.finish();
            }, (err) => {
              console.log(err);
              this.backgroundGeolocation.finish();
            });
      });

    // Turn ON the background-geolocation system.
    this.backgroundGeolocation.start();
  }

  async getLocation(): Promise<GPSKoordinata> {
    let resp: GPSKoordinata;
    const lastGeo = this.lastGeolocation.getValue();
    //if (this.isOfflineMukodes() && lastGeo) {
    if (lastGeo) {
      console.log("LASTBACKGEOLOCATION")
      resp = {
        hosszusagiFok: lastGeo.longitude,
        szelessegiFok: lastGeo.latitude
      }
    } else {
      console.log("CURRENTPOSITION");
      try {
        const geoposition = await this.geolocationProvider.getCurrentPosition(this.geolocationOptions);
        resp = {
          hosszusagiFok: geoposition.coords.longitude,
          szelessegiFok: geoposition.coords.latitude
        }
      } catch (error){
        console.error(error)
      }
    }
    return resp;
  }

  isOnlineMukodes() : boolean{
    return this.onlineStatus.getValue();
  }

  isOfflineMukodes() : boolean{
    return !this.onlineStatus.getValue();
  }

  private async sendLokaciokToServer() {
    await this.lokacioMutex.runExclusive(() => {
      if (this.isOnlineMukodes() && this.isFullyAuthenticated()) {
        this.databaseService.loadLokaciokForSync().then(lokaciok => {
          if (lokaciok.length > 0) {
            this.ellenorLocationHistoryControllerService.batchLokacioRogzitesUsingPOST({ requestList: lokaciok }).subscribe(
              () => {
                console.log("Lokációk szinkronizálva: [" + lokaciok.map(lokacio => lokacio.id) + "]")
              },
              error => {
                console.log(error)
                this.databaseService.rollbackLokacioList(lokaciok);
              });
          }
        })
      }
    })
  }

  private async sendEllenorzesekToServer() {
    await this.ellenorzesekMutex.runExclusive(() => {
      if (this.isOnlineMukodes() && this.isFullyAuthenticated()) {
        this.databaseService.loadEllenorzesekForSync().then(ellenorzesek => {
          if (ellenorzesek.length > 0) {
            this.ellenorControllerService.batchHorgaszAdatKeresesUsingPOST({ horgaszAdatRequestList: ellenorzesek }).subscribe(
              () => {
                console.log("Ellenőrzések szinkronizálva: [" + ellenorzesek.map(lokacio => lokacio.id) + "]")
              },
              error => {
                console.log(error)
                this.databaseService.rollbackEllenorzesList(ellenorzesek);
              });
          }
        })
      }
    })
  }
}
