/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VersenyFilter { 
    erintettDatum?: string;
    ev?: number;
    globalSearch?: string;
    honap?: number;
    kezdesIg?: string;
    kezdesTol?: string;
    limit?: number;
    nap?: number;
    offset?: number;
    statuszList?: Array<VersenyFilter.StatuszListEnum>;
    szemelyId?: number;
    tsQueryString?: string;
}
export namespace VersenyFilter {
    export type StatuszListEnum = 'TERVEZETT' | 'JELENTKEZES_IDO_ALATT' | 'LEZART_JELENTKEZES' | 'LEZART_VERSENY';
    export const StatuszListEnum = {
        TERVEZETT: 'TERVEZETT' as StatuszListEnum,
        JELENTKEZESIDOALATT: 'JELENTKEZES_IDO_ALATT' as StatuszListEnum,
        LEZARTJELENTKEZES: 'LEZART_JELENTKEZES' as StatuszListEnum,
        LEZARTVERSENY: 'LEZART_VERSENY' as StatuszListEnum
    };
}