/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MasEgyesuletiTagsag { 
    availableCommands?: Array<MasEgyesuletiTagsag.AvailableCommandsEnum>;
    egyesuletNeve?: string;
    tagsagKezdete?: string;
}
export namespace MasEgyesuletiTagsag {
    export type AvailableCommandsEnum = 'SZERKESZTES';
    export const AvailableCommandsEnum = {
        SZERKESZTES: 'SZERKESZTES' as AvailableCommandsEnum
    };
}