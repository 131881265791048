/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BerbeadasSzolgaltatasVegrehajtottLepes } from './berbeadasSzolgaltatasVegrehajtottLepes';
import { SzolgaltatasRegisztracioBiralat } from './szolgaltatasRegisztracioBiralat';

export interface BerbeadasSzolgaltatasRegisztracio { 
    aktualisLepes?: BerbeadasSzolgaltatasRegisztracio.AktualisLepesEnum;
    altalanosLeiras?: string;
    egyediAzonosito?: string;
    elutasitasList?: Array<SzolgaltatasRegisztracioBiralat>;
    hosszusagiFok?: number;
    jovahagyasList?: Array<SzolgaltatasRegisztracioBiralat>;
    kepIds?: Array<number>;
    megkozelithetoseg?: string;
    megyeKod?: string;
    nev?: string;
    ownerSzereploId?: number;
    ownerSzereploNev?: string;
    szelessegiFok?: number;
    vegrehajtottLepesek?: Array<BerbeadasSzolgaltatasVegrehajtottLepes>;
}
export namespace BerbeadasSzolgaltatasRegisztracio {
    export type AktualisLepesEnum = 'ALTALANOSLEIRAS' | 'ELHELYEZKEDES' | 'FENYKEPEK' | 'OSSZEGZES';
    export const AktualisLepesEnum = {
        ALTALANOSLEIRAS: 'ALTALANOSLEIRAS' as AktualisLepesEnum,
        ELHELYEZKEDES: 'ELHELYEZKEDES' as AktualisLepesEnum,
        FENYKEPEK: 'FENYKEPEK' as AktualisLepesEnum,
        OSSZEGZES: 'OSSZEGZES' as AktualisLepesEnum
    };
}