/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { HalgazdalkodoHozzarendelesRequest } from '../model/halgazdalkodoHozzarendelesRequest';
import { HalgazdalkodoLevalasztasRequest } from '../model/halgazdalkodoLevalasztasRequest';
import { SzervezetAdapter } from '../model/szervezetAdapter';
import { Vizterulet } from '../model/vizterulet';
import { VizteruletFilter } from '../model/vizteruletFilter';
import { VizteruletLetrehozasRequest } from '../model/vizteruletLetrehozasRequest';
import { VizteruletModositasRequest } from '../model/vizteruletModositasRequest';
import { VizteruletRequest } from '../model/vizteruletRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class VizteruletMgmtControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * countByFilter
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countByFilterUsingPOST(body: VizteruletFilter, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public countByFilterUsingPOST(body: VizteruletFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public countByFilterUsingPOST(body: VizteruletFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public countByFilterUsingPOST(body: VizteruletFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling countByFilterUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/vizteruletmgmt/countbyfilter`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchAll
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchAllUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<Vizterulet>>;
    public fetchAllUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Vizterulet>>>;
    public fetchAllUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Vizterulet>>>;
    public fetchAllUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Vizterulet>>('post',`${this.basePath}/api/mohosz/vizteruletmgmt/fetchall`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchById
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchByIdUsingPOST(body: VizteruletRequest, observe?: 'body', reportProgress?: boolean): Observable<Vizterulet>;
    public fetchByIdUsingPOST(body: VizteruletRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Vizterulet>>;
    public fetchByIdUsingPOST(body: VizteruletRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Vizterulet>>;
    public fetchByIdUsingPOST(body: VizteruletRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fetchByIdUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Vizterulet>('post',`${this.basePath}/api/mohosz/vizteruletmgmt/fetch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findByFilter
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findByFilterUsingPOST(body: VizteruletFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<Vizterulet>>;
    public findByFilterUsingPOST(body: VizteruletFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Vizterulet>>>;
    public findByFilterUsingPOST(body: VizteruletFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Vizterulet>>>;
    public findByFilterUsingPOST(body: VizteruletFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findByFilterUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<Vizterulet>>('post',`${this.basePath}/api/mohosz/vizteruletmgmt/findbyfilter`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * halgazdalkodoHozzarendeles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public halgazdalkodoHozzarendelesUsingPOST(body: HalgazdalkodoHozzarendelesRequest, observe?: 'body', reportProgress?: boolean): Observable<Vizterulet>;
    public halgazdalkodoHozzarendelesUsingPOST(body: HalgazdalkodoHozzarendelesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Vizterulet>>;
    public halgazdalkodoHozzarendelesUsingPOST(body: HalgazdalkodoHozzarendelesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Vizterulet>>;
    public halgazdalkodoHozzarendelesUsingPOST(body: HalgazdalkodoHozzarendelesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling halgazdalkodoHozzarendelesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Vizterulet>('post',`${this.basePath}/api/mohosz/vizteruletmgmt/halgazdalkodo-hozzarendeles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * halgazdalkodoLevalasztas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public halgazdalkodoLevalasztasUsingPOST(body: HalgazdalkodoLevalasztasRequest, observe?: 'body', reportProgress?: boolean): Observable<Vizterulet>;
    public halgazdalkodoLevalasztasUsingPOST(body: HalgazdalkodoLevalasztasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Vizterulet>>;
    public halgazdalkodoLevalasztasUsingPOST(body: HalgazdalkodoLevalasztasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Vizterulet>>;
    public halgazdalkodoLevalasztasUsingPOST(body: HalgazdalkodoLevalasztasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling halgazdalkodoLevalasztasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Vizterulet>('post',`${this.basePath}/api/mohosz/vizteruletmgmt/halgazdalkodo-levalasztas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * halgazdalkodoModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public halgazdalkodoModositasUsingPOST(body: HalgazdalkodoHozzarendelesRequest, observe?: 'body', reportProgress?: boolean): Observable<Vizterulet>;
    public halgazdalkodoModositasUsingPOST(body: HalgazdalkodoHozzarendelesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Vizterulet>>;
    public halgazdalkodoModositasUsingPOST(body: HalgazdalkodoHozzarendelesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Vizterulet>>;
    public halgazdalkodoModositasUsingPOST(body: HalgazdalkodoHozzarendelesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling halgazdalkodoModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Vizterulet>('post',`${this.basePath}/api/mohosz/vizteruletmgmt/halgazdalkodo-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * letrehozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public letrehozasUsingPOST(body: VizteruletLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<Vizterulet>;
    public letrehozasUsingPOST(body: VizteruletLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Vizterulet>>;
    public letrehozasUsingPOST(body: VizteruletLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Vizterulet>>;
    public letrehozasUsingPOST(body: VizteruletLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling letrehozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Vizterulet>('post',`${this.basePath}/api/mohosz/vizteruletmgmt/letrehozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * modositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modositasUsingPOST(body: VizteruletModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<Vizterulet>;
    public modositasUsingPOST(body: VizteruletModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Vizterulet>>;
    public modositasUsingPOST(body: VizteruletModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Vizterulet>>;
    public modositasUsingPOST(body: VizteruletModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling modositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Vizterulet>('post',`${this.basePath}/api/mohosz/vizteruletmgmt/modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * valaszthatoSzervezetek
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public valaszthatoSzervezetekUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<SzervezetAdapter>>;
    public valaszthatoSzervezetekUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SzervezetAdapter>>>;
    public valaszthatoSzervezetekUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SzervezetAdapter>>>;
    public valaszthatoSzervezetekUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SzervezetAdapter>>('post',`${this.basePath}/api/mohosz/vizteruletmgmt/valaszthato-szervezetek`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
