/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SzervezetVizteruletCsoportRequest { 
    globalSearch?: string;
    limit?: number;
    nemSajatVizterulet?: boolean;
    offset?: number;
    statuszList?: Array<SzervezetVizteruletCsoportRequest.StatuszListEnum>;
    tipus?: SzervezetVizteruletCsoportRequest.TipusEnum;
}
export namespace SzervezetVizteruletCsoportRequest {
    export type StatuszListEnum = 'AKTIV' | 'INAKTIV';
    export const StatuszListEnum = {
        AKTIV: 'AKTIV' as StatuszListEnum,
        INAKTIV: 'INAKTIV' as StatuszListEnum
    };
    export type TipusEnum = 'OSSZEVONT_VIZTERULET_CSOPORT' | 'EGYESITETT_VIZTERULET_CSOPORT' | 'OSSZEVONT_ORSZAGOS_VIZTERULET_CSOPORT' | 'VIZTERULET';
    export const TipusEnum = {
        OSSZEVONTVIZTERULETCSOPORT: 'OSSZEVONT_VIZTERULET_CSOPORT' as TipusEnum,
        EGYESITETTVIZTERULETCSOPORT: 'EGYESITETT_VIZTERULET_CSOPORT' as TipusEnum,
        OSSZEVONTORSZAGOSVIZTERULETCSOPORT: 'OSSZEVONT_ORSZAGOS_VIZTERULET_CSOPORT' as TipusEnum,
        VIZTERULET: 'VIZTERULET' as TipusEnum
    };
}