/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AdatEllenorzesFilter { 
    feladatTipusList?: Array<AdatEllenorzesFilter.FeladatTipusListEnum>;
    filterLabels?: Array<AdatEllenorzesFilter.FilterLabelsEnum>;
    folyamatban?: boolean;
    labels?: Array<string>;
    limit?: number;
    randomOrder?: boolean;
    tsQueryString?: string;
}
export namespace AdatEllenorzesFilter {
    export type FeladatTipusListEnum = 'REGISZTRACIOS_ADATELLENORZES' | 'ADATVALTOZAS_ELLENORZES' | 'TOVABBI_UGYINTEZES';
    export const FeladatTipusListEnum = {
        REGISZTRACIOSADATELLENORZES: 'REGISZTRACIOS_ADATELLENORZES' as FeladatTipusListEnum,
        ADATVALTOZASELLENORZES: 'ADATVALTOZAS_ELLENORZES' as FeladatTipusListEnum,
        TOVABBIUGYINTEZES: 'TOVABBI_UGYINTEZES' as FeladatTipusListEnum
    };
    export type FilterLabelsEnum = 'ONLINE' | 'KULFOLDI' | 'EMPTYLABELS';
    export const FilterLabelsEnum = {
        ONLINE: 'ONLINE' as FilterLabelsEnum,
        KULFOLDI: 'KULFOLDI' as FilterLabelsEnum,
        EMPTYLABELS: 'EMPTYLABELS' as FilterLabelsEnum
    };
}