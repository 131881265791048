/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Cim } from './cim';
import { VizsgabizottsagVizsgaContext } from './vizsgabizottsagVizsgaContext';
import { VizsgabizottsagVizsgaTag } from './vizsgabizottsagVizsgaTag';
import { VizsgaraJelentkezett } from './vizsgaraJelentkezett';

export interface VizsgabizottsagVizsga { 
    availableCommands?: Array<VizsgabizottsagVizsga.AvailableCommandsEnum>;
    context?: VizsgabizottsagVizsgaContext;
    globalVizsgahelyId?: string;
    globalVizsgaidopontId?: string;
    id?: number;
    idopont?: Date;
    jelentkezesiHatarido?: Date;
    maxJelentkezokSzama?: number;
    statusz?: VizsgabizottsagVizsga.StatuszEnum;
    szervezetId?: number;
    szervezetNev?: string;
    tagList?: Array<VizsgabizottsagVizsgaTag>;
    vege?: boolean;
    vizsgabizottsagElerhetoseg?: string;
    vizsgabizottsagId?: number;
    vizsgabizottsagNev?: string;
    vizsgahelyCim?: Cim;
    vizsgahelyId?: number;
    vizsgaidopontId?: number;
    vizsgaraJelentkezettList?: Array<VizsgaraJelentkezett>;
}
export namespace VizsgabizottsagVizsga {
    export type AvailableCommandsEnum = 'VIZSGAINDITAS' | 'VIZSGAFOLYTATAS' | 'LEMONDAS' | 'KAPCSOLATTARTAS_MODOSITAS' | 'VBJELENTKEZTETES' | 'JEGYZOKONYVNYOMTATAS' | 'MEGHIUSITAS';
    export const AvailableCommandsEnum = {
        VIZSGAINDITAS: 'VIZSGAINDITAS' as AvailableCommandsEnum,
        VIZSGAFOLYTATAS: 'VIZSGAFOLYTATAS' as AvailableCommandsEnum,
        LEMONDAS: 'LEMONDAS' as AvailableCommandsEnum,
        KAPCSOLATTARTASMODOSITAS: 'KAPCSOLATTARTAS_MODOSITAS' as AvailableCommandsEnum,
        VBJELENTKEZTETES: 'VBJELENTKEZTETES' as AvailableCommandsEnum,
        JEGYZOKONYVNYOMTATAS: 'JEGYZOKONYVNYOMTATAS' as AvailableCommandsEnum,
        MEGHIUSITAS: 'MEGHIUSITAS' as AvailableCommandsEnum
    };
    export type StatuszEnum = 'FOLYAMATBAN' | 'INDITHATO' | 'TERVEZETT' | 'LEZARULT' | 'MEGHIUSULT';
    export const StatuszEnum = {
        FOLYAMATBAN: 'FOLYAMATBAN' as StatuszEnum,
        INDITHATO: 'INDITHATO' as StatuszEnum,
        TERVEZETT: 'TERVEZETT' as StatuszEnum,
        LEZARULT: 'LEZARULT' as StatuszEnum,
        MEGHIUSULT: 'MEGHIUSULT' as StatuszEnum
    };
}