/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { HatarozatFelulbiralasFeloldasaRequest } from '../model/hatarozatFelulbiralasFeloldasaRequest';
import { HatarozatotFelulbiralRequest } from '../model/hatarozatotFelulbiralRequest';
import { NebihEltiltas } from '../model/nebihEltiltas';
import { NebihEltiltasFilter } from '../model/nebihEltiltasFilter';
import { NebihEltiltasHorgaszhozKapcsolRequest } from '../model/nebihEltiltasHorgaszhozKapcsolRequest';
import { NebihEltiltasHorgaszrolLevalasztRequest } from '../model/nebihEltiltasHorgaszrolLevalasztRequest';
import { NebihEltiltasKezelesResponse } from '../model/nebihEltiltasKezelesResponse';
import { NebihEltiltasKoteg } from '../model/nebihEltiltasKoteg';
import { NebihEltiltasRequest } from '../model/nebihEltiltasRequest';
import { NebihEltiltasSearchRequest } from '../model/nebihEltiltasSearchRequest';
import { NebihPartnerEltiltasaiRequest } from '../model/nebihPartnerEltiltasaiRequest';
import { NebihSzemelyEltiltasok } from '../model/nebihSzemelyEltiltasok';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class NebihEltiltasControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * eltiltasFeltoltes
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public eltiltasFeltoltesUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public eltiltasFeltoltesUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public eltiltasFeltoltesUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public eltiltasFeltoltesUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling eltiltasFeltoltesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/eltiltas/nebih/eltiltasfeltoltes`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchAllNebihEltiltasKoteg
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchAllNebihEltiltasKotegUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<NebihEltiltasKoteg>>;
    public fetchAllNebihEltiltasKotegUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NebihEltiltasKoteg>>>;
    public fetchAllNebihEltiltasKotegUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NebihEltiltasKoteg>>>;
    public fetchAllNebihEltiltasKotegUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<NebihEltiltasKoteg>>('get',`${this.basePath}/api/mohosz/eltiltas/nebih/fetchall-nebiheltiltaskoteg`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchKotegCsvContent
     * 
     * @param kotegId kotegId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchKotegCsvContentUsingGET(kotegId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public fetchKotegCsvContentUsingGET(kotegId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public fetchKotegCsvContentUsingGET(kotegId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public fetchKotegCsvContentUsingGET(kotegId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kotegId === null || kotegId === undefined) {
            throw new Error('Required parameter kotegId was null or undefined when calling fetchKotegCsvContentUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (kotegId !== undefined && kotegId !== null) {
            queryParameters = queryParameters.set('kotegId', <any>kotegId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/eltiltas/nebih/fetch-koteg-csvcontent`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findAll
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAllUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<NebihSzemelyEltiltasok>>;
    public findAllUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NebihSzemelyEltiltasok>>>;
    public findAllUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NebihSzemelyEltiltasok>>>;
    public findAllUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<NebihSzemelyEltiltasok>>('post',`${this.basePath}/api/mohosz/eltiltas/nebih/findall`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findByFilter
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findByFilterUsingPOST(body: NebihEltiltasFilter, observe?: 'body', reportProgress?: boolean): Observable<NebihEltiltasKezelesResponse>;
    public findByFilterUsingPOST(body: NebihEltiltasFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NebihEltiltasKezelesResponse>>;
    public findByFilterUsingPOST(body: NebihEltiltasFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NebihEltiltasKezelesResponse>>;
    public findByFilterUsingPOST(body: NebihEltiltasFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findByFilterUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<NebihEltiltasKezelesResponse>('post',`${this.basePath}/api/mohosz/eltiltas/nebih/findby-filter`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findNebihEltiltas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findNebihEltiltasUsingPOST(body: NebihEltiltasRequest, observe?: 'body', reportProgress?: boolean): Observable<NebihEltiltas>;
    public findNebihEltiltasUsingPOST(body: NebihEltiltasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NebihEltiltas>>;
    public findNebihEltiltasUsingPOST(body: NebihEltiltasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NebihEltiltas>>;
    public findNebihEltiltasUsingPOST(body: NebihEltiltasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findNebihEltiltasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<NebihEltiltas>('post',`${this.basePath}/api/mohosz/eltiltas/nebih/find-nebih-eltiltas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findNebihSzemelyEltiltasList
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findNebihSzemelyEltiltasListUsingPOST(body: NebihPartnerEltiltasaiRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<NebihEltiltas>>;
    public findNebihSzemelyEltiltasListUsingPOST(body: NebihPartnerEltiltasaiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NebihEltiltas>>>;
    public findNebihSzemelyEltiltasListUsingPOST(body: NebihPartnerEltiltasaiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NebihEltiltas>>>;
    public findNebihSzemelyEltiltasListUsingPOST(body: NebihPartnerEltiltasaiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findNebihSzemelyEltiltasListUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<NebihEltiltas>>('post',`${this.basePath}/api/mohosz/eltiltas/nebih/find-nebih-szemely-eltiltaslist`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * hatarozatManualisFelulbiralasFeloldasa
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hatarozatManualisFelulbiralasFeloldasaUsingPOST(body: HatarozatFelulbiralasFeloldasaRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public hatarozatManualisFelulbiralasFeloldasaUsingPOST(body: HatarozatFelulbiralasFeloldasaRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public hatarozatManualisFelulbiralasFeloldasaUsingPOST(body: HatarozatFelulbiralasFeloldasaRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public hatarozatManualisFelulbiralasFeloldasaUsingPOST(body: HatarozatFelulbiralasFeloldasaRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling hatarozatManualisFelulbiralasFeloldasaUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/eltiltas/nebih/hatarozat-manualis-felulbiralas-feloldasa`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * hatarozatotManualisanFelulbiral
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hatarozatotManualisanFelulbiralUsingPOST(body: HatarozatotFelulbiralRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public hatarozatotManualisanFelulbiralUsingPOST(body: HatarozatotFelulbiralRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public hatarozatotManualisanFelulbiralUsingPOST(body: HatarozatotFelulbiralRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public hatarozatotManualisanFelulbiralUsingPOST(body: HatarozatotFelulbiralRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling hatarozatotManualisanFelulbiralUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/eltiltas/nebih/hatarozatot-manualisan-felulbiral`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kotegUjraBedolgozas
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kotegUjraBedolgozasUsingGET(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public kotegUjraBedolgozasUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public kotegUjraBedolgozasUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public kotegUjraBedolgozasUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/eltiltas/nebih/koteg-ujra-bedolgozas`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * nebihEltiltastHorgaszhozKapcsol
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public nebihEltiltastHorgaszhozKapcsolUsingPOST(body: NebihEltiltasHorgaszhozKapcsolRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public nebihEltiltastHorgaszhozKapcsolUsingPOST(body: NebihEltiltasHorgaszhozKapcsolRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public nebihEltiltastHorgaszhozKapcsolUsingPOST(body: NebihEltiltasHorgaszhozKapcsolRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public nebihEltiltastHorgaszhozKapcsolUsingPOST(body: NebihEltiltasHorgaszhozKapcsolRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling nebihEltiltastHorgaszhozKapcsolUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/eltiltas/nebih/eltiltast-horgaszhoz-kapcsol`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * nebihEltiltastHorgaszrolLevalaszt
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public nebihEltiltastHorgaszrolLevalasztUsingPOST(body: NebihEltiltasHorgaszrolLevalasztRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public nebihEltiltastHorgaszrolLevalasztUsingPOST(body: NebihEltiltasHorgaszrolLevalasztRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public nebihEltiltastHorgaszrolLevalasztUsingPOST(body: NebihEltiltasHorgaszrolLevalasztRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public nebihEltiltastHorgaszrolLevalasztUsingPOST(body: NebihEltiltasHorgaszrolLevalasztRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling nebihEltiltastHorgaszrolLevalasztUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/eltiltas/nebih/eltiltast-horgaszrol-levalaszt`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchUsingPOST(body: NebihEltiltasSearchRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<NebihSzemelyEltiltasok>>;
    public searchUsingPOST(body: NebihEltiltasSearchRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NebihSzemelyEltiltasok>>>;
    public searchUsingPOST(body: NebihEltiltasSearchRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NebihSzemelyEltiltasok>>>;
    public searchUsingPOST(body: NebihEltiltasSearchRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling searchUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<NebihSzemelyEltiltasok>>('post',`${this.basePath}/api/mohosz/eltiltas/nebih/search`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
