/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SzereploCimzett } from './szereploCimzett';

export interface UzenetCimzett { 
    csatornaSet?: Array<UzenetCimzett.CsatornaSetEnum>;
    egyesuletSzervezetSet?: Array<SzereploCimzett>;
    szemelySet?: Array<SzereploCimzett>;
    szervezetSet?: Array<SzereploCimzett>;
}
export namespace UzenetCimzett {
    export type CsatornaSetEnum = 'ONLINE' | 'UGYINTEZO' | 'UGYFELSZOLGALAT' | 'ALTALANOS';
    export const CsatornaSetEnum = {
        ONLINE: 'ONLINE' as CsatornaSetEnum,
        UGYINTEZO: 'UGYINTEZO' as CsatornaSetEnum,
        UGYFELSZOLGALAT: 'UGYFELSZOLGALAT' as CsatornaSetEnum,
        ALTALANOS: 'ALTALANOS' as CsatornaSetEnum
    };
}