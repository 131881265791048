/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SajatSzemelyAlapadatRequest { 
    anyjaNeve?: string;
    keresztnev?: string;
    nem?: SajatSzemelyAlapadatRequest.NemEnum;
    szuletesiDatum?: string;
    szuletesiHely?: string;
    vezeteknev?: string;
}
export namespace SajatSzemelyAlapadatRequest {
    export type NemEnum = 'NINCS_MEGADVA' | 'NO' | 'FERFI';
    export const NemEnum = {
        NINCSMEGADVA: 'NINCS_MEGADVA' as NemEnum,
        NO: 'NO' as NemEnum,
        FERFI: 'FERFI' as NemEnum
    };
}