/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UzenetCimzett } from './uzenetCimzett';
import { UzenetDetails } from './uzenetDetails';

export interface Uzenet { 
    aktiv?: boolean;
    cimzett?: UzenetCimzett;
    details?: UzenetDetails;
    feladoSzereploId?: number;
    fromDate?: string;
    id?: number;
    modositva?: Date;
    statusz?: Uzenet.StatuszEnum;
    tipus?: Uzenet.TipusEnum;
    toDate?: string;
    uzenet?: string;
    uzenetCim?: string;
}
export namespace Uzenet {
    export type StatuszEnum = 'ERVENYES' | 'ERVENYTELEN';
    export const StatuszEnum = {
        ERVENYES: 'ERVENYES' as StatuszEnum,
        ERVENYTELEN: 'ERVENYTELEN' as StatuszEnum
    };
    export type TipusEnum = 'ALTALANOS' | 'SURGOS' | 'FELADAT' | 'ERTESITES';
    export const TipusEnum = {
        ALTALANOS: 'ALTALANOS' as TipusEnum,
        SURGOS: 'SURGOS' as TipusEnum,
        FELADAT: 'FELADAT' as TipusEnum,
        ERTESITES: 'ERTESITES' as TipusEnum
    };
}