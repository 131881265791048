/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { NebihEltiltas } from './nebihEltiltas';
import { NebihEltiltasCim } from './nebihEltiltasCim';
import { NebihSzemelyEltiltasokDetails } from './nebihSzemelyEltiltasokDetails';

export interface NebihSzemelyEltiltasok { 
    anyjaNeve?: string;
    availableCommands?: Array<NebihSzemelyEltiltasok.AvailableCommandsEnum>;
    details?: NebihSzemelyEltiltasokDetails;
    eltiltasCim?: NebihEltiltasCim;
    horgaszAzonosito?: string;
    keresztnev?: string;
    manualisanKapcsolt?: boolean;
    nebihPartnerId?: string;
    szemely4TAdataiValtoztak?: boolean;
    szemelyEltiltasok?: Array<NebihEltiltas>;
    szemelyEltiltva?: boolean;
    szemelyId?: number;
    szuletesiDatum?: string;
    szuletesiHely?: string;
    titulus?: string;
    vezeteknev?: string;
}
export namespace NebihSzemelyEltiltasok {
    export type AvailableCommandsEnum = 'KAPCSOLAS' | 'LEVALASZTAS' | 'HATAROZATOK';
    export const AvailableCommandsEnum = {
        KAPCSOLAS: 'KAPCSOLAS' as AvailableCommandsEnum,
        LEVALASZTAS: 'LEVALASZTAS' as AvailableCommandsEnum,
        HATAROZATOK: 'HATAROZATOK' as AvailableCommandsEnum
    };
}