/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SzemelyJegy { 
    allamiJegyTipus?: SzemelyJegy.AllamiJegyTipusEnum;
    ervenyessegiEv?: number;
    sorszam?: string;
}
export namespace SzemelyJegy {
    export type AllamiJegyTipusEnum = 'TURISTA_ALLAMIJEGY' | 'ALLAMIJEGY';
    export const AllamiJegyTipusEnum = {
        TURISTAALLAMIJEGY: 'TURISTA_ALLAMIJEGY' as AllamiJegyTipusEnum,
        ALLAMIJEGY: 'ALLAMIJEGY' as AllamiJegyTipusEnum
    };
}