/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EllenorzesiTevekenysegKontextus } from './ellenorzesiTevekenysegKontextus';
import { EllenorzottSzemelyAdatai } from './ellenorzottSzemelyAdatai';

export interface EllenorzesiTevekenyseg { 
    ellenorzottSzemelyAdatai?: EllenorzottSzemelyAdatai;
    id?: number;
    kontextus?: EllenorzesiTevekenysegKontextus;
    minosites?: EllenorzesiTevekenyseg.MinositesEnum;
    szabalysertesId?: number;
}
export namespace EllenorzesiTevekenyseg {
    export type MinositesEnum = 'RENDBEN' | 'SZABALYSERTES';
    export const MinositesEnum = {
        RENDBEN: 'RENDBEN' as MinositesEnum,
        SZABALYSERTES: 'SZABALYSERTES' as MinositesEnum
    };
}