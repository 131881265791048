/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UjragyartasIgenylesModositasRequest { 
    dokumentumKepId?: number;
    ervenyessegMegegyezikAzEredetivel?: boolean;
    igenylesOka?: UjragyartasIgenylesModositasRequest.IgenylesOkaEnum;
    szemelyId?: number;
}
export namespace UjragyartasIgenylesModositasRequest {
    export type IgenylesOkaEnum = 'ELSO_GYARTAS' | 'TERMESZETES_MODON_MEGVALTOZO_ADAT' | 'KARTYA_HIANY' | 'IGAZOLT_KARTYA_HIANY' | 'ADATHIBA_A_KARTYAN_HORGASZ_HIBAJABOL' | 'ADATHIBA_A_KARTYAN_UGYFELSZOLGALAT_HIBAJABOL' | 'LEJART_KARTYA';
    export const IgenylesOkaEnum = {
        ELSOGYARTAS: 'ELSO_GYARTAS' as IgenylesOkaEnum,
        TERMESZETESMODONMEGVALTOZOADAT: 'TERMESZETES_MODON_MEGVALTOZO_ADAT' as IgenylesOkaEnum,
        KARTYAHIANY: 'KARTYA_HIANY' as IgenylesOkaEnum,
        IGAZOLTKARTYAHIANY: 'IGAZOLT_KARTYA_HIANY' as IgenylesOkaEnum,
        ADATHIBAAKARTYANHORGASZHIBAJABOL: 'ADATHIBA_A_KARTYAN_HORGASZ_HIBAJABOL' as IgenylesOkaEnum,
        ADATHIBAAKARTYANUGYFELSZOLGALATHIBAJABOL: 'ADATHIBA_A_KARTYAN_UGYFELSZOLGALAT_HIBAJABOL' as IgenylesOkaEnum,
        LEJARTKARTYA: 'LEJART_KARTYA' as IgenylesOkaEnum
    };
}