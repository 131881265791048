/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MahorElofizetesErtekesitesFilter { 
    ertekesitoSzervezetId?: number;
    ev?: number;
    fizetesTipus?: MahorElofizetesErtekesitesFilter.FizetesTipusEnum;
    honap?: number;
    limit?: number;
    offset?: number;
}
export namespace MahorElofizetesErtekesitesFilter {
    export type FizetesTipusEnum = 'KH' | 'ERTEKESITESIPONT' | 'RK_FIZETES';
    export const FizetesTipusEnum = {
        KH: 'KH' as FizetesTipusEnum,
        ERTEKESITESIPONT: 'ERTEKESITESIPONT' as FizetesTipusEnum,
        RKFIZETES: 'RK_FIZETES' as FizetesTipusEnum
    };
}