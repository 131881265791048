/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { KosarFizetes } from './kosarFizetes';

export interface HorgaszkartyaIgenylesTortenet { 
    availableCommands?: Array<HorgaszkartyaIgenylesTortenet.AvailableCommandsEnum>;
    dokumentumKepId?: number;
    ervenyessegKezdete?: string;
    ervenyessegVege?: string;
    fizetesFolyamatban?: boolean;
    fizetesOnlineFolytathato?: boolean;
    fizetesreVar?: boolean;
    folyamatban?: boolean;
    gyartasMuvelet?: HorgaszkartyaIgenylesTortenet.GyartasMuveletEnum;
    gyartasraKuldesDatuma?: Date;
    horgaszkartyaGyartasStatusz?: HorgaszkartyaIgenylesTortenet.HorgaszkartyaGyartasStatuszEnum;
    horgaszkartyaTipus?: HorgaszkartyaIgenylesTortenet.HorgaszkartyaTipusEnum;
    id?: number;
    igenylesIdopontja?: Date;
    igenylesOka?: HorgaszkartyaIgenylesTortenet.IgenylesOkaEnum;
    kosarFizetes?: KosarFizetes;
    nullasFizetes?: boolean;
    osszeg?: number;
    postazasDatuma?: string;
    statusz?: HorgaszkartyaIgenylesTortenet.StatuszEnum;
}
export namespace HorgaszkartyaIgenylesTortenet {
    export type AvailableCommandsEnum = 'CSEKKFIZETES' | 'KHFIZETESINDITAS' | 'KHFIZETESFOLYTATAS' | 'NULLASJOVAHAGYAS' | 'IGENYLESMODOSITAS' | 'IGENYLESTORLES' | 'GYARTASVISSZATARTAS';
    export const AvailableCommandsEnum = {
        CSEKKFIZETES: 'CSEKKFIZETES' as AvailableCommandsEnum,
        KHFIZETESINDITAS: 'KHFIZETESINDITAS' as AvailableCommandsEnum,
        KHFIZETESFOLYTATAS: 'KHFIZETESFOLYTATAS' as AvailableCommandsEnum,
        NULLASJOVAHAGYAS: 'NULLASJOVAHAGYAS' as AvailableCommandsEnum,
        IGENYLESMODOSITAS: 'IGENYLESMODOSITAS' as AvailableCommandsEnum,
        IGENYLESTORLES: 'IGENYLESTORLES' as AvailableCommandsEnum,
        GYARTASVISSZATARTAS: 'GYARTASVISSZATARTAS' as AvailableCommandsEnum
    };
    export type GyartasMuveletEnum = 'GYARTAS' | 'UJRAGYARTAS';
    export const GyartasMuveletEnum = {
        GYARTAS: 'GYARTAS' as GyartasMuveletEnum,
        UJRAGYARTAS: 'UJRAGYARTAS' as GyartasMuveletEnum
    };
    export type HorgaszkartyaGyartasStatuszEnum = 'GYARTAS_JOVAHAGYASRA_VAR' | 'GYARTASRA_VAR' | 'GYARTAS_FOLYAMATBAN' | 'GYARTAS_KESZ';
    export const HorgaszkartyaGyartasStatuszEnum = {
        GYARTASJOVAHAGYASRAVAR: 'GYARTAS_JOVAHAGYASRA_VAR' as HorgaszkartyaGyartasStatuszEnum,
        GYARTASRAVAR: 'GYARTASRA_VAR' as HorgaszkartyaGyartasStatuszEnum,
        GYARTASFOLYAMATBAN: 'GYARTAS_FOLYAMATBAN' as HorgaszkartyaGyartasStatuszEnum,
        GYARTASKESZ: 'GYARTAS_KESZ' as HorgaszkartyaGyartasStatuszEnum
    };
    export type HorgaszkartyaTipusEnum = 'MHK' | 'VIP';
    export const HorgaszkartyaTipusEnum = {
        MHK: 'MHK' as HorgaszkartyaTipusEnum,
        VIP: 'VIP' as HorgaszkartyaTipusEnum
    };
    export type IgenylesOkaEnum = 'ELSO_GYARTAS' | 'TERMESZETES_MODON_MEGVALTOZO_ADAT' | 'KARTYA_HIANY' | 'IGAZOLT_KARTYA_HIANY' | 'ADATHIBA_A_KARTYAN_HORGASZ_HIBAJABOL' | 'ADATHIBA_A_KARTYAN_UGYFELSZOLGALAT_HIBAJABOL' | 'LEJART_KARTYA';
    export const IgenylesOkaEnum = {
        ELSOGYARTAS: 'ELSO_GYARTAS' as IgenylesOkaEnum,
        TERMESZETESMODONMEGVALTOZOADAT: 'TERMESZETES_MODON_MEGVALTOZO_ADAT' as IgenylesOkaEnum,
        KARTYAHIANY: 'KARTYA_HIANY' as IgenylesOkaEnum,
        IGAZOLTKARTYAHIANY: 'IGAZOLT_KARTYA_HIANY' as IgenylesOkaEnum,
        ADATHIBAAKARTYANHORGASZHIBAJABOL: 'ADATHIBA_A_KARTYAN_HORGASZ_HIBAJABOL' as IgenylesOkaEnum,
        ADATHIBAAKARTYANUGYFELSZOLGALATHIBAJABOL: 'ADATHIBA_A_KARTYAN_UGYFELSZOLGALAT_HIBAJABOL' as IgenylesOkaEnum,
        LEJARTKARTYA: 'LEJART_KARTYA' as IgenylesOkaEnum
    };
    export type StatuszEnum = 'UGYFELSZOLGALATI_LETREHOZAS_ALATT' | 'HORGASZ_FIZETESRE_VAR' | 'HORGASZ_FIZETES_FOLYAMATBAN' | 'GYARTAS_ALATT' | 'KESZ';
    export const StatuszEnum = {
        UGYFELSZOLGALATILETREHOZASALATT: 'UGYFELSZOLGALATI_LETREHOZAS_ALATT' as StatuszEnum,
        HORGASZFIZETESREVAR: 'HORGASZ_FIZETESRE_VAR' as StatuszEnum,
        HORGASZFIZETESFOLYAMATBAN: 'HORGASZ_FIZETES_FOLYAMATBAN' as StatuszEnum,
        GYARTASALATT: 'GYARTAS_ALATT' as StatuszEnum,
        KESZ: 'KESZ' as StatuszEnum
    };
}