/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MahorElofizetesCiklus } from './mahorElofizetesCiklus';
import { MahorElofizetesDetails } from './mahorElofizetesDetails';

export interface MahorElofizetes { 
    availableCommands?: Array<MahorElofizetes.AvailableCommandsEnum>;
    details?: MahorElofizetesDetails;
    elsoPeldanyEv?: number;
    elsoPeldanyHonap?: number;
    id?: number;
    latestCiklus?: MahorElofizetesCiklus;
    modositva?: Date;
    nonAdminReszletek?: boolean;
    nonAdminSzerkesztheto?: boolean;
    postaStatusz?: MahorElofizetes.PostaStatuszEnum;
    statusz?: MahorElofizetes.StatuszEnum;
    szereploId?: number;
    utolsoBefizetesDatum?: Date;
    utolsoBefizetettCiklus?: MahorElofizetesCiklus;
    utolsoPeldanyEv?: number;
    utolsoPeldanyHonap?: number;
}
export namespace MahorElofizetes {
    export type AvailableCommandsEnum = 'RESZLETEK' | 'MODOSITAS' | 'MANUALIS_POSTARA_KULDENDO' | 'ELOZMENYEK_MEGTEKINTESE' | 'FIZETES_INDITAS' | 'KHFIZETES_FOLYTATAS' | 'ELOFIZETES_MEGUJITAS' | 'ELOFIZETES_MEGUJITAS_VISSZAVONAS' | 'SZEREPLO_KAPCSOLAS' | 'RENDSZEREN_KIVULI_MINOSITES' | 'TORLES' | 'PELDANYSZAM_MODOSITHATO' | 'SZEREPLO_LEVALASZTAS' | 'RENDSZERENKIVULI_FIZETES_MODOSITAS' | 'CIKLUS_TORLES';
    export const AvailableCommandsEnum = {
        RESZLETEK: 'RESZLETEK' as AvailableCommandsEnum,
        MODOSITAS: 'MODOSITAS' as AvailableCommandsEnum,
        MANUALISPOSTARAKULDENDO: 'MANUALIS_POSTARA_KULDENDO' as AvailableCommandsEnum,
        ELOZMENYEKMEGTEKINTESE: 'ELOZMENYEK_MEGTEKINTESE' as AvailableCommandsEnum,
        FIZETESINDITAS: 'FIZETES_INDITAS' as AvailableCommandsEnum,
        KHFIZETESFOLYTATAS: 'KHFIZETES_FOLYTATAS' as AvailableCommandsEnum,
        ELOFIZETESMEGUJITAS: 'ELOFIZETES_MEGUJITAS' as AvailableCommandsEnum,
        ELOFIZETESMEGUJITASVISSZAVONAS: 'ELOFIZETES_MEGUJITAS_VISSZAVONAS' as AvailableCommandsEnum,
        SZEREPLOKAPCSOLAS: 'SZEREPLO_KAPCSOLAS' as AvailableCommandsEnum,
        RENDSZERENKIVULIMINOSITES: 'RENDSZEREN_KIVULI_MINOSITES' as AvailableCommandsEnum,
        TORLES: 'TORLES' as AvailableCommandsEnum,
        PELDANYSZAMMODOSITHATO: 'PELDANYSZAM_MODOSITHATO' as AvailableCommandsEnum,
        SZEREPLOLEVALASZTAS: 'SZEREPLO_LEVALASZTAS' as AvailableCommandsEnum,
        RENDSZERENKIVULIFIZETESMODOSITAS: 'RENDSZERENKIVULI_FIZETES_MODOSITAS' as AvailableCommandsEnum,
        CIKLUSTORLES: 'CIKLUS_TORLES' as AvailableCommandsEnum
    };
    export type PostaStatuszEnum = 'POSTARA_KULDENDO' | 'POSTARA_KULDVE' | 'POSTAN_BEDOLGOZOTT' | 'POSTAN_HIBAS' | 'POSTAN_TOROLVE';
    export const PostaStatuszEnum = {
        POSTARAKULDENDO: 'POSTARA_KULDENDO' as PostaStatuszEnum,
        POSTARAKULDVE: 'POSTARA_KULDVE' as PostaStatuszEnum,
        POSTANBEDOLGOZOTT: 'POSTAN_BEDOLGOZOTT' as PostaStatuszEnum,
        POSTANHIBAS: 'POSTAN_HIBAS' as PostaStatuszEnum,
        POSTANTOROLVE: 'POSTAN_TOROLVE' as PostaStatuszEnum
    };
    export type StatuszEnum = 'FIZETESRE_VAR' | 'FIZETES_FOLYAMATBAN' | 'TOROLT' | 'AKTIV' | 'LEJART';
    export const StatuszEnum = {
        FIZETESREVAR: 'FIZETESRE_VAR' as StatuszEnum,
        FIZETESFOLYAMATBAN: 'FIZETES_FOLYAMATBAN' as StatuszEnum,
        TOROLT: 'TOROLT' as StatuszEnum,
        AKTIV: 'AKTIV' as StatuszEnum,
        LEJART: 'LEJART' as StatuszEnum
    };
}