/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TeruletiJegyElszamolasItem { 
    arresMertek?: number;
    ertekesitettDarabszam?: number;
    maxArresMertek?: number;
    maxRendszerhasznalatiDij?: number;
    minArresMertek?: number;
    minRendszerhasznalatiDij?: number;
    rendszerhasznalatiDij?: number;
    sztornozottDarabszam?: number;
    teljesArresMertek?: number;
    teljesEladasiAr?: number;
    teljesElszamolasiAr?: number;
    teljesRendszerhasznalatiDij?: number;
    teruletiJegyTermekAr?: number;
    teruletiJegyTermekId?: number;
    teruletiJegyTermekNev?: string;
    tovabbiArresMertek?: number;
    vizteruletCsoportId?: number;
    vizteruletCsoportNev?: string;
}