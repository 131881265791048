/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FelfedezesTorzsadat } from '../model/felfedezesTorzsadat';
import { GPSKoordinata } from '../model/gPSKoordinata';
import { HorgaszturizmusPoi } from '../model/horgaszturizmusPoi';
import { HorgaszturizmusPoiFilter } from '../model/horgaszturizmusPoiFilter';
import { HorgaszturizmusPoiItem } from '../model/horgaszturizmusPoiItem';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class FelfedezesControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * countByFilter
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countByFilterUsingPOST(body: HorgaszturizmusPoiFilter, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public countByFilterUsingPOST(body: HorgaszturizmusPoiFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public countByFilterUsingPOST(body: HorgaszturizmusPoiFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public countByFilterUsingPOST(body: HorgaszturizmusPoiFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling countByFilterUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/horgaszturizmus/felfedezes/felfedezes-kereses-szamossag`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findByFilter
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findByFilterUsingPOST(body: HorgaszturizmusPoiFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<HorgaszturizmusPoiItem>>;
    public findByFilterUsingPOST(body: HorgaszturizmusPoiFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HorgaszturizmusPoiItem>>>;
    public findByFilterUsingPOST(body: HorgaszturizmusPoiFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HorgaszturizmusPoiItem>>>;
    public findByFilterUsingPOST(body: HorgaszturizmusPoiFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findByFilterUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<HorgaszturizmusPoiItem>>('post',`${this.basePath}/api/mohosz/horgaszturizmus/felfedezes/felfedezes-kereses`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findNearbyPois
     * 
     * @param body koordinata
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findNearbyPoisUsingPOST(body: GPSKoordinata, observe?: 'body', reportProgress?: boolean): Observable<Array<HorgaszturizmusPoi>>;
    public findNearbyPoisUsingPOST(body: GPSKoordinata, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HorgaszturizmusPoi>>>;
    public findNearbyPoisUsingPOST(body: GPSKoordinata, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HorgaszturizmusPoi>>>;
    public findNearbyPoisUsingPOST(body: GPSKoordinata, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findNearbyPoisUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<HorgaszturizmusPoi>>('post',`${this.basePath}/api/mohosz/horgaszturizmus/felfedezes/felfedezes-nearbypois`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kiemeltSzolgaltatasok
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kiemeltSzolgaltatasokUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<HorgaszturizmusPoiItem>>;
    public kiemeltSzolgaltatasokUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HorgaszturizmusPoiItem>>>;
    public kiemeltSzolgaltatasokUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HorgaszturizmusPoiItem>>>;
    public kiemeltSzolgaltatasokUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<HorgaszturizmusPoiItem>>('post',`${this.basePath}/api/mohosz/horgaszturizmus/felfedezes/felfedezes-kiemeltszolgaltatasok`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * torzsadat
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public torzsadatUsingGET(observe?: 'body', reportProgress?: boolean): Observable<FelfedezesTorzsadat>;
    public torzsadatUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FelfedezesTorzsadat>>;
    public torzsadatUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FelfedezesTorzsadat>>;
    public torzsadatUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FelfedezesTorzsadat>('get',`${this.basePath}/api/mohosz/horgaszturizmus/felfedezes/felfedezes-torzsadat`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
