/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Cim } from './cim';
import { VizteruletEltiltas } from './vizteruletEltiltas';

export interface VizetuletSzemelyEltiltasokDetails { 
    anyjaNeve?: string;
    eltiltasId?: number;
    eltiltasok?: Array<VizteruletEltiltas>;
    horgaszAzonosito?: string;
    keresztnev?: string;
    lakcim?: Cim;
    statusz?: VizetuletSzemelyEltiltasokDetails.StatuszEnum;
    szemelyId?: number;
    szuletesiDatum?: string;
    szuletesiHely?: string;
    utolsoEltiltasKezdete?: string;
    utolsoEltiltasVege?: string;
    vezeteknev?: string;
    vizterkod?: string;
    vizteruletId?: number;
    vizteruletNev?: string;
}
export namespace VizetuletSzemelyEltiltasokDetails {
    export type StatuszEnum = 'AKTIV' | 'INAKTIV' | 'FELOLDOTT';
    export const StatuszEnum = {
        AKTIV: 'AKTIV' as StatuszEnum,
        INAKTIV: 'INAKTIV' as StatuszEnum,
        FELOLDOTT: 'FELOLDOTT' as StatuszEnum
    };
}