/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CsekkesFizetesElismeresContext } from './csekkesFizetesElismeresContext';
import { CsekkesFizetesSzemely } from './csekkesFizetesSzemely';

export interface CsekkesFizetesElismeres { 
    arvaPostaiElismeres?: boolean;
    context?: CsekkesFizetesElismeresContext;
    elismerve?: boolean;
    fizetesId?: string;
    megbizoSzervezetSzereploId?: number;
    postaiElismeres?: boolean;
    postaiTeljesitesDatum?: string;
    szemely?: CsekkesFizetesSzemely;
    tiffIndex?: number;
    ugyintezoSzemelyId?: number;
    ugyintezoiAllapot?: CsekkesFizetesElismeres.UgyintezoiAllapotEnum;
    ugyintezoiFeldolgozas?: boolean;
    ugyintezoiTeljesitesDatum?: string;
}
export namespace CsekkesFizetesElismeres {
    export type UgyintezoiAllapotEnum = 'ELISMERVE' | 'ELUTASITVA';
    export const UgyintezoiAllapotEnum = {
        ELISMERVE: 'ELISMERVE' as UgyintezoiAllapotEnum,
        ELUTASITVA: 'ELUTASITVA' as UgyintezoiAllapotEnum
    };
}