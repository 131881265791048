/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SzolgaltatoAlapadat { 
    adoszam?: string;
    nev?: string;
    szervezetiForma?: SzolgaltatoAlapadat.SzervezetiFormaEnum;
}
export namespace SzolgaltatoAlapadat {
    export type SzervezetiFormaEnum = 'KORLATOLTFELELOSSEGUTARSASAG' | 'RESZVENYTARSASAG' | 'BETETITARSASAG' | 'SZOVETKEZET' | 'NONPROFITGAZDASAGITARSASAG' | 'EGYEBJOGISZEMELYISEGUVALLALKOZAS' | 'EGYENICEG' | 'EGYENIVALLALKOZO' | 'ADOSZAMMALRENDELKEZOMAGANSZEMELY' | 'ALAPITVANY' | 'SZOVETSEG' | 'EGYESULET' | 'EGYEBNONPROFITSZERVEZET' | 'KOLTSEGVETESISZERV';
    export const SzervezetiFormaEnum = {
        KORLATOLTFELELOSSEGUTARSASAG: 'KORLATOLTFELELOSSEGUTARSASAG' as SzervezetiFormaEnum,
        RESZVENYTARSASAG: 'RESZVENYTARSASAG' as SzervezetiFormaEnum,
        BETETITARSASAG: 'BETETITARSASAG' as SzervezetiFormaEnum,
        SZOVETKEZET: 'SZOVETKEZET' as SzervezetiFormaEnum,
        NONPROFITGAZDASAGITARSASAG: 'NONPROFITGAZDASAGITARSASAG' as SzervezetiFormaEnum,
        EGYEBJOGISZEMELYISEGUVALLALKOZAS: 'EGYEBJOGISZEMELYISEGUVALLALKOZAS' as SzervezetiFormaEnum,
        EGYENICEG: 'EGYENICEG' as SzervezetiFormaEnum,
        EGYENIVALLALKOZO: 'EGYENIVALLALKOZO' as SzervezetiFormaEnum,
        ADOSZAMMALRENDELKEZOMAGANSZEMELY: 'ADOSZAMMALRENDELKEZOMAGANSZEMELY' as SzervezetiFormaEnum,
        ALAPITVANY: 'ALAPITVANY' as SzervezetiFormaEnum,
        SZOVETSEG: 'SZOVETSEG' as SzervezetiFormaEnum,
        EGYESULET: 'EGYESULET' as SzervezetiFormaEnum,
        EGYEBNONPROFITSZERVEZET: 'EGYEBNONPROFITSZERVEZET' as SzervezetiFormaEnum,
        KOLTSEGVETESISZERV: 'KOLTSEGVETESISZERV' as SzervezetiFormaEnum
    };
}