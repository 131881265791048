/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Szemely } from '../model/szemely';
import { SzemelyKezbesitesResponse } from '../model/szemelyKezbesitesResponse';
import { SzemelySearchRequest } from '../model/szemelySearchRequest';
import { SzereploUzenetKezbesitesFilter } from '../model/szereploUzenetKezbesitesFilter';
import { SzervezetAdapter } from '../model/szervezetAdapter';
import { SzervezetFilter } from '../model/szervezetFilter';
import { Uzenet } from '../model/uzenet';
import { UzenetElvegezveRequest } from '../model/uzenetElvegezveRequest';
import { UzenetKezbesitesRequest } from '../model/uzenetKezbesitesRequest';
import { UzenetLetrehozasRequest } from '../model/uzenetLetrehozasRequest';
import { UzenetModositasRequest } from '../model/uzenetModositasRequest';
import { UzenetRequest } from '../model/uzenetRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UzenetMgmtControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * cimzettKeresoElerhetoEgyesuletek
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cimzettKeresoElerhetoEgyesuletekUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<SzervezetAdapter>>;
    public cimzettKeresoElerhetoEgyesuletekUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SzervezetAdapter>>>;
    public cimzettKeresoElerhetoEgyesuletekUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SzervezetAdapter>>>;
    public cimzettKeresoElerhetoEgyesuletekUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SzervezetAdapter>>('post',`${this.basePath}/api/mohosz/uzenetmgmt/cimzettkereso-elerhetoegyesuletek`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * cimzettKeresoSzemelyKereses
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cimzettKeresoSzemelyKeresesUsingPOST(body: SzemelySearchRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<Szemely>>;
    public cimzettKeresoSzemelyKeresesUsingPOST(body: SzemelySearchRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Szemely>>>;
    public cimzettKeresoSzemelyKeresesUsingPOST(body: SzemelySearchRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Szemely>>>;
    public cimzettKeresoSzemelyKeresesUsingPOST(body: SzemelySearchRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling cimzettKeresoSzemelyKeresesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<Szemely>>('post',`${this.basePath}/api/mohosz/uzenetmgmt/cimzettkereso-szemely-kereses`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * cimzettKeresoSzemelySzervezetRendelkezok
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cimzettKeresoSzemelySzervezetRendelkezokUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<Szemely>>;
    public cimzettKeresoSzemelySzervezetRendelkezokUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Szemely>>>;
    public cimzettKeresoSzemelySzervezetRendelkezokUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Szemely>>>;
    public cimzettKeresoSzemelySzervezetRendelkezokUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Szemely>>('post',`${this.basePath}/api/mohosz/uzenetmgmt/cimzettkereso-szemely-szervezetrendelkezok`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * cimzettKeresoSzervezetKereses
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cimzettKeresoSzervezetKeresesUsingPOST(body: SzervezetFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<SzervezetAdapter>>;
    public cimzettKeresoSzervezetKeresesUsingPOST(body: SzervezetFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SzervezetAdapter>>>;
    public cimzettKeresoSzervezetKeresesUsingPOST(body: SzervezetFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SzervezetAdapter>>>;
    public cimzettKeresoSzervezetKeresesUsingPOST(body: SzervezetFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling cimzettKeresoSzervezetKeresesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<SzervezetAdapter>>('post',`${this.basePath}/api/mohosz/uzenetmgmt/cimzettkereso-szervezet-kereses`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * feladatElvegezve
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public feladatElvegezveUsingPOST(body: UzenetElvegezveRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public feladatElvegezveUsingPOST(body: UzenetElvegezveRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public feladatElvegezveUsingPOST(body: UzenetElvegezveRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public feladatElvegezveUsingPOST(body: UzenetElvegezveRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling feladatElvegezveUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/uzenetmgmt/uzenet-feladat-elvegezve`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * uzenetAktivalas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uzenetAktivalasUsingPOST(body: UzenetRequest, observe?: 'body', reportProgress?: boolean): Observable<Uzenet>;
    public uzenetAktivalasUsingPOST(body: UzenetRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Uzenet>>;
    public uzenetAktivalasUsingPOST(body: UzenetRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Uzenet>>;
    public uzenetAktivalasUsingPOST(body: UzenetRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling uzenetAktivalasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Uzenet>('post',`${this.basePath}/api/mohosz/uzenetmgmt/uzenet-aktivalas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * uzenetInaktivalas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uzenetInaktivalasUsingPOST(body: UzenetRequest, observe?: 'body', reportProgress?: boolean): Observable<Uzenet>;
    public uzenetInaktivalasUsingPOST(body: UzenetRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Uzenet>>;
    public uzenetInaktivalasUsingPOST(body: UzenetRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Uzenet>>;
    public uzenetInaktivalasUsingPOST(body: UzenetRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling uzenetInaktivalasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Uzenet>('post',`${this.basePath}/api/mohosz/uzenetmgmt/uzenet-inaktivalas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * uzenetKezbesitesListazas
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uzenetKezbesitesListazasUsingPOST(body: SzereploUzenetKezbesitesFilter, observe?: 'body', reportProgress?: boolean): Observable<SzemelyKezbesitesResponse>;
    public uzenetKezbesitesListazasUsingPOST(body: SzereploUzenetKezbesitesFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SzemelyKezbesitesResponse>>;
    public uzenetKezbesitesListazasUsingPOST(body: SzereploUzenetKezbesitesFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SzemelyKezbesitesResponse>>;
    public uzenetKezbesitesListazasUsingPOST(body: SzereploUzenetKezbesitesFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling uzenetKezbesitesListazasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SzemelyKezbesitesResponse>('post',`${this.basePath}/api/mohosz/uzenetmgmt/uzenet-kezbesitesek-listazas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * uzenetKezbesites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uzenetKezbesitesUsingPOST(body: UzenetKezbesitesRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public uzenetKezbesitesUsingPOST(body: UzenetKezbesitesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public uzenetKezbesitesUsingPOST(body: UzenetKezbesitesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public uzenetKezbesitesUsingPOST(body: UzenetKezbesitesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling uzenetKezbesitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/uzenetmgmt/uzenet-kezbesites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * uzenetLetrehozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uzenetLetrehozasUsingPOST(body: UzenetLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<Uzenet>;
    public uzenetLetrehozasUsingPOST(body: UzenetLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Uzenet>>;
    public uzenetLetrehozasUsingPOST(body: UzenetLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Uzenet>>;
    public uzenetLetrehozasUsingPOST(body: UzenetLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling uzenetLetrehozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Uzenet>('post',`${this.basePath}/api/mohosz/uzenetmgmt/uzenet-letrehozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * uzenetListazas
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uzenetListazasUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<Uzenet>>;
    public uzenetListazasUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Uzenet>>>;
    public uzenetListazasUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Uzenet>>>;
    public uzenetListazasUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Uzenet>>('post',`${this.basePath}/api/mohosz/uzenetmgmt/uzenet-listazas`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * uzenetModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uzenetModositasUsingPOST(body: UzenetModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<Uzenet>;
    public uzenetModositasUsingPOST(body: UzenetModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Uzenet>>;
    public uzenetModositasUsingPOST(body: UzenetModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Uzenet>>;
    public uzenetModositasUsingPOST(body: UzenetModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling uzenetModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Uzenet>('post',`${this.basePath}/api/mohosz/uzenetmgmt/uzenet-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * uzenetTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uzenetTorlesUsingPOST(body: UzenetRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public uzenetTorlesUsingPOST(body: UzenetRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public uzenetTorlesUsingPOST(body: UzenetRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public uzenetTorlesUsingPOST(body: UzenetRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling uzenetTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/uzenetmgmt/uzenet-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
