/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GPSKoordinata } from './gPSKoordinata';

export interface EllenorTevekenysegRiportRow { 
    ellenorzesiTevekenysegId?: number;
    ellenorzottSzemelyNev?: string;
    horgaszAzonosito?: string;
    horgaszSzemelyId?: number;
    idopont?: Date;
    kapcsoltSzabalysertesSorszama?: string;
    keresettKifejezes?: string;
    koordinata?: GPSKoordinata;
    szabalysertesId?: number;
    tipus?: EllenorTevekenysegRiportRow.TipusEnum;
    vizterKod?: string;
    vizteruletNev?: string;
}
export namespace EllenorTevekenysegRiportRow {
    export type TipusEnum = 'ELLENOR_LOKACIO' | 'RENDBEN_ELLENORZES' | 'SZABALYSERTES_ELLENORZES' | 'MINOSITES_NELKULI_ELLENORZES';
    export const TipusEnum = {
        ELLENORLOKACIO: 'ELLENOR_LOKACIO' as TipusEnum,
        RENDBENELLENORZES: 'RENDBEN_ELLENORZES' as TipusEnum,
        SZABALYSERTESELLENORZES: 'SZABALYSERTES_ELLENORZES' as TipusEnum,
        MINOSITESNELKULIELLENORZES: 'MINOSITES_NELKULI_ELLENORZES' as TipusEnum
    };
}