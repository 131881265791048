/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VersenyzoiEngedelyArlistaTetel { 
    ar?: number;
    korKategoria?: VersenyzoiEngedelyArlistaTetel.KorKategoriaEnum;
}
export namespace VersenyzoiEngedelyArlistaTetel {
    export type KorKategoriaEnum = 'FELNOTT' | 'IFJUSAGI_18_ALATTI' | 'KISKORU_14_ALATTI';
    export const KorKategoriaEnum = {
        FELNOTT: 'FELNOTT' as KorKategoriaEnum,
        IFJUSAGI18ALATTI: 'IFJUSAGI_18_ALATTI' as KorKategoriaEnum,
        KISKORU14ALATTI: 'KISKORU_14_ALATTI' as KorKategoriaEnum
    };
}