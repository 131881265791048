/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OnlinePenztarFizetesInditasRequest { 
    fizetesTipus?: OnlinePenztarFizetesInditasRequest.FizetesTipusEnum;
    kosarId?: number;
}
export namespace OnlinePenztarFizetesInditasRequest {
    export type FizetesTipusEnum = 'KH' | 'CSEKKES' | 'ERTEKESITESIPONT' | 'DIJMENTES' | 'SZEPKH' | 'RK' | 'OTPSZEP' | 'MKBSZEP';
    export const FizetesTipusEnum = {
        KH: 'KH' as FizetesTipusEnum,
        CSEKKES: 'CSEKKES' as FizetesTipusEnum,
        ERTEKESITESIPONT: 'ERTEKESITESIPONT' as FizetesTipusEnum,
        DIJMENTES: 'DIJMENTES' as FizetesTipusEnum,
        SZEPKH: 'SZEPKH' as FizetesTipusEnum,
        RK: 'RK' as FizetesTipusEnum,
        OTPSZEP: 'OTPSZEP' as FizetesTipusEnum,
        MKBSZEP: 'MKBSZEP' as FizetesTipusEnum
    };
}