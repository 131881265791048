/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TeruletiJegyElszamolasItem } from './teruletiJegyElszamolasItem';

export interface TeruletiJegyElszamolasVizteruletItem { 
    ertekesitettDarabszam?: number;
    sztornozottDarabszam?: number;
    teljesArresMertek?: number;
    teljesEladasiAr?: number;
    teljesElszamolasiAr?: number;
    teljesRendszerhasznalatiDij?: number;
    termekItems?: Array<TeruletiJegyElszamolasItem>;
    vizteruletCsoportId?: number;
    vizteruletCsoportNev?: string;
}