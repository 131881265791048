/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizsgaztatasRiport { 
    jelentkezettSzemelyId?: number;
    jelentkezettSzemelyNev?: string;
    jelentkezettVizsgaStatusz?: VizsgaztatasRiport.JelentkezettVizsgaStatuszEnum;
    szervezetId?: number;
    szervezetNev?: string;
    vizsgaBefejezve?: Date;
    vizsgaInditva?: Date;
    vizsgaStatusz?: VizsgaztatasRiport.VizsgaStatuszEnum;
    vizsgabizottsagId?: number;
    vizsgabizottsagNev?: string;
    vizsgahelyCim?: string;
    vizsgahelyId?: string;
    vizsgaidopont?: Date;
}
export namespace VizsgaztatasRiport {
    export type JelentkezettVizsgaStatuszEnum = 'JELENTKEZETT' | 'FOLYAMATBAN' | 'KIZART' | 'SIKERES' | 'SIKERTELEN' | 'NEM_JELENT_MEG' | 'MEGHIUSULT';
    export const JelentkezettVizsgaStatuszEnum = {
        JELENTKEZETT: 'JELENTKEZETT' as JelentkezettVizsgaStatuszEnum,
        FOLYAMATBAN: 'FOLYAMATBAN' as JelentkezettVizsgaStatuszEnum,
        KIZART: 'KIZART' as JelentkezettVizsgaStatuszEnum,
        SIKERES: 'SIKERES' as JelentkezettVizsgaStatuszEnum,
        SIKERTELEN: 'SIKERTELEN' as JelentkezettVizsgaStatuszEnum,
        NEMJELENTMEG: 'NEM_JELENT_MEG' as JelentkezettVizsgaStatuszEnum,
        MEGHIUSULT: 'MEGHIUSULT' as JelentkezettVizsgaStatuszEnum
    };
    export type VizsgaStatuszEnum = 'FOLYAMATBAN' | 'INDITHATO' | 'TERVEZETT' | 'LEZARULT' | 'MEGHIUSULT';
    export const VizsgaStatuszEnum = {
        FOLYAMATBAN: 'FOLYAMATBAN' as VizsgaStatuszEnum,
        INDITHATO: 'INDITHATO' as VizsgaStatuszEnum,
        TERVEZETT: 'TERVEZETT' as VizsgaStatuszEnum,
        LEZARULT: 'LEZARULT' as VizsgaStatuszEnum,
        MEGHIUSULT: 'MEGHIUSULT' as VizsgaStatuszEnum
    };
}