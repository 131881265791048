/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MahorIntegracioFilter { 
    globalSearch?: string;
    integracioTipus?: MahorIntegracioFilter.IntegracioTipusEnum;
    limit?: number;
    offset?: number;
    tsQueryString?: string;
}
export namespace MahorIntegracioFilter {
    export type IntegracioTipusEnum = 'OUT_POSTA' | 'IN_POSTA';
    export const IntegracioTipusEnum = {
        OUTPOSTA: 'OUT_POSTA' as IntegracioTipusEnum,
        INPOSTA: 'IN_POSTA' as IntegracioTipusEnum
    };
}