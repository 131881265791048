/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VersenyzoiEngedelyErtekesites { 
    ar?: number;
    befizetesDatum?: Date;
    ertekesitoSzervezetId?: number;
    ertekesitoSzervezetNev?: string;
    ervenyessegiEv?: number;
    fizetesTipus?: VersenyzoiEngedelyErtekesites.FizetesTipusEnum;
    horgaszAzonosito?: string;
    korkategoria?: VersenyzoiEngedelyErtekesites.KorkategoriaEnum;
    nev?: string;
    szemelyId?: number;
}
export namespace VersenyzoiEngedelyErtekesites {
    export type FizetesTipusEnum = 'KH' | 'ERTEKESITESIPONT' | 'RK_FIZETES';
    export const FizetesTipusEnum = {
        KH: 'KH' as FizetesTipusEnum,
        ERTEKESITESIPONT: 'ERTEKESITESIPONT' as FizetesTipusEnum,
        RKFIZETES: 'RK_FIZETES' as FizetesTipusEnum
    };
    export type KorkategoriaEnum = 'FELNOTT' | 'IFJUSAGI_18_ALATTI' | 'KISKORU_14_ALATTI';
    export const KorkategoriaEnum = {
        FELNOTT: 'FELNOTT' as KorkategoriaEnum,
        IFJUSAGI18ALATTI: 'IFJUSAGI_18_ALATTI' as KorkategoriaEnum,
        KISKORU14ALATTI: 'KISKORU_14_ALATTI' as KorkategoriaEnum
    };
}