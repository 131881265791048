/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SorszamOszto } from './sorszamOszto';
import { VizsgabizottsagTag } from './vizsgabizottsagTag';
import { Vizsgahely } from './vizsgahely';
import { Vizsgaidopont } from './vizsgaidopont';

export interface Vizsgabizottsag { 
    elerhetoseg?: string;
    id?: number;
    nev?: string;
    normalizedNev?: string;
    sorszamOszto?: SorszamOszto;
    szervezetId?: number;
    tagList?: Array<VizsgabizottsagTag>;
    vizsgahelyList?: Array<Vizsgahely>;
    vizsgaidopontList?: Array<Vizsgaidopont>;
}