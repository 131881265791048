/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ElkovetesLokacio } from './elkovetesLokacio';

export interface SzabalysertesAlapadatRogzitesRequest { 
    anyjaNeve?: string;
    elkovetesHelye?: ElkovetesLokacio;
    elkovetesIdeje?: Date;
    elkovetoElismerte?: boolean;
    elkovetoIndoklasa?: string;
    ellenorSzemelyId?: number;
    ellenorzesId?: number;
    keresztnev?: string;
    sorszam?: string;
    szemelyId?: number;
    szervezetId?: number;
    szuletesiDatum?: string;
    szuletesiHely?: string;
    tipusai?: Array<SzabalysertesAlapadatRogzitesRequest.TipusaiEnum>;
    validElkovetesIdeje?: boolean;
    validSzervezetId?: boolean;
    vezeteknev?: string;
}
export namespace SzabalysertesAlapadatRogzitesRequest {
    export type TipusaiEnum = 'HELYI_HORGASZRENDI' | 'JOGSZABALYI';
    export const TipusaiEnum = {
        HELYIHORGASZRENDI: 'HELYI_HORGASZRENDI' as TipusaiEnum,
        JOGSZABALYI: 'JOGSZABALYI' as TipusaiEnum
    };
}