/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { OfflineVizsgaOsszesito } from '../model/offlineVizsgaOsszesito';
import { SzemelyRequest } from '../model/szemelyRequest';
import { SzemelyVizsgakOverride } from '../model/szemelyVizsgakOverride';
import { SzemelyVizsgakOverrideRequest } from '../model/szemelyVizsgakOverrideRequest';
import { VizsgaTorlesRequest } from '../model/vizsgaTorlesRequest';
import { VizsgaTortenet } from '../model/vizsgaTortenet';
import { VizsgaTortenetRequest } from '../model/vizsgaTortenetRequest';
import { VizsgabizottsagVizsga } from '../model/vizsgabizottsagVizsga';
import { VizsgaidopontJelentkezesRequest } from '../model/vizsgaidopontJelentkezesRequest';
import { VizsgaidopontKeresesRequest } from '../model/vizsgaidopontKeresesRequest';
import { VizsgaidopontSzemelyRequest } from '../model/vizsgaidopontSzemelyRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OfflineVizsgaControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * jelentkezettVizsgaidopontok
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public jelentkezettVizsgaidopontokUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<VizsgabizottsagVizsga>>;
    public jelentkezettVizsgaidopontokUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VizsgabizottsagVizsga>>>;
    public jelentkezettVizsgaidopontokUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VizsgabizottsagVizsga>>>;
    public jelentkezettVizsgaidopontokUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling jelentkezettVizsgaidopontokUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<VizsgabizottsagVizsga>>('post',`${this.basePath}/api/mohosz/offlinevizsga/jelentkezett-vizsgaidopontok`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kapcsolattartasModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kapcsolattartasModositasUsingPOST(body: VizsgaidopontJelentkezesRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgabizottsagVizsga>;
    public kapcsolattartasModositasUsingPOST(body: VizsgaidopontJelentkezesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgabizottsagVizsga>>;
    public kapcsolattartasModositasUsingPOST(body: VizsgaidopontJelentkezesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgabizottsagVizsga>>;
    public kapcsolattartasModositasUsingPOST(body: VizsgaidopontJelentkezesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kapcsolattartasModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgabizottsagVizsga>('post',`${this.basePath}/api/mohosz/offlinevizsga/jelentkezett-kapcsolattartas-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaOsszesito
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaOsszesitoUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<OfflineVizsgaOsszesito>;
    public vizsgaOsszesitoUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OfflineVizsgaOsszesito>>;
    public vizsgaOsszesitoUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OfflineVizsgaOsszesito>>;
    public vizsgaOsszesitoUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizsgaOsszesitoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OfflineVizsgaOsszesito>('post',`${this.basePath}/api/mohosz/offlinevizsga/offlinevizsga-osszesito`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaOverride
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaOverrideUsingPOST(body: SzemelyVizsgakOverrideRequest, observe?: 'body', reportProgress?: boolean): Observable<SzemelyVizsgakOverride>;
    public vizsgaOverrideUsingPOST(body: SzemelyVizsgakOverrideRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SzemelyVizsgakOverride>>;
    public vizsgaOverrideUsingPOST(body: SzemelyVizsgakOverrideRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SzemelyVizsgakOverride>>;
    public vizsgaOverrideUsingPOST(body: SzemelyVizsgakOverrideRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizsgaOverrideUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SzemelyVizsgakOverride>('post',`${this.basePath}/api/mohosz/offlinevizsga/szemelyvizsgak-override`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaTorlesUsingPOST(body: VizsgaTorlesRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public vizsgaTorlesUsingPOST(body: VizsgaTorlesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public vizsgaTorlesUsingPOST(body: VizsgaTorlesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public vizsgaTorlesUsingPOST(body: VizsgaTorlesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizsgaTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/offlinevizsga/offlinevizsga-vizsga-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaTortent
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaTortentUsingPOST(body: VizsgaTortenetRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<VizsgaTortenet>>;
    public vizsgaTortentUsingPOST(body: VizsgaTortenetRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VizsgaTortenet>>>;
    public vizsgaTortentUsingPOST(body: VizsgaTortenetRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VizsgaTortenet>>>;
    public vizsgaTortentUsingPOST(body: VizsgaTortenetRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizsgaTortentUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<VizsgaTortenet>>('post',`${this.basePath}/api/mohosz/offlinevizsga/offlinevizsga-tortent`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaidopontKereses
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaidopontKeresesUsingPOST(body: VizsgaidopontKeresesRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<VizsgabizottsagVizsga>>;
    public vizsgaidopontKeresesUsingPOST(body: VizsgaidopontKeresesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VizsgabizottsagVizsga>>>;
    public vizsgaidopontKeresesUsingPOST(body: VizsgaidopontKeresesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VizsgabizottsagVizsga>>>;
    public vizsgaidopontKeresesUsingPOST(body: VizsgaidopontKeresesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizsgaidopontKeresesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<VizsgabizottsagVizsga>>('post',`${this.basePath}/api/mohosz/offlinevizsga/vizsgaidopont-kereses`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaidopontLemondas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaidopontLemondasUsingPOST(body: VizsgaidopontSzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgabizottsagVizsga>;
    public vizsgaidopontLemondasUsingPOST(body: VizsgaidopontSzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgabizottsagVizsga>>;
    public vizsgaidopontLemondasUsingPOST(body: VizsgaidopontSzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgabizottsagVizsga>>;
    public vizsgaidopontLemondasUsingPOST(body: VizsgaidopontSzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizsgaidopontLemondasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgabizottsagVizsga>('post',`${this.basePath}/api/mohosz/offlinevizsga/vizsgaidopont-lemondas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaidopontraJelentkezes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaidopontraJelentkezesUsingPOST(body: VizsgaidopontJelentkezesRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgabizottsagVizsga>;
    public vizsgaidopontraJelentkezesUsingPOST(body: VizsgaidopontJelentkezesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgabizottsagVizsga>>;
    public vizsgaidopontraJelentkezesUsingPOST(body: VizsgaidopontJelentkezesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgabizottsagVizsga>>;
    public vizsgaidopontraJelentkezesUsingPOST(body: VizsgaidopontJelentkezesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizsgaidopontraJelentkezesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgabizottsagVizsga>('post',`${this.basePath}/api/mohosz/offlinevizsga/vizsgaidopontra-jelentkezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
