/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SssUser { 
    active?: boolean;
    authorities?: Array<string>;
    authorityChannel?: string;
    email?: string;
    entrustingId?: number;
    lastLoggedIn?: Date;
    loginId?: string;
    trusteeId?: number;
}