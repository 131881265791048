/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface KosarFizetes { 
    fizetesAllapot?: KosarFizetes.FizetesAllapotEnum;
    fizetesId?: string;
    fizetesOnlineFolytathato?: boolean;
    fizetesTipus?: KosarFizetes.FizetesTipusEnum;
    khFizetesUrl?: string;
    kosarId?: number;
    kosarStatusz?: KosarFizetes.KosarStatuszEnum;
    osszeg?: number;
}
export namespace KosarFizetes {
    export type FizetesAllapotEnum = 'LETREHOZVA' | 'VISSZAVONVA' | 'LEJART' | 'SIKERTELEN' | 'SIKERES' | 'TOROLT';
    export const FizetesAllapotEnum = {
        LETREHOZVA: 'LETREHOZVA' as FizetesAllapotEnum,
        VISSZAVONVA: 'VISSZAVONVA' as FizetesAllapotEnum,
        LEJART: 'LEJART' as FizetesAllapotEnum,
        SIKERTELEN: 'SIKERTELEN' as FizetesAllapotEnum,
        SIKERES: 'SIKERES' as FizetesAllapotEnum,
        TOROLT: 'TOROLT' as FizetesAllapotEnum
    };
    export type FizetesTipusEnum = 'KH' | 'CSEKKES' | 'ERTEKESITESIPONT' | 'DIJMENTES' | 'SZEPKH' | 'RK' | 'OTPSZEP' | 'MKBSZEP';
    export const FizetesTipusEnum = {
        KH: 'KH' as FizetesTipusEnum,
        CSEKKES: 'CSEKKES' as FizetesTipusEnum,
        ERTEKESITESIPONT: 'ERTEKESITESIPONT' as FizetesTipusEnum,
        DIJMENTES: 'DIJMENTES' as FizetesTipusEnum,
        SZEPKH: 'SZEPKH' as FizetesTipusEnum,
        RK: 'RK' as FizetesTipusEnum,
        OTPSZEP: 'OTPSZEP' as FizetesTipusEnum,
        MKBSZEP: 'MKBSZEP' as FizetesTipusEnum
    };
    export type KosarStatuszEnum = 'PENZTAR' | 'NYITOTT' | 'LEZART' | 'TOROLT';
    export const KosarStatuszEnum = {
        PENZTAR: 'PENZTAR' as KosarStatuszEnum,
        NYITOTT: 'NYITOTT' as KosarStatuszEnum,
        LEZART: 'LEZART' as KosarStatuszEnum,
        TOROLT: 'TOROLT' as KosarStatuszEnum
    };
}