/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FeladovevenyesFizetes { 
    allapot?: FeladovevenyesFizetes.AllapotEnum;
    fizetesId?: string;
    horgaszAzonosito?: string;
    szemelyNev?: string;
    szervezetId?: number;
    szervezetNev?: string;
    termekNev?: string;
    termekNevList?: Array<string>;
}
export namespace FeladovevenyesFizetes {
    export type AllapotEnum = 'LETREHOZVA' | 'VISSZAVONVA' | 'LEJART' | 'SIKERTELEN' | 'SIKERES' | 'TOROLT';
    export const AllapotEnum = {
        LETREHOZVA: 'LETREHOZVA' as AllapotEnum,
        VISSZAVONVA: 'VISSZAVONVA' as AllapotEnum,
        LEJART: 'LEJART' as AllapotEnum,
        SIKERTELEN: 'SIKERTELEN' as AllapotEnum,
        SIKERES: 'SIKERES' as AllapotEnum,
        TOROLT: 'TOROLT' as AllapotEnum
    };
}