/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizteruletCsoportModositasRequest { 
    nev?: string;
    statusz?: VizteruletCsoportModositasRequest.StatuszEnum;
    vizteruletCsoportId?: number;
    vizteruletIdSet?: Array<number>;
}
export namespace VizteruletCsoportModositasRequest {
    export type StatuszEnum = 'AKTIV' | 'INAKTIV';
    export const StatuszEnum = {
        AKTIV: 'AKTIV' as StatuszEnum,
        INAKTIV: 'INAKTIV' as StatuszEnum
    };
}