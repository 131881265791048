/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Szemely4T } from './szemely4T';

export interface AdatEllenorzesFeladat { 
    eredetiSzemely4T?: Szemely4T;
    feladatEredet?: AdatEllenorzesFeladat.FeladatEredetEnum;
    feladatTipus?: AdatEllenorzesFeladat.FeladatTipusEnum;
    folyamatban?: boolean;
    id?: number;
    letrehozasDatum?: Date;
    letrehozoSzemelyId?: number;
    letrehozoSzemelyLoginId?: string;
    letrehozoSzemelyNev?: string;
    letrehozoSzervezetId?: number;
    letrehozoSzervezetNev?: string;
    letrehozvaAktualisEvben?: boolean;
    onlineLetrehozva?: boolean;
    skip?: boolean;
    szemely4T?: Szemely4T;
    vegrehajtasDatum?: Date;
    vegrehajtoSzemelyId?: number;
    vegrehajtoSzemelyLoginId?: string;
    vegrehajtoSzemelyNev?: string;
    vegrehajtoSzervezetId?: number;
    vegrehajtoSzervezetNev?: string;
    vegrehajtva?: boolean;
}
export namespace AdatEllenorzesFeladat {
    export type FeladatEredetEnum = 'ONLINE_HORGASZ_REGISZTRACIO' | 'OFFLINE_HORGASZ_REGISZTRACIO' | 'ALLAMIJEGY_VASARLAS' | 'ALLAMI_VIZSGA' | 'GYARTAS_VISSZATARTAS' | 'ADATVALTOZAS_BEJELENTES';
    export const FeladatEredetEnum = {
        ONLINEHORGASZREGISZTRACIO: 'ONLINE_HORGASZ_REGISZTRACIO' as FeladatEredetEnum,
        OFFLINEHORGASZREGISZTRACIO: 'OFFLINE_HORGASZ_REGISZTRACIO' as FeladatEredetEnum,
        ALLAMIJEGYVASARLAS: 'ALLAMIJEGY_VASARLAS' as FeladatEredetEnum,
        ALLAMIVIZSGA: 'ALLAMI_VIZSGA' as FeladatEredetEnum,
        GYARTASVISSZATARTAS: 'GYARTAS_VISSZATARTAS' as FeladatEredetEnum,
        ADATVALTOZASBEJELENTES: 'ADATVALTOZAS_BEJELENTES' as FeladatEredetEnum
    };
    export type FeladatTipusEnum = 'REGISZTRACIOS_ADATELLENORZES' | 'ADATVALTOZAS_ELLENORZES' | 'TOVABBI_UGYINTEZES';
    export const FeladatTipusEnum = {
        REGISZTRACIOSADATELLENORZES: 'REGISZTRACIOS_ADATELLENORZES' as FeladatTipusEnum,
        ADATVALTOZASELLENORZES: 'ADATVALTOZAS_ELLENORZES' as FeladatTipusEnum,
        TOVABBIUGYINTEZES: 'TOVABBI_UGYINTEZES' as FeladatTipusEnum
    };
}