/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SzereploFizetesSzamla { 
    allapot?: SzereploFizetesSzamla.AllapotEnum;
    availableCommands?: Array<SzereploFizetesSzamla.AvailableCommandsEnum>;
    contentObjectStoreIds?: Array<number>;
    csekkFeladovevenyKepId?: number;
    externalSzamlaIds?: Array<string>;
    fizetesId?: string;
    isCsekkFeladovevenyType?: boolean;
    isFizetesIdCserelheto?: boolean;
    khFizetesFolytathato?: boolean;
    khFizetesUrl?: string;
    kosarId?: number;
    letrehozva?: Date;
    megbizoHorgaszAzonosito?: string;
    megbizoNev?: string;
    szereploId?: number;
    teljesitve?: string;
    termekNevList?: Array<string>;
    tipus?: SzereploFizetesSzamla.TipusEnum;
}
export namespace SzereploFizetesSzamla {
    export type AllapotEnum = 'LETREHOZVA' | 'VISSZAVONVA' | 'LEJART' | 'SIKERTELEN' | 'SIKERES' | 'TOROLT';
    export const AllapotEnum = {
        LETREHOZVA: 'LETREHOZVA' as AllapotEnum,
        VISSZAVONVA: 'VISSZAVONVA' as AllapotEnum,
        LEJART: 'LEJART' as AllapotEnum,
        SIKERTELEN: 'SIKERTELEN' as AllapotEnum,
        SIKERES: 'SIKERES' as AllapotEnum,
        TOROLT: 'TOROLT' as AllapotEnum
    };
    export type AvailableCommandsEnum = 'FIZETESIDCSERE' | 'TORLES' | 'CSEKKRENDEZES' | 'RKBEFIZETES' | 'ONLINESTATUSZLEKERDEZES';
    export const AvailableCommandsEnum = {
        FIZETESIDCSERE: 'FIZETESIDCSERE' as AvailableCommandsEnum,
        TORLES: 'TORLES' as AvailableCommandsEnum,
        CSEKKRENDEZES: 'CSEKKRENDEZES' as AvailableCommandsEnum,
        RKBEFIZETES: 'RKBEFIZETES' as AvailableCommandsEnum,
        ONLINESTATUSZLEKERDEZES: 'ONLINESTATUSZLEKERDEZES' as AvailableCommandsEnum
    };
    export type TipusEnum = 'KH' | 'CSEKKES' | 'ERTEKESITESIPONT' | 'DIJMENTES' | 'SZEPKH' | 'RK' | 'OTPSZEP' | 'MKBSZEP';
    export const TipusEnum = {
        KH: 'KH' as TipusEnum,
        CSEKKES: 'CSEKKES' as TipusEnum,
        ERTEKESITESIPONT: 'ERTEKESITESIPONT' as TipusEnum,
        DIJMENTES: 'DIJMENTES' as TipusEnum,
        SZEPKH: 'SZEPKH' as TipusEnum,
        RK: 'RK' as TipusEnum,
        OTPSZEP: 'OTPSZEP' as TipusEnum,
        MKBSZEP: 'MKBSZEP' as TipusEnum
    };
}