/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AdatvaltozasEllenorzesRendbenRequest { 
    feladatEredet?: AdatvaltozasEllenorzesRendbenRequest.FeladatEredetEnum;
    szemelyId?: number;
}
export namespace AdatvaltozasEllenorzesRendbenRequest {
    export type FeladatEredetEnum = 'ONLINE_HORGASZ_REGISZTRACIO' | 'OFFLINE_HORGASZ_REGISZTRACIO' | 'ALLAMIJEGY_VASARLAS' | 'ALLAMI_VIZSGA' | 'GYARTAS_VISSZATARTAS' | 'ADATVALTOZAS_BEJELENTES';
    export const FeladatEredetEnum = {
        ONLINEHORGASZREGISZTRACIO: 'ONLINE_HORGASZ_REGISZTRACIO' as FeladatEredetEnum,
        OFFLINEHORGASZREGISZTRACIO: 'OFFLINE_HORGASZ_REGISZTRACIO' as FeladatEredetEnum,
        ALLAMIJEGYVASARLAS: 'ALLAMIJEGY_VASARLAS' as FeladatEredetEnum,
        ALLAMIVIZSGA: 'ALLAMI_VIZSGA' as FeladatEredetEnum,
        GYARTASVISSZATARTAS: 'GYARTAS_VISSZATARTAS' as FeladatEredetEnum,
        ADATVALTOZASBEJELENTES: 'ADATVALTOZAS_BEJELENTES' as FeladatEredetEnum
    };
}