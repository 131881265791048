/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Ertesites { 
    cim?: string;
    cimzettValasz?: boolean;
    kuldesDatum?: Date;
    leiras?: string;
    olvasott?: boolean;
    sourceId?: number;
    tipus?: Ertesites.TipusEnum;
    uzenetValaszLehetoseg?: boolean;
}
export namespace Ertesites {
    export type TipusEnum = 'UZENET';
    export const TipusEnum = {
        UZENET: 'UZENET' as TipusEnum
    };
}