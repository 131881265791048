/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { VizteruletView } from './vizteruletView';

export interface TeruletiKiegeszitoJegy { 
    availableCommands?: Array<TeruletiKiegeszitoJegy.AvailableCommandsEnum>;
    ertekesitesTipus?: TeruletiKiegeszitoJegy.ErtekesitesTipusEnum;
    ertekesitoSzervezetId?: number;
    ervenyes?: boolean;
    ervenyessegKezdete?: Date;
    ervenyessegVege?: Date;
    fizetesTipus?: TeruletiKiegeszitoJegy.FizetesTipusEnum;
    hghJegysorszam?: string;
    id?: number;
    idoszak?: TeruletiKiegeszitoJegy.IdoszakEnum;
    nemSztornozott?: boolean;
    sorszam?: string;
    statusz?: TeruletiKiegeszitoJegy.StatuszEnum;
    sztornozasDatuma?: Date;
    tipus?: TeruletiKiegeszitoJegy.TipusEnum;
    vasarlasDatuma?: Date;
    vizterulet?: VizteruletView;
}
export namespace TeruletiKiegeszitoJegy {
    export type AvailableCommandsEnum = 'SZTORNO' | 'RKSZTORNO' | 'NYOMTATAS' | 'VISSZAVONASROLLBACK';
    export const AvailableCommandsEnum = {
        SZTORNO: 'SZTORNO' as AvailableCommandsEnum,
        RKSZTORNO: 'RKSZTORNO' as AvailableCommandsEnum,
        NYOMTATAS: 'NYOMTATAS' as AvailableCommandsEnum,
        VISSZAVONASROLLBACK: 'VISSZAVONASROLLBACK' as AvailableCommandsEnum
    };
    export type ErtekesitesTipusEnum = 'ONLINE' | 'SAJAT' | 'KOZVETLEN' | 'VISZONT';
    export const ErtekesitesTipusEnum = {
        ONLINE: 'ONLINE' as ErtekesitesTipusEnum,
        SAJAT: 'SAJAT' as ErtekesitesTipusEnum,
        KOZVETLEN: 'KOZVETLEN' as ErtekesitesTipusEnum,
        VISZONT: 'VISZONT' as ErtekesitesTipusEnum
    };
    export type FizetesTipusEnum = 'KH' | 'CSEKKES' | 'ERTEKESITESIPONT' | 'DIJMENTES' | 'SZEPKH' | 'RK' | 'OTPSZEP' | 'MKBSZEP';
    export const FizetesTipusEnum = {
        KH: 'KH' as FizetesTipusEnum,
        CSEKKES: 'CSEKKES' as FizetesTipusEnum,
        ERTEKESITESIPONT: 'ERTEKESITESIPONT' as FizetesTipusEnum,
        DIJMENTES: 'DIJMENTES' as FizetesTipusEnum,
        SZEPKH: 'SZEPKH' as FizetesTipusEnum,
        RK: 'RK' as FizetesTipusEnum,
        OTPSZEP: 'OTPSZEP' as FizetesTipusEnum,
        MKBSZEP: 'MKBSZEP' as FizetesTipusEnum
    };
    export type IdoszakEnum = 'EVES' | 'EVES_RESZLET1' | 'EVES_RESZLET2' | 'FELEVES' | 'NAP10' | 'NAP7' | 'ORA72' | 'NAP3' | 'ORA48' | 'NAP2' | 'ORA24' | 'NAPI' | 'EJSZAKAI';
    export const IdoszakEnum = {
        EVES: 'EVES' as IdoszakEnum,
        EVESRESZLET1: 'EVES_RESZLET1' as IdoszakEnum,
        EVESRESZLET2: 'EVES_RESZLET2' as IdoszakEnum,
        FELEVES: 'FELEVES' as IdoszakEnum,
        NAP10: 'NAP10' as IdoszakEnum,
        NAP7: 'NAP7' as IdoszakEnum,
        ORA72: 'ORA72' as IdoszakEnum,
        NAP3: 'NAP3' as IdoszakEnum,
        ORA48: 'ORA48' as IdoszakEnum,
        NAP2: 'NAP2' as IdoszakEnum,
        ORA24: 'ORA24' as IdoszakEnum,
        NAPI: 'NAPI' as IdoszakEnum,
        EJSZAKAI: 'EJSZAKAI' as IdoszakEnum
    };
    export type StatuszEnum = 'MEGVASAROLT' | 'ERVENYES' | 'LEJART' | 'VISSZAVONT' | 'SZTORNOZOTT' | 'RKSZTORNOZOTT';
    export const StatuszEnum = {
        MEGVASAROLT: 'MEGVASAROLT' as StatuszEnum,
        ERVENYES: 'ERVENYES' as StatuszEnum,
        LEJART: 'LEJART' as StatuszEnum,
        VISSZAVONT: 'VISSZAVONT' as StatuszEnum,
        SZTORNOZOTT: 'SZTORNOZOTT' as StatuszEnum,
        RKSZTORNOZOTT: 'RKSZTORNOZOTT' as StatuszEnum
    };
    export type TipusEnum = 'CSONAKHASZNALATI' | 'BOJLIS' | 'BEHUZOS' | 'VERSENY_RESZVETELI' | 'EJSZAKAI_HORGASZATI' | 'HELYFOGLALASOS' | 'SPECIALIS_VIZTERULETI' | 'TOBBLET_HALELVITELI' | 'HAROM_BOTOS';
    export const TipusEnum = {
        CSONAKHASZNALATI: 'CSONAKHASZNALATI' as TipusEnum,
        BOJLIS: 'BOJLIS' as TipusEnum,
        BEHUZOS: 'BEHUZOS' as TipusEnum,
        VERSENYRESZVETELI: 'VERSENY_RESZVETELI' as TipusEnum,
        EJSZAKAIHORGASZATI: 'EJSZAKAI_HORGASZATI' as TipusEnum,
        HELYFOGLALASOS: 'HELYFOGLALASOS' as TipusEnum,
        SPECIALISVIZTERULETI: 'SPECIALIS_VIZTERULETI' as TipusEnum,
        TOBBLETHALELVITELI: 'TOBBLET_HALELVITELI' as TipusEnum,
        HAROMBOTOS: 'HAROM_BOTOS' as TipusEnum
    };
}