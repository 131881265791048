/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Cim } from './cim';
import { MahorElofizetesCiklus } from './mahorElofizetesCiklus';
import { MahorElofizetesMigracio } from './mahorElofizetesMigracio';

export interface MahorElofizetesDetails { 
    ciklusCounter?: number;
    ciklusList?: Array<MahorElofizetesCiklus>;
    gyermekHorgaszAzonosito?: string;
    hitelesitettEmail?: boolean;
    horgaszAzonosito?: string;
    kezbesitesTipus?: MahorElofizetesDetails.KezbesitesTipusEnum;
    kod?: string;
    labels?: Array<string>;
    lejaratErtesitesEngedelyezve?: boolean;
    mahorElofizetesMigracio?: MahorElofizetesMigracio;
    megjegyzesek?: string;
    nev?: string;
    sajatElofizetes?: boolean;
    szallitasiCim?: Cim;
    szamlazasiAdoszam?: string;
    szamlazasiCim?: Cim;
    szamlazasiNev?: string;
    szereploTipus?: MahorElofizetesDetails.SzereploTipusEnum;
    tipus?: MahorElofizetesDetails.TipusEnum;
}
export namespace MahorElofizetesDetails {
    export type KezbesitesTipusEnum = 'LISTAS' | 'CIMZETT';
    export const KezbesitesTipusEnum = {
        LISTAS: 'LISTAS' as KezbesitesTipusEnum,
        CIMZETT: 'CIMZETT' as KezbesitesTipusEnum
    };
    export type SzereploTipusEnum = 'SZEMELY' | 'MOHOSZ' | 'TAGEGYESULET' | 'TAGSZOVETSEG' | 'BOLT' | 'HALGAZDALKODO' | 'ALTALANOS';
    export const SzereploTipusEnum = {
        SZEMELY: 'SZEMELY' as SzereploTipusEnum,
        MOHOSZ: 'MOHOSZ' as SzereploTipusEnum,
        TAGEGYESULET: 'TAGEGYESULET' as SzereploTipusEnum,
        TAGSZOVETSEG: 'TAGSZOVETSEG' as SzereploTipusEnum,
        BOLT: 'BOLT' as SzereploTipusEnum,
        HALGAZDALKODO: 'HALGAZDALKODO' as SzereploTipusEnum,
        ALTALANOS: 'ALTALANOS' as SzereploTipusEnum
    };
    export type TipusEnum = 'NORMAL' | 'ARVA' | 'RENDSZEREN_KIVULI';
    export const TipusEnum = {
        NORMAL: 'NORMAL' as TipusEnum,
        ARVA: 'ARVA' as TipusEnum,
        RENDSZERENKIVULI: 'RENDSZEREN_KIVULI' as TipusEnum
    };
}