/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SzamlaReadModel } from './szamlaReadModel';
import { VasarlasiElozmenyTermekDetails } from './vasarlasiElozmenyTermekDetails';

export interface VasarlasiElozmeny { 
    allapot?: VasarlasiElozmeny.AllapotEnum;
    fizetesId?: string;
    khFizetesFolytathato?: boolean;
    khFizetesUrl?: string;
    letrehozva?: Date;
    sikeresKategoria?: boolean;
    szamlaIds?: Array<SzamlaReadModel>;
    teljesitve?: string;
    termekek?: Array<VasarlasiElozmenyTermekDetails>;
    tipus?: VasarlasiElozmeny.TipusEnum;
    vegosszeg?: number;
}
export namespace VasarlasiElozmeny {
    export type AllapotEnum = 'LETREHOZVA' | 'VISSZAVONVA' | 'LEJART' | 'SIKERTELEN' | 'SIKERES' | 'TOROLT';
    export const AllapotEnum = {
        LETREHOZVA: 'LETREHOZVA' as AllapotEnum,
        VISSZAVONVA: 'VISSZAVONVA' as AllapotEnum,
        LEJART: 'LEJART' as AllapotEnum,
        SIKERTELEN: 'SIKERTELEN' as AllapotEnum,
        SIKERES: 'SIKERES' as AllapotEnum,
        TOROLT: 'TOROLT' as AllapotEnum
    };
    export type TipusEnum = 'KH' | 'CSEKKES' | 'ERTEKESITESIPONT' | 'DIJMENTES' | 'SZEPKH' | 'RK' | 'OTPSZEP' | 'MKBSZEP';
    export const TipusEnum = {
        KH: 'KH' as TipusEnum,
        CSEKKES: 'CSEKKES' as TipusEnum,
        ERTEKESITESIPONT: 'ERTEKESITESIPONT' as TipusEnum,
        DIJMENTES: 'DIJMENTES' as TipusEnum,
        SZEPKH: 'SZEPKH' as TipusEnum,
        RK: 'RK' as TipusEnum,
        OTPSZEP: 'OTPSZEP' as TipusEnum,
        MKBSZEP: 'MKBSZEP' as TipusEnum
    };
}