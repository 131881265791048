/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TeruletiJegyElszamolasFilter { 
    ertekesitesEv?: number;
    ertekesitesHonap?: number;
    ertekesitesNap?: number;
    ertekesitesTipus?: TeruletiJegyElszamolasFilter.ErtekesitesTipusEnum;
    ertekesitoSzervezetId?: number;
    fromDate?: string;
    termekSzervezetId?: number;
    toDate?: string;
}
export namespace TeruletiJegyElszamolasFilter {
    export type ErtekesitesTipusEnum = 'ONLINE' | 'SAJAT' | 'KOZVETLEN' | 'VISZONT';
    export const ErtekesitesTipusEnum = {
        ONLINE: 'ONLINE' as ErtekesitesTipusEnum,
        SAJAT: 'SAJAT' as ErtekesitesTipusEnum,
        KOZVETLEN: 'KOZVETLEN' as ErtekesitesTipusEnum,
        VISZONT: 'VISZONT' as ErtekesitesTipusEnum
    };
}