/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { HasonloElofizetesekRequest } from '../model/hasonloElofizetesekRequest';
import { KosarFizetes } from '../model/kosarFizetes';
import { MahorElofizetes } from '../model/mahorElofizetes';
import { MahorElofizetesFilter } from '../model/mahorElofizetesFilter';
import { MahorElofizetesLetrehozasRequest } from '../model/mahorElofizetesLetrehozasRequest';
import { MahorElofizetesMegjegyzesekModositasRequest } from '../model/mahorElofizetesMegjegyzesekModositasRequest';
import { MahorElofizetesModositasRequest } from '../model/mahorElofizetesModositasRequest';
import { MahorElofizetesRequest } from '../model/mahorElofizetesRequest';
import { MahorElofizetesTorzsadat } from '../model/mahorElofizetesTorzsadat';
import { OfflineFizetesRequest } from '../model/offlineFizetesRequest';
import { RendszerenKivuliBefizetesModositasRequest } from '../model/rendszerenKivuliBefizetesModositasRequest';
import { SzereploRequest } from '../model/szereploRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MahorElofizetesControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * ar
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public arUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<number>;
    public arUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public arUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public arUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/mahorelofizetes/mahorelofizetes-ar`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * hasonloListazas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hasonloListazasUsingPOST(body: HasonloElofizetesekRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<MahorElofizetes>>;
    public hasonloListazasUsingPOST(body: HasonloElofizetesekRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MahorElofizetes>>>;
    public hasonloListazasUsingPOST(body: HasonloElofizetesekRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MahorElofizetes>>>;
    public hasonloListazasUsingPOST(body: HasonloElofizetesekRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling hasonloListazasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<MahorElofizetes>>('post',`${this.basePath}/api/mohosz/mahorelofizetes/mahorelofizetes-hasonlo-listazas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * letrehozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public letrehozasUsingPOST(body: MahorElofizetesLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<MahorElofizetes>;
    public letrehozasUsingPOST(body: MahorElofizetesLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MahorElofizetes>>;
    public letrehozasUsingPOST(body: MahorElofizetesLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MahorElofizetes>>;
    public letrehozasUsingPOST(body: MahorElofizetesLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling letrehozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MahorElofizetes>('post',`${this.basePath}/api/mohosz/mahorelofizetes/mahorelofizetes-letrehozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listazas
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listazasUsingPOST(body: MahorElofizetesFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<MahorElofizetes>>;
    public listazasUsingPOST(body: MahorElofizetesFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MahorElofizetes>>>;
    public listazasUsingPOST(body: MahorElofizetesFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MahorElofizetes>>>;
    public listazasUsingPOST(body: MahorElofizetesFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling listazasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<MahorElofizetes>>('post',`${this.basePath}/api/mohosz/mahorelofizetes/mahorelofizetes-listazas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * megjegyzesekModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public megjegyzesekModositasUsingPOST(body: MahorElofizetesMegjegyzesekModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<MahorElofizetes>;
    public megjegyzesekModositasUsingPOST(body: MahorElofizetesMegjegyzesekModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MahorElofizetes>>;
    public megjegyzesekModositasUsingPOST(body: MahorElofizetesMegjegyzesekModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MahorElofizetes>>;
    public megjegyzesekModositasUsingPOST(body: MahorElofizetesMegjegyzesekModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling megjegyzesekModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MahorElofizetes>('post',`${this.basePath}/api/mohosz/mahorelofizetes/mahorelofizetes-megjegyzesek-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * megujitas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public megujitasUsingPOST(body: MahorElofizetesRequest, observe?: 'body', reportProgress?: boolean): Observable<MahorElofizetes>;
    public megujitasUsingPOST(body: MahorElofizetesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MahorElofizetes>>;
    public megujitasUsingPOST(body: MahorElofizetesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MahorElofizetes>>;
    public megujitasUsingPOST(body: MahorElofizetesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling megujitasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MahorElofizetes>('post',`${this.basePath}/api/mohosz/mahorelofizetes/mahorelofizetes-megujitas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * megujitasVisszavonas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public megujitasVisszavonasUsingPOST(body: MahorElofizetesRequest, observe?: 'body', reportProgress?: boolean): Observable<MahorElofizetes>;
    public megujitasVisszavonasUsingPOST(body: MahorElofizetesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MahorElofizetes>>;
    public megujitasVisszavonasUsingPOST(body: MahorElofizetesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MahorElofizetes>>;
    public megujitasVisszavonasUsingPOST(body: MahorElofizetesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling megujitasVisszavonasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MahorElofizetes>('post',`${this.basePath}/api/mohosz/mahorelofizetes/mahorelofizetes-megujitas-visszavonas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * modositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modositasUsingPOST(body: MahorElofizetesModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<MahorElofizetes>;
    public modositasUsingPOST(body: MahorElofizetesModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MahorElofizetes>>;
    public modositasUsingPOST(body: MahorElofizetesModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MahorElofizetes>>;
    public modositasUsingPOST(body: MahorElofizetesModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling modositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MahorElofizetes>('post',`${this.basePath}/api/mohosz/mahorelofizetes/mahorelofizetes-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * offlineFizetesInditas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offlineFizetesInditasUsingPOST(body: OfflineFizetesRequest, observe?: 'body', reportProgress?: boolean): Observable<MahorElofizetes>;
    public offlineFizetesInditasUsingPOST(body: OfflineFizetesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MahorElofizetes>>;
    public offlineFizetesInditasUsingPOST(body: OfflineFizetesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MahorElofizetes>>;
    public offlineFizetesInditasUsingPOST(body: OfflineFizetesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling offlineFizetesInditasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MahorElofizetes>('post',`${this.basePath}/api/mohosz/mahorelofizetes/mahorelofizetes-offlinefizetesinditas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * onlineFizetesInditas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public onlineFizetesInditasUsingPOST(body: MahorElofizetesRequest, observe?: 'body', reportProgress?: boolean): Observable<KosarFizetes>;
    public onlineFizetesInditasUsingPOST(body: MahorElofizetesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KosarFizetes>>;
    public onlineFizetesInditasUsingPOST(body: MahorElofizetesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KosarFizetes>>;
    public onlineFizetesInditasUsingPOST(body: MahorElofizetesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling onlineFizetesInditasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<KosarFizetes>('post',`${this.basePath}/api/mohosz/mahorelofizetes/mahorelofizetes-onlinefizetesinditas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * rendszerenKivuliFizetesModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rendszerenKivuliFizetesModositasUsingPOST(body: RendszerenKivuliBefizetesModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<MahorElofizetes>;
    public rendszerenKivuliFizetesModositasUsingPOST(body: RendszerenKivuliBefizetesModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MahorElofizetes>>;
    public rendszerenKivuliFizetesModositasUsingPOST(body: RendszerenKivuliBefizetesModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MahorElofizetes>>;
    public rendszerenKivuliFizetesModositasUsingPOST(body: RendszerenKivuliBefizetesModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling rendszerenKivuliFizetesModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MahorElofizetes>('post',`${this.basePath}/api/mohosz/mahorelofizetes/mahorelofizetes-rendszerenkivulifizetes-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * torles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public torlesUsingPOST(body: MahorElofizetesRequest, observe?: 'body', reportProgress?: boolean): Observable<MahorElofizetes>;
    public torlesUsingPOST(body: MahorElofizetesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MahorElofizetes>>;
    public torlesUsingPOST(body: MahorElofizetesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MahorElofizetes>>;
    public torlesUsingPOST(body: MahorElofizetesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling torlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MahorElofizetes>('post',`${this.basePath}/api/mohosz/mahorelofizetes/mahorelofizetes-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * torzsadat
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public torzsadatUsingPOST(body: SzereploRequest, observe?: 'body', reportProgress?: boolean): Observable<MahorElofizetesTorzsadat>;
    public torzsadatUsingPOST(body: SzereploRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MahorElofizetesTorzsadat>>;
    public torzsadatUsingPOST(body: SzereploRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MahorElofizetesTorzsadat>>;
    public torzsadatUsingPOST(body: SzereploRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling torzsadatUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MahorElofizetesTorzsadat>('post',`${this.basePath}/api/mohosz/mahorelofizetes/mahorelofizetes-torzsadat`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
