/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SzereploCimzett } from './szereploCimzett';

export interface KezbesitendoUzenet { 
    cimzettValasz?: boolean;
    cimzettValaszLehetoseg?: boolean;
    felado?: SzereploCimzett;
    feladoSzereploId?: number;
    feladoSzereploNev?: string;
    id?: number;
    kezbesitve?: Date;
    letrehozva?: Date;
    tipus?: KezbesitendoUzenet.TipusEnum;
    ugyintezoNev?: string;
    uzenet?: string;
    uzenetCim?: string;
}
export namespace KezbesitendoUzenet {
    export type TipusEnum = 'ALTALANOS' | 'SURGOS' | 'FELADAT' | 'ERTESITES';
    export const TipusEnum = {
        ALTALANOS: 'ALTALANOS' as TipusEnum,
        SURGOS: 'SURGOS' as TipusEnum,
        FELADAT: 'FELADAT' as TipusEnum,
        ERTESITES: 'ERTESITES' as TipusEnum
    };
}