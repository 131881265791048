/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { VizsgaztatasRiportElszamolasItem } from './vizsgaztatasRiportElszamolasItem';

export interface VizsgaztatasRiportElszamolas { 
    elszamolasItemList?: Array<VizsgaztatasRiportElszamolasItem>;
    elszamolasKezdete?: string;
    elszamolasVege?: string;
    elszamoloSzervezetId?: number;
    elszamoloSzervezetNev?: string;
    sumJelentkezokSzama?: number;
    sumKizartSzemelyekSzama?: number;
    sumMegjelentekSzama?: number;
    sumNemJelentMegJelentkezokSzama?: number;
    sumSikeresVizsgazokSzama?: number;
    sumSikertelenVizsgazokSzama?: number;
}