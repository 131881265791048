/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NebihEltiltasFilter { 
    anyjaNeve?: string;
    dcSzemelyIds?: Array<number>;
    filterLabels?: Array<NebihEltiltasFilter.FilterLabelsEnum>;
    horgaszAzonosito?: string;
    keresztnev?: string;
    limit?: number;
    nebihPartnerId?: string;
    offset?: number;
    queryString?: string;
    szuletesiDatum?: string;
    szuletesiEv?: string;
    szuletesiHely?: string;
    tsQueryString?: string;
    unique4TId?: string;
    vezeteknev?: string;
}
export namespace NebihEltiltasFilter {
    export type FilterLabelsEnum = 'HORGASZHOZ_KAPCSOLT' | 'NEM_HORGASZHOZ_KAPCSOLT' | 'ELTILTOTT' | 'MANUALISAN_KAPCSOLT' | 'VAN_HASONLO_SZEMELY';
    export const FilterLabelsEnum = {
        HORGASZHOZKAPCSOLT: 'HORGASZHOZ_KAPCSOLT' as FilterLabelsEnum,
        NEMHORGASZHOZKAPCSOLT: 'NEM_HORGASZHOZ_KAPCSOLT' as FilterLabelsEnum,
        ELTILTOTT: 'ELTILTOTT' as FilterLabelsEnum,
        MANUALISANKAPCSOLT: 'MANUALISAN_KAPCSOLT' as FilterLabelsEnum,
        VANHASONLOSZEMELY: 'VAN_HASONLO_SZEMELY' as FilterLabelsEnum
    };
}