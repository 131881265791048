/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TeruletiJegySzervezetOsszesitesItem { 
    arres?: number;
    eladasiAr?: number;
    elszamolasiAr?: number;
    elszamoloSzervezetId?: number;
    elszamoloSzervezetNev?: string;
    ertekesitesTipus?: TeruletiJegySzervezetOsszesitesItem.ErtekesitesTipusEnum;
    ertekesitettDarabszam?: number;
    osszegzesSzukseges?: boolean;
    rendszerhasznalatiDij?: number;
    sztornozottDarabszam?: number;
    termekAr?: number;
}
export namespace TeruletiJegySzervezetOsszesitesItem {
    export type ErtekesitesTipusEnum = 'ONLINE' | 'SAJAT' | 'KOZVETLEN' | 'VISZONT';
    export const ErtekesitesTipusEnum = {
        ONLINE: 'ONLINE' as ErtekesitesTipusEnum,
        SAJAT: 'SAJAT' as ErtekesitesTipusEnum,
        KOZVETLEN: 'KOZVETLEN' as ErtekesitesTipusEnum,
        VISZONT: 'VISZONT' as ErtekesitesTipusEnum
    };
}