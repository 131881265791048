import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  declinedUrls = ['logout', 'login', 'megbizokivalasztas']

  constructor(
    private authenticationService: AuthenticationService
  ) {
  }

  private isUrlAccepted(httpUrl: string): boolean {
    return this.declinedUrls.every(declinedUrl => !httpUrl.endsWith(declinedUrl))
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((response) => {
        if (response instanceof HttpErrorResponse) {
          const status = (response.error && response.error.status) ? response.error.status : response.status
          if (status === 403 || status === 401 && this.isUrlAccepted(response.url)) {
            this.authenticationService.reLogin();
            //amelyik hivas nem kezeli a requestRepeat erteket annak hibat dobunk
            if (!response.url.endsWith('horgaszadat-kereses')) {
              return throwError(response);
            }
          } else {
            return throwError(response);
          }
        }
      })
    );
  }
}
