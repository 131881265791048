/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SzolgaltatasFilter { 
    egyediAzonosito?: string;
    enableFetchAll?: boolean;
    globalSearch?: string;
    limit?: number;
    offset?: number;
    ownerSzereploId?: number;
    slobalSearch?: boolean;
    statuszList?: Array<SzolgaltatasFilter.StatuszListEnum>;
}
export namespace SzolgaltatasFilter {
    export type StatuszListEnum = 'ROGZITESALATT' | 'JOVAHAGYASRAVAR' | 'AKTIV' | 'INAKTIV';
    export const StatuszListEnum = {
        ROGZITESALATT: 'ROGZITESALATT' as StatuszListEnum,
        JOVAHAGYASRAVAR: 'JOVAHAGYASRAVAR' as StatuszListEnum,
        AKTIV: 'AKTIV' as StatuszListEnum,
        INAKTIV: 'INAKTIV' as StatuszListEnum
    };
}