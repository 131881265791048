import { Configuration } from 'src/api';
import { environment } from 'src/environments/environment';


export function apiModuleConfiguration(): Configuration {
    console.log('apiModuleConfiguration: environment', environment);
    let basePath = environment.serverUrl
    if (!environment.serverUrl) {
        const l = window.location
        basePath = l.protocol + '//' + l.host
    }
    console.log('apiModuleConfiguration: basePath', basePath);
    return new Configuration({
        withCredentials: true,
        basePath: basePath
    });
}
