/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Szemely4T } from './szemely4T';

export interface DuplicationCandidate { 
    horgaszAzonosito?: string;
    similarity?: number;
    szemely4T?: Szemely4T;
    szemelyId?: number;
}