/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Feladatsor } from '../model/feladatsor';
import { FeladatsorAktivalasRequest } from '../model/feladatsorAktivalasRequest';
import { FeladatsorLetrehozasRequest } from '../model/feladatsorLetrehozasRequest';
import { FeladatsorModositasRequest } from '../model/feladatsorModositasRequest';
import { Kerdes } from '../model/kerdes';
import { KerdesCsoportHozzaadasaRequest } from '../model/kerdesCsoportHozzaadasaRequest';
import { KerdesCsoportModositasaRequest } from '../model/kerdesCsoportModositasaRequest';
import { KerdesCsoportTorleseRequest } from '../model/kerdesCsoportTorleseRequest';
import { KerdesHozzaadasaFeladatsorhozRequest } from '../model/kerdesHozzaadasaFeladatsorhozRequest';
import { KerdesKeresesRequest } from '../model/kerdesKeresesRequest';
import { KerdesKeresesResponse } from '../model/kerdesKeresesResponse';
import { KerdesModositasaFeladatsorbanRequest } from '../model/kerdesModositasaFeladatsorbanRequest';
import { KerdesTorleseFeladatsorbolRequest } from '../model/kerdesTorleseFeladatsorbolRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class FeladatsorControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * aktivalas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public aktivalasUsingPOST(body: FeladatsorAktivalasRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public aktivalasUsingPOST(body: FeladatsorAktivalasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public aktivalasUsingPOST(body: FeladatsorAktivalasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public aktivalasUsingPOST(body: FeladatsorAktivalasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling aktivalasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/feladatsor/feladatsor-aktivalas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * feladatsorKepUpload
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public feladatsorKepUploadUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public feladatsorKepUploadUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public feladatsorKepUploadUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public feladatsorKepUploadUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling feladatsorKepUploadUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/feladatsor/feladatsor-kep-upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * feladatsorLetrehozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public feladatsorLetrehozasUsingPOST(body: FeladatsorLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<Feladatsor>;
    public feladatsorLetrehozasUsingPOST(body: FeladatsorLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Feladatsor>>;
    public feladatsorLetrehozasUsingPOST(body: FeladatsorLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Feladatsor>>;
    public feladatsorLetrehozasUsingPOST(body: FeladatsorLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling feladatsorLetrehozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Feladatsor>('post',`${this.basePath}/api/mohosz/feladatsor/feladatsor-letrehozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * feladatsorModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public feladatsorModositasUsingPOST(body: FeladatsorModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<Feladatsor>;
    public feladatsorModositasUsingPOST(body: FeladatsorModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Feladatsor>>;
    public feladatsorModositasUsingPOST(body: FeladatsorModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Feladatsor>>;
    public feladatsorModositasUsingPOST(body: FeladatsorModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling feladatsorModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Feladatsor>('post',`${this.basePath}/api/mohosz/feladatsor/feladatsor-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * feladatsorNevLetezik
     * 
     * @param nev nev
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public feladatsorNevLetezikUsingPOST(nev: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public feladatsorNevLetezikUsingPOST(nev: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public feladatsorNevLetezikUsingPOST(nev: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public feladatsorNevLetezikUsingPOST(nev: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (nev === null || nev === undefined) {
            throw new Error('Required parameter nev was null or undefined when calling feladatsorNevLetezikUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (nev !== undefined && nev !== null) {
            queryParameters = queryParameters.set('nev', <any>nev);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/mohosz/feladatsor/feladatsor-nev-letezik`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * feladatsorTorles
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public feladatsorTorlesUsingPOST(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public feladatsorTorlesUsingPOST(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public feladatsorTorlesUsingPOST(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public feladatsorTorlesUsingPOST(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling feladatsorTorlesUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/feladatsor/feladatsor-torles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchAllFeladatsor
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchAllFeladatsorUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<Feladatsor>>;
    public fetchAllFeladatsorUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Feladatsor>>>;
    public fetchAllFeladatsorUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Feladatsor>>>;
    public fetchAllFeladatsorUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Feladatsor>>('post',`${this.basePath}/api/mohosz/feladatsor/fetch-all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchAllKerdes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchAllKerdesUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<Kerdes>>;
    public fetchAllKerdesUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Kerdes>>>;
    public fetchAllKerdesUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Kerdes>>>;
    public fetchAllKerdesUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Kerdes>>('post',`${this.basePath}/api/mohosz/feladatsor/fetch-all-kerdes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findEgyFeladatsor
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findEgyFeladatsorUsingPOST(id: number, observe?: 'body', reportProgress?: boolean): Observable<Feladatsor>;
    public findEgyFeladatsorUsingPOST(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Feladatsor>>;
    public findEgyFeladatsorUsingPOST(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Feladatsor>>;
    public findEgyFeladatsorUsingPOST(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling findEgyFeladatsorUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Feladatsor>('post',`${this.basePath}/api/mohosz/feladatsor/egy-feladatsor-kereses`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findEgyKerdes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findEgyKerdesUsingPOST(body: KerdesKeresesRequest, observe?: 'body', reportProgress?: boolean): Observable<KerdesKeresesResponse>;
    public findEgyKerdesUsingPOST(body: KerdesKeresesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KerdesKeresesResponse>>;
    public findEgyKerdesUsingPOST(body: KerdesKeresesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KerdesKeresesResponse>>;
    public findEgyKerdesUsingPOST(body: KerdesKeresesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findEgyKerdesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<KerdesKeresesResponse>('post',`${this.basePath}/api/mohosz/feladatsor/egy-kerdes-kereses`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * inaktivalas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inaktivalasUsingPOST(body: FeladatsorAktivalasRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public inaktivalasUsingPOST(body: FeladatsorAktivalasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public inaktivalasUsingPOST(body: FeladatsorAktivalasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public inaktivalasUsingPOST(body: FeladatsorAktivalasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling inaktivalasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/feladatsor/feladatsor-inaktivalas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kerdesCsoportHozzaadasaFeladatsorhoz
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kerdesCsoportHozzaadasaFeladatsorhozUsingPOST(body: KerdesCsoportHozzaadasaRequest, observe?: 'body', reportProgress?: boolean): Observable<Feladatsor>;
    public kerdesCsoportHozzaadasaFeladatsorhozUsingPOST(body: KerdesCsoportHozzaadasaRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Feladatsor>>;
    public kerdesCsoportHozzaadasaFeladatsorhozUsingPOST(body: KerdesCsoportHozzaadasaRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Feladatsor>>;
    public kerdesCsoportHozzaadasaFeladatsorhozUsingPOST(body: KerdesCsoportHozzaadasaRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kerdesCsoportHozzaadasaFeladatsorhozUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Feladatsor>('post',`${this.basePath}/api/mohosz/feladatsor/kerdescsoport-hozzaadasa-feladatsorhoz`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kerdesCsoportModositasaFeladatsorban
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kerdesCsoportModositasaFeladatsorbanUsingPOST(body: KerdesCsoportModositasaRequest, observe?: 'body', reportProgress?: boolean): Observable<Feladatsor>;
    public kerdesCsoportModositasaFeladatsorbanUsingPOST(body: KerdesCsoportModositasaRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Feladatsor>>;
    public kerdesCsoportModositasaFeladatsorbanUsingPOST(body: KerdesCsoportModositasaRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Feladatsor>>;
    public kerdesCsoportModositasaFeladatsorbanUsingPOST(body: KerdesCsoportModositasaRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kerdesCsoportModositasaFeladatsorbanUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Feladatsor>('post',`${this.basePath}/api/mohosz/feladatsor/kerdescsoport-modositasa-feladatsorban`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kerdesCsoportTorleseFeladatsorbol
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kerdesCsoportTorleseFeladatsorbolUsingPOST(body: KerdesCsoportTorleseRequest, observe?: 'body', reportProgress?: boolean): Observable<Feladatsor>;
    public kerdesCsoportTorleseFeladatsorbolUsingPOST(body: KerdesCsoportTorleseRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Feladatsor>>;
    public kerdesCsoportTorleseFeladatsorbolUsingPOST(body: KerdesCsoportTorleseRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Feladatsor>>;
    public kerdesCsoportTorleseFeladatsorbolUsingPOST(body: KerdesCsoportTorleseRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kerdesCsoportTorleseFeladatsorbolUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Feladatsor>('post',`${this.basePath}/api/mohosz/feladatsor/kerdescsoport-torlese-feladatsorbol`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kerdesHozzaAdasaFeladatsorhoz
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kerdesHozzaAdasaFeladatsorhozUsingPOST(body: KerdesHozzaadasaFeladatsorhozRequest, observe?: 'body', reportProgress?: boolean): Observable<Feladatsor>;
    public kerdesHozzaAdasaFeladatsorhozUsingPOST(body: KerdesHozzaadasaFeladatsorhozRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Feladatsor>>;
    public kerdesHozzaAdasaFeladatsorhozUsingPOST(body: KerdesHozzaadasaFeladatsorhozRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Feladatsor>>;
    public kerdesHozzaAdasaFeladatsorhozUsingPOST(body: KerdesHozzaadasaFeladatsorhozRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kerdesHozzaAdasaFeladatsorhozUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Feladatsor>('post',`${this.basePath}/api/mohosz/feladatsor/kerdes-hozzaadasa-feladatsorhoz`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kerdesModositasaFeladatsorban
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kerdesModositasaFeladatsorbanUsingPOST(body: KerdesModositasaFeladatsorbanRequest, observe?: 'body', reportProgress?: boolean): Observable<Feladatsor>;
    public kerdesModositasaFeladatsorbanUsingPOST(body: KerdesModositasaFeladatsorbanRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Feladatsor>>;
    public kerdesModositasaFeladatsorbanUsingPOST(body: KerdesModositasaFeladatsorbanRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Feladatsor>>;
    public kerdesModositasaFeladatsorbanUsingPOST(body: KerdesModositasaFeladatsorbanRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kerdesModositasaFeladatsorbanUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Feladatsor>('post',`${this.basePath}/api/mohosz/feladatsor/kerdes-modositas-feladatsorban`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kerdesTorleseFeladatsorbol
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kerdesTorleseFeladatsorbolUsingPOST(body: KerdesTorleseFeladatsorbolRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public kerdesTorleseFeladatsorbolUsingPOST(body: KerdesTorleseFeladatsorbolRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public kerdesTorleseFeladatsorbolUsingPOST(body: KerdesTorleseFeladatsorbolRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public kerdesTorleseFeladatsorbolUsingPOST(body: KerdesTorleseFeladatsorbolRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kerdesTorleseFeladatsorbolUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/feladatsor/kerdes-torlese-feladatsorbol`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
