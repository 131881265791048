/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Cim } from './cim';

export interface MahorElofizetesLetrehozasRequest { 
    idotartam?: MahorElofizetesLetrehozasRequest.IdotartamEnum;
    kezbesitesTipus?: MahorElofizetesLetrehozasRequest.KezbesitesTipusEnum;
    lejaratErtesitesEngedelyezve?: boolean;
    nev?: string;
    peldanyszam?: number;
    rendszerenKivuliElofizetes?: boolean;
    sajatElofizetes?: boolean;
    szallitasiCim?: Cim;
    szamlazasiAdoszam?: string;
    szamlazasiCim?: Cim;
    szamlazasiNev?: string;
    szereploId?: number;
    validIdotartamPeldanyszam?: boolean;
    validSzereploId?: boolean;
}
export namespace MahorElofizetesLetrehozasRequest {
    export type IdotartamEnum = 'EVES' | 'FELEVES';
    export const IdotartamEnum = {
        EVES: 'EVES' as IdotartamEnum,
        FELEVES: 'FELEVES' as IdotartamEnum
    };
    export type KezbesitesTipusEnum = 'LISTAS' | 'CIMZETT';
    export const KezbesitesTipusEnum = {
        LISTAS: 'LISTAS' as KezbesitesTipusEnum,
        CIMZETT: 'CIMZETT' as KezbesitesTipusEnum
    };
}