/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UzenetLetrehozasRequest { 
    cimzettCsatornaSet?: Array<UzenetLetrehozasRequest.CimzettCsatornaSetEnum>;
    cimzettEgyesuletSzervezetIdSet?: Array<number>;
    cimzettSzemelyIdSet?: Array<number>;
    cimzettSzervezetIdSet?: Array<number>;
    cimzettValaszLehetoseg?: boolean;
    emailKikuldes?: boolean;
    feladatTipus?: UzenetLetrehozasRequest.FeladatTipusEnum;
    feladoSzereploId?: number;
    fromDate?: string;
    tipus?: UzenetLetrehozasRequest.TipusEnum;
    toDate?: string;
    uzenet?: string;
    uzenetCim?: string;
    validCimzett?: boolean;
    validSzereploId?: boolean;
    validTipus?: boolean;
}
export namespace UzenetLetrehozasRequest {
    export type CimzettCsatornaSetEnum = 'ONLINE' | 'UGYINTEZO' | 'UGYFELSZOLGALAT' | 'ALTALANOS';
    export const CimzettCsatornaSetEnum = {
        ONLINE: 'ONLINE' as CimzettCsatornaSetEnum,
        UGYINTEZO: 'UGYINTEZO' as CimzettCsatornaSetEnum,
        UGYFELSZOLGALAT: 'UGYFELSZOLGALAT' as CimzettCsatornaSetEnum,
        ALTALANOS: 'ALTALANOS' as CimzettCsatornaSetEnum
    };
    export type FeladatTipusEnum = 'TERULETIJEGY_SZTORNO' | 'EAH_SZTORNO';
    export const FeladatTipusEnum = {
        TERULETIJEGYSZTORNO: 'TERULETIJEGY_SZTORNO' as FeladatTipusEnum,
        EAHSZTORNO: 'EAH_SZTORNO' as FeladatTipusEnum
    };
    export type TipusEnum = 'ALTALANOS' | 'SURGOS' | 'FELADAT' | 'ERTESITES';
    export const TipusEnum = {
        ALTALANOS: 'ALTALANOS' as TipusEnum,
        SURGOS: 'SURGOS' as TipusEnum,
        FELADAT: 'FELADAT' as TipusEnum,
        ERTESITES: 'ERTESITES' as TipusEnum
    };
}