/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CsekkesFizetesElismeresFilter { 
    arvaPostaiElismeres?: boolean;
    limit?: number;
    offset?: number;
    postaiElismeres?: boolean;
    szemelyKitoltve?: boolean;
    szemelyTalalat?: boolean;
    tsQueryString?: string;
    ugyintezoiFeldolgozas?: boolean;
    ugyintezoiFizetesAllapot?: CsekkesFizetesElismeresFilter.UgyintezoiFizetesAllapotEnum;
}
export namespace CsekkesFizetesElismeresFilter {
    export type UgyintezoiFizetesAllapotEnum = 'ELISMERVE' | 'ELUTASITVA';
    export const UgyintezoiFizetesAllapotEnum = {
        ELISMERVE: 'ELISMERVE' as UgyintezoiFizetesAllapotEnum,
        ELUTASITVA: 'ELUTASITVA' as UgyintezoiFizetesAllapotEnum
    };
}