/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizteruletCsoportListazasAlapadat { 
    id?: number;
    isCsoport?: boolean;
    kedvenc?: boolean;
    kepIds?: Array<number>;
    nev?: string;
    vizteruletCsoportTipus?: VizteruletCsoportListazasAlapadat.VizteruletCsoportTipusEnum;
}
export namespace VizteruletCsoportListazasAlapadat {
    export type VizteruletCsoportTipusEnum = 'OSSZEVONT_VIZTERULET_CSOPORT' | 'EGYESITETT_VIZTERULET_CSOPORT' | 'OSSZEVONT_ORSZAGOS_VIZTERULET_CSOPORT' | 'VIZTERULET';
    export const VizteruletCsoportTipusEnum = {
        OSSZEVONTVIZTERULETCSOPORT: 'OSSZEVONT_VIZTERULET_CSOPORT' as VizteruletCsoportTipusEnum,
        EGYESITETTVIZTERULETCSOPORT: 'EGYESITETT_VIZTERULET_CSOPORT' as VizteruletCsoportTipusEnum,
        OSSZEVONTORSZAGOSVIZTERULETCSOPORT: 'OSSZEVONT_ORSZAGOS_VIZTERULET_CSOPORT' as VizteruletCsoportTipusEnum,
        VIZTERULET: 'VIZTERULET' as VizteruletCsoportTipusEnum
    };
}