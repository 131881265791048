/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SzemelyEllenorzes { 
    idopont?: Date;
    minosites?: SzemelyEllenorzes.MinositesEnum;
    szabalysertesSorszama?: string;
    szervezetNev?: string;
    vizetuletNev?: string;
    vizterKod?: string;
}
export namespace SzemelyEllenorzes {
    export type MinositesEnum = 'RENDBEN' | 'SZABALYSERTES';
    export const MinositesEnum = {
        RENDBEN: 'RENDBEN' as MinositesEnum,
        SZABALYSERTES: 'SZABALYSERTES' as MinositesEnum
    };
}