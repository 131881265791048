/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EllenorTevekenysegRiportRow } from './ellenorTevekenysegRiportRow';
import { GPSKoordinata } from './gPSKoordinata';

export interface EllenorTevekenysegRiportPOI { 
    koordinata?: GPSKoordinata;
    poiszin?: EllenorTevekenysegRiportPOI.PoiszinEnum;
    tevekenysegek?: Array<EllenorTevekenysegRiportRow>;
}
export namespace EllenorTevekenysegRiportPOI {
    export type PoiszinEnum = 'PIROS' | 'SARGA' | 'ZOLD' | 'KEK';
    export const PoiszinEnum = {
        PIROS: 'PIROS' as PoiszinEnum,
        SARGA: 'SARGA' as PoiszinEnum,
        ZOLD: 'ZOLD' as PoiszinEnum,
        KEK: 'KEK' as PoiszinEnum
    };
}