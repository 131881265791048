/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VersenyzoiEngedelyErtekesitesFilter { 
    ertekesitoSzervezetId?: number;
    ev?: number;
    fizetesTipus?: VersenyzoiEngedelyErtekesitesFilter.FizetesTipusEnum;
    globalSearch?: string;
    honap?: number;
    korkategoriaList?: Array<VersenyzoiEngedelyErtekesitesFilter.KorkategoriaListEnum>;
    limit?: number;
    offset?: number;
}
export namespace VersenyzoiEngedelyErtekesitesFilter {
    export type FizetesTipusEnum = 'KH' | 'ERTEKESITESIPONT' | 'RK_FIZETES';
    export const FizetesTipusEnum = {
        KH: 'KH' as FizetesTipusEnum,
        ERTEKESITESIPONT: 'ERTEKESITESIPONT' as FizetesTipusEnum,
        RKFIZETES: 'RK_FIZETES' as FizetesTipusEnum
    };
    export type KorkategoriaListEnum = 'FELNOTT' | 'IFJUSAGI_18_ALATTI' | 'KISKORU_14_ALATTI';
    export const KorkategoriaListEnum = {
        FELNOTT: 'FELNOTT' as KorkategoriaListEnum,
        IFJUSAGI18ALATTI: 'IFJUSAGI_18_ALATTI' as KorkategoriaListEnum,
        KISKORU14ALATTI: 'KISKORU_14_ALATTI' as KorkategoriaListEnum
    };
}