/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AllamiJegy } from './allamiJegy';
import { FogasiNaplo } from './fogasiNaplo';
import { HorgaszNyilatkozat } from './horgaszNyilatkozat';
import { HorgaszSzemelyesAdat } from './horgaszSzemelyesAdat';
import { MasEgyesuletiTagsag } from './masEgyesuletiTagsag';
import { MohoszEgyesuletiTagsag } from './mohoszEgyesuletiTagsag';
import { TeruletiJegy } from './teruletiJegy';
import { VersenyzoiEngedely } from './versenyzoiEngedely';

export interface HorgaszBugyellaris { 
    allamiJegyek?: Array<AllamiJegy>;
    availableCommands?: Array<HorgaszBugyellaris.AvailableCommandsEnum>;
    eloStatusz?: boolean;
    fogasiNaplok?: Array<FogasiNaplo>;
    hasAllamiHorgaszvizsga?: boolean;
    hasHorgaszkartya?: boolean;
    hasTuristaHorgaszvizsga?: boolean;
    horgaszAzonosito?: string;
    horgaszvizsgaDatum?: string;
    horgaszvizsgaIgazolasTipus?: HorgaszBugyellaris.HorgaszvizsgaIgazolasTipusEnum;
    horgaszvizsgaKepId?: number;
    horgaszvizsgaSzam?: string;
    masEgyesuletiTagsagSet?: Array<MasEgyesuletiTagsag>;
    mohoszEgyesuletiTagsagSet?: Array<MohoszEgyesuletiTagsag>;
    nyilatkozatok?: Array<HorgaszNyilatkozat>;
    statusz?: HorgaszBugyellaris.StatuszEnum;
    szemelyId?: number;
    szemelyesAdat?: HorgaszSzemelyesAdat;
    sztornozottAllamiJegyek?: Array<AllamiJegy>;
    teruletiJegyek?: Array<TeruletiJegy>;
    versenyzoiEngedelyek?: Array<VersenyzoiEngedely>;
}
export namespace HorgaszBugyellaris {
    export type AvailableCommandsEnum = 'HORGASZVIZSGA_TORLES';
    export const AvailableCommandsEnum = {
        TORLES: 'HORGASZVIZSGA_TORLES' as AvailableCommandsEnum
    };
    export type HorgaszvizsgaIgazolasTipusEnum = 'FOGASINAPLO' | 'HORGASZVIZSGA' | 'MENTESITESIIGAZOLAS';
    export const HorgaszvizsgaIgazolasTipusEnum = {
        FOGASINAPLO: 'FOGASINAPLO' as HorgaszvizsgaIgazolasTipusEnum,
        HORGASZVIZSGA: 'HORGASZVIZSGA' as HorgaszvizsgaIgazolasTipusEnum,
        MENTESITESIIGAZOLAS: 'MENTESITESIIGAZOLAS' as HorgaszvizsgaIgazolasTipusEnum
    };
    export type StatuszEnum = 'REGISZTRACIO_FOLYAMATBAN' | 'FIZETES_FOLYAMATBAN' | 'ELO' | 'ADATEGYEZTETESMIATTI_FELFUGGESZTETT_ELO' | 'FELADOVEVENYES_ELO' | 'UGYINTEZOI_JOVAHAGYASRA_VAR' | 'FELFUGGESZTVE';
    export const StatuszEnum = {
        REGISZTRACIOFOLYAMATBAN: 'REGISZTRACIO_FOLYAMATBAN' as StatuszEnum,
        FIZETESFOLYAMATBAN: 'FIZETES_FOLYAMATBAN' as StatuszEnum,
        ELO: 'ELO' as StatuszEnum,
        ADATEGYEZTETESMIATTIFELFUGGESZTETTELO: 'ADATEGYEZTETESMIATTI_FELFUGGESZTETT_ELO' as StatuszEnum,
        FELADOVEVENYESELO: 'FELADOVEVENYES_ELO' as StatuszEnum,
        UGYINTEZOIJOVAHAGYASRAVAR: 'UGYINTEZOI_JOVAHAGYASRA_VAR' as StatuszEnum,
        FELFUGGESZTVE: 'FELFUGGESZTVE' as StatuszEnum
    };
}