/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CsapatAthelyezesRequest } from '../model/csapatAthelyezesRequest';
import { CsapatAthelyezesResponse } from '../model/csapatAthelyezesResponse';
import { CsapatAzonositoGeneralasReply } from '../model/csapatAzonositoGeneralasReply';
import { CsapatLetrehozasRequest } from '../model/csapatLetrehozasRequest';
import { CsapatTagAthelyezesRequest } from '../model/csapatTagAthelyezesRequest';
import { CsapatTagHozzaadasRequest } from '../model/csapatTagHozzaadasRequest';
import { CsapatTagTorlesRequest } from '../model/csapatTagTorlesRequest';
import { CsapatTorlesRequest } from '../model/csapatTorlesRequest';
import { SzemelyVersenyRequest } from '../model/szemelyVersenyRequest';
import { Verseny } from '../model/verseny';
import { VersenyFilter } from '../model/versenyFilter';
import { VersenyJelentkezesAthelyezesRequest } from '../model/versenyJelentkezesAthelyezesRequest';
import { VersenyJelentkezesRequest } from '../model/versenyJelentkezesRequest';
import { VersenyKapcsolattartasModositasRequest } from '../model/versenyKapcsolattartasModositasRequest';
import { VersenyLetrehozasRequest } from '../model/versenyLetrehozasRequest';
import { VersenyModositasRequest } from '../model/versenyModositasRequest';
import { VersenyRequest } from '../model/versenyRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class VersenyMgmtControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * csapatAthelyezes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public csapatAthelyezesUsingPOST(body: CsapatAthelyezesRequest, observe?: 'body', reportProgress?: boolean): Observable<CsapatAthelyezesResponse>;
    public csapatAthelyezesUsingPOST(body: CsapatAthelyezesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CsapatAthelyezesResponse>>;
    public csapatAthelyezesUsingPOST(body: CsapatAthelyezesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CsapatAthelyezesResponse>>;
    public csapatAthelyezesUsingPOST(body: CsapatAthelyezesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling csapatAthelyezesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CsapatAthelyezesResponse>('post',`${this.basePath}/api/mohosz/versenymgmt/csapat-athelyezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * csapatLetrehozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public csapatLetrehozasUsingPOST(body: CsapatLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public csapatLetrehozasUsingPOST(body: CsapatLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public csapatLetrehozasUsingPOST(body: CsapatLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public csapatLetrehozasUsingPOST(body: CsapatLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling csapatLetrehozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenymgmt/csapat-letrehozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * csapatTagAthelyezes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public csapatTagAthelyezesUsingPOST(body: CsapatTagAthelyezesRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public csapatTagAthelyezesUsingPOST(body: CsapatTagAthelyezesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public csapatTagAthelyezesUsingPOST(body: CsapatTagAthelyezesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public csapatTagAthelyezesUsingPOST(body: CsapatTagAthelyezesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling csapatTagAthelyezesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenymgmt/csapat-tag-athelyezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * csapatTagHozzaadas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public csapatTagHozzaadasUsingPOST(body: CsapatTagHozzaadasRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public csapatTagHozzaadasUsingPOST(body: CsapatTagHozzaadasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public csapatTagHozzaadasUsingPOST(body: CsapatTagHozzaadasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public csapatTagHozzaadasUsingPOST(body: CsapatTagHozzaadasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling csapatTagHozzaadasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenymgmt/csapat-tag-hozzaadas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * csapatTagTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public csapatTagTorlesUsingPOST(body: CsapatTagTorlesRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public csapatTagTorlesUsingPOST(body: CsapatTagTorlesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public csapatTagTorlesUsingPOST(body: CsapatTagTorlesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public csapatTagTorlesUsingPOST(body: CsapatTagTorlesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling csapatTagTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenymgmt/csapat-tag-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * csapatTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public csapatTorlesUsingPOST(body: CsapatTorlesRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public csapatTorlesUsingPOST(body: CsapatTorlesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public csapatTorlesUsingPOST(body: CsapatTorlesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public csapatTorlesUsingPOST(body: CsapatTorlesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling csapatTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenymgmt/csapat-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * jelentkezesAthelyezes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public jelentkezesAthelyezesUsingPOST(body: VersenyJelentkezesAthelyezesRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public jelentkezesAthelyezesUsingPOST(body: VersenyJelentkezesAthelyezesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public jelentkezesAthelyezesUsingPOST(body: VersenyJelentkezesAthelyezesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public jelentkezesAthelyezesUsingPOST(body: VersenyJelentkezesAthelyezesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling jelentkezesAthelyezesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenymgmt/jelentkezes-athelyezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * jelentkezesTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public jelentkezesTorlesUsingPOST(body: SzemelyVersenyRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public jelentkezesTorlesUsingPOST(body: SzemelyVersenyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public jelentkezesTorlesUsingPOST(body: SzemelyVersenyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public jelentkezesTorlesUsingPOST(body: SzemelyVersenyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling jelentkezesTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenymgmt/jelentkezes-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * jelentkezes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public jelentkezesUsingPOST(body: VersenyJelentkezesRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public jelentkezesUsingPOST(body: VersenyJelentkezesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public jelentkezesUsingPOST(body: VersenyJelentkezesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public jelentkezesUsingPOST(body: VersenyJelentkezesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling jelentkezesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenymgmt/jelentkezes-letrehozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kapcsolattartasModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kapcsolattartasModositasUsingPOST(body: VersenyKapcsolattartasModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public kapcsolattartasModositasUsingPOST(body: VersenyKapcsolattartasModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public kapcsolattartasModositasUsingPOST(body: VersenyKapcsolattartasModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public kapcsolattartasModositasUsingPOST(body: VersenyKapcsolattartasModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kapcsolattartasModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenymgmt/kapcsolattartas-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * letrehozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public letrehozasUsingPOST(body: VersenyLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public letrehozasUsingPOST(body: VersenyLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public letrehozasUsingPOST(body: VersenyLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public letrehozasUsingPOST(body: VersenyLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling letrehozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenymgmt/verseny-letrehozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listazas
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listazasUsingPOST(body: VersenyFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<Verseny>>;
    public listazasUsingPOST(body: VersenyFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Verseny>>>;
    public listazasUsingPOST(body: VersenyFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Verseny>>>;
    public listazasUsingPOST(body: VersenyFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling listazasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<Verseny>>('post',`${this.basePath}/api/mohosz/versenymgmt/verseny-listazas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * modositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modositasUsingPOST(body: VersenyModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public modositasUsingPOST(body: VersenyModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public modositasUsingPOST(body: VersenyModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public modositasUsingPOST(body: VersenyModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling modositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenymgmt/verseny-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * nextAzonosito
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public nextAzonositoUsingPOST(body: VersenyRequest, observe?: 'body', reportProgress?: boolean): Observable<CsapatAzonositoGeneralasReply>;
    public nextAzonositoUsingPOST(body: VersenyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CsapatAzonositoGeneralasReply>>;
    public nextAzonositoUsingPOST(body: VersenyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CsapatAzonositoGeneralasReply>>;
    public nextAzonositoUsingPOST(body: VersenyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling nextAzonositoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CsapatAzonositoGeneralasReply>('post',`${this.basePath}/api/mohosz/versenymgmt/azonosito-generalas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * offlineFizetes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public offlineFizetesUsingPOST(body: SzemelyVersenyRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public offlineFizetesUsingPOST(body: SzemelyVersenyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public offlineFizetesUsingPOST(body: SzemelyVersenyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public offlineFizetesUsingPOST(body: SzemelyVersenyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling offlineFizetesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenymgmt/offline-fizetes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szamossag
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szamossagUsingPOST(body: VersenyFilter, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public szamossagUsingPOST(body: VersenyFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public szamossagUsingPOST(body: VersenyFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public szamossagUsingPOST(body: VersenyFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szamossagUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/versenymgmt/verseny-szamossag`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * torles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public torlesUsingPOST(body: VersenyRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public torlesUsingPOST(body: VersenyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public torlesUsingPOST(body: VersenyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public torlesUsingPOST(body: VersenyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling torlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/versenymgmt/verseny-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * versenyExport
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public versenyExportUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public versenyExportUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public versenyExportUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public versenyExportUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling versenyExportUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/versenymgmt/verseny-export`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * versenyFetch
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public versenyFetchUsingPOST(body: VersenyRequest, observe?: 'body', reportProgress?: boolean): Observable<Verseny>;
    public versenyFetchUsingPOST(body: VersenyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Verseny>>;
    public versenyFetchUsingPOST(body: VersenyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Verseny>>;
    public versenyFetchUsingPOST(body: VersenyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling versenyFetchUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Verseny>('post',`${this.basePath}/api/mohosz/versenymgmt/verseny-fetch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
