/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AllamiJegyVasarlasEllenorzesRequest { 
    egyesuletiTagsagKategoria?: AllamiJegyVasarlasEllenorzesRequest.EgyesuletiTagsagKategoriaEnum;
    ervenyessegiEv?: number;
    fogyatekkalElo?: boolean;
    korkategoria?: AllamiJegyVasarlasEllenorzesRequest.KorkategoriaEnum;
    szemelyId?: number;
    tagEgyesuletId?: number;
}
export namespace AllamiJegyVasarlasEllenorzesRequest {
    export type EgyesuletiTagsagKategoriaEnum = 'IFJUSAGI' | 'FELNOTT' | 'FELNOTT_KEDVEZMENYEZETT' | 'IFJUSAGI_IHJ' | 'FELNOTT_IHJ' | 'FELNOTT_JOGFENNTARTO_IHJ' | 'MOHOSZON_KIVULI';
    export const EgyesuletiTagsagKategoriaEnum = {
        IFJUSAGI: 'IFJUSAGI' as EgyesuletiTagsagKategoriaEnum,
        FELNOTT: 'FELNOTT' as EgyesuletiTagsagKategoriaEnum,
        FELNOTTKEDVEZMENYEZETT: 'FELNOTT_KEDVEZMENYEZETT' as EgyesuletiTagsagKategoriaEnum,
        IFJUSAGIIHJ: 'IFJUSAGI_IHJ' as EgyesuletiTagsagKategoriaEnum,
        FELNOTTIHJ: 'FELNOTT_IHJ' as EgyesuletiTagsagKategoriaEnum,
        FELNOTTJOGFENNTARTOIHJ: 'FELNOTT_JOGFENNTARTO_IHJ' as EgyesuletiTagsagKategoriaEnum,
        MOHOSZONKIVULI: 'MOHOSZON_KIVULI' as EgyesuletiTagsagKategoriaEnum
    };
    export type KorkategoriaEnum = 'GYERMEK' | 'IFJUSAGI' | 'FELNOTT';
    export const KorkategoriaEnum = {
        GYERMEK: 'GYERMEK' as KorkategoriaEnum,
        IFJUSAGI: 'IFJUSAGI' as KorkategoriaEnum,
        FELNOTT: 'FELNOTT' as KorkategoriaEnum
    };
}