/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DuplicationCandidate } from './duplicationCandidate';

export interface SzemelyEltiltasokDetails { 
    aktivEltiltas?: boolean;
    anyjaNeve?: string;
    availableCommands?: Array<SzemelyEltiltasokDetails.AvailableCommandsEnum>;
    hasonloSzemelyList?: Array<DuplicationCandidate>;
    horgaszAzonosito?: string;
    keresztnev?: string;
    nebihPartnerId?: string;
    szemelyId?: number;
    szuletesiDatum?: string;
    szuletesiHely?: string;
    titulus?: string;
    utolsoEltiltasVege?: string;
    vezeteknev?: string;
}
export namespace SzemelyEltiltasokDetails {
    export type AvailableCommandsEnum = 'KAPCSOLAS' | 'LEVALASZTAS' | 'HATAROZATOK';
    export const AvailableCommandsEnum = {
        KAPCSOLAS: 'KAPCSOLAS' as AvailableCommandsEnum,
        LEVALASZTAS: 'LEVALASZTAS' as AvailableCommandsEnum,
        HATAROZATOK: 'HATAROZATOK' as AvailableCommandsEnum
    };
}