/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ErtekesitesRiportFilter { 
    csakFogasiNaplo?: boolean;
    csakSajatErtekesites?: boolean;
    dijkategoriaList?: Array<ErtekesitesRiportFilter.DijkategoriaListEnum>;
    ev?: number;
    fromDate?: string;
    honap?: number;
    jogosultsagList?: Array<ErtekesitesRiportFilter.JogosultsagListEnum>;
    jutalek?: number;
    korkategoriaList?: Array<ErtekesitesRiportFilter.KorkategoriaListEnum>;
    limit?: number;
    offset?: number;
    sajatErtekesitoSzervezetId?: number;
    tipusList?: Array<ErtekesitesRiportFilter.TipusListEnum>;
    toDate?: string;
    validIdoszak?: boolean;
    validIdoszakNotNull?: boolean;
}
export namespace ErtekesitesRiportFilter {
    export type DijkategoriaListEnum = 'ALAPDIJ' | 'EMELTDIJ';
    export const DijkategoriaListEnum = {
        ALAPDIJ: 'ALAPDIJ' as DijkategoriaListEnum,
        EMELTDIJ: 'EMELTDIJ' as DijkategoriaListEnum
    };
    export type JogosultsagListEnum = 'TELJESJOGU' | 'MENTESITETT' | 'NEMKOTELEZETT' | 'MENTESITETT_70EVFELETTI' | 'MENTESITETT_FOGYATEKKALELO' | 'MENTESITETT_GYERMEK' | 'NEMKOTELEZETT_KULFOLDI' | 'NEMKOTELEZETT_NEMMOHOSZTAG';
    export const JogosultsagListEnum = {
        TELJESJOGU: 'TELJESJOGU' as JogosultsagListEnum,
        MENTESITETT: 'MENTESITETT' as JogosultsagListEnum,
        NEMKOTELEZETT: 'NEMKOTELEZETT' as JogosultsagListEnum,
        MENTESITETT70EVFELETTI: 'MENTESITETT_70EVFELETTI' as JogosultsagListEnum,
        MENTESITETTFOGYATEKKALELO: 'MENTESITETT_FOGYATEKKALELO' as JogosultsagListEnum,
        MENTESITETTGYERMEK: 'MENTESITETT_GYERMEK' as JogosultsagListEnum,
        NEMKOTELEZETTKULFOLDI: 'NEMKOTELEZETT_KULFOLDI' as JogosultsagListEnum,
        NEMKOTELEZETTNEMMOHOSZTAG: 'NEMKOTELEZETT_NEMMOHOSZTAG' as JogosultsagListEnum
    };
    export type KorkategoriaListEnum = 'GYERMEK' | 'IFJUSAGI' | 'FELNOTT';
    export const KorkategoriaListEnum = {
        GYERMEK: 'GYERMEK' as KorkategoriaListEnum,
        IFJUSAGI: 'IFJUSAGI' as KorkategoriaListEnum,
        FELNOTT: 'FELNOTT' as KorkategoriaListEnum
    };
    export type TipusListEnum = 'NORMALERTEKESITETT' | 'SZTORNOZOTT' | 'RONTOTTALLAMIJEGY' | 'KESZLETROLELVESZETT';
    export const TipusListEnum = {
        NORMALERTEKESITETT: 'NORMALERTEKESITETT' as TipusListEnum,
        SZTORNOZOTT: 'SZTORNOZOTT' as TipusListEnum,
        RONTOTTALLAMIJEGY: 'RONTOTTALLAMIJEGY' as TipusListEnum,
        KESZLETROLELVESZETT: 'KESZLETROLELVESZETT' as TipusListEnum
    };
}