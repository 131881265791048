/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizteruletCsoportZarvatartasRogitesRequest { 
    kiegeszitoJegyTipusList?: Array<VizteruletCsoportZarvatartasRogitesRequest.KiegeszitoJegyTipusListEnum>;
    meddig?: string;
    mettol?: string;
    oka?: string;
    validDatumok?: boolean;
    vizteruletCsoportId?: number;
}
export namespace VizteruletCsoportZarvatartasRogitesRequest {
    export type KiegeszitoJegyTipusListEnum = 'CSONAKHASZNALATI' | 'BOJLIS' | 'BEHUZOS' | 'VERSENY_RESZVETELI' | 'EJSZAKAI_HORGASZATI' | 'HELYFOGLALASOS' | 'SPECIALIS_VIZTERULETI' | 'TOBBLET_HALELVITELI' | 'HAROM_BOTOS';
    export const KiegeszitoJegyTipusListEnum = {
        CSONAKHASZNALATI: 'CSONAKHASZNALATI' as KiegeszitoJegyTipusListEnum,
        BOJLIS: 'BOJLIS' as KiegeszitoJegyTipusListEnum,
        BEHUZOS: 'BEHUZOS' as KiegeszitoJegyTipusListEnum,
        VERSENYRESZVETELI: 'VERSENY_RESZVETELI' as KiegeszitoJegyTipusListEnum,
        EJSZAKAIHORGASZATI: 'EJSZAKAI_HORGASZATI' as KiegeszitoJegyTipusListEnum,
        HELYFOGLALASOS: 'HELYFOGLALASOS' as KiegeszitoJegyTipusListEnum,
        SPECIALISVIZTERULETI: 'SPECIALIS_VIZTERULETI' as KiegeszitoJegyTipusListEnum,
        TOBBLETHALELVITELI: 'TOBBLET_HALELVITELI' as KiegeszitoJegyTipusListEnum,
        HAROMBOTOS: 'HAROM_BOTOS' as KiegeszitoJegyTipusListEnum
    };
}