/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AllamiJegyTortenetTetel { 
    akcio?: AllamiJegyTortenetTetel.AkcioEnum;
    atadoMegbizottSzemelyId?: number;
    atadoMegbizottSzemelyNev?: string;
    atadoSzemely?: boolean;
    atadoSzereploId?: number;
    atadoSzereploNev?: string;
    atadoSzereploTipus?: AllamiJegyTortenetTetel.AtadoSzereploTipusEnum;
    atadoSzervezet?: boolean;
    atvevoSzemely?: boolean;
    atvevoSzereploId?: number;
    atvevoSzereploNev?: string;
    atvevoSzereploTipus?: AllamiJegyTortenetTetel.AtvevoSzereploTipusEnum;
    atvevoSzervezet?: boolean;
    atvevoSzovetseg?: boolean;
    datum?: Date;
}
export namespace AllamiJegyTortenetTetel {
    export type AkcioEnum = 'NYOMDAI_BEVETELEZES' | 'KESZLETATADAS' | 'HORGASZJEGY_KIADAS' | 'HORGASZJEGY_BETELT_LEADAS' | 'HORGASZJEGY_LEADAS' | 'HORGASZJEGY_UJRAFELHASZNALHATO_SZTORNO' | 'HORGASZJEGY_VEGLEGES_SZTORNO' | 'HORGASZJEGY_KESZLETROLELVESZETT_SZTORNO' | 'FOGASINAPLO_KIADAS' | 'ELVESZETT_BEJELENTES' | 'MEGSEMMISULT_BEJELENTES' | 'HORGASZJEGY_VISSZAVONAS';
    export const AkcioEnum = {
        NYOMDAIBEVETELEZES: 'NYOMDAI_BEVETELEZES' as AkcioEnum,
        KESZLETATADAS: 'KESZLETATADAS' as AkcioEnum,
        HORGASZJEGYKIADAS: 'HORGASZJEGY_KIADAS' as AkcioEnum,
        HORGASZJEGYBETELTLEADAS: 'HORGASZJEGY_BETELT_LEADAS' as AkcioEnum,
        HORGASZJEGYLEADAS: 'HORGASZJEGY_LEADAS' as AkcioEnum,
        HORGASZJEGYUJRAFELHASZNALHATOSZTORNO: 'HORGASZJEGY_UJRAFELHASZNALHATO_SZTORNO' as AkcioEnum,
        HORGASZJEGYVEGLEGESSZTORNO: 'HORGASZJEGY_VEGLEGES_SZTORNO' as AkcioEnum,
        HORGASZJEGYKESZLETROLELVESZETTSZTORNO: 'HORGASZJEGY_KESZLETROLELVESZETT_SZTORNO' as AkcioEnum,
        FOGASINAPLOKIADAS: 'FOGASINAPLO_KIADAS' as AkcioEnum,
        ELVESZETTBEJELENTES: 'ELVESZETT_BEJELENTES' as AkcioEnum,
        MEGSEMMISULTBEJELENTES: 'MEGSEMMISULT_BEJELENTES' as AkcioEnum,
        HORGASZJEGYVISSZAVONAS: 'HORGASZJEGY_VISSZAVONAS' as AkcioEnum
    };
    export type AtadoSzereploTipusEnum = 'SZEMELY' | 'MOHOSZ' | 'TAGEGYESULET' | 'TAGSZOVETSEG' | 'BOLT' | 'HALGAZDALKODO' | 'ALTALANOS';
    export const AtadoSzereploTipusEnum = {
        SZEMELY: 'SZEMELY' as AtadoSzereploTipusEnum,
        MOHOSZ: 'MOHOSZ' as AtadoSzereploTipusEnum,
        TAGEGYESULET: 'TAGEGYESULET' as AtadoSzereploTipusEnum,
        TAGSZOVETSEG: 'TAGSZOVETSEG' as AtadoSzereploTipusEnum,
        BOLT: 'BOLT' as AtadoSzereploTipusEnum,
        HALGAZDALKODO: 'HALGAZDALKODO' as AtadoSzereploTipusEnum,
        ALTALANOS: 'ALTALANOS' as AtadoSzereploTipusEnum
    };
    export type AtvevoSzereploTipusEnum = 'SZEMELY' | 'MOHOSZ' | 'TAGEGYESULET' | 'TAGSZOVETSEG' | 'BOLT' | 'HALGAZDALKODO' | 'ALTALANOS';
    export const AtvevoSzereploTipusEnum = {
        SZEMELY: 'SZEMELY' as AtvevoSzereploTipusEnum,
        MOHOSZ: 'MOHOSZ' as AtvevoSzereploTipusEnum,
        TAGEGYESULET: 'TAGEGYESULET' as AtvevoSzereploTipusEnum,
        TAGSZOVETSEG: 'TAGSZOVETSEG' as AtvevoSzereploTipusEnum,
        BOLT: 'BOLT' as AtvevoSzereploTipusEnum,
        HALGAZDALKODO: 'HALGAZDALKODO' as AtvevoSzereploTipusEnum,
        ALTALANOS: 'ALTALANOS' as AtvevoSzereploTipusEnum
    };
}