/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Vizterulet } from './vizterulet';
import { VizteruletCsoportDetails } from './vizteruletCsoportDetails';
import { VizteruletCsoportLeiras } from './vizteruletCsoportLeiras';
import { VizteruletCsoportZarvatartas } from './vizteruletCsoportZarvatartas';

export interface VizteruletCsoport { 
    aktiv?: boolean;
    availableCommands?: Array<VizteruletCsoport.AvailableCommandsEnum>;
    csoport?: boolean;
    details?: VizteruletCsoportDetails;
    dokumentumIdList?: Array<number>;
    id?: number;
    leirasList?: Array<VizteruletCsoportLeiras>;
    megjelenitesiNev?: string;
    nev?: string;
    terulet?: number;
    uniqueId?: string;
    vizteruletCsoportTipus?: VizteruletCsoport.VizteruletCsoportTipusEnum;
    vizteruletList?: Array<Vizterulet>;
    zarvatartasIdSequence?: number;
    zarvatartasList?: Array<VizteruletCsoportZarvatartas>;
}
export namespace VizteruletCsoport {
    export type AvailableCommandsEnum = 'CSOPORT_MODOSITAS' | 'EGYEB_MODOSITAS' | 'EGYEDI_VIZTERULET_NEVEK';
    export const AvailableCommandsEnum = {
        CSOPORTMODOSITAS: 'CSOPORT_MODOSITAS' as AvailableCommandsEnum,
        EGYEBMODOSITAS: 'EGYEB_MODOSITAS' as AvailableCommandsEnum,
        EGYEDIVIZTERULETNEVEK: 'EGYEDI_VIZTERULET_NEVEK' as AvailableCommandsEnum
    };
    export type VizteruletCsoportTipusEnum = 'OSSZEVONT_VIZTERULET_CSOPORT' | 'EGYESITETT_VIZTERULET_CSOPORT' | 'OSSZEVONT_ORSZAGOS_VIZTERULET_CSOPORT' | 'VIZTERULET';
    export const VizteruletCsoportTipusEnum = {
        OSSZEVONTVIZTERULETCSOPORT: 'OSSZEVONT_VIZTERULET_CSOPORT' as VizteruletCsoportTipusEnum,
        EGYESITETTVIZTERULETCSOPORT: 'EGYESITETT_VIZTERULET_CSOPORT' as VizteruletCsoportTipusEnum,
        OSSZEVONTORSZAGOSVIZTERULETCSOPORT: 'OSSZEVONT_ORSZAGOS_VIZTERULET_CSOPORT' as VizteruletCsoportTipusEnum,
        VIZTERULET: 'VIZTERULET' as VizteruletCsoportTipusEnum
    };
}