/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OfflineHozzatartozo { 
    keresztnev?: string;
    loginId?: string;
    szemelyId?: number;
    titulus?: OfflineHozzatartozo.TitulusEnum;
    vezeteknev?: string;
}
export namespace OfflineHozzatartozo {
    export type TitulusEnum = 'dr' | 'id' | 'ifj' | 'özv' | 'prof';
    export const TitulusEnum = {
        Dr: 'dr' as TitulusEnum,
        Id: 'id' as TitulusEnum,
        Ifj: 'ifj' as TitulusEnum,
        Zv: 'özv' as TitulusEnum,
        Prof: 'prof' as TitulusEnum
    };
}