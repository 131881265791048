/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface RogzithetoEgyesuletiTagsagResponse { 
    valaszthatoEgyesuletiTagsagKategoriaList?: Array<RogzithetoEgyesuletiTagsagResponse.ValaszthatoEgyesuletiTagsagKategoriaListEnum>;
    vanErvenyesAllamiJegye?: boolean;
}
export namespace RogzithetoEgyesuletiTagsagResponse {
    export type ValaszthatoEgyesuletiTagsagKategoriaListEnum = 'IFJUSAGI' | 'FELNOTT' | 'FELNOTT_KEDVEZMENYEZETT' | 'IFJUSAGI_IHJ' | 'FELNOTT_IHJ' | 'FELNOTT_JOGFENNTARTO_IHJ' | 'MOHOSZON_KIVULI';
    export const ValaszthatoEgyesuletiTagsagKategoriaListEnum = {
        IFJUSAGI: 'IFJUSAGI' as ValaszthatoEgyesuletiTagsagKategoriaListEnum,
        FELNOTT: 'FELNOTT' as ValaszthatoEgyesuletiTagsagKategoriaListEnum,
        FELNOTTKEDVEZMENYEZETT: 'FELNOTT_KEDVEZMENYEZETT' as ValaszthatoEgyesuletiTagsagKategoriaListEnum,
        IFJUSAGIIHJ: 'IFJUSAGI_IHJ' as ValaszthatoEgyesuletiTagsagKategoriaListEnum,
        FELNOTTIHJ: 'FELNOTT_IHJ' as ValaszthatoEgyesuletiTagsagKategoriaListEnum,
        FELNOTTJOGFENNTARTOIHJ: 'FELNOTT_JOGFENNTARTO_IHJ' as ValaszthatoEgyesuletiTagsagKategoriaListEnum,
        MOHOSZONKIVULI: 'MOHOSZON_KIVULI' as ValaszthatoEgyesuletiTagsagKategoriaListEnum
    };
}