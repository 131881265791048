/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AllamiJegy } from '../model/allamiJegy';
import { AllamiJegyKeszletezoInfo } from '../model/allamiJegyKeszletezoInfo';
import { AllamiJegyRequest } from '../model/allamiJegyRequest';
import { AllamiJegyVasarlasArReply } from '../model/allamiJegyVasarlasArReply';
import { AllamiJegyVasarlasEllenorzesRequest } from '../model/allamiJegyVasarlasEllenorzesRequest';
import { AllamiJegyVasarlasNyomtatasiKep } from '../model/allamiJegyVasarlasNyomtatasiKep';
import { AllamiJegyVasarlasRequest } from '../model/allamiJegyVasarlasRequest';
import { AllamiJegyekReply } from '../model/allamiJegyekReply';
import { EgyesuletiTagsagRequest } from '../model/egyesuletiTagsagRequest';
import { EgyesuletiTagsagRogzitesRequest } from '../model/egyesuletiTagsagRogzitesRequest';
import { EgyesuletiTagsagTorlesRequest } from '../model/egyesuletiTagsagTorlesRequest';
import { ErtekesithetoEgyesuletiTagsagRequest } from '../model/ertekesithetoEgyesuletiTagsagRequest';
import { ErtekesithetoEgyesuletiTagsagResponse } from '../model/ertekesithetoEgyesuletiTagsagResponse';
import { FogasiNaplo } from '../model/fogasiNaplo';
import { FogasiNaploArReply } from '../model/fogasiNaploArReply';
import { FogasiNaploLeadasRequest } from '../model/fogasiNaploLeadasRequest';
import { FogasiNaploRequest } from '../model/fogasiNaploRequest';
import { FogasiNaploRogzitesForecast } from '../model/fogasiNaploRogzitesForecast';
import { FogasiNaploRogzitesForecastRequest } from '../model/fogasiNaploRogzitesForecastRequest';
import { FogasiNaploRogzitesRequest } from '../model/fogasiNaploRogzitesRequest';
import { FogasiNaploVasarlasEllenorzesRequest } from '../model/fogasiNaploVasarlasEllenorzesRequest';
import { FogasiNaploVasarlasRequest } from '../model/fogasiNaploVasarlasRequest';
import { HianyzoFogasiNaploRequest } from '../model/hianyzoFogasiNaploRequest';
import { HorgaszBugyellaris } from '../model/horgaszBugyellaris';
import { HorgaszEgyesuletek } from '../model/horgaszEgyesuletek';
import { HorgaszvizsgaRogzitesRequest } from '../model/horgaszvizsgaRogzitesRequest';
import { IgazoltFogasiNaploRogzitesRequest } from '../model/igazoltFogasiNaploRogzitesRequest';
import { JegyekRequest } from '../model/jegyekRequest';
import { JegyekResponse } from '../model/jegyekResponse';
import { NextAllamiJegySorszam } from '../model/nextAllamiJegySorszam';
import { Nyilatkozat } from '../model/nyilatkozat';
import { OnlinePenztar } from '../model/onlinePenztar';
import { RogzithetoEgyesuletiTagsagRequest } from '../model/rogzithetoEgyesuletiTagsagRequest';
import { RogzithetoEgyesuletiTagsagResponse } from '../model/rogzithetoEgyesuletiTagsagResponse';
import { SzemelyRequest } from '../model/szemelyRequest';
import { SzervezetAdapter } from '../model/szervezetAdapter';
import { TermekMozgatasRequest } from '../model/termekMozgatasRequest';
import { TeruletiJegy } from '../model/teruletiJegy';
import { TeruletiJegyOnlineSztornoRequest } from '../model/teruletiJegyOnlineSztornoRequest';
import { TeruletiJegyRequest } from '../model/teruletiJegyRequest';
import { TeruletiJegyVasarlasRequest } from '../model/teruletiJegyVasarlasRequest';
import { TeruletiKiegeszitoJegyVasarlasRequest } from '../model/teruletiKiegeszitoJegyVasarlasRequest';
import { TuristaAllamiJegyArReply } from '../model/turistaAllamiJegyArReply';
import { TuristaAllamiJegyVasarlasRequest } from '../model/turistaAllamiJegyVasarlasRequest';
import { TuristaFogasiNaploLeadasRequest } from '../model/turistaFogasiNaploLeadasRequest';
import { VasarlasEllenorzesReply } from '../model/vasarlasEllenorzesReply';
import { VasarlasEllenorzesRequest } from '../model/vasarlasEllenorzesRequest';
import { VersenyzoiEngedely } from '../model/versenyzoiEngedely';
import { VersenyzoiEngedelyArReply } from '../model/versenyzoiEngedelyArReply';
import { VersenyzoiEngedelyRequest } from '../model/versenyzoiEngedelyRequest';
import { VersenyzoiEngedelyVasarlasRequest } from '../model/versenyzoiEngedelyVasarlasRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class HorgaszBugyellarisControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * allamiJegyErvenyessegVisszavonasRollback
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public allamiJegyErvenyessegVisszavonasRollbackUsingPOST(body: AllamiJegyRequest, observe?: 'body', reportProgress?: boolean): Observable<AllamiJegy>;
    public allamiJegyErvenyessegVisszavonasRollbackUsingPOST(body: AllamiJegyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllamiJegy>>;
    public allamiJegyErvenyessegVisszavonasRollbackUsingPOST(body: AllamiJegyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllamiJegy>>;
    public allamiJegyErvenyessegVisszavonasRollbackUsingPOST(body: AllamiJegyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling allamiJegyErvenyessegVisszavonasRollbackUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AllamiJegy>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/allamijegy-ervenyessegvisszavonas-rollback`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * allamiJegyKeszletezoInfo
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public allamiJegyKeszletezoInfoUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<AllamiJegyKeszletezoInfo>;
    public allamiJegyKeszletezoInfoUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllamiJegyKeszletezoInfo>>;
    public allamiJegyKeszletezoInfoUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllamiJegyKeszletezoInfo>>;
    public allamiJegyKeszletezoInfoUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AllamiJegyKeszletezoInfo>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/allamijegy-keszletezo-info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * allamiJegyMozgatas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public allamiJegyMozgatasUsingPOST(body: TermekMozgatasRequest, observe?: 'body', reportProgress?: boolean): Observable<AllamiJegyekReply>;
    public allamiJegyMozgatasUsingPOST(body: TermekMozgatasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllamiJegyekReply>>;
    public allamiJegyMozgatasUsingPOST(body: TermekMozgatasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllamiJegyekReply>>;
    public allamiJegyMozgatasUsingPOST(body: TermekMozgatasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling allamiJegyMozgatasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AllamiJegyekReply>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/allamijegy-mozgatas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * allamiJegyVasarlasAr
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public allamiJegyVasarlasArUsingPOST(body: AllamiJegyVasarlasRequest, observe?: 'body', reportProgress?: boolean): Observable<AllamiJegyVasarlasArReply>;
    public allamiJegyVasarlasArUsingPOST(body: AllamiJegyVasarlasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllamiJegyVasarlasArReply>>;
    public allamiJegyVasarlasArUsingPOST(body: AllamiJegyVasarlasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllamiJegyVasarlasArReply>>;
    public allamiJegyVasarlasArUsingPOST(body: AllamiJegyVasarlasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling allamiJegyVasarlasArUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AllamiJegyVasarlasArReply>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/allamijegy-vasarlas-arlekerdezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * allamiJegyVasarlasEllenorzes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public allamiJegyVasarlasEllenorzesUsingPOST(body: AllamiJegyVasarlasEllenorzesRequest, observe?: 'body', reportProgress?: boolean): Observable<VasarlasEllenorzesReply>;
    public allamiJegyVasarlasEllenorzesUsingPOST(body: AllamiJegyVasarlasEllenorzesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VasarlasEllenorzesReply>>;
    public allamiJegyVasarlasEllenorzesUsingPOST(body: AllamiJegyVasarlasEllenorzesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VasarlasEllenorzesReply>>;
    public allamiJegyVasarlasEllenorzesUsingPOST(body: AllamiJegyVasarlasEllenorzesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling allamiJegyVasarlasEllenorzesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VasarlasEllenorzesReply>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/allamijegy-vasarlas-ellenorzes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * allamiJegyVasarlas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public allamiJegyVasarlasUsingPOST(body: AllamiJegyVasarlasRequest, observe?: 'body', reportProgress?: boolean): Observable<AllamiJegyVasarlasNyomtatasiKep>;
    public allamiJegyVasarlasUsingPOST(body: AllamiJegyVasarlasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllamiJegyVasarlasNyomtatasiKep>>;
    public allamiJegyVasarlasUsingPOST(body: AllamiJegyVasarlasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllamiJegyVasarlasNyomtatasiKep>>;
    public allamiJegyVasarlasUsingPOST(body: AllamiJegyVasarlasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling allamiJegyVasarlasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AllamiJegyVasarlasNyomtatasiKep>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/allamijegy-vasarlas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * egyesuletitagsagListazas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public egyesuletitagsagListazasUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszEgyesuletek>;
    public egyesuletitagsagListazasUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszEgyesuletek>>;
    public egyesuletitagsagListazasUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszEgyesuletek>>;
    public egyesuletitagsagListazasUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling egyesuletitagsagListazasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszEgyesuletek>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/egyesuletitagsag-listazas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * egyesuletitagsagModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public egyesuletitagsagModositasUsingPOST(body: EgyesuletiTagsagRogzitesRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public egyesuletitagsagModositasUsingPOST(body: EgyesuletiTagsagRogzitesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public egyesuletitagsagModositasUsingPOST(body: EgyesuletiTagsagRogzitesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public egyesuletitagsagModositasUsingPOST(body: EgyesuletiTagsagRogzitesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling egyesuletitagsagModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/egyesuletitagsag-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * egyesuletitagsagRogzites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public egyesuletitagsagRogzitesUsingPOST(body: EgyesuletiTagsagRogzitesRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public egyesuletitagsagRogzitesUsingPOST(body: EgyesuletiTagsagRogzitesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public egyesuletitagsagRogzitesUsingPOST(body: EgyesuletiTagsagRogzitesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public egyesuletitagsagRogzitesUsingPOST(body: EgyesuletiTagsagRogzitesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling egyesuletitagsagRogzitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/egyesuletitagsag-rogzites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * egyesuletitagsagTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public egyesuletitagsagTorlesUsingPOST(body: EgyesuletiTagsagTorlesRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public egyesuletitagsagTorlesUsingPOST(body: EgyesuletiTagsagTorlesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public egyesuletitagsagTorlesUsingPOST(body: EgyesuletiTagsagTorlesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public egyesuletitagsagTorlesUsingPOST(body: EgyesuletiTagsagTorlesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling egyesuletitagsagTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/egyesuletitagsag-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ertekesithetoEgyesuletiTagsag
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ertekesithetoEgyesuletiTagsagUsingPOST(body: ErtekesithetoEgyesuletiTagsagRequest, observe?: 'body', reportProgress?: boolean): Observable<ErtekesithetoEgyesuletiTagsagResponse>;
    public ertekesithetoEgyesuletiTagsagUsingPOST(body: ErtekesithetoEgyesuletiTagsagRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ErtekesithetoEgyesuletiTagsagResponse>>;
    public ertekesithetoEgyesuletiTagsagUsingPOST(body: ErtekesithetoEgyesuletiTagsagRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ErtekesithetoEgyesuletiTagsagResponse>>;
    public ertekesithetoEgyesuletiTagsagUsingPOST(body: ErtekesithetoEgyesuletiTagsagRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ertekesithetoEgyesuletiTagsagUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ErtekesithetoEgyesuletiTagsagResponse>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/ertekesitheto-egyesuletitagsag`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetch
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public fetchUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public fetchUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public fetchUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fetchUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/fetch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findAllamiJegyek
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAllamiJegyekUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<AllamiJegyekReply>;
    public findAllamiJegyekUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllamiJegyekReply>>;
    public findAllamiJegyekUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllamiJegyekReply>>;
    public findAllamiJegyekUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findAllamiJegyekUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AllamiJegyekReply>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/find-allamijegyek`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findFogasiNaplok
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findFogasiNaplokUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<FogasiNaplo>>;
    public findFogasiNaplokUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FogasiNaplo>>>;
    public findFogasiNaplokUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FogasiNaplo>>>;
    public findFogasiNaplokUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findFogasiNaplokUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<FogasiNaplo>>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/find-fogasinaplok`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findJegyek
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findJegyekUsingPOST(body: JegyekRequest, observe?: 'body', reportProgress?: boolean): Observable<JegyekResponse>;
    public findJegyekUsingPOST(body: JegyekRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<JegyekResponse>>;
    public findJegyekUsingPOST(body: JegyekRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<JegyekResponse>>;
    public findJegyekUsingPOST(body: JegyekRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findJegyekUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<JegyekResponse>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/find-jegyek`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findTeruletiJegyek
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findTeruletiJegyekUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<TeruletiJegy>>;
    public findTeruletiJegyekUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TeruletiJegy>>>;
    public findTeruletiJegyekUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TeruletiJegy>>>;
    public findTeruletiJegyekUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findTeruletiJegyekUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<TeruletiJegy>>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/find-teruletijegyek`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findVersenyzoiEngedelyek
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findVersenyzoiEngedelyekUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<VersenyzoiEngedely>>;
    public findVersenyzoiEngedelyekUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersenyzoiEngedely>>>;
    public findVersenyzoiEngedelyekUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersenyzoiEngedely>>>;
    public findVersenyzoiEngedelyekUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findVersenyzoiEngedelyekUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<VersenyzoiEngedely>>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/find-versenyzoiengedelyek`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fogasiNaploIgazolasUpload
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fogasiNaploIgazolasUploadUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public fogasiNaploIgazolasUploadUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public fogasiNaploIgazolasUploadUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public fogasiNaploIgazolasUploadUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling fogasiNaploIgazolasUploadUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/fogasinaplo-igazolas-upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fogasiNaploLeadasRollback
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fogasiNaploLeadasRollbackUsingPOST(body: FogasiNaploRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public fogasiNaploLeadasRollbackUsingPOST(body: FogasiNaploRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public fogasiNaploLeadasRollbackUsingPOST(body: FogasiNaploRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public fogasiNaploLeadasRollbackUsingPOST(body: FogasiNaploRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fogasiNaploLeadasRollbackUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/fogasinaplo-leadas-rollback`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fogasiNaploLeadas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fogasiNaploLeadasUsingPOST(body: FogasiNaploLeadasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public fogasiNaploLeadasUsingPOST(body: FogasiNaploLeadasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public fogasiNaploLeadasUsingPOST(body: FogasiNaploLeadasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public fogasiNaploLeadasUsingPOST(body: FogasiNaploLeadasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fogasiNaploLeadasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/fogasinaplo-leadas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fogasiNaploRogzitesForecast
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fogasiNaploRogzitesForecastUsingPOST(body: FogasiNaploRogzitesForecastRequest, observe?: 'body', reportProgress?: boolean): Observable<FogasiNaploRogzitesForecast>;
    public fogasiNaploRogzitesForecastUsingPOST(body: FogasiNaploRogzitesForecastRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FogasiNaploRogzitesForecast>>;
    public fogasiNaploRogzitesForecastUsingPOST(body: FogasiNaploRogzitesForecastRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FogasiNaploRogzitesForecast>>;
    public fogasiNaploRogzitesForecastUsingPOST(body: FogasiNaploRogzitesForecastRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fogasiNaploRogzitesForecastUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<FogasiNaploRogzitesForecast>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/fogasinaplo-rogzites-forecast`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fogasiNaploRogzites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fogasiNaploRogzitesUsingPOST(body: FogasiNaploRogzitesRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public fogasiNaploRogzitesUsingPOST(body: FogasiNaploRogzitesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public fogasiNaploRogzitesUsingPOST(body: FogasiNaploRogzitesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public fogasiNaploRogzitesUsingPOST(body: FogasiNaploRogzitesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fogasiNaploRogzitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/fogasinaplo-rogzites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fogasiNaploTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fogasiNaploTorlesUsingPOST(body: FogasiNaploRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public fogasiNaploTorlesUsingPOST(body: FogasiNaploRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public fogasiNaploTorlesUsingPOST(body: FogasiNaploRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public fogasiNaploTorlesUsingPOST(body: FogasiNaploRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fogasiNaploTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/fogasinaplo-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fogasiNaploVasarlasAr
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fogasiNaploVasarlasArUsingPOST(body: FogasiNaploVasarlasRequest, observe?: 'body', reportProgress?: boolean): Observable<FogasiNaploArReply>;
    public fogasiNaploVasarlasArUsingPOST(body: FogasiNaploVasarlasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FogasiNaploArReply>>;
    public fogasiNaploVasarlasArUsingPOST(body: FogasiNaploVasarlasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FogasiNaploArReply>>;
    public fogasiNaploVasarlasArUsingPOST(body: FogasiNaploVasarlasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fogasiNaploVasarlasArUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<FogasiNaploArReply>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/fogasinaplo-vasarlas-arlekerdezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fogasiNaploVasarlasEllenorzes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fogasiNaploVasarlasEllenorzesUsingPOST(body: FogasiNaploVasarlasEllenorzesRequest, observe?: 'body', reportProgress?: boolean): Observable<VasarlasEllenorzesReply>;
    public fogasiNaploVasarlasEllenorzesUsingPOST(body: FogasiNaploVasarlasEllenorzesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VasarlasEllenorzesReply>>;
    public fogasiNaploVasarlasEllenorzesUsingPOST(body: FogasiNaploVasarlasEllenorzesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VasarlasEllenorzesReply>>;
    public fogasiNaploVasarlasEllenorzesUsingPOST(body: FogasiNaploVasarlasEllenorzesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fogasiNaploVasarlasEllenorzesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VasarlasEllenorzesReply>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/fogasinaplo-vasarlas-ellenorzes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fogasiNaploVasarlas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fogasiNaploVasarlasUsingPOST(body: FogasiNaploVasarlasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public fogasiNaploVasarlasUsingPOST(body: FogasiNaploVasarlasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public fogasiNaploVasarlasUsingPOST(body: FogasiNaploVasarlasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public fogasiNaploVasarlasUsingPOST(body: FogasiNaploVasarlasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fogasiNaploVasarlasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/fogasinaplo-vasarlas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * hianyzoFogasiNaploRogzites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hianyzoFogasiNaploRogzitesUsingPOST(body: HianyzoFogasiNaploRequest, observe?: 'body', reportProgress?: boolean): Observable<Nyilatkozat>;
    public hianyzoFogasiNaploRogzitesUsingPOST(body: HianyzoFogasiNaploRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Nyilatkozat>>;
    public hianyzoFogasiNaploRogzitesUsingPOST(body: HianyzoFogasiNaploRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Nyilatkozat>>;
    public hianyzoFogasiNaploRogzitesUsingPOST(body: HianyzoFogasiNaploRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling hianyzoFogasiNaploRogzitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Nyilatkozat>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/hianyzo-fogasinaplo-rogzites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * horgaszManualisEltiltas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszManualisEltiltasUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public horgaszManualisEltiltasUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public horgaszManualisEltiltasUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public horgaszManualisEltiltasUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgaszManualisEltiltasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/horgasz-manualis-eltiltas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * horgaszvizsgaRogzites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszvizsgaRogzitesUsingPOST(body: HorgaszvizsgaRogzitesRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public horgaszvizsgaRogzitesUsingPOST(body: HorgaszvizsgaRogzitesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public horgaszvizsgaRogzitesUsingPOST(body: HorgaszvizsgaRogzitesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public horgaszvizsgaRogzitesUsingPOST(body: HorgaszvizsgaRogzitesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgaszvizsgaRogzitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/horgaszvizsga-rogzites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * horgaszvizsgaTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszvizsgaTorlesUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public horgaszvizsgaTorlesUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public horgaszvizsgaTorlesUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public horgaszvizsgaTorlesUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgaszvizsgaTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/horgaszvizsga-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * horgaszvizsgaUpload
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszvizsgaUploadUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public horgaszvizsgaUploadUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public horgaszvizsgaUploadUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public horgaszvizsgaUploadUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling horgaszvizsgaUploadUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/horgaszvizsga-upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * igazoltFogasiNaploRogzites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public igazoltFogasiNaploRogzitesUsingPOST(body: IgazoltFogasiNaploRogzitesRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public igazoltFogasiNaploRogzitesUsingPOST(body: IgazoltFogasiNaploRogzitesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public igazoltFogasiNaploRogzitesUsingPOST(body: IgazoltFogasiNaploRogzitesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public igazoltFogasiNaploRogzitesUsingPOST(body: IgazoltFogasiNaploRogzitesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling igazoltFogasiNaploRogzitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/igazolt-fogasinaplo-rogzites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * masEgyesuletiTagsagRogzites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masEgyesuletiTagsagRogzitesUsingPOST(body: EgyesuletiTagsagRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public masEgyesuletiTagsagRogzitesUsingPOST(body: EgyesuletiTagsagRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public masEgyesuletiTagsagRogzitesUsingPOST(body: EgyesuletiTagsagRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public masEgyesuletiTagsagRogzitesUsingPOST(body: EgyesuletiTagsagRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling masEgyesuletiTagsagRogzitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/masegyesuletitagsag-rogzites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * mindenEgyesuletitagsagTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mindenEgyesuletitagsagTorlesUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public mindenEgyesuletitagsagTorlesUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public mindenEgyesuletitagsagTorlesUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public mindenEgyesuletitagsagTorlesUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling mindenEgyesuletitagsagTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/minden-egyesuletitagsag-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * nextAllamiJegySorszam
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public nextAllamiJegySorszamUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<NextAllamiJegySorszam>;
    public nextAllamiJegySorszamUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NextAllamiJegySorszam>>;
    public nextAllamiJegySorszamUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NextAllamiJegySorszam>>;
    public nextAllamiJegySorszamUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<NextAllamiJegySorszam>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/next-allamijegy-sorszam`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * rogzithetoEgyesuletek
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rogzithetoEgyesuletekUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<SzervezetAdapter>>;
    public rogzithetoEgyesuletekUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SzervezetAdapter>>>;
    public rogzithetoEgyesuletekUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SzervezetAdapter>>>;
    public rogzithetoEgyesuletekUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SzervezetAdapter>>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/rogzitheto-egyesuletek`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * rogzithetoEgyesuletiTagsag
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rogzithetoEgyesuletiTagsagUsingPOST(body: RogzithetoEgyesuletiTagsagRequest, observe?: 'body', reportProgress?: boolean): Observable<RogzithetoEgyesuletiTagsagResponse>;
    public rogzithetoEgyesuletiTagsagUsingPOST(body: RogzithetoEgyesuletiTagsagRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RogzithetoEgyesuletiTagsagResponse>>;
    public rogzithetoEgyesuletiTagsagUsingPOST(body: RogzithetoEgyesuletiTagsagRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RogzithetoEgyesuletiTagsagResponse>>;
    public rogzithetoEgyesuletiTagsagUsingPOST(body: RogzithetoEgyesuletiTagsagRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling rogzithetoEgyesuletiTagsagUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<RogzithetoEgyesuletiTagsagResponse>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/rogzitheto-egyesuletitagsag`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiJegyErvenyessegVisszavonasRollback
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiJegyErvenyessegVisszavonasRollbackUsingPOST(body: TeruletiJegyRequest, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegy>;
    public teruletiJegyErvenyessegVisszavonasRollbackUsingPOST(body: TeruletiJegyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegy>>;
    public teruletiJegyErvenyessegVisszavonasRollbackUsingPOST(body: TeruletiJegyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegy>>;
    public teruletiJegyErvenyessegVisszavonasRollbackUsingPOST(body: TeruletiJegyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teruletiJegyErvenyessegVisszavonasRollbackUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegy>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/teruletijegy-ervenyessegvisszavonas-rollback`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiJegyMozgatas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiJegyMozgatasUsingPOST(body: TermekMozgatasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public teruletiJegyMozgatasUsingPOST(body: TermekMozgatasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public teruletiJegyMozgatasUsingPOST(body: TermekMozgatasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public teruletiJegyMozgatasUsingPOST(body: TermekMozgatasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teruletiJegyMozgatasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/teruletijegy-mozgatas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiJegyNyomtatas
     * 
     * @param szemelyId szemelyId
     * @param sorszam sorszam
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiJegyNyomtatasUsingGET(szemelyId: number, sorszam: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public teruletiJegyNyomtatasUsingGET(szemelyId: number, sorszam: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public teruletiJegyNyomtatasUsingGET(szemelyId: number, sorszam: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public teruletiJegyNyomtatasUsingGET(szemelyId: number, sorszam: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (szemelyId === null || szemelyId === undefined) {
            throw new Error('Required parameter szemelyId was null or undefined when calling teruletiJegyNyomtatasUsingGET.');
        }

        if (sorszam === null || sorszam === undefined) {
            throw new Error('Required parameter sorszam was null or undefined when calling teruletiJegyNyomtatasUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (szemelyId !== undefined && szemelyId !== null) {
            queryParameters = queryParameters.set('szemelyId', <any>szemelyId);
        }
        if (sorszam !== undefined && sorszam !== null) {
            queryParameters = queryParameters.set('sorszam', <any>sorszam);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/horgaszbugyellaris/teruletijegy-nyomtatas`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiJegyOnlinePenztarVasarlasInditas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiJegyOnlinePenztarVasarlasInditasUsingPOST(body: TeruletiJegyVasarlasRequest, observe?: 'body', reportProgress?: boolean): Observable<OnlinePenztar>;
    public teruletiJegyOnlinePenztarVasarlasInditasUsingPOST(body: TeruletiJegyVasarlasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OnlinePenztar>>;
    public teruletiJegyOnlinePenztarVasarlasInditasUsingPOST(body: TeruletiJegyVasarlasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OnlinePenztar>>;
    public teruletiJegyOnlinePenztarVasarlasInditasUsingPOST(body: TeruletiJegyVasarlasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teruletiJegyOnlinePenztarVasarlasInditasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OnlinePenztar>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/teruletijegy-onlinepenztar-vasarlas-inditas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiJegyOnlineSztornozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiJegyOnlineSztornozasUsingPOST(body: TeruletiJegyOnlineSztornoRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public teruletiJegyOnlineSztornozasUsingPOST(body: TeruletiJegyOnlineSztornoRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public teruletiJegyOnlineSztornozasUsingPOST(body: TeruletiJegyOnlineSztornoRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public teruletiJegyOnlineSztornozasUsingPOST(body: TeruletiJegyOnlineSztornoRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teruletiJegyOnlineSztornozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/teruletijegy-online-sztornozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiJegyRkSztornozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiJegyRkSztornozasUsingPOST(body: TeruletiJegyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public teruletiJegyRkSztornozasUsingPOST(body: TeruletiJegyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public teruletiJegyRkSztornozasUsingPOST(body: TeruletiJegyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public teruletiJegyRkSztornozasUsingPOST(body: TeruletiJegyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teruletiJegyRkSztornozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/teruletijegy-rksztornozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiJegySztornozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiJegySztornozasUsingPOST(body: TeruletiJegyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public teruletiJegySztornozasUsingPOST(body: TeruletiJegyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public teruletiJegySztornozasUsingPOST(body: TeruletiJegyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public teruletiJegySztornozasUsingPOST(body: TeruletiJegyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teruletiJegySztornozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/teruletijegy-sztornozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiJegyVasarlasEllenorzes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiJegyVasarlasEllenorzesUsingPOST(body: VasarlasEllenorzesRequest, observe?: 'body', reportProgress?: boolean): Observable<VasarlasEllenorzesReply>;
    public teruletiJegyVasarlasEllenorzesUsingPOST(body: VasarlasEllenorzesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VasarlasEllenorzesReply>>;
    public teruletiJegyVasarlasEllenorzesUsingPOST(body: VasarlasEllenorzesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VasarlasEllenorzesReply>>;
    public teruletiJegyVasarlasEllenorzesUsingPOST(body: VasarlasEllenorzesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teruletiJegyVasarlasEllenorzesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VasarlasEllenorzesReply>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/teruletijegy-vasarlas-ellenorzes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiJegyVasarlas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiJegyVasarlasUsingPOST(body: TeruletiJegyVasarlasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public teruletiJegyVasarlasUsingPOST(body: TeruletiJegyVasarlasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public teruletiJegyVasarlasUsingPOST(body: TeruletiJegyVasarlasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public teruletiJegyVasarlasUsingPOST(body: TeruletiJegyVasarlasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teruletiJegyVasarlasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/teruletijegy-vasarlas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiKiegeszitoJegyOnlinePenztarVasarlasInditas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiKiegeszitoJegyOnlinePenztarVasarlasInditasUsingPOST(body: TeruletiKiegeszitoJegyVasarlasRequest, observe?: 'body', reportProgress?: boolean): Observable<OnlinePenztar>;
    public teruletiKiegeszitoJegyOnlinePenztarVasarlasInditasUsingPOST(body: TeruletiKiegeszitoJegyVasarlasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OnlinePenztar>>;
    public teruletiKiegeszitoJegyOnlinePenztarVasarlasInditasUsingPOST(body: TeruletiKiegeszitoJegyVasarlasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OnlinePenztar>>;
    public teruletiKiegeszitoJegyOnlinePenztarVasarlasInditasUsingPOST(body: TeruletiKiegeszitoJegyVasarlasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teruletiKiegeszitoJegyOnlinePenztarVasarlasInditasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OnlinePenztar>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/teruletikiegeszitojegy-onlinepenztar-vasarlas-inditas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiKiegeszitoJegyRkSztornozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiKiegeszitoJegyRkSztornozasUsingPOST(body: TeruletiJegyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public teruletiKiegeszitoJegyRkSztornozasUsingPOST(body: TeruletiJegyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public teruletiKiegeszitoJegyRkSztornozasUsingPOST(body: TeruletiJegyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public teruletiKiegeszitoJegyRkSztornozasUsingPOST(body: TeruletiJegyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teruletiKiegeszitoJegyRkSztornozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/teruletikiegeszitojegy-rksztornozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiKiegeszitoJegySztornozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiKiegeszitoJegySztornozasUsingPOST(body: TeruletiJegyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public teruletiKiegeszitoJegySztornozasUsingPOST(body: TeruletiJegyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public teruletiKiegeszitoJegySztornozasUsingPOST(body: TeruletiJegyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public teruletiKiegeszitoJegySztornozasUsingPOST(body: TeruletiJegyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teruletiKiegeszitoJegySztornozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/teruletikiegeszitojegy-sztornozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiKiegeszitoJegyVasarlas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiKiegeszitoJegyVasarlasUsingPOST(body: TeruletiKiegeszitoJegyVasarlasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public teruletiKiegeszitoJegyVasarlasUsingPOST(body: TeruletiKiegeszitoJegyVasarlasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public teruletiKiegeszitoJegyVasarlasUsingPOST(body: TeruletiKiegeszitoJegyVasarlasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public teruletiKiegeszitoJegyVasarlasUsingPOST(body: TeruletiKiegeszitoJegyVasarlasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teruletiKiegeszitoJegyVasarlasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/teruletikiegeszitojegy-vasarlas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * turistaAllamiJegyNyomtatas
     * 
     * @param szemelyId szemelyId
     * @param sorszam sorszam
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public turistaAllamiJegyNyomtatasUsingGET(szemelyId: number, sorszam: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public turistaAllamiJegyNyomtatasUsingGET(szemelyId: number, sorszam: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public turistaAllamiJegyNyomtatasUsingGET(szemelyId: number, sorszam: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public turistaAllamiJegyNyomtatasUsingGET(szemelyId: number, sorszam: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (szemelyId === null || szemelyId === undefined) {
            throw new Error('Required parameter szemelyId was null or undefined when calling turistaAllamiJegyNyomtatasUsingGET.');
        }

        if (sorszam === null || sorszam === undefined) {
            throw new Error('Required parameter sorszam was null or undefined when calling turistaAllamiJegyNyomtatasUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (szemelyId !== undefined && szemelyId !== null) {
            queryParameters = queryParameters.set('szemelyId', <any>szemelyId);
        }
        if (sorszam !== undefined && sorszam !== null) {
            queryParameters = queryParameters.set('sorszam', <any>sorszam);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/horgaszbugyellaris/turistaallamijegy-nyomtatas`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * turistaAllamiJegyOnlinePenztarVasarlasInditas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public turistaAllamiJegyOnlinePenztarVasarlasInditasUsingPOST(body: TuristaAllamiJegyVasarlasRequest, observe?: 'body', reportProgress?: boolean): Observable<OnlinePenztar>;
    public turistaAllamiJegyOnlinePenztarVasarlasInditasUsingPOST(body: TuristaAllamiJegyVasarlasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OnlinePenztar>>;
    public turistaAllamiJegyOnlinePenztarVasarlasInditasUsingPOST(body: TuristaAllamiJegyVasarlasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OnlinePenztar>>;
    public turistaAllamiJegyOnlinePenztarVasarlasInditasUsingPOST(body: TuristaAllamiJegyVasarlasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling turistaAllamiJegyOnlinePenztarVasarlasInditasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OnlinePenztar>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/turistaallamijegy-onlinepenztar-vasarlas-inditas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * turistaAllamiJegyVasarlasAr
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public turistaAllamiJegyVasarlasArUsingPOST(body: TuristaAllamiJegyVasarlasRequest, observe?: 'body', reportProgress?: boolean): Observable<TuristaAllamiJegyArReply>;
    public turistaAllamiJegyVasarlasArUsingPOST(body: TuristaAllamiJegyVasarlasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TuristaAllamiJegyArReply>>;
    public turistaAllamiJegyVasarlasArUsingPOST(body: TuristaAllamiJegyVasarlasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TuristaAllamiJegyArReply>>;
    public turistaAllamiJegyVasarlasArUsingPOST(body: TuristaAllamiJegyVasarlasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling turistaAllamiJegyVasarlasArUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TuristaAllamiJegyArReply>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/turistaallamijegy-vasarlas-arlekerdezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * turistaAllamiJegyVasarlasEllenorzes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public turistaAllamiJegyVasarlasEllenorzesUsingPOST(body: VasarlasEllenorzesRequest, observe?: 'body', reportProgress?: boolean): Observable<VasarlasEllenorzesReply>;
    public turistaAllamiJegyVasarlasEllenorzesUsingPOST(body: VasarlasEllenorzesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VasarlasEllenorzesReply>>;
    public turistaAllamiJegyVasarlasEllenorzesUsingPOST(body: VasarlasEllenorzesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VasarlasEllenorzesReply>>;
    public turistaAllamiJegyVasarlasEllenorzesUsingPOST(body: VasarlasEllenorzesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling turistaAllamiJegyVasarlasEllenorzesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VasarlasEllenorzesReply>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/turistaallamijegy-vasarlas-ellenorzes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * turistaFogasiNaploLeadas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public turistaFogasiNaploLeadasUsingPOST(body: TuristaFogasiNaploLeadasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public turistaFogasiNaploLeadasUsingPOST(body: TuristaFogasiNaploLeadasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public turistaFogasiNaploLeadasUsingPOST(body: TuristaFogasiNaploLeadasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public turistaFogasiNaploLeadasUsingPOST(body: TuristaFogasiNaploLeadasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling turistaFogasiNaploLeadasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/turistafogasinaplo-leadas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * turistaFogasiNaploNyomtatas
     * 
     * @param szemelyId szemelyId
     * @param sorszam sorszam
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public turistaFogasiNaploNyomtatasUsingGET(szemelyId: number, sorszam: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public turistaFogasiNaploNyomtatasUsingGET(szemelyId: number, sorszam: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public turistaFogasiNaploNyomtatasUsingGET(szemelyId: number, sorszam: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public turistaFogasiNaploNyomtatasUsingGET(szemelyId: number, sorszam: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (szemelyId === null || szemelyId === undefined) {
            throw new Error('Required parameter szemelyId was null or undefined when calling turistaFogasiNaploNyomtatasUsingGET.');
        }

        if (sorszam === null || sorszam === undefined) {
            throw new Error('Required parameter sorszam was null or undefined when calling turistaFogasiNaploNyomtatasUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (szemelyId !== undefined && szemelyId !== null) {
            queryParameters = queryParameters.set('szemelyId', <any>szemelyId);
        }
        if (sorszam !== undefined && sorszam !== null) {
            queryParameters = queryParameters.set('sorszam', <any>sorszam);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/horgaszbugyellaris/turistafogasinaplo-nyomtatas`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * turistaFogasiNaploUpload
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public turistaFogasiNaploUploadUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public turistaFogasiNaploUploadUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public turistaFogasiNaploUploadUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public turistaFogasiNaploUploadUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling turistaFogasiNaploUploadUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/turistafogasinaplo-upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * versenyzoiEngedelyAr
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public versenyzoiEngedelyArUsingPOST(body: VersenyzoiEngedelyVasarlasRequest, observe?: 'body', reportProgress?: boolean): Observable<VersenyzoiEngedelyArReply>;
    public versenyzoiEngedelyArUsingPOST(body: VersenyzoiEngedelyVasarlasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersenyzoiEngedelyArReply>>;
    public versenyzoiEngedelyArUsingPOST(body: VersenyzoiEngedelyVasarlasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersenyzoiEngedelyArReply>>;
    public versenyzoiEngedelyArUsingPOST(body: VersenyzoiEngedelyVasarlasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling versenyzoiEngedelyArUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersenyzoiEngedelyArReply>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/versenyzoi-engedely-arlekerdezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * versenyzoiEngedelyIgazolasNyomtatas
     * 
     * @param szemelyId szemelyId
     * @param ev ev
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public versenyzoiEngedelyIgazolasNyomtatasUsingGET(szemelyId: number, ev: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public versenyzoiEngedelyIgazolasNyomtatasUsingGET(szemelyId: number, ev: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public versenyzoiEngedelyIgazolasNyomtatasUsingGET(szemelyId: number, ev: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public versenyzoiEngedelyIgazolasNyomtatasUsingGET(szemelyId: number, ev: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (szemelyId === null || szemelyId === undefined) {
            throw new Error('Required parameter szemelyId was null or undefined when calling versenyzoiEngedelyIgazolasNyomtatasUsingGET.');
        }

        if (ev === null || ev === undefined) {
            throw new Error('Required parameter ev was null or undefined when calling versenyzoiEngedelyIgazolasNyomtatasUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (szemelyId !== undefined && szemelyId !== null) {
            queryParameters = queryParameters.set('szemelyId', <any>szemelyId);
        }
        if (ev !== undefined && ev !== null) {
            queryParameters = queryParameters.set('ev', <any>ev);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/horgaszbugyellaris/versenyzoiengedely-igazolas-nyomtatas`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * versenyzoiEngedelyOnlinePenztarVasarlasInditas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public versenyzoiEngedelyOnlinePenztarVasarlasInditasUsingPOST(body: VersenyzoiEngedelyVasarlasRequest, observe?: 'body', reportProgress?: boolean): Observable<OnlinePenztar>;
    public versenyzoiEngedelyOnlinePenztarVasarlasInditasUsingPOST(body: VersenyzoiEngedelyVasarlasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OnlinePenztar>>;
    public versenyzoiEngedelyOnlinePenztarVasarlasInditasUsingPOST(body: VersenyzoiEngedelyVasarlasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OnlinePenztar>>;
    public versenyzoiEngedelyOnlinePenztarVasarlasInditasUsingPOST(body: VersenyzoiEngedelyVasarlasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling versenyzoiEngedelyOnlinePenztarVasarlasInditasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OnlinePenztar>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/versenyzoi-engedely-onlinepenztar-vasarlas-inditas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * versenyzoiEngedelySztornozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public versenyzoiEngedelySztornozasUsingPOST(body: VersenyzoiEngedelyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public versenyzoiEngedelySztornozasUsingPOST(body: VersenyzoiEngedelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public versenyzoiEngedelySztornozasUsingPOST(body: VersenyzoiEngedelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public versenyzoiEngedelySztornozasUsingPOST(body: VersenyzoiEngedelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling versenyzoiEngedelySztornozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/versenyzoi-engedely-sztornozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * versenyzoiEngedelyVasarlasEllenorzes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public versenyzoiEngedelyVasarlasEllenorzesUsingPOST(body: VasarlasEllenorzesRequest, observe?: 'body', reportProgress?: boolean): Observable<VasarlasEllenorzesReply>;
    public versenyzoiEngedelyVasarlasEllenorzesUsingPOST(body: VasarlasEllenorzesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VasarlasEllenorzesReply>>;
    public versenyzoiEngedelyVasarlasEllenorzesUsingPOST(body: VasarlasEllenorzesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VasarlasEllenorzesReply>>;
    public versenyzoiEngedelyVasarlasEllenorzesUsingPOST(body: VasarlasEllenorzesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling versenyzoiEngedelyVasarlasEllenorzesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VasarlasEllenorzesReply>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/versenyzoi-engedely-vasarlas-ellenorzes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * versenyzoiEngedelyVasarlas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public versenyzoiEngedelyVasarlasUsingPOST(body: VersenyzoiEngedelyVasarlasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszBugyellaris>;
    public versenyzoiEngedelyVasarlasUsingPOST(body: VersenyzoiEngedelyVasarlasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszBugyellaris>>;
    public versenyzoiEngedelyVasarlasUsingPOST(body: VersenyzoiEngedelyVasarlasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszBugyellaris>>;
    public versenyzoiEngedelyVasarlasUsingPOST(body: VersenyzoiEngedelyVasarlasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling versenyzoiEngedelyVasarlasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszBugyellaris>('post',`${this.basePath}/api/mohosz/horgaszbugyellaris/versenyzoi-engedely-vasarlas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
