/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Cim { 
    ajto?: string;
    alternativCimzett?: string;
    emelet?: string;
    hazszam?: string;
    iranyitoszam?: string;
    kozteruletJelleg?: string;
    kozteruletNev?: string;
    megjegyzes?: string;
    orszagKod?: string;
    telepules?: string;
    validKozteruletNev?: boolean;
}