/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { KotegFeltoltes } from './kotegFeltoltes';

export interface NebihEltiltas { 
    allamiHorgaszjegySzama?: string;
    allapot?: NebihEltiltas.AllapotEnum;
    befizetesDatuma?: string;
    eltiltasKezdete?: string;
    eltiltasVege?: string;
    felulbiraltHatarozat?: boolean;
    hatarozatszam?: string;
    kotegFeltoltesDatuma?: Date;
    kotegFeltoltesList?: Array<KotegFeltoltes>;
    kotegId?: number;
    manualisanFelulbiraltHatarozat?: boolean;
    nebihEltiltasId?: string;
    rogzitesDatuma?: string;
    statusz?: NebihEltiltas.StatuszEnum;
}
export namespace NebihEltiltas {
    export type AllapotEnum = 'AKTIV' | 'BEFIZETETT' | 'BEFIZETETT_AKTIV' | 'FELOLDOTT' | 'LEJART_NEM_BEFIZETETT' | 'VISSZAVONT' | 'TOROLT';
    export const AllapotEnum = {
        AKTIV: 'AKTIV' as AllapotEnum,
        BEFIZETETT: 'BEFIZETETT' as AllapotEnum,
        BEFIZETETTAKTIV: 'BEFIZETETT_AKTIV' as AllapotEnum,
        FELOLDOTT: 'FELOLDOTT' as AllapotEnum,
        LEJARTNEMBEFIZETETT: 'LEJART_NEM_BEFIZETETT' as AllapotEnum,
        VISSZAVONT: 'VISSZAVONT' as AllapotEnum,
        TOROLT: 'TOROLT' as AllapotEnum
    };
    export type StatuszEnum = 'ELO' | 'NEM_ELO' | 'FELULBIRALT';
    export const StatuszEnum = {
        ELO: 'ELO' as StatuszEnum,
        NEMELO: 'NEM_ELO' as StatuszEnum,
        FELULBIRALT: 'FELULBIRALT' as StatuszEnum
    };
}