/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ElszamolasRiportItem } from './elszamolasRiportItem';

export interface ElszamolasRiport { 
    csakSajatErtekesites?: boolean;
    eahAr?: number;
    eahJutalekAr?: number;
    elszamoloFelhasznaloNev?: string;
    elszamoloSzervezetNev?: string;
    eszhAr?: number;
    eszhTagszovetsegiDijhanyadAr?: number;
    fromDate?: string;
    items?: Array<ElszamolasRiportItem>;
    jutalek?: number;
    nemTovabbUtalandoAr?: number;
    sumDarabszam?: number;
    sumErtekesitettDarabszam?: number;
    sumSztornozottDarabszam?: number;
    szerzodesSzam?: string;
    toDate?: string;
    utalandoEahAr?: number;
    utalandoEszhAr?: number;
    utalandoKeszletrolElveszettAr?: number;
}