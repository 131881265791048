/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SzemelySearchRequest { 
    egyesuletiTagsagIdList?: Array<number>;
    kepesitesTipusList?: Array<SzemelySearchRequest.KepesitesTipusListEnum>;
    limit?: number;
    offset?: number;
    queryString?: string;
    searchType?: SzemelySearchRequest.SearchTypeEnum;
}
export namespace SzemelySearchRequest {
    export type KepesitesTipusListEnum = 'HALGAZDALKODASIFELSOFOKU' | 'HALORIVIZSGA';
    export const KepesitesTipusListEnum = {
        HALGAZDALKODASIFELSOFOKU: 'HALGAZDALKODASIFELSOFOKU' as KepesitesTipusListEnum,
        HALORIVIZSGA: 'HALORIVIZSGA' as KepesitesTipusListEnum
    };
    export type SearchTypeEnum = 'MINDEN' | 'FELNOTT' | 'FELHASZNALO' | 'HORGASZ' | 'EMAIL' | 'ELLENOR';
    export const SearchTypeEnum = {
        MINDEN: 'MINDEN' as SearchTypeEnum,
        FELNOTT: 'FELNOTT' as SearchTypeEnum,
        FELHASZNALO: 'FELHASZNALO' as SearchTypeEnum,
        HORGASZ: 'HORGASZ' as SearchTypeEnum,
        EMAIL: 'EMAIL' as SearchTypeEnum,
        ELLENOR: 'ELLENOR' as SearchTypeEnum
    };
}