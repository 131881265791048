/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MahorElofizetesErtekesites { 
    ar?: number;
    befizetesDatum?: Date;
    elofizetesCim?: string;
    elofizetesNev?: string;
    ertekesitoSzervezetId?: number;
    ertekesitoSzervezetNev?: string;
    fizetesTipus?: MahorElofizetesErtekesites.FizetesTipusEnum;
    id?: string;
    kod?: string;
    mahorElofizetesId?: number;
}
export namespace MahorElofizetesErtekesites {
    export type FizetesTipusEnum = 'KH' | 'ERTEKESITESIPONT' | 'RK_FIZETES';
    export const FizetesTipusEnum = {
        KH: 'KH' as FizetesTipusEnum,
        ERTEKESITESIPONT: 'ERTEKESITESIPONT' as FizetesTipusEnum,
        RKFIZETES: 'RK_FIZETES' as FizetesTipusEnum
    };
}