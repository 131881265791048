/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AllamiJegyTortenetTetel } from './allamiJegyTortenetTetel';

export interface AllamiJegyTortenet { 
    availableCommands?: Array<AllamiJegyTortenet.AvailableCommandsEnum>;
    ownerSzemely?: boolean;
    ownerSzereploId?: number;
    ownerSzereploNev?: string;
    sorszam?: string;
    statusz?: AllamiJegyTortenet.StatuszEnum;
    tetelList?: Array<AllamiJegyTortenetTetel>;
}
export namespace AllamiJegyTortenet {
    export type AvailableCommandsEnum = 'VEGLEGESSZTORNO_ROLLBACK' | 'KESZLETROLELVESZETTSZTORNO_ROLLBACK' | 'UJRAFELHASZNALHATOSZTORNO_ROLLBACK' | 'SZTORNO' | 'HIANYZO_JOVAHAGY' | 'HIANYZO_ROLLBACK' | 'SZTORNO_JOVAHAGY';
    export const AvailableCommandsEnum = {
        VEGLEGESSZTORNOROLLBACK: 'VEGLEGESSZTORNO_ROLLBACK' as AvailableCommandsEnum,
        KESZLETROLELVESZETTSZTORNOROLLBACK: 'KESZLETROLELVESZETTSZTORNO_ROLLBACK' as AvailableCommandsEnum,
        UJRAFELHASZNALHATOSZTORNOROLLBACK: 'UJRAFELHASZNALHATOSZTORNO_ROLLBACK' as AvailableCommandsEnum,
        SZTORNO: 'SZTORNO' as AvailableCommandsEnum,
        HIANYZOJOVAHAGY: 'HIANYZO_JOVAHAGY' as AvailableCommandsEnum,
        HIANYZOROLLBACK: 'HIANYZO_ROLLBACK' as AvailableCommandsEnum,
        SZTORNOJOVAHAGY: 'SZTORNO_JOVAHAGY' as AvailableCommandsEnum
    };
    export type StatuszEnum = 'KESZLETEN' | 'VEGLEGESEN_STORNOZOTT' | 'KESZLETROLELVESZETT_SZTORNOZOTT' | 'ERVENYES' | 'KIADOTT' | 'LEJART' | 'BETELT' | 'LEADOTT' | 'SZTORNOZASRA_VAR' | 'VEGLEGES_SZTORNOZASRA_VAR' | 'KESZLETROLELVESZETT_SZTORNOZASRA_VAR' | 'ELVESZETT' | 'MEGSEMMISULT' | 'ERVENYES_ELVESZETT_JOVAHAGYASRA_VAR' | 'ERVENYES_MEGSEMMISULT_JOVAHAGYASRA_VAR' | 'LEJART_ELVESZETT_JOVAHAGYASRA_VAR' | 'LEJART_MEGSEMMISULT_JOVAHAGYASRA_VAR' | 'KIADOTT_ELVESZETT_JOVAHAGYASRA_VAR' | 'KIADOTT_MEGSEMMISULT_JOVAHAGYASRA_VAR' | 'VISSZAVONT';
    export const StatuszEnum = {
        KESZLETEN: 'KESZLETEN' as StatuszEnum,
        VEGLEGESENSTORNOZOTT: 'VEGLEGESEN_STORNOZOTT' as StatuszEnum,
        KESZLETROLELVESZETTSZTORNOZOTT: 'KESZLETROLELVESZETT_SZTORNOZOTT' as StatuszEnum,
        ERVENYES: 'ERVENYES' as StatuszEnum,
        KIADOTT: 'KIADOTT' as StatuszEnum,
        LEJART: 'LEJART' as StatuszEnum,
        BETELT: 'BETELT' as StatuszEnum,
        LEADOTT: 'LEADOTT' as StatuszEnum,
        SZTORNOZASRAVAR: 'SZTORNOZASRA_VAR' as StatuszEnum,
        VEGLEGESSZTORNOZASRAVAR: 'VEGLEGES_SZTORNOZASRA_VAR' as StatuszEnum,
        KESZLETROLELVESZETTSZTORNOZASRAVAR: 'KESZLETROLELVESZETT_SZTORNOZASRA_VAR' as StatuszEnum,
        ELVESZETT: 'ELVESZETT' as StatuszEnum,
        MEGSEMMISULT: 'MEGSEMMISULT' as StatuszEnum,
        ERVENYESELVESZETTJOVAHAGYASRAVAR: 'ERVENYES_ELVESZETT_JOVAHAGYASRA_VAR' as StatuszEnum,
        ERVENYESMEGSEMMISULTJOVAHAGYASRAVAR: 'ERVENYES_MEGSEMMISULT_JOVAHAGYASRA_VAR' as StatuszEnum,
        LEJARTELVESZETTJOVAHAGYASRAVAR: 'LEJART_ELVESZETT_JOVAHAGYASRA_VAR' as StatuszEnum,
        LEJARTMEGSEMMISULTJOVAHAGYASRAVAR: 'LEJART_MEGSEMMISULT_JOVAHAGYASRA_VAR' as StatuszEnum,
        KIADOTTELVESZETTJOVAHAGYASRAVAR: 'KIADOTT_ELVESZETT_JOVAHAGYASRA_VAR' as StatuszEnum,
        KIADOTTMEGSEMMISULTJOVAHAGYASRAVAR: 'KIADOTT_MEGSEMMISULT_JOVAHAGYASRA_VAR' as StatuszEnum,
        VISSZAVONT: 'VISSZAVONT' as StatuszEnum
    };
}