/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Megye } from './megye';
import { Orszag } from './orszag';
import { Telepules } from './telepules';

export interface TorzsadatBundle { 
    defaultOrszagKod?: string;
    megyeList?: Array<Megye>;
    orszagList?: Array<Orszag>;
    telepulesList?: Array<Telepules>;
}