/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HorgaszkartyaGyartasFilter { 
    limit?: number;
    statusz?: HorgaszkartyaGyartasFilter.StatuszEnum;
}
export namespace HorgaszkartyaGyartasFilter {
    export type StatuszEnum = 'GYARTAS_JOVAHAGYASRA_VAR' | 'GYARTASRA_VAR' | 'GYARTAS_FOLYAMATBAN' | 'GYARTAS_KESZ';
    export const StatuszEnum = {
        GYARTASJOVAHAGYASRAVAR: 'GYARTAS_JOVAHAGYASRA_VAR' as StatuszEnum,
        GYARTASRAVAR: 'GYARTASRA_VAR' as StatuszEnum,
        GYARTASFOLYAMATBAN: 'GYARTAS_FOLYAMATBAN' as StatuszEnum,
        GYARTASKESZ: 'GYARTAS_KESZ' as StatuszEnum
    };
}