/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizsgaraJelentkezettJegyzokonyv { 
    hozzajaruloNyilatkozatEllenorizve?: boolean;
    megjelent?: boolean;
    szemelyAzonositasMegtortent?: boolean;
    vizsgaStatusz?: VizsgaraJelentkezettJegyzokonyv.VizsgaStatuszEnum;
}
export namespace VizsgaraJelentkezettJegyzokonyv {
    export type VizsgaStatuszEnum = 'JELENTKEZETT' | 'FOLYAMATBAN' | 'KIZART' | 'SIKERES' | 'SIKERTELEN' | 'NEM_JELENT_MEG' | 'MEGHIUSULT';
    export const VizsgaStatuszEnum = {
        JELENTKEZETT: 'JELENTKEZETT' as VizsgaStatuszEnum,
        FOLYAMATBAN: 'FOLYAMATBAN' as VizsgaStatuszEnum,
        KIZART: 'KIZART' as VizsgaStatuszEnum,
        SIKERES: 'SIKERES' as VizsgaStatuszEnum,
        SIKERTELEN: 'SIKERTELEN' as VizsgaStatuszEnum,
        NEMJELENTMEG: 'NEM_JELENT_MEG' as VizsgaStatuszEnum,
        MEGHIUSULT: 'MEGHIUSULT' as VizsgaStatuszEnum
    };
}