import * as moment from "moment";

export class AppHelper {
  public static toServerDate(date: any) {
    if (date instanceof Date) {
      return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
    }
    return date;
  }

  public static serverDateNow() {
    return this.toServerDate(new Date());
  }
}
