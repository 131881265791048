/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TeruletiJegyOsszesitesItem } from './teruletiJegyOsszesitesItem';

export interface TeruletiJegyMohoszOsszesites { 
    arres?: number;
    arresRendszerhasznalatiDij?: number;
    eladasiAr?: number;
    elszamolasiAr?: number;
    items?: Array<TeruletiJegyOsszesitesItem>;
    rendszerhasznalatiDij?: number;
}