/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TeruletiJegyElszamolasVizteruletItem } from './teruletiJegyElszamolasVizteruletItem';

export interface TeruletiJegyElszamolas { 
    ertekesitesTipus?: TeruletiJegyElszamolas.ErtekesitesTipusEnum;
    ertekesitettDarabszam?: number;
    sztornozottDarabszam?: number;
    teljesArresMertek?: number;
    teljesEladasiAr?: number;
    teljesElszamolasiAr?: number;
    teljesRendszerhasznalatiDijAr?: number;
    vizteruletItems?: Array<TeruletiJegyElszamolasVizteruletItem>;
}
export namespace TeruletiJegyElszamolas {
    export type ErtekesitesTipusEnum = 'ONLINE' | 'SAJAT' | 'KOZVETLEN' | 'VISZONT';
    export const ErtekesitesTipusEnum = {
        ONLINE: 'ONLINE' as ErtekesitesTipusEnum,
        SAJAT: 'SAJAT' as ErtekesitesTipusEnum,
        KOZVETLEN: 'KOZVETLEN' as ErtekesitesTipusEnum,
        VISZONT: 'VISZONT' as ErtekesitesTipusEnum
    };
}