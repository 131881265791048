/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Kepesites { 
    dokumentumId?: number;
    modositasDatuma?: Date;
    tipus?: Kepesites.TipusEnum;
}
export namespace Kepesites {
    export type TipusEnum = 'HALGAZDALKODASIFELSOFOKU' | 'HALORIVIZSGA';
    export const TipusEnum = {
        HALGAZDALKODASIFELSOFOKU: 'HALGAZDALKODASIFELSOFOKU' as TipusEnum,
        HALORIVIZSGA: 'HALORIVIZSGA' as TipusEnum
    };
}