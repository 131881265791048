/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SzervezetFilter { 
    filterLabels?: Array<SzervezetFilter.FilterLabelsEnum>;
    globalSearch?: string;
    limit?: number;
    offset?: number;
    szervezetIdList?: Array<number>;
    szolgaltatoOwnerSzemelyId?: number;
    tipusList?: Array<SzervezetFilter.TipusListEnum>;
    tsQueryString?: string;
    uniqueIds?: Array<string>;
}
export namespace SzervezetFilter {
    export type FilterLabelsEnum = 'VIZSGAZTATO' | 'HALGAZDALKODO' | 'SZOLGALTATO';
    export const FilterLabelsEnum = {
        VIZSGAZTATO: 'VIZSGAZTATO' as FilterLabelsEnum,
        HALGAZDALKODO: 'HALGAZDALKODO' as FilterLabelsEnum,
        SZOLGALTATO: 'SZOLGALTATO' as FilterLabelsEnum
    };
    export type TipusListEnum = 'SZEMELY' | 'MOHOSZ' | 'TAGEGYESULET' | 'TAGSZOVETSEG' | 'BOLT' | 'HALGAZDALKODO' | 'ALTALANOS';
    export const TipusListEnum = {
        SZEMELY: 'SZEMELY' as TipusListEnum,
        MOHOSZ: 'MOHOSZ' as TipusListEnum,
        TAGEGYESULET: 'TAGEGYESULET' as TipusListEnum,
        TAGSZOVETSEG: 'TAGSZOVETSEG' as TipusListEnum,
        BOLT: 'BOLT' as TipusListEnum,
        HALGAZDALKODO: 'HALGAZDALKODO' as TipusListEnum,
        ALTALANOS: 'ALTALANOS' as TipusListEnum
    };
}