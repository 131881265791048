/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TeruletiJegyRiportFilter { 
    csakAkcios?: boolean;
    ertekesitesEv?: number;
    ertekesitesHonap?: number;
    ertekesitesNap?: number;
    ertekesitesTipus?: TeruletiJegyRiportFilter.ErtekesitesTipusEnum;
    ertekesitoSzervezetId?: number;
    kiegeszitoJegyTipusList?: Array<TeruletiJegyRiportFilter.KiegeszitoJegyTipusListEnum>;
    limit?: number;
    offset?: number;
    termekIdoszak?: TeruletiJegyRiportFilter.TermekIdoszakEnum;
    termekJogcim?: TeruletiJegyRiportFilter.TermekJogcimEnum;
    termekJogcimCsoport?: TeruletiJegyRiportFilter.TermekJogcimCsoportEnum;
    termekSzervezetId?: number;
    teruletiJegyTermekId?: number;
    tsQueryString?: string;
    validErtekesitesNap?: boolean;
    vizteruletCsoportId?: number;
}
export namespace TeruletiJegyRiportFilter {
    export type ErtekesitesTipusEnum = 'ONLINE' | 'SAJAT' | 'KOZVETLEN' | 'VISZONT';
    export const ErtekesitesTipusEnum = {
        ONLINE: 'ONLINE' as ErtekesitesTipusEnum,
        SAJAT: 'SAJAT' as ErtekesitesTipusEnum,
        KOZVETLEN: 'KOZVETLEN' as ErtekesitesTipusEnum,
        VISZONT: 'VISZONT' as ErtekesitesTipusEnum
    };
    export type KiegeszitoJegyTipusListEnum = 'CSONAKHASZNALATI' | 'BOJLIS' | 'BEHUZOS' | 'VERSENY_RESZVETELI' | 'EJSZAKAI_HORGASZATI' | 'HELYFOGLALASOS' | 'SPECIALIS_VIZTERULETI' | 'TOBBLET_HALELVITELI' | 'HAROM_BOTOS';
    export const KiegeszitoJegyTipusListEnum = {
        CSONAKHASZNALATI: 'CSONAKHASZNALATI' as KiegeszitoJegyTipusListEnum,
        BOJLIS: 'BOJLIS' as KiegeszitoJegyTipusListEnum,
        BEHUZOS: 'BEHUZOS' as KiegeszitoJegyTipusListEnum,
        VERSENYRESZVETELI: 'VERSENY_RESZVETELI' as KiegeszitoJegyTipusListEnum,
        EJSZAKAIHORGASZATI: 'EJSZAKAI_HORGASZATI' as KiegeszitoJegyTipusListEnum,
        HELYFOGLALASOS: 'HELYFOGLALASOS' as KiegeszitoJegyTipusListEnum,
        SPECIALISVIZTERULETI: 'SPECIALIS_VIZTERULETI' as KiegeszitoJegyTipusListEnum,
        TOBBLETHALELVITELI: 'TOBBLET_HALELVITELI' as KiegeszitoJegyTipusListEnum,
        HAROMBOTOS: 'HAROM_BOTOS' as KiegeszitoJegyTipusListEnum
    };
    export type TermekIdoszakEnum = 'EVES' | 'EVES_RESZLET1' | 'EVES_RESZLET2' | 'FELEVES' | 'NAP10' | 'NAP7' | 'ORA72' | 'NAP3' | 'ORA48' | 'NAP2' | 'ORA24' | 'NAPI' | 'EJSZAKAI';
    export const TermekIdoszakEnum = {
        EVES: 'EVES' as TermekIdoszakEnum,
        EVESRESZLET1: 'EVES_RESZLET1' as TermekIdoszakEnum,
        EVESRESZLET2: 'EVES_RESZLET2' as TermekIdoszakEnum,
        FELEVES: 'FELEVES' as TermekIdoszakEnum,
        NAP10: 'NAP10' as TermekIdoszakEnum,
        NAP7: 'NAP7' as TermekIdoszakEnum,
        ORA72: 'ORA72' as TermekIdoszakEnum,
        NAP3: 'NAP3' as TermekIdoszakEnum,
        ORA48: 'ORA48' as TermekIdoszakEnum,
        NAP2: 'NAP2' as TermekIdoszakEnum,
        ORA24: 'ORA24' as TermekIdoszakEnum,
        NAPI: 'NAPI' as TermekIdoszakEnum,
        EJSZAKAI: 'EJSZAKAI' as TermekIdoszakEnum
    };
    export type TermekJogcimEnum = 'GYERMEK' | 'GYERMEK_USZOS' | 'IFJUSAGI' | 'IFJUSAGI_EMELTARAS' | 'IFJUSAGI_TURISTA' | 'IFJUSAGI_KULFOLDI' | 'FELNOTT' | 'FELNOTT_KEDVEZMENYEZETT' | 'FELNOTT_KORKEDVEZMENYEZETT' | 'FELNOTT_KEDVEZMENYEZETT_1BOTOS' | 'FELNOTT_EMELTARAS' | 'FELNOTT_TURISTA' | 'FELNOTT_KULFOLDI' | 'FELNOTT_MENTESITETT' | 'IFJUSAGI_IHJ' | 'FELNOTT_IHJ' | 'FELNOTT_JOGFENNTARTO_IHJ' | 'TISZTSEGVISELOI' | 'SZOLGALATI' | 'BONUSZ';
    export const TermekJogcimEnum = {
        GYERMEK: 'GYERMEK' as TermekJogcimEnum,
        GYERMEKUSZOS: 'GYERMEK_USZOS' as TermekJogcimEnum,
        IFJUSAGI: 'IFJUSAGI' as TermekJogcimEnum,
        IFJUSAGIEMELTARAS: 'IFJUSAGI_EMELTARAS' as TermekJogcimEnum,
        IFJUSAGITURISTA: 'IFJUSAGI_TURISTA' as TermekJogcimEnum,
        IFJUSAGIKULFOLDI: 'IFJUSAGI_KULFOLDI' as TermekJogcimEnum,
        FELNOTT: 'FELNOTT' as TermekJogcimEnum,
        FELNOTTKEDVEZMENYEZETT: 'FELNOTT_KEDVEZMENYEZETT' as TermekJogcimEnum,
        FELNOTTKORKEDVEZMENYEZETT: 'FELNOTT_KORKEDVEZMENYEZETT' as TermekJogcimEnum,
        FELNOTTKEDVEZMENYEZETT1BOTOS: 'FELNOTT_KEDVEZMENYEZETT_1BOTOS' as TermekJogcimEnum,
        FELNOTTEMELTARAS: 'FELNOTT_EMELTARAS' as TermekJogcimEnum,
        FELNOTTTURISTA: 'FELNOTT_TURISTA' as TermekJogcimEnum,
        FELNOTTKULFOLDI: 'FELNOTT_KULFOLDI' as TermekJogcimEnum,
        FELNOTTMENTESITETT: 'FELNOTT_MENTESITETT' as TermekJogcimEnum,
        IFJUSAGIIHJ: 'IFJUSAGI_IHJ' as TermekJogcimEnum,
        FELNOTTIHJ: 'FELNOTT_IHJ' as TermekJogcimEnum,
        FELNOTTJOGFENNTARTOIHJ: 'FELNOTT_JOGFENNTARTO_IHJ' as TermekJogcimEnum,
        TISZTSEGVISELOI: 'TISZTSEGVISELOI' as TermekJogcimEnum,
        SZOLGALATI: 'SZOLGALATI' as TermekJogcimEnum,
        BONUSZ: 'BONUSZ' as TermekJogcimEnum
    };
    export type TermekJogcimCsoportEnum = 'ALTALANOS' | 'PARTI' | 'BOJLIS' | 'SPORTCELU';
    export const TermekJogcimCsoportEnum = {
        ALTALANOS: 'ALTALANOS' as TermekJogcimCsoportEnum,
        PARTI: 'PARTI' as TermekJogcimCsoportEnum,
        BOJLIS: 'BOJLIS' as TermekJogcimCsoportEnum,
        SPORTCELU: 'SPORTCELU' as TermekJogcimCsoportEnum
    };
}