/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { VersenyCsapatResztvevo } from './versenyCsapatResztvevo';
import { VersenyEgyeniResztvevo } from './versenyEgyeniResztvevo';

export interface VersenyDetails { 
    egyeniJelentkezok?: Array<VersenyEgyeniResztvevo>;
    isCsapatverseny?: boolean;
    jelentkezettCsapatok?: Array<VersenyCsapatResztvevo>;
    maxCsapatletszam?: number;
    maxEgyeniJelentkezo?: number;
    maxKorhatar?: number;
    maxResztvevoCsapat?: number;
    minCsapatletszam?: number;
    minKorhatar?: number;
    nevezesiDij?: number;
    versenyKiiras?: string;
    versenyszabalyzatUrl?: string;
}