/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizsgaKerdesValaszRequest { 
    checkBoxValasz?: Array<number>;
    datumValasz?: string;
    igazHamisValasz?: boolean;
    kerdesId?: number;
    kerdesTipus?: VizsgaKerdesValaszRequest.KerdesTipusEnum;
    radioGombValasz?: number;
    skipped?: boolean;
    szamValasz?: string;
    szemelyId?: number;
    vizsgaId?: number;
}
export namespace VizsgaKerdesValaszRequest {
    export type KerdesTipusEnum = 'RADIOGOMB' | 'CHECKBOX' | 'DATUM' | 'SZAM' | 'IGAZHAMIS';
    export const KerdesTipusEnum = {
        RADIOGOMB: 'RADIOGOMB' as KerdesTipusEnum,
        CHECKBOX: 'CHECKBOX' as KerdesTipusEnum,
        DATUM: 'DATUM' as KerdesTipusEnum,
        SZAM: 'SZAM' as KerdesTipusEnum,
        IGAZHAMIS: 'IGAZHAMIS' as KerdesTipusEnum
    };
}