/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Character } from './character';
import { SorszamIntervallum } from './sorszamIntervallum';

export interface PersonalizedAllamiJegyAtadas { 
    allapot?: PersonalizedAllamiJegyAtadas.AllapotEnum;
    atadoSzereploId?: number;
    atadoSzereploNev?: string;
    atvevoSzereploId?: number;
    atvevoSzereploNev?: string;
    availableCommands?: Array<PersonalizedAllamiJegyAtadas.AvailableCommandsEnum>;
    darab?: number;
    ervenyessegiEv?: number;
    id?: number;
    keszlet?: Array<SorszamIntervallum>;
    modositva?: Date;
    sorozatAzonosito?: Character;
}
export namespace PersonalizedAllamiJegyAtadas {
    export type AllapotEnum = 'SZERKESZTES_ALATT' | 'ELUTASITVA' | 'ATVEVE';
    export const AllapotEnum = {
        SZERKESZTESALATT: 'SZERKESZTES_ALATT' as AllapotEnum,
        ELUTASITVA: 'ELUTASITVA' as AllapotEnum,
        ATVEVE: 'ATVEVE' as AllapotEnum
    };
    export type AvailableCommandsEnum = 'ELUTASITAS' | 'VEGLEGESITES' | 'MODOSITAS';
    export const AvailableCommandsEnum = {
        ELUTASITAS: 'ELUTASITAS' as AvailableCommandsEnum,
        VEGLEGESITES: 'VEGLEGESITES' as AvailableCommandsEnum,
        MODOSITAS: 'MODOSITAS' as AvailableCommandsEnum
    };
}