/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { VizsgaztatasRiport } from '../model/vizsgaztatasRiport';
import { VizsgaztatasRiportElszamolas } from '../model/vizsgaztatasRiportElszamolas';
import { VizsgaztatasRiportFilter } from '../model/vizsgaztatasRiportFilter';
import { VizsgaztatasTorzsadat } from '../model/vizsgaztatasTorzsadat';
import { VizsgaztatasTorzsadatRequest } from '../model/vizsgaztatasTorzsadatRequest';
import { VizsgaztatoSzervezetekRiportReply } from '../model/vizsgaztatoSzervezetekRiportReply';
import { VizsgaztatoSzervezetekRiportRequest } from '../model/vizsgaztatoSzervezetekRiportRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class VizsgaztatasRiportControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * elszamolasGeneralas
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public elszamolasGeneralasUsingGET(filter: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public elszamolasGeneralasUsingGET(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public elszamolasGeneralasUsingGET(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public elszamolasGeneralasUsingGET(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling elszamolasGeneralasUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/vizsgaztatasriport/elszamolas-generalas`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * elszamolasLekerdezes
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public elszamolasLekerdezesUsingPOST(body: VizsgaztatasRiportFilter, observe?: 'body', reportProgress?: boolean): Observable<VizsgaztatasRiportElszamolas>;
    public elszamolasLekerdezesUsingPOST(body: VizsgaztatasRiportFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgaztatasRiportElszamolas>>;
    public elszamolasLekerdezesUsingPOST(body: VizsgaztatasRiportFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgaztatasRiportElszamolas>>;
    public elszamolasLekerdezesUsingPOST(body: VizsgaztatasRiportFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling elszamolasLekerdezesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgaztatasRiportElszamolas>('post',`${this.basePath}/api/mohosz/vizsgaztatasriport/elszamolas-lekerdezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findByFilter
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findByFilterUsingPOST(body: VizsgaztatasRiportFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<VizsgaztatasRiport>>;
    public findByFilterUsingPOST(body: VizsgaztatasRiportFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VizsgaztatasRiport>>>;
    public findByFilterUsingPOST(body: VizsgaztatasRiportFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VizsgaztatasRiport>>>;
    public findByFilterUsingPOST(body: VizsgaztatasRiportFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findByFilterUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<VizsgaztatasRiport>>('post',`${this.basePath}/api/mohosz/vizsgaztatasriport/findbyfilter`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findbyfilterCsvGet
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findbyfilterCsvGetUsingGET(filter: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public findbyfilterCsvGetUsingGET(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public findbyfilterCsvGetUsingGET(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public findbyfilterCsvGetUsingGET(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling findbyfilterCsvGetUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/vizsgaztatasriport/findbyfilter-csv`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getVizsgaztatoSzervezetekRiport
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVizsgaztatoSzervezetekRiportUsingPOST(body: VizsgaztatoSzervezetekRiportRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgaztatoSzervezetekRiportReply>;
    public getVizsgaztatoSzervezetekRiportUsingPOST(body: VizsgaztatoSzervezetekRiportRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgaztatoSzervezetekRiportReply>>;
    public getVizsgaztatoSzervezetekRiportUsingPOST(body: VizsgaztatoSzervezetekRiportRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgaztatoSzervezetekRiportReply>>;
    public getVizsgaztatoSzervezetekRiportUsingPOST(body: VizsgaztatoSzervezetekRiportRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getVizsgaztatoSzervezetekRiportUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgaztatoSzervezetekRiportReply>('post',`${this.basePath}/api/mohosz/vizsgaztatasriport/vizsgaztato-szervezetek-riport`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * isAuthorized
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isAuthorizedUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public isAuthorizedUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public isAuthorizedUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public isAuthorizedUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/mohosz/vizsgaztatasriport/isauthorized`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * valaszthatoSzervezetek
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public valaszthatoSzervezetekUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<VizsgaztatasTorzsadat>>;
    public valaszthatoSzervezetekUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VizsgaztatasTorzsadat>>>;
    public valaszthatoSzervezetekUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VizsgaztatasTorzsadat>>>;
    public valaszthatoSzervezetekUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VizsgaztatasTorzsadat>>('post',`${this.basePath}/api/mohosz/vizsgaztatasriport/valaszthato-szervezetek`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * valaszthatoVizsgabizottsagok
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public valaszthatoVizsgabizottsagokUsingPOST(body: VizsgaztatasTorzsadatRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<VizsgaztatasTorzsadat>>;
    public valaszthatoVizsgabizottsagokUsingPOST(body: VizsgaztatasTorzsadatRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VizsgaztatasTorzsadat>>>;
    public valaszthatoVizsgabizottsagokUsingPOST(body: VizsgaztatasTorzsadatRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VizsgaztatasTorzsadat>>>;
    public valaszthatoVizsgabizottsagokUsingPOST(body: VizsgaztatasTorzsadatRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling valaszthatoVizsgabizottsagokUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<VizsgaztatasTorzsadat>>('post',`${this.basePath}/api/mohosz/vizsgaztatasriport/valaszthato-vizsgabizottsagok`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * valaszthatoVizsgahelyek
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public valaszthatoVizsgahelyekUsingPOST(body: VizsgaztatasTorzsadatRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<VizsgaztatasTorzsadat>>;
    public valaszthatoVizsgahelyekUsingPOST(body: VizsgaztatasTorzsadatRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VizsgaztatasTorzsadat>>>;
    public valaszthatoVizsgahelyekUsingPOST(body: VizsgaztatasTorzsadatRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VizsgaztatasTorzsadat>>>;
    public valaszthatoVizsgahelyekUsingPOST(body: VizsgaztatasTorzsadatRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling valaszthatoVizsgahelyekUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<VizsgaztatasTorzsadat>>('post',`${this.basePath}/api/mohosz/vizsgaztatasriport/valaszthato-vizsgahelyek`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
