/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { VizteruletView } from './vizteruletView';

export interface TeruletiKiegeszitoJegyTermekId { 
    idoszak?: TeruletiKiegeszitoJegyTermekId.IdoszakEnum;
    tipus?: TeruletiKiegeszitoJegyTermekId.TipusEnum;
    vizterulet?: VizteruletView;
}
export namespace TeruletiKiegeszitoJegyTermekId {
    export type IdoszakEnum = 'EVES' | 'EVES_RESZLET1' | 'EVES_RESZLET2' | 'FELEVES' | 'NAP10' | 'NAP7' | 'ORA72' | 'NAP3' | 'ORA48' | 'NAP2' | 'ORA24' | 'NAPI' | 'EJSZAKAI';
    export const IdoszakEnum = {
        EVES: 'EVES' as IdoszakEnum,
        EVESRESZLET1: 'EVES_RESZLET1' as IdoszakEnum,
        EVESRESZLET2: 'EVES_RESZLET2' as IdoszakEnum,
        FELEVES: 'FELEVES' as IdoszakEnum,
        NAP10: 'NAP10' as IdoszakEnum,
        NAP7: 'NAP7' as IdoszakEnum,
        ORA72: 'ORA72' as IdoszakEnum,
        NAP3: 'NAP3' as IdoszakEnum,
        ORA48: 'ORA48' as IdoszakEnum,
        NAP2: 'NAP2' as IdoszakEnum,
        ORA24: 'ORA24' as IdoszakEnum,
        NAPI: 'NAPI' as IdoszakEnum,
        EJSZAKAI: 'EJSZAKAI' as IdoszakEnum
    };
    export type TipusEnum = 'CSONAKHASZNALATI' | 'BOJLIS' | 'BEHUZOS' | 'VERSENY_RESZVETELI' | 'EJSZAKAI_HORGASZATI' | 'HELYFOGLALASOS' | 'SPECIALIS_VIZTERULETI' | 'TOBBLET_HALELVITELI' | 'HAROM_BOTOS';
    export const TipusEnum = {
        CSONAKHASZNALATI: 'CSONAKHASZNALATI' as TipusEnum,
        BOJLIS: 'BOJLIS' as TipusEnum,
        BEHUZOS: 'BEHUZOS' as TipusEnum,
        VERSENYRESZVETELI: 'VERSENY_RESZVETELI' as TipusEnum,
        EJSZAKAIHORGASZATI: 'EJSZAKAI_HORGASZATI' as TipusEnum,
        HELYFOGLALASOS: 'HELYFOGLALASOS' as TipusEnum,
        SPECIALISVIZTERULETI: 'SPECIALIS_VIZTERULETI' as TipusEnum,
        TOBBLETHALELVITELI: 'TOBBLET_HALELVITELI' as TipusEnum,
        HAROMBOTOS: 'HAROM_BOTOS' as TipusEnum
    };
}