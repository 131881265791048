import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AdatEllenorzesControllerService } from './api/adatEllenorzesController.service';
import { AllamiJegyArlistaControllerService } from './api/allamiJegyArlistaController.service';
import { AllamiJegyAtadasControllerService } from './api/allamiJegyAtadasController.service';
import { AllamiJegyKeszletezoControllerService } from './api/allamiJegyKeszletezoController.service';
import { AllamiJegyTortenetControllerService } from './api/allamiJegyTortenetController.service';
import { AltalanosControllerService } from './api/altalanosController.service';
import { AuthRestControllerService } from './api/authRestController.service';
import { BelsoKommunikacioControllerService } from './api/belsoKommunikacioController.service';
import { BerbeadasSzolgaltatasControllerService } from './api/berbeadasSzolgaltatasController.service';
import { CimzettCsoportControllerService } from './api/cimzettCsoportController.service';
import { DokumentumControllerService } from './api/dokumentumController.service';
import { DokumentumMgmtControllerService } from './api/dokumentumMgmtController.service';
import { EgyebTermekArazasControllerService } from './api/egyebTermekArazasController.service';
import { EgyesuletTagsagKategoriaMgmtControllerService } from './api/egyesuletTagsagKategoriaMgmtController.service';
import { EgyesuletiTagdijArlistaControllerService } from './api/egyesuletiTagdijArlistaController.service';
import { EgyesuletiTagsagRiportControllerService } from './api/egyesuletiTagsagRiportController.service';
import { EllenorControllerService } from './api/ellenorController.service';
import { EllenorLocationHistoryControllerService } from './api/ellenorLocationHistoryController.service';
import { EllenorMgmtControllerService } from './api/ellenorMgmtController.service';
import { EllenorzesiTevekenysegControllerService } from './api/ellenorzesiTevekenysegController.service';
import { ElszamolohazAllamiJegyMgmtControllerService } from './api/elszamolohazAllamiJegyMgmtController.service';
import { ElszamolohazControllerService } from './api/elszamolohazController.service';
import { ElszamolohazTeruletiJegyMgmtControllerService } from './api/elszamolohazTeruletiJegyMgmtController.service';
import { EltiltasControllerService } from './api/eltiltasController.service';
import { ErtekesitesLimitControllerService } from './api/ertekesitesLimitController.service';
import { ErtekesitesLimitMgmtControllerService } from './api/ertekesitesLimitMgmtController.service';
import { ErtekesitesOsztonzesControllerService } from './api/ertekesitesOsztonzesController.service';
import { ErtekesitesRiportControllerService } from './api/ertekesitesRiportController.service';
import { ErtesitesControllerService } from './api/ertesitesController.service';
import { FeladatsorControllerService } from './api/feladatsorController.service';
import { FelfedezesControllerService } from './api/felfedezesController.service';
import { FigyelmeztetoUzenetControllerService } from './api/figyelmeztetoUzenetController.service';
import { FigyelmeztetoUzenetMgmtControllerService } from './api/figyelmeztetoUzenetMgmtController.service';
import { FizetesControllerService } from './api/fizetesController.service';
import { FizetesMgmtControllerService } from './api/fizetesMgmtController.service';
import { FlogControllerService } from './api/flogController.service';
import { FogasNyilvantartasControllerService } from './api/fogasNyilvantartasController.service';
import { FogasNyilvantartasRiportControllerService } from './api/fogasNyilvantartasRiportController.service';
import { FogasiNaploRiportControllerService } from './api/fogasiNaploRiportController.service';
import { ForgalomRiportControllerService } from './api/forgalomRiportController.service';
import { HalfajControllerService } from './api/halfajController.service';
import { HorgaszBugyellarisControllerService } from './api/horgaszBugyellarisController.service';
import { HorgaszRegisztracioControllerService } from './api/horgaszRegisztracioController.service';
import { HorgaszkartyaGyartasControllerService } from './api/horgaszkartyaGyartasController.service';
import { HorgasztatasSzolgaltatasControllerService } from './api/horgasztatasSzolgaltatasController.service';
import { JogosultsagControllerService } from './api/jogosultsagController.service';
import { LocaleChangeControllerService } from './api/localeChangeController.service';
import { MahorElofizetesControllerService } from './api/mahorElofizetesController.service';
import { MahorElofizetesRiportControllerService } from './api/mahorElofizetesRiportController.service';
import { NebihAllamiJegyControllerService } from './api/nebihAllamiJegyController.service';
import { NebihEltiltasControllerService } from './api/nebihEltiltasController.service';
import { NyilatkozatControllerService } from './api/nyilatkozatController.service';
import { ObjectStoreControllerService } from './api/objectStoreController.service';
import { OfflineProfilControllerService } from './api/offlineProfilController.service';
import { OfflineVizsgaControllerService } from './api/offlineVizsgaController.service';
import { OnlineEllenorControllerService } from './api/onlineEllenorController.service';
import { OnlineMahorElofizetesControllerService } from './api/onlineMahorElofizetesController.service';
import { OnlinePenztarControllerService } from './api/onlinePenztarController.service';
import { OnlineProfilControllerService } from './api/onlineProfilController.service';
import { OnlineVersenyzoiEngedelyControllerService } from './api/onlineVersenyzoiEngedelyController.service';
import { OnlineVizsgaControllerService } from './api/onlineVizsgaController.service';
import { PostaiCimjegyzekControllerService } from './api/postaiCimjegyzekController.service';
import { RendszeruzenetControllerService } from './api/rendszeruzenetController.service';
import { RiportControllerService } from './api/riportController.service';
import { SugoControllerService } from './api/sugoController.service';
import { SwaggerModelControllerService } from './api/swaggerModelController.service';
import { SzabalysertesControllerService } from './api/szabalysertesController.service';
import { SzabalysertoControllerService } from './api/szabalysertoController.service';
import { SzamlaControllerService } from './api/szamlaController.service';
import { SzemelyControllerService } from './api/szemelyController.service';
import { SzemelyHorgaszkartyaIgenylesekControllerService } from './api/szemelyHorgaszkartyaIgenylesekController.service';
import { SzemelyHorgaszkartyaIgenylesekMgmtControllerService } from './api/szemelyHorgaszkartyaIgenylesekMgmtController.service';
import { SzemelyKepesitesekControllerService } from './api/szemelyKepesitesekController.service';
import { SzervezetControllerService } from './api/szervezetController.service';
import { SzervezetMgmtControllerService } from './api/szervezetMgmtController.service';
import { SzerzodesControllerService } from './api/szerzodesController.service';
import { SzolgaltatasFelugyeloControllerService } from './api/szolgaltatasFelugyeloController.service';
import { SzolgaltatasMgmtControllerService } from './api/szolgaltatasMgmtController.service';
import { SzolgaltatoMgmtControllerService } from './api/szolgaltatoMgmtController.service';
import { TeendoControllerService } from './api/teendoController.service';
import { TeruletiJegyRiportControllerService } from './api/teruletiJegyRiportController.service';
import { TeruletiJegyTermekErtekesitesControllerService } from './api/teruletiJegyTermekErtekesitesController.service';
import { TeruletiJegyTermekMgmtControllerService } from './api/teruletiJegyTermekMgmtController.service';
import { TorzsadatControllerService } from './api/torzsadatController.service';
import { UgyfelszolgalatiHorgaszRegisztracioControllerService } from './api/ugyfelszolgalatiHorgaszRegisztracioController.service';
import { UgyintezoiHorgaszRegisztracioControllerService } from './api/ugyintezoiHorgaszRegisztracioController.service';
import { UgyintezoiHorgaszkartyaIgenylesekControllerService } from './api/ugyintezoiHorgaszkartyaIgenylesekController.service';
import { UserMgmtControllerService } from './api/userMgmtController.service';
import { UzenetMgmtControllerService } from './api/uzenetMgmtController.service';
import { VersenyMgmtControllerService } from './api/versenyMgmtController.service';
import { VersenyNevezesControllerService } from './api/versenyNevezesController.service';
import { VersenyzoiEngedelyArlistaControllerService } from './api/versenyzoiEngedelyArlistaController.service';
import { VersenyzoiEngedelyRiportControllerService } from './api/versenyzoiEngedelyRiportController.service';
import { VizsgabizottsagControllerService } from './api/vizsgabizottsagController.service';
import { VizsgabizottsagVizsgaMgmtControllerService } from './api/vizsgabizottsagVizsgaMgmtController.service';
import { VizsgaztatasRiportControllerService } from './api/vizsgaztatasRiportController.service';
import { VizteruletControllerService } from './api/vizteruletController.service';
import { VizteruletCsoportControllerService } from './api/vizteruletCsoportController.service';
import { VizteruletCsoportMgmtControllerService } from './api/vizteruletCsoportMgmtController.service';
import { VizteruletEltiltasControllerService } from './api/vizteruletEltiltasController.service';
import { VizteruletMgmtControllerService } from './api/vizteruletMgmtController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AdatEllenorzesControllerService,
    AllamiJegyArlistaControllerService,
    AllamiJegyAtadasControllerService,
    AllamiJegyKeszletezoControllerService,
    AllamiJegyTortenetControllerService,
    AltalanosControllerService,
    AuthRestControllerService,
    BelsoKommunikacioControllerService,
    BerbeadasSzolgaltatasControllerService,
    CimzettCsoportControllerService,
    DokumentumControllerService,
    DokumentumMgmtControllerService,
    EgyebTermekArazasControllerService,
    EgyesuletTagsagKategoriaMgmtControllerService,
    EgyesuletiTagdijArlistaControllerService,
    EgyesuletiTagsagRiportControllerService,
    EllenorControllerService,
    EllenorLocationHistoryControllerService,
    EllenorMgmtControllerService,
    EllenorzesiTevekenysegControllerService,
    ElszamolohazAllamiJegyMgmtControllerService,
    ElszamolohazControllerService,
    ElszamolohazTeruletiJegyMgmtControllerService,
    EltiltasControllerService,
    ErtekesitesLimitControllerService,
    ErtekesitesLimitMgmtControllerService,
    ErtekesitesOsztonzesControllerService,
    ErtekesitesRiportControllerService,
    ErtesitesControllerService,
    FeladatsorControllerService,
    FelfedezesControllerService,
    FigyelmeztetoUzenetControllerService,
    FigyelmeztetoUzenetMgmtControllerService,
    FizetesControllerService,
    FizetesMgmtControllerService,
    FlogControllerService,
    FogasNyilvantartasControllerService,
    FogasNyilvantartasRiportControllerService,
    FogasiNaploRiportControllerService,
    ForgalomRiportControllerService,
    HalfajControllerService,
    HorgaszBugyellarisControllerService,
    HorgaszRegisztracioControllerService,
    HorgaszkartyaGyartasControllerService,
    HorgasztatasSzolgaltatasControllerService,
    JogosultsagControllerService,
    LocaleChangeControllerService,
    MahorElofizetesControllerService,
    MahorElofizetesRiportControllerService,
    NebihAllamiJegyControllerService,
    NebihEltiltasControllerService,
    NyilatkozatControllerService,
    ObjectStoreControllerService,
    OfflineProfilControllerService,
    OfflineVizsgaControllerService,
    OnlineEllenorControllerService,
    OnlineMahorElofizetesControllerService,
    OnlinePenztarControllerService,
    OnlineProfilControllerService,
    OnlineVersenyzoiEngedelyControllerService,
    OnlineVizsgaControllerService,
    PostaiCimjegyzekControllerService,
    RendszeruzenetControllerService,
    RiportControllerService,
    SugoControllerService,
    SwaggerModelControllerService,
    SzabalysertesControllerService,
    SzabalysertoControllerService,
    SzamlaControllerService,
    SzemelyControllerService,
    SzemelyHorgaszkartyaIgenylesekControllerService,
    SzemelyHorgaszkartyaIgenylesekMgmtControllerService,
    SzemelyKepesitesekControllerService,
    SzervezetControllerService,
    SzervezetMgmtControllerService,
    SzerzodesControllerService,
    SzolgaltatasFelugyeloControllerService,
    SzolgaltatasMgmtControllerService,
    SzolgaltatoMgmtControllerService,
    TeendoControllerService,
    TeruletiJegyRiportControllerService,
    TeruletiJegyTermekErtekesitesControllerService,
    TeruletiJegyTermekMgmtControllerService,
    TorzsadatControllerService,
    UgyfelszolgalatiHorgaszRegisztracioControllerService,
    UgyintezoiHorgaszRegisztracioControllerService,
    UgyintezoiHorgaszkartyaIgenylesekControllerService,
    UserMgmtControllerService,
    UzenetMgmtControllerService,
    VersenyMgmtControllerService,
    VersenyNevezesControllerService,
    VersenyzoiEngedelyArlistaControllerService,
    VersenyzoiEngedelyRiportControllerService,
    VizsgabizottsagControllerService,
    VizsgabizottsagVizsgaMgmtControllerService,
    VizsgaztatasRiportControllerService,
    VizteruletControllerService,
    VizteruletCsoportControllerService,
    VizteruletCsoportMgmtControllerService,
    VizteruletEltiltasControllerService,
    VizteruletMgmtControllerService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
