/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ElszamolasRiportItem { 
    allamiJegyAr?: number;
    allamiJegyDarabAr?: number;
    allamiJegyJutalekAr?: number;
    allamiJegyJutalekDarabAr?: number;
    darabszam?: number;
    eahAr?: number;
    eahDarabAr?: number;
    ertekesitettDarabszam?: number;
    eszhAr?: number;
    eszhDarabAr?: number;
    eszhTagszovetsegiDijhanyadAr?: number;
    eszhTagszovetsegiDijhanyadDarabAr?: number;
    fogasiNaploAr?: number;
    fogasiNaploDarabAr?: number;
    megnevezesAllamiJegy?: string;
    megnevezesESZH?: string;
    nemTovabbUtalandoAr?: number;
    riportSorszam?: string;
    sztornozottDarabszam?: number;
    tagszovetsegElszamolas?: boolean;
    tipus?: ElszamolasRiportItem.TipusEnum;
}
export namespace ElszamolasRiportItem {
    export type TipusEnum = 'NORMALERTEKESITETT' | 'SZTORNOZOTT' | 'RONTOTTALLAMIJEGY' | 'KESZLETROLELVESZETT';
    export const TipusEnum = {
        NORMALERTEKESITETT: 'NORMALERTEKESITETT' as TipusEnum,
        SZTORNOZOTT: 'SZTORNOZOTT' as TipusEnum,
        RONTOTTALLAMIJEGY: 'RONTOTTALLAMIJEGY' as TipusEnum,
        KESZLETROLELVESZETT: 'KESZLETROLELVESZETT' as TipusEnum
    };
}