/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SzemelyJogszabalyiEltiltas { 
    aktivEltiltas?: boolean;
    befizetesDatuma?: string;
    eltiltasKezdete?: string;
    eltiltasVege?: string;
    hatarozatszam?: string;
    nebihPartnerId?: string;
    statusz?: SzemelyJogszabalyiEltiltas.StatuszEnum;
}
export namespace SzemelyJogszabalyiEltiltas {
    export type StatuszEnum = 'ELO' | 'NEM_ELO' | 'FELULBIRALT';
    export const StatuszEnum = {
        ELO: 'ELO' as StatuszEnum,
        NEMELO: 'NEM_ELO' as StatuszEnum,
        FELULBIRALT: 'FELULBIRALT' as StatuszEnum
    };
}