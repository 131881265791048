/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { SzervezetRequest } from '../model/szervezetRequest';
import { SzolgaltatasElutasitasRequest } from '../model/szolgaltatasElutasitasRequest';
import { SzolgaltatasFilter } from '../model/szolgaltatasFilter';
import { SzolgaltatasListItem } from '../model/szolgaltatasListItem';
import { SzolgaltatasRequest } from '../model/szolgaltatasRequest';
import { SzolgaltatoAlapadat } from '../model/szolgaltatoAlapadat';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SzolgaltatasFelugyeloControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * szolgaltatasElutasitas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szolgaltatasElutasitasUsingPOST(body: SzolgaltatasElutasitasRequest, observe?: 'body', reportProgress?: boolean): Observable<SzolgaltatasListItem>;
    public szolgaltatasElutasitasUsingPOST(body: SzolgaltatasElutasitasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SzolgaltatasListItem>>;
    public szolgaltatasElutasitasUsingPOST(body: SzolgaltatasElutasitasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SzolgaltatasListItem>>;
    public szolgaltatasElutasitasUsingPOST(body: SzolgaltatasElutasitasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szolgaltatasElutasitasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SzolgaltatasListItem>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatasfelugyelo/szolgaltatas-elutasitas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szolgaltatasJovahagyas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szolgaltatasJovahagyasUsingPOST(body: SzolgaltatasRequest, observe?: 'body', reportProgress?: boolean): Observable<SzolgaltatasListItem>;
    public szolgaltatasJovahagyasUsingPOST(body: SzolgaltatasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SzolgaltatasListItem>>;
    public szolgaltatasJovahagyasUsingPOST(body: SzolgaltatasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SzolgaltatasListItem>>;
    public szolgaltatasJovahagyasUsingPOST(body: SzolgaltatasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szolgaltatasJovahagyasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SzolgaltatasListItem>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatasfelugyelo/szolgaltatas-jovahagyas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szolgaltatasKereso
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szolgaltatasKeresoUsingPOST(body: SzolgaltatasFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<SzolgaltatasListItem>>;
    public szolgaltatasKeresoUsingPOST(body: SzolgaltatasFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SzolgaltatasListItem>>>;
    public szolgaltatasKeresoUsingPOST(body: SzolgaltatasFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SzolgaltatasListItem>>>;
    public szolgaltatasKeresoUsingPOST(body: SzolgaltatasFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szolgaltatasKeresoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<SzolgaltatasListItem>>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatasfelugyelo/szolgaltatas-kereso`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szolgaltatoAlapadat
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szolgaltatoAlapadatUsingPOST(body: SzervezetRequest, observe?: 'body', reportProgress?: boolean): Observable<SzolgaltatoAlapadat>;
    public szolgaltatoAlapadatUsingPOST(body: SzervezetRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SzolgaltatoAlapadat>>;
    public szolgaltatoAlapadatUsingPOST(body: SzervezetRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SzolgaltatoAlapadat>>;
    public szolgaltatoAlapadatUsingPOST(body: SzervezetRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szolgaltatoAlapadatUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SzolgaltatoAlapadat>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatasfelugyelo/szolgaltato-alapadat`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
