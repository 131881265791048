import {VizteruletTorzsadat} from 'src/api';

export class AuthenticatedUser {
  active?: boolean;
  authorities?: Array<string>;
  authorityChannel?: string;
  email?: string;
  entrustingId?: number;
  lastLoggedIn?: Date;
  loginId?: string;
  trusteeId?: number;

  selectedVizterulet?: VizteruletTorzsadat;
  selectedVizteruletNev?: string;
  password?: string;
  entrustingNev?: string;
}
