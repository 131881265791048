/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MahorIntegracio { 
    contentId?: number;
    darabszam?: number;
    filename?: string;
    id?: number;
    letrehozva?: Date;
    tipus?: MahorIntegracio.TipusEnum;
}
export namespace MahorIntegracio {
    export type TipusEnum = 'OUT_POSTA' | 'IN_POSTA';
    export const TipusEnum = {
        OUTPOSTA: 'OUT_POSTA' as TipusEnum,
        INPOSTA: 'IN_POSTA' as TipusEnum
    };
}