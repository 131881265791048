export * from './activateUserRequest';
export * from './adatEllenorzes';
export * from './adatEllenorzesAssignLabelsRequest';
export * from './adatEllenorzesDetails';
export * from './adatEllenorzesFeladat';
export * from './adatEllenorzesFilter';
export * from './adatvaltozasEllenorzesLetrehozasRequest';
export * from './adatvaltozasEllenorzesRendbenRequest';
export * from './addFogasRequest';
export * from './addFogasResponse';
export * from './ajvNyomtatasiKepArlistaTetel';
export * from './alapadatRequest';
export * from './allamiJegy';
export * from './allamiJegyArDimenzio';
export * from './allamiJegyArlista';
export * from './allamiJegyArlistaCloneRequest';
export * from './allamiJegyArlistaFilter';
export * from './allamiJegyArlistaLetrehozRequest';
export * from './allamiJegyArlistaModositRequest';
export * from './allamiJegyArlistaTetel';
export * from './allamiJegyAtadas';
export * from './allamiJegyAtadasLetrehozRequest';
export * from './allamiJegyAtadasModositRequest';
export * from './allamiJegyAtadasRequest';
export * from './allamiJegyAtadasSearchFilter';
export * from './allamiJegyIntervallumTortenetRequest';
export * from './allamiJegyJutalek';
export * from './allamiJegyKeszletezo';
export * from './allamiJegyKeszletezoFilter';
export * from './allamiJegyKeszletezoInfo';
export * from './allamiJegyKeszletezoKeszletModositRequest';
export * from './allamiJegyKeszletezoLetrehozRequest';
export * from './allamiJegyRequest';
export * from './allamiJegySzervezetOsszesitesItem';
export * from './allamiJegySztornoRequest';
export * from './allamiJegyTortenet';
export * from './allamiJegyTortenetRequest';
export * from './allamiJegyTortenetSearchRequest';
export * from './allamiJegyTortenetTetel';
export * from './allamiJegyVasarlasArReply';
export * from './allamiJegyVasarlasEllenorzesRequest';
export * from './allamiJegyVasarlasNyomtatasiKep';
export * from './allamiJegyVasarlasRequest';
export * from './allamiJegyekReply';
export * from './allamiVizsga';
export * from './arresElszamolasItem';
export * from './arresTermekElszamolasFilter';
export * from './arresTermekElszamolasTorzsadat';
export * from './authority';
export * from './base64Content';
export * from './batchEllenorLokacioRogzitesRequest';
export * from './batchHorgaszAdatLekerdezesRequest';
export * from './batchSzemelyRequest';
export * from './befizetoAzonositoCsereRequest';
export * from './belsoKommunikacioFilterResponse';
export * from './belsoKommunikacioTorzsadatResponse';
export * from './berbeadasSzolgaltatasAltalanosLeirasRequest';
export * from './berbeadasSzolgaltatasBundle';
export * from './berbeadasSzolgaltatasElhelyezkedesRequest';
export * from './berbeadasSzolgaltatasFenykepekRequest';
export * from './berbeadasSzolgaltatasRegisztracio';
export * from './berbeadasSzolgaltatasTorzsadat';
export * from './berbeadasSzolgaltatasVegrehajtottLepes';
export * from './bigFishTransactionResult';
export * from './bontottVasarlasiElozmenyek';
export * from './changeLoginIdRequest';
export * from './changePasswordRequest';
export * from './character';
export * from './cim';
export * from './cimekRequest';
export * from './cimzettBundle';
export * from './cimzettCsoport';
export * from './cimzettCsoportLetrehozasRequest';
export * from './cimzettCsoportModositasRequest';
export * from './cimzettCsoportRequest';
export * from './contentRequest';
export * from './csapatAthelyezesRequest';
export * from './csapatAthelyezesResponse';
export * from './csapatAzonositoGeneralasReply';
export * from './csapatLetrehozasRequest';
export * from './csapatTagAthelyezesRequest';
export * from './csapatTagHozzaadasRequest';
export * from './csapatTagTorlesRequest';
export * from './csapatTorlesRequest';
export * from './csekkBefizetesElismerRequest';
export * from './csekkBefizetesTorlesRequest';
export * from './csekkesFizetes';
export * from './csekkesFizetesElismeres';
export * from './csekkesFizetesElismeresContext';
export * from './csekkesFizetesElismeresFilter';
export * from './csekkesFizetesSzemely';
export * from './csekkesFizetesSzemelyHorgasz';
export * from './deleteFogasRequest';
export * from './dokumentum';
export * from './dokumentumFilterRequest';
export * from './dokumentumLetrehozasRequest';
export * from './dokumentumModositasRequest';
export * from './dokumentumRequest';
export * from './dokumentumtar';
export * from './duplicationCandidate';
export * from './egyebTermekAr';
export * from './egyebTermekArazasCloneRequest';
export * from './egyebTermekArazasFilter';
export * from './egyesuletTagsagKategoria';
export * from './egyesuletTagsagKategoriaDetails';
export * from './egyesuletTagsagKategoriaRequest';
export * from './egyesuletTagsagKategoriaUpdateRequest';
export * from './egyesuletiTagdijArlista';
export * from './egyesuletiTagdijArlistaFilter';
export * from './egyesuletiTagdijArlistaLetrehozRequest';
export * from './egyesuletiTagdijArlistaModositRequest';
export * from './egyesuletiTagdijArlistaTetel';
export * from './egyesuletiTagdijArlistaTorlesRequest';
export * from './egyesuletiTagsagRequest';
export * from './egyesuletiTagsagRiportFilter';
export * from './egyesuletiTagsagRiportSor';
export * from './egyesuletiTagsagRiportStatisztika';
export * from './egyesuletiTagsagRogzitesRequest';
export * from './egyesuletiTagsagTorlesRequest';
export * from './elkovetesLokacio';
export * from './ellenorLokacioRogzitesRequest';
export * from './ellenorSssUser';
export * from './ellenorTevekenysegRiport';
export * from './ellenorTevekenysegRiportPOI';
export * from './ellenorTevekenysegRiportRequest';
export * from './ellenorTevekenysegRiportRow';
export * from './ellenorzesiTevekenyseg';
export * from './ellenorzesiTevekenysegFilterRequest';
export * from './ellenorzesiTevekenysegKeresesRequest';
export * from './ellenorzesiTevekenysegKontextus';
export * from './ellenorzesiTevekenysegMinositesRequest';
export * from './ellenorzesiTevekenysegReply';
export * from './ellenorzesiTevekenysegReplyRow';
export * from './ellenorzottSzemelyAdatai';
export * from './elszamolasRiport';
export * from './elszamolasRiportItem';
export * from './elszamolohazFilter';
export * from './eltiltasBundle';
export * from './ertekesites';
export * from './ertekesitesDetails';
export * from './ertekesitesOsztonzes';
export * from './ertekesitesRiportFilter';
export * from './ertekesitesRiportSzemely';
export * from './ertekesitesUtaniModositasRequest';
export * from './ertekesithetoEgyesuletiTagsagRequest';
export * from './ertekesithetoEgyesuletiTagsagResponse';
export * from './ertesites';
export * from './evesTagsag';
export * from './feladatsor';
export * from './feladatsorAktivalasRequest';
export * from './feladatsorLetrehozasRequest';
export * from './feladatsorModositasRequest';
export * from './feladovevenyesFizetes';
export * from './felfedezesTorzsadat';
export * from './felhasznalo';
export * from './felhasznaloMeghivasRequest';
export * from './felhasznaloRequest';
export * from './feltoltes';
export * from './feltoltesRequest';
export * from './figyelmeztetoUzenet';
export * from './figyelmeztetoUzenetFilter';
export * from './figyelmeztetoUzenetKeresesRequest';
export * from './figyelmeztetoUzenetKontextus';
export * from './figyelmeztetoUzenetLetrehozasRequest';
export * from './figyelmeztetoUzenetModositasRequest';
export * from './figyelmeztetoUzenetTorlesRequest';
export * from './figyelmeztetoUzenetVasarlasFilter';
export * from './findJogcimRequest';
export * from './findTeruletiJegyTermekRequest';
export * from './findVizteruletCsoportRequest';
export * from './fizetesIdsRequest';
export * from './flog';
export * from './flogFilter';
export * from './flogLetrehozasRequest';
export * from './flogModositasRequest';
export * from './flogRequest';
export * from './fogas';
export * from './fogasNyilvantartas';
export * from './fogasNyilvantartasEllenorzesRequest';
export * from './fogasNyilvantartasEllenorzesResponse';
export * from './fogasNyilvantartasRiportFilter';
export * from './fogasNyilvantartasRiportSor';
export * from './fogasNyilvantartasRiportSorHalfaj';
export * from './fogasNyilvantartasRogzitesRequest';
export * from './fogasiNaplo';
export * from './fogasiNaploArReply';
export * from './fogasiNaploIdRequest';
export * from './fogasiNaploLeadasRequest';
export * from './fogasiNaploRequest';
export * from './fogasiNaploRiport';
export * from './fogasiNaploRiportDetails';
export * from './fogasiNaploRiportFilter';
export * from './fogasiNaploRogzitesForecast';
export * from './fogasiNaploRogzitesForecastRequest';
export * from './fogasiNaploRogzitesRequest';
export * from './fogasiNaploVasarlasEllenorzesRequest';
export * from './fogasiNaploVasarlasRequest';
export * from './fogasiRekord';
export * from './forgalomRiportAggregation';
export * from './forgalomRiportAggregationBundle';
export * from './forgalomRiportFilter';
export * from './gPSKoordinata';
export * from './gondviseloCsereRequest';
export * from './gondviseloFelhasznaloRequest';
export * from './gondviseloMegadasRequest';
export * from './gondviseloSzemelyesAdatRequest';
export * from './gyartasIgenylesLetrehozasRequest';
export * from './halallomany';
export * from './halfaj';
export * from './halgazdalkodoHozzarendelesRequest';
export * from './halgazdalkodoLevalasztasRequest';
export * from './halmatrix';
export * from './halmatrixItem';
export * from './hasonloElofizetesekRequest';
export * from './hatarozatFelulbiralasFeloldasaRequest';
export * from './hatarozatotFelulbiralRequest';
export * from './hghJegysorszamMigracioRowIdEvent';
export * from './hianyzoFogasiNaploRequest';
export * from './hibasErtekesites';
export * from './hibasErtekesitesBundle';
export * from './hogyanTovabb';
export * from './horgasz';
export * from './horgaszAdatReply';
export * from './horgaszAdatRequest';
export * from './horgaszAdatSzemely';
export * from './horgaszBugyellaris';
export * from './horgaszEgyesuletek';
export * from './horgaszInfoReply';
export * from './horgaszInfoRequest';
export * from './horgaszNyilatkozat';
export * from './horgaszRegisztracio';
export * from './horgaszSzemelyesAdat';
export * from './horgaszkartyaGyartas';
export * from './horgaszkartyaGyartasDarabszam';
export * from './horgaszkartyaGyartasFilter';
export * from './horgaszkartyaGyartasKoteg';
export * from './horgaszkartyaIgenyles';
export * from './horgaszkartyaIgenylesKombinacio';
export * from './horgaszkartyaIgenylesTortenet';
export * from './horgaszkartyaIgenylesTortenetBundle';
export * from './horgaszmodszer';
export * from './horgasztatasSzolgaltatasAlapadatRequest';
export * from './horgasztatasSzolgaltatasBundle';
export * from './horgasztatasSzolgaltatasElhelyezkedesRequest';
export * from './horgasztatasSzolgaltatasFenykepekRequest';
export * from './horgasztatasSzolgaltatasHalallomanyRequest';
export * from './horgasztatasSzolgaltatasHorgaszrendRequest';
export * from './horgasztatasSzolgaltatasJellemzokRequest';
export * from './horgasztatasSzolgaltatasRegisztracio';
export * from './horgasztatasSzolgaltatasTerkepesInformaciokRequest';
export * from './horgasztatasSzolgaltatasTorzsadat';
export * from './horgasztatasSzolgaltatasVegrehajtottLepes';
export * from './horgasztatasSzolgaltatasZarvatartasModositasRequest';
export * from './horgasztatasSzolgaltatasZarvatartasRogzitesRequest';
export * from './horgasztatasSzolgaltatasZarvatartasTorlesRequest';
export * from './horgasztatasZarvatartas';
export * from './horgaszturizmusPoi';
export * from './horgaszturizmusPoiDetails';
export * from './horgaszturizmusPoiFilter';
export * from './horgaszturizmusPoiItem';
export * from './horgaszvizsgaIgazoloDokumentum';
export * from './horgaszvizsgaRogzitesRequest';
export * from './horinfoKapcsolatTortenet';
export * from './hozzatartozo';
export * from './idoszak';
export * from './igazoltFogasiNaploRogzitesRequest';
export * from './igenylesCsekkFizetesRequest';
export * from './jegyFilter';
export * from './jegyekRequest';
export * from './jegyekResponse';
export * from './jelentkezesEllenorzesReply';
export * from './jelentkezesEllenorzesRequest';
export * from './jelentkezettVizsgaStatusz';
export * from './jogosultsag';
export * from './jogosultsagTorlesRequest';
export * from './kepesites';
export * from './kerdes';
export * from './kerdesAlapadat';
export * from './kerdesCsoport';
export * from './kerdesCsoportHozzaadasaRequest';
export * from './kerdesCsoportModositasaRequest';
export * from './kerdesCsoportTorleseRequest';
export * from './kerdesFlatBean';
export * from './kerdesHozzaadasaFeladatsorhozRequest';
export * from './kerdesKeresesRequest';
export * from './kerdesKeresesResponse';
export * from './kerdesModositasaFeladatsorbanRequest';
export * from './kerdesTorleseFeladatsorbolRequest';
export * from './kezbesitendoUzenet';
export * from './kosarFizetes';
export * from './kosarIdsRequest';
export * from './kosarRequest';
export * from './kotegFeltoltes';
export * from './kotegLetrehozasRequest';
export * from './kozvetlenErtekesitoArres';
export * from './loginIdJavaslat';
export * from './loginIdRequest';
export * from './mahorElofizetes';
export * from './mahorElofizetesCiklus';
export * from './mahorElofizetesCiklusRequest';
export * from './mahorElofizetesDetails';
export * from './mahorElofizetesErtekesites';
export * from './mahorElofizetesErtekesitesFilter';
export * from './mahorElofizetesErtekesitesTorzsadatRequest';
export * from './mahorElofizetesFilter';
export * from './mahorElofizetesLetrehozasRequest';
export * from './mahorElofizetesMegjegyzesekModositasRequest';
export * from './mahorElofizetesMigracio';
export * from './mahorElofizetesModositasRequest';
export * from './mahorElofizetesRequest';
export * from './mahorElofizetesTorzsadat';
export * from './mahorIntegracio';
export * from './mahorIntegracioFilter';
export * from './manualisErtekesitesRogzitesRequest';
export * from './mappa';
export * from './mappaLetrehozasRequest';
export * from './mappaModositasRequest';
export * from './mappaRequest';
export * from './mappaTartalom';
export * from './masEgyesuletiTagsag';
export * from './megbizoDetails';
export * from './megbizoJogosultsagBundle';
export * from './megbizoKivalasztasRequest';
export * from './megye';
export * from './megyePoiSzamossag';
export * from './metadata';
export * from './metadataItem';
export * from './mohoszEgyesuletiTagsag';
export * from './nebihAllamiJegy';
export * from './nebihAllamiJegyAtmozgatasRequest';
export * from './nebihAllamiJegyDetails';
export * from './nebihAllamiJegyLeadasRequest';
export * from './nebihAllamiJegyRequest';
export * from './nebihAllamiJegySearchRequest';
export * from './nebihEltiltas';
export * from './nebihEltiltasCim';
export * from './nebihEltiltasFilter';
export * from './nebihEltiltasHorgaszhozKapcsolRequest';
export * from './nebihEltiltasHorgaszrolLevalasztRequest';
export * from './nebihEltiltasKezelesResponse';
export * from './nebihEltiltasKoteg';
export * from './nebihEltiltasRequest';
export * from './nebihEltiltasSearchRequest';
export * from './nebihPartnerEltiltasaiRequest';
export * from './nebihSzemelyEltiltasok';
export * from './nebihSzemelyEltiltasokDetails';
export * from './nextAllamiJegySorszam';
export * from './nyilatkozat';
export * from './nyilatkozatDetails';
export * from './nyilatkozatFeltoltesRequest';
export * from './nyilatkozatLetrehozRequest';
export * from './nyilatkozatRequest';
export * from './offlineFelhasznalo';
export * from './offlineFizetesRequest';
export * from './offlineHozzatartozo';
export * from './offlineVizsgaOsszesito';
export * from './onlineFizetesAllapotResponse';
export * from './onlineMahorElofizetesList';
export * from './onlinePenztar';
export * from './onlinePenztarFizetesInditasRequest';
export * from './onlinePenztarTermek';
export * from './orszag';
export * from './osszesitettErtekesites';
export * from './osszesitettErtekesitesRiport';
export * from './passwordReset';
export * from './personalizedAdatEllenorzes';
export * from './personalizedAllamiJegyAtadas';
export * from './personalizedAtvevo';
export * from './personalizedEllenor';
export * from './personalizedFlog';
export * from './personalizedJogosultsag';
export * from './poiTypeSzamossag';
export * from './postaExportRequest';
export * from './postaIntegracio';
export * from './postaiCimjegyzekBundle';
export * from './postaiCimjegyzekFilter';
export * from './registerUserRequest';
export * from './rendszerenKivuliBefizetesModositasRequest';
export * from './rendszerenKivuliFizetesRogzitesRequest';
export * from './resetPasswordByTokenRequest';
export * from './riport';
export * from './rogzithetoEgyesuletiTagsagRequest';
export * from './rogzithetoEgyesuletiTagsagResponse';
export * from './sajatSzemelyAlapadatRequest';
export * from './sorszamIntervallum';
export * from './sorszamOszto';
export * from './sssUser';
export * from './sssUserEmailValidation';
export * from './sssUserInfo';
export * from './sssUserRegistration';
export * from './storedObjectRequest';
export * from './sugo';
export * from './sugoElem';
export * from './sugoKeresesRequest';
export * from './sugoKeresesResponse';
export * from './szabalysertesAlapadatModositasRequest';
export * from './szabalysertesAlapadatRogzitesRequest';
export * from './szabalysertesAllomany';
export * from './szabalysertesFilter';
export * from './szabalysertesKeresesRequest';
export * from './szabalysertesModositasRequest';
export * from './szabalysertesResponse';
export * from './szabalysertesRogzitesRequest';
export * from './szabalysertesTorlesRequest';
export * from './szabalyserteshezKapcsolRequest';
export * from './szabalyserto';
export * from './szamlaIdsRequest';
export * from './szamlaReadModel';
export * from './szemely';
export * from './szemely4T';
export * from './szemelyAlapadatRequest';
export * from './szemelyAttekinto';
export * from './szemelyCimRequest';
export * from './szemelyCsekkesFizetesek';
export * from './szemelyDetails';
export * from './szemelyEllenorzes';
export * from './szemelyEltiltas';
export * from './szemelyEltiltasokDetails';
export * from './szemelyGondviseloDuplication';
export * from './szemelyJegy';
export * from './szemelyJogszabalyiEltiltas';
export * from './szemelyKepesitesRequest';
export * from './szemelyKepesitesTorlesRequest';
export * from './szemelyKepesitesek';
export * from './szemelyKeresesRequest';
export * from './szemelyKeresesResponse';
export * from './szemelyKezbesites';
export * from './szemelyKezbesitesResponse';
export * from './szemelyNyilatkozatok';
export * from './szemelyProfilkepRequest';
export * from './szemelyRequest';
export * from './szemelySearchRequest';
export * from './szemelyTeruletiEltiltas';
export * from './szemelyTulajdonsagok';
export * from './szemelyVerseny';
export * from './szemelyVersenyRequest';
export * from './szemelyVizsgaMozgatasRequest';
export * from './szemelyVizsgakOverride';
export * from './szemelyVizsgakOverrideRequest';
export * from './szemelyVizteruletEltiltas';
export * from './szereploAllamiJegyKeszletezoInfo';
export * from './szereploCimzett';
export * from './szereploFizetesMozgatasRequest';
export * from './szereploFizetesSzamla';
export * from './szereploKapcsolasRequest';
export * from './szereploKeresesRequest';
export * from './szereploKeresesResponse';
export * from './szereploRequest';
export * from './szereploUzenetKezbesitesFilter';
export * from './szervezet';
export * from './szervezetAdapter';
export * from './szervezetDetails';
export * from './szervezetFilter';
export * from './szervezetKeresoTalalat';
export * from './szervezetLetrehozasRequest';
export * from './szervezetLogoBeallitasRequest';
export * from './szervezetMgmtSearchItem';
export * from './szervezetModositasRequest';
export * from './szervezetOsszesites';
export * from './szervezetOsszesitesItem';
export * from './szervezetRequest';
export * from './szervezetTorzsadatRequest';
export * from './szervezetVizteruletCsoportRequest';
export * from './szerzodes';
export * from './szerzodesDetails';
export * from './szerzodesErtekesitesLimit';
export * from './szerzodesErtekesitesLimitFilter';
export * from './szerzodesFilter';
export * from './szerzodesHierarchiaSzervezet';
export * from './szerzodesLetrehozRequest';
export * from './szerzodesMegbizottSzervezetRequest';
export * from './szerzodesModositRequest';
export * from './szerzodesRequest';
export * from './szerzodesTorlesRequest';
export * from './szolgaltatasElutasitasRequest';
export * from './szolgaltatasFilter';
export * from './szolgaltatasListItem';
export * from './szolgaltatasRegisztracioBiralat';
export * from './szolgaltatasRequest';
export * from './szolgaltato';
export * from './szolgaltatoAlapadat';
export * from './szolgaltatoLetrehozasRequest';
export * from './sztornozandoSzamla';
export * from './tagdijBefizetes';
export * from './teendoFeladat';
export * from './teendok';
export * from './telefonosAdatEllenorzes';
export * from './telefonosAzonositasRogzitesRequest';
export * from './telepules';
export * from './termek';
export * from './termekMozgatasRequest';
export * from './termekTetelAr';
export * from './teruletiJegy';
export * from './teruletiJegyDefaultKozvetlenJutalekRequest';
export * from './teruletiJegyElszamolas';
export * from './teruletiJegyElszamolasFilter';
export * from './teruletiJegyElszamolasItem';
export * from './teruletiJegyElszamolasTobbSzerepkorTorzsadat';
export * from './teruletiJegyElszamolasTorzsadat';
export * from './teruletiJegyElszamolasVizteruletItem';
export * from './teruletiJegyErtekesites';
export * from './teruletiJegyErtekesitesFilter';
export * from './teruletiJegyJutalek';
export * from './teruletiJegyKozvetlenJutalek';
export * from './teruletiJegyKozvetlenJutalekRequest';
export * from './teruletiJegyMohoszOsszesites';
export * from './teruletiJegyOnlineSztornoDetails';
export * from './teruletiJegyOnlineSztornoRequest';
export * from './teruletiJegyOsszesitesItem';
export * from './teruletiJegyRequest';
export * from './teruletiJegyRiport';
export * from './teruletiJegyRiportDetails';
export * from './teruletiJegyRiportFilter';
export * from './teruletiJegySzervezetOsszesitesItem';
export * from './teruletiJegyTermek';
export * from './teruletiJegyTermekDetails';
export * from './teruletiJegyTermekDetailsRequest';
export * from './teruletiJegyTermekFilter';
export * from './teruletiJegyTermekHorgaszrendModositasRequest';
export * from './teruletiJegyTermekIdoszakErvenyesseg';
export * from './teruletiJegyTermekKiegeszitoTorzsadat';
export * from './teruletiJegyTermekKiegeszitoTorzsadatBundle';
export * from './teruletiJegyTermekKombinacio';
export * from './teruletiJegyTermekKombinacioFilter';
export * from './teruletiJegyTermekLeiras';
export * from './teruletiJegyTermekLeirasModositasRequest';
export * from './teruletiJegyTermekLetrehozasRequest';
export * from './teruletiJegyTermekMasolasRequest';
export * from './teruletiJegyTermekMatrix';
export * from './teruletiJegyTermekModositasRequest';
export * from './teruletiJegyTermekRequest';
export * from './teruletiJegyTermekVizterulet';
export * from './teruletiJegyVasarlasForecast';
export * from './teruletiJegyVasarlasForecastRequest';
export * from './teruletiJegyVasarlasRequest';
export * from './teruletiKiegeszitoJegy';
export * from './teruletiKiegeszitoJegyRequest';
export * from './teruletiKiegeszitoJegyRiport';
export * from './teruletiKiegeszitoJegyTermek';
export * from './teruletiKiegeszitoJegyTermekBeallitasRequest';
export * from './teruletiKiegeszitoJegyTermekId';
export * from './teruletiKiegeszitoJegyTermekVasarlas';
export * from './teruletiKiegeszitoJegyVasarlasForecast';
export * from './teruletiKiegeszitoJegyVasarlasForecastRequest';
export * from './teruletiKiegeszitoJegyVasarlasRequest';
export * from './torzsadatBundle';
export * from './transactionResult';
export * from './turistaAllamiJegyArReply';
export * from './turistaAllamiJegyVasarlasRequest';
export * from './turistaFogasiNaploLeadasRequest';
export * from './ugyintezoiSzemelyAlapadatRequest';
export * from './ujragyartasIgenylesLetrehozasRequest';
export * from './ujragyartasIgenylesModositasRequest';
export * from './updateFogasRequest';
export * from './updateFogasResponse';
export * from './uzenet';
export * from './uzenetCimzett';
export * from './uzenetDetails';
export * from './uzenetElvegezveRequest';
export * from './uzenetFilterRequest';
export * from './uzenetKezbesitesRequest';
export * from './uzenetLetrehozasRequest';
export * from './uzenetModositasRequest';
export * from './uzenetRequest';
export * from './valasz';
export * from './valaszKiertekeles';
export * from './valaszOpcio';
export * from './valaszthatoTorzsadatRequest';
export * from './validateEmailRequest';
export * from './valosBefizetoAzonositoRogzitesRequest';
export * from './vasarlasEllenorzesReply';
export * from './vasarlasEllenorzesRequest';
export * from './vasarlasiElozmeny';
export * from './vasarlasiElozmenyTermekDetails';
export * from './vasarlasiElozmenyTeruletDetails';
export * from './vedettVizterulet';
export * from './veglegesitesRequest';
export * from './verseny';
export * from './versenyCsapatResztvevo';
export * from './versenyDetails';
export * from './versenyEgyeniResztvevo';
export * from './versenyFilter';
export * from './versenyJelentkezesAthelyezesRequest';
export * from './versenyJelentkezesRequest';
export * from './versenyKapcsolattartasModositasRequest';
export * from './versenyLetrehozasRequest';
export * from './versenyModositasRequest';
export * from './versenyNevezesFilter';
export * from './versenyNevezesFizetesInditasRequest';
export * from './versenyRequest';
export * from './versenyUrlReply';
export * from './versenyUrlRequest';
export * from './versenyzo';
export * from './versenyzoiEngedely';
export * from './versenyzoiEngedelyArReply';
export * from './versenyzoiEngedelyArlista';
export * from './versenyzoiEngedelyArlistaCloneRequest';
export * from './versenyzoiEngedelyArlistaFilter';
export * from './versenyzoiEngedelyArlistaLetrehozRequest';
export * from './versenyzoiEngedelyArlistaModositRequest';
export * from './versenyzoiEngedelyArlistaTetel';
export * from './versenyzoiEngedelyErtekesites';
export * from './versenyzoiEngedelyErtekesitesFilter';
export * from './versenyzoiEngedelyErtekesitesTorzsadatRequest';
export * from './versenyzoiEngedelyRequest';
export * from './versenyzoiEngedelyVasarlasRequest';
export * from './vizetuletSzemelyEltiltasokDetails';
export * from './vizsgaBefejezesRequest';
export * from './vizsgaElozmeny';
export * from './vizsgaEredmeny';
export * from './vizsgaInditasRequest';
export * from './vizsgaInditasResponse';
export * from './vizsgaKerdesValaszRequest';
export * from './vizsgaKerdesValaszResponse';
export * from './vizsgaKovetkezoKerdes';
export * from './vizsgaMegszakitasRequest';
export * from './vizsgaOsszesito';
export * from './vizsgaTorlesRequest';
export * from './vizsgaTortenet';
export * from './vizsgaTortenetRequest';
export * from './vizsgabizottsag';
export * from './vizsgabizottsagLetrehozasRequest';
export * from './vizsgabizottsagModositasRequest';
export * from './vizsgabizottsagTag';
export * from './vizsgabizottsagTagRequest';
export * from './vizsgabizottsagVizsga';
export * from './vizsgabizottsagVizsgaContext';
export * from './vizsgabizottsagVizsgaFilterRequest';
export * from './vizsgabizottsagVizsgaJegyzokonyvUploadRequest';
export * from './vizsgabizottsagVizsgaRequest';
export * from './vizsgabizottsagVizsgaTag';
export * from './vizsgabizottsagVizsgaTagJegyzokonyvKitoltesRequest';
export * from './vizsgabizottsagVizsgahelyLetrehozasRequest';
export * from './vizsgahely';
export * from './vizsgahelyLetrehozasRequest';
export * from './vizsgahelyModositasRequest';
export * from './vizsgahelyRequest';
export * from './vizsgaidopont';
export * from './vizsgaidopontJelentkezesRequest';
export * from './vizsgaidopontKeresesRequest';
export * from './vizsgaidopontLetrehozasRequest';
export * from './vizsgaidopontModositasRequest';
export * from './vizsgaidopontRequest';
export * from './vizsgaidopontSzemelyRequest';
export * from './vizsgaraJelentkezett';
export * from './vizsgaraJelentkezettJegyzokonyv';
export * from './vizsgaraJelentkezettJegyzokonyvKitoltesRequest';
export * from './vizsgaraJelentkezettRequest';
export * from './vizsgaztatasRiport';
export * from './vizsgaztatasRiportElszamolas';
export * from './vizsgaztatasRiportElszamolasItem';
export * from './vizsgaztatasRiportFilter';
export * from './vizsgaztatasTorzsadat';
export * from './vizsgaztatasTorzsadatRequest';
export * from './vizsgaztatoSzervezetekRiport';
export * from './vizsgaztatoSzervezetekRiportReply';
export * from './vizsgaztatoSzervezetekRiportRequest';
export * from './vizterulet';
export * from './vizteruletCsoport';
export * from './vizteruletCsoportDetails';
export * from './vizteruletCsoportLeiras';
export * from './vizteruletCsoportLeirasModositasRequest';
export * from './vizteruletCsoportLetrehozasRequest';
export * from './vizteruletCsoportListazasAlapadat';
export * from './vizteruletCsoportListazasResponse';
export * from './vizteruletCsoportModositasRequest';
export * from './vizteruletCsoportRequest';
export * from './vizteruletCsoportZarvatartas';
export * from './vizteruletCsoportZarvatartasModositasRequest';
export * from './vizteruletCsoportZarvatartasRogitesRequest';
export * from './vizteruletCsoportZarvatartasTorlesRequest';
export * from './vizteruletEltiltas';
export * from './vizteruletEltiltasFeloldasRequest';
export * from './vizteruletEltiltasFilter';
export * from './vizteruletEltiltasKezelesResponse';
export * from './vizteruletEltiltasModositasRequest';
export * from './vizteruletEltiltasRogzitesRequest';
export * from './vizteruletFilter';
export * from './vizteruletHalgazdalkodo';
export * from './vizteruletHorgaszaiRequest';
export * from './vizteruletLetrehozasRequest';
export * from './vizteruletModositasRequest';
export * from './vizteruletRequest';
export * from './vizteruletSzemelyEltiltasokKeresesRequest';
export * from './vizteruletTorzsadat';
export * from './vizteruletTulajdonsag';
export * from './vizteruletView';
