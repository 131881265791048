/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AdatEllenorzes } from '../model/adatEllenorzes';
import { AdatEllenorzesAssignLabelsRequest } from '../model/adatEllenorzesAssignLabelsRequest';
import { AdatEllenorzesFilter } from '../model/adatEllenorzesFilter';
import { AdatvaltozasEllenorzesLetrehozasRequest } from '../model/adatvaltozasEllenorzesLetrehozasRequest';
import { AdatvaltozasEllenorzesRendbenRequest } from '../model/adatvaltozasEllenorzesRendbenRequest';
import { Nyilatkozat } from '../model/nyilatkozat';
import { PersonalizedAdatEllenorzes } from '../model/personalizedAdatEllenorzes';
import { SzemelyAlapadatRequest } from '../model/szemelyAlapadatRequest';
import { SzemelyGondviseloDuplication } from '../model/szemelyGondviseloDuplication';
import { SzemelyRequest } from '../model/szemelyRequest';
import { TelefonosAzonositasRogzitesRequest } from '../model/telefonosAzonositasRogzitesRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AdatEllenorzesControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * adatvaltozasEllenorzesLetrehozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adatvaltozasEllenorzesLetrehozasUsingPOST(body: AdatvaltozasEllenorzesLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<Nyilatkozat>;
    public adatvaltozasEllenorzesLetrehozasUsingPOST(body: AdatvaltozasEllenorzesLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Nyilatkozat>>;
    public adatvaltozasEllenorzesLetrehozasUsingPOST(body: AdatvaltozasEllenorzesLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Nyilatkozat>>;
    public adatvaltozasEllenorzesLetrehozasUsingPOST(body: AdatvaltozasEllenorzesLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling adatvaltozasEllenorzesLetrehozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Nyilatkozat>('post',`${this.basePath}/api/mohosz/adatellenorzes/adatvaltozasellenorzes-letrehozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * adatvaltozasEllenorzesRendben
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adatvaltozasEllenorzesRendbenUsingPOST(body: AdatvaltozasEllenorzesRendbenRequest, observe?: 'body', reportProgress?: boolean): Observable<AdatEllenorzes>;
    public adatvaltozasEllenorzesRendbenUsingPOST(body: AdatvaltozasEllenorzesRendbenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdatEllenorzes>>;
    public adatvaltozasEllenorzesRendbenUsingPOST(body: AdatvaltozasEllenorzesRendbenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdatEllenorzes>>;
    public adatvaltozasEllenorzesRendbenUsingPOST(body: AdatvaltozasEllenorzesRendbenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling adatvaltozasEllenorzesRendbenUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AdatEllenorzes>('post',`${this.basePath}/api/mohosz/adatellenorzes/adatvaltozasellenorzes-rendben`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * adatvaltozasEllenorzesSzukseges
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adatvaltozasEllenorzesSzuksegesUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public adatvaltozasEllenorzesSzuksegesUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public adatvaltozasEllenorzesSzuksegesUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public adatvaltozasEllenorzesSzuksegesUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling adatvaltozasEllenorzesSzuksegesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/mohosz/adatellenorzes/adatvaltozasellenorzes-szukseges`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * assignLabels
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assignLabelsUsingPOST(body: AdatEllenorzesAssignLabelsRequest, observe?: 'body', reportProgress?: boolean): Observable<PersonalizedAdatEllenorzes>;
    public assignLabelsUsingPOST(body: AdatEllenorzesAssignLabelsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PersonalizedAdatEllenorzes>>;
    public assignLabelsUsingPOST(body: AdatEllenorzesAssignLabelsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PersonalizedAdatEllenorzes>>;
    public assignLabelsUsingPOST(body: AdatEllenorzesAssignLabelsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling assignLabelsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PersonalizedAdatEllenorzes>('post',`${this.basePath}/api/mohosz/adatellenorzes/assign-labels`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * duplicationCandidateList
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public duplicationCandidateListUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<SzemelyGondviseloDuplication>;
    public duplicationCandidateListUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SzemelyGondviseloDuplication>>;
    public duplicationCandidateListUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SzemelyGondviseloDuplication>>;
    public duplicationCandidateListUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling duplicationCandidateListUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SzemelyGondviseloDuplication>('post',`${this.basePath}/api/mohosz/adatellenorzes/szemely-duplication-candidates`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchAdatEllenorzesListCount
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchAdatEllenorzesListCountUsingPOST(body: AdatEllenorzesFilter, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public fetchAdatEllenorzesListCountUsingPOST(body: AdatEllenorzesFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public fetchAdatEllenorzesListCountUsingPOST(body: AdatEllenorzesFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public fetchAdatEllenorzesListCountUsingPOST(body: AdatEllenorzesFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fetchAdatEllenorzesListCountUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/adatellenorzes/findall-adatellenorzes-count`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findAdatEllenorzes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAdatEllenorzesUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<PersonalizedAdatEllenorzes>;
    public findAdatEllenorzesUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PersonalizedAdatEllenorzes>>;
    public findAdatEllenorzesUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PersonalizedAdatEllenorzes>>;
    public findAdatEllenorzesUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findAdatEllenorzesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PersonalizedAdatEllenorzes>('post',`${this.basePath}/api/mohosz/adatellenorzes/find-adatellenorzes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findAllAdatEllenorzesList
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAllAdatEllenorzesListUsingPOST(body: AdatEllenorzesFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<PersonalizedAdatEllenorzes>>;
    public findAllAdatEllenorzesListUsingPOST(body: AdatEllenorzesFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PersonalizedAdatEllenorzes>>>;
    public findAllAdatEllenorzesListUsingPOST(body: AdatEllenorzesFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PersonalizedAdatEllenorzes>>>;
    public findAllAdatEllenorzesListUsingPOST(body: AdatEllenorzesFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findAllAdatEllenorzesListUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PersonalizedAdatEllenorzes>>('post',`${this.basePath}/api/mohosz/adatellenorzes/findall-adatellenorzes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findAllLAbels
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAllLAbelsUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public findAllLAbelsUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public findAllLAbelsUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public findAllLAbelsUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('post',`${this.basePath}/api/mohosz/adatellenorzes/findall-labels`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * telefonosAzonositasRogzites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public telefonosAzonositasRogzitesUsingPOST(body: TelefonosAzonositasRogzitesRequest, observe?: 'body', reportProgress?: boolean): Observable<AdatEllenorzes>;
    public telefonosAzonositasRogzitesUsingPOST(body: TelefonosAzonositasRogzitesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdatEllenorzes>>;
    public telefonosAzonositasRogzitesUsingPOST(body: TelefonosAzonositasRogzitesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdatEllenorzes>>;
    public telefonosAzonositasRogzitesUsingPOST(body: TelefonosAzonositasRogzitesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling telefonosAzonositasRogzitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AdatEllenorzes>('post',`${this.basePath}/api/mohosz/adatellenorzes/telefonos-azonositas-rogzites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ugyfelszolgalatiAlapadatModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ugyfelszolgalatiAlapadatModositasUsingPOST(body: SzemelyAlapadatRequest, observe?: 'body', reportProgress?: boolean): Observable<AdatEllenorzes>;
    public ugyfelszolgalatiAlapadatModositasUsingPOST(body: SzemelyAlapadatRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdatEllenorzes>>;
    public ugyfelszolgalatiAlapadatModositasUsingPOST(body: SzemelyAlapadatRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdatEllenorzes>>;
    public ugyfelszolgalatiAlapadatModositasUsingPOST(body: SzemelyAlapadatRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ugyfelszolgalatiAlapadatModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AdatEllenorzes>('post',`${this.basePath}/api/mohosz/adatellenorzes/ugyfelszolgalati-alapadat-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ugyfelszolgalatiJovahagyas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ugyfelszolgalatiJovahagyasUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<AdatEllenorzes>;
    public ugyfelszolgalatiJovahagyasUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdatEllenorzes>>;
    public ugyfelszolgalatiJovahagyasUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdatEllenorzes>>;
    public ugyfelszolgalatiJovahagyasUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ugyfelszolgalatiJovahagyasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AdatEllenorzes>('post',`${this.basePath}/api/mohosz/adatellenorzes/ugyfelszolgalati-jovahagyas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
