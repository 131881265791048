/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Teendok } from './teendok';

export interface Hozzatartozo { 
    eloHorgaszRegisztracio?: boolean;
    gondviselorolLevalaszthato?: boolean;
    horgaszAzonosito?: string;
    keresztnev?: string;
    profilkepId?: number;
    szemelyId?: number;
    teendok?: Teendok;
    vezeteknev?: string;
}