/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Cim } from './cim';
import { Szemely } from './szemely';

export interface HorgaszRegisztracio { 
    adatEllenorzesMiattRegisztracioMegallitva?: boolean;
    allamiJegyVasarlashozKaryaKotelezo?: boolean;
    allandoLakcim?: Cim;
    anyjaNeve?: string;
    bekertTelefonszam?: string;
    eloRegisztracio?: boolean;
    gondviseloMarHorgasz?: boolean;
    gondviseloSzemely?: Szemely;
    gondviseloSzemelyId?: number;
    gondviselorolLevalaszthato?: boolean;
    gyermek?: boolean;
    hianyzoLepesek?: Array<HorgaszRegisztracio.HianyzoLepesekEnum>;
    horgaszAzonosito?: string;
    horgaszBugyellarisStatusz?: HorgaszRegisztracio.HorgaszBugyellarisStatuszEnum;
    keresztnev?: string;
    kezbesitesiCim?: Cim;
    kezbesitesiCimLakcimEgyezes?: boolean;
    kulfoldiAllampolgar?: boolean;
    lehetsegesLepesek?: Array<HorgaszRegisztracio.LehetsegesLepesekEnum>;
    loginId?: string;
    modosithatoGondviseloSzemelyesAdat?: boolean;
    modosithatoRegisztracio?: boolean;
    nem?: HorgaszRegisztracio.NemEnum;
    szemelyId?: number;
    szuletesiDatum?: string;
    szuletesiHely?: string;
    titulus?: HorgaszRegisztracio.TitulusEnum;
    utolsoLepes?: boolean;
    vezeteknev?: string;
}
export namespace HorgaszRegisztracio {
    export type HianyzoLepesekEnum = 'ALAPADAT' | 'FELHASZNALO' | 'GONDVISELO_SZEMELYESADAT' | 'GONDVISELO_FELHASZNALO' | 'CIMEK' | 'FIZETES' | 'VEGLEGESITES';
    export const HianyzoLepesekEnum = {
        ALAPADAT: 'ALAPADAT' as HianyzoLepesekEnum,
        FELHASZNALO: 'FELHASZNALO' as HianyzoLepesekEnum,
        GONDVISELOSZEMELYESADAT: 'GONDVISELO_SZEMELYESADAT' as HianyzoLepesekEnum,
        GONDVISELOFELHASZNALO: 'GONDVISELO_FELHASZNALO' as HianyzoLepesekEnum,
        CIMEK: 'CIMEK' as HianyzoLepesekEnum,
        FIZETES: 'FIZETES' as HianyzoLepesekEnum,
        VEGLEGESITES: 'VEGLEGESITES' as HianyzoLepesekEnum
    };
    export type HorgaszBugyellarisStatuszEnum = 'REGISZTRACIO_FOLYAMATBAN' | 'FIZETES_FOLYAMATBAN' | 'ELO' | 'ADATEGYEZTETESMIATTI_FELFUGGESZTETT_ELO' | 'FELADOVEVENYES_ELO' | 'UGYINTEZOI_JOVAHAGYASRA_VAR' | 'FELFUGGESZTVE';
    export const HorgaszBugyellarisStatuszEnum = {
        REGISZTRACIOFOLYAMATBAN: 'REGISZTRACIO_FOLYAMATBAN' as HorgaszBugyellarisStatuszEnum,
        FIZETESFOLYAMATBAN: 'FIZETES_FOLYAMATBAN' as HorgaszBugyellarisStatuszEnum,
        ELO: 'ELO' as HorgaszBugyellarisStatuszEnum,
        ADATEGYEZTETESMIATTIFELFUGGESZTETTELO: 'ADATEGYEZTETESMIATTI_FELFUGGESZTETT_ELO' as HorgaszBugyellarisStatuszEnum,
        FELADOVEVENYESELO: 'FELADOVEVENYES_ELO' as HorgaszBugyellarisStatuszEnum,
        UGYINTEZOIJOVAHAGYASRAVAR: 'UGYINTEZOI_JOVAHAGYASRA_VAR' as HorgaszBugyellarisStatuszEnum,
        FELFUGGESZTVE: 'FELFUGGESZTVE' as HorgaszBugyellarisStatuszEnum
    };
    export type LehetsegesLepesekEnum = 'ALAPADAT' | 'FELHASZNALO' | 'GONDVISELO_SZEMELYESADAT' | 'GONDVISELO_FELHASZNALO' | 'CIMEK' | 'FIZETES' | 'VEGLEGESITES';
    export const LehetsegesLepesekEnum = {
        ALAPADAT: 'ALAPADAT' as LehetsegesLepesekEnum,
        FELHASZNALO: 'FELHASZNALO' as LehetsegesLepesekEnum,
        GONDVISELOSZEMELYESADAT: 'GONDVISELO_SZEMELYESADAT' as LehetsegesLepesekEnum,
        GONDVISELOFELHASZNALO: 'GONDVISELO_FELHASZNALO' as LehetsegesLepesekEnum,
        CIMEK: 'CIMEK' as LehetsegesLepesekEnum,
        FIZETES: 'FIZETES' as LehetsegesLepesekEnum,
        VEGLEGESITES: 'VEGLEGESITES' as LehetsegesLepesekEnum
    };
    export type NemEnum = 'NINCS_MEGADVA' | 'NO' | 'FERFI';
    export const NemEnum = {
        NINCSMEGADVA: 'NINCS_MEGADVA' as NemEnum,
        NO: 'NO' as NemEnum,
        FERFI: 'FERFI' as NemEnum
    };
    export type TitulusEnum = 'dr' | 'id' | 'ifj' | 'özv' | 'prof';
    export const TitulusEnum = {
        Dr: 'dr' as TitulusEnum,
        Id: 'id' as TitulusEnum,
        Ifj: 'ifj' as TitulusEnum,
        Zv: 'özv' as TitulusEnum,
        Prof: 'prof' as TitulusEnum
    };
}