import {Component} from '@angular/core';

import {Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AuthenticationService} from './services/authentication.service';
import {Router, RouterEvent} from '@angular/router';
import {MegbizoDetails} from 'src/api';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  loggedIn = false;
  hasValasztottVizterulet = false;
  addedValasztoMenu = false;
  megbizok: MegbizoDetails[] = [];

  public appLoggedInPages = [
    {
      title: 'Vízterület választó',
      url: '/members/vizteruletek',
      icon: ''
    }
  ];

  public appHasVizteruletPages = [
    {
      title: 'Ellenőrzés',
      url: '/members/ellenorzes',
      icon: ''
    },
    {
      title: 'Előzmények',
      url: '/members/ellenorzesek',
      icon: ''
    },
    {
      title: 'Vízterület választó',
      url: '/members/vizteruletek',
      icon: ''
    }
  ];

  public appLoggedOutPages = [
    {
      title: 'Bejelentkezés',
      url: '/login',
      icon: ''
    },
  ];
  selectedPath = '';

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.initializeApp();
  }

  logout() {
    this.authenticationService.logout();
  }

  async initializeApp() {
    this.platform.ready().then(() => {
      //this.statusBar.styleDefault();
      //this.statusBar.hide();
      //this.statusBar.overlaysWebView(true);
      this.splashScreen.hide();
    });
    this.router.events.subscribe((event: RouterEvent) => {
      this.selectedPath = event.url;
    });
    this.authenticationService.sssUser.subscribe(async sssUser => {
      if (sssUser && !sssUser.trusteeId) {
        this.loggedIn = false;
        this.router.navigate(['login']);
      }
      if (sssUser && sssUser.trusteeId && !sssUser.entrustingId && !sssUser.selectedVizterulet) {
        this.loggedIn = true;
        this.hasValasztottVizterulet = false;
        this.router.navigate(['members', 'vizteruletek']);
      }
      if (sssUser && sssUser.trusteeId && sssUser.entrustingId !== null && sssUser.selectedVizterulet) {
        this.loggedIn = true;
        this.hasValasztottVizterulet = true;
        this.router.navigate(['members', 'ellenorzes']);
      }
      if (sssUser && sssUser.trusteeId && sssUser.entrustingId !== null && !sssUser.selectedVizterulet) {
        this.loggedIn = true;
        this.hasValasztottVizterulet = false;
        this.router.navigate(['members', 'vizteruletek']);
      }
    });
  }

  navigateDashboard() {
    this.router.navigate(['members', 'dashboard'], { state: { megbizok: this.megbizok } });
  }


}
