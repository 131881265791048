/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AllamiVizsga } from '../model/allamiVizsga';
import { SzemelyRequest } from '../model/szemelyRequest';
import { VizsgaBefejezesRequest } from '../model/vizsgaBefejezesRequest';
import { VizsgaInditasRequest } from '../model/vizsgaInditasRequest';
import { VizsgaInditasResponse } from '../model/vizsgaInditasResponse';
import { VizsgaKerdesValaszRequest } from '../model/vizsgaKerdesValaszRequest';
import { VizsgaKerdesValaszResponse } from '../model/vizsgaKerdesValaszResponse';
import { VizsgaMegszakitasRequest } from '../model/vizsgaMegszakitasRequest';
import { VizsgaOsszesito } from '../model/vizsgaOsszesito';
import { VizsgabizottsagVizsga } from '../model/vizsgabizottsagVizsga';
import { VizsgaidopontJelentkezesRequest } from '../model/vizsgaidopontJelentkezesRequest';
import { VizsgaidopontKeresesRequest } from '../model/vizsgaidopontKeresesRequest';
import { VizsgaidopontSzemelyRequest } from '../model/vizsgaidopontSzemelyRequest';
import { VizsgaraJelentkezettRequest } from '../model/vizsgaraJelentkezettRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OnlineVizsgaControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * allamiVizsgaBetoltes
     * 
     * @param body id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public allamiVizsgaBetoltesUsingPOST(body: number, observe?: 'body', reportProgress?: boolean): Observable<VizsgabizottsagVizsga>;
    public allamiVizsgaBetoltesUsingPOST(body: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgabizottsagVizsga>>;
    public allamiVizsgaBetoltesUsingPOST(body: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgabizottsagVizsga>>;
    public allamiVizsgaBetoltesUsingPOST(body: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling allamiVizsgaBetoltesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgabizottsagVizsga>('post',`${this.basePath}/api/mohosz/onlinevizsga/allamivizsga-betoltes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * allamiVizsgaFolytatas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public allamiVizsgaFolytatasUsingPOST(body: VizsgaraJelentkezettRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgaInditasResponse>;
    public allamiVizsgaFolytatasUsingPOST(body: VizsgaraJelentkezettRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgaInditasResponse>>;
    public allamiVizsgaFolytatasUsingPOST(body: VizsgaraJelentkezettRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgaInditasResponse>>;
    public allamiVizsgaFolytatasUsingPOST(body: VizsgaraJelentkezettRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling allamiVizsgaFolytatasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgaInditasResponse>('post',`${this.basePath}/api/mohosz/onlinevizsga/allamivizsga-folytatas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * allamiVizsgaInditas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public allamiVizsgaInditasUsingPOST(body: VizsgaraJelentkezettRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgaInditasResponse>;
    public allamiVizsgaInditasUsingPOST(body: VizsgaraJelentkezettRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgaInditasResponse>>;
    public allamiVizsgaInditasUsingPOST(body: VizsgaraJelentkezettRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgaInditasResponse>>;
    public allamiVizsgaInditasUsingPOST(body: VizsgaraJelentkezettRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling allamiVizsgaInditasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgaInditasResponse>('post',`${this.basePath}/api/mohosz/onlinevizsga/allamivizsga-inditas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * allamiVizsgaJelentkezes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public allamiVizsgaJelentkezesUsingPOST(body: VizsgaidopontJelentkezesRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgabizottsagVizsga>;
    public allamiVizsgaJelentkezesUsingPOST(body: VizsgaidopontJelentkezesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgabizottsagVizsga>>;
    public allamiVizsgaJelentkezesUsingPOST(body: VizsgaidopontJelentkezesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgabizottsagVizsga>>;
    public allamiVizsgaJelentkezesUsingPOST(body: VizsgaidopontJelentkezesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling allamiVizsgaJelentkezesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgabizottsagVizsga>('post',`${this.basePath}/api/mohosz/onlinevizsga/allamivizsga-jelentkezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * allamiVizsgaKereses
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public allamiVizsgaKeresesUsingPOST(body: VizsgaidopontKeresesRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<VizsgabizottsagVizsga>>;
    public allamiVizsgaKeresesUsingPOST(body: VizsgaidopontKeresesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VizsgabizottsagVizsga>>>;
    public allamiVizsgaKeresesUsingPOST(body: VizsgaidopontKeresesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VizsgabizottsagVizsga>>>;
    public allamiVizsgaKeresesUsingPOST(body: VizsgaidopontKeresesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling allamiVizsgaKeresesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<VizsgabizottsagVizsga>>('post',`${this.basePath}/api/mohosz/onlinevizsga/allamivizsga-kereses`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * allamiVizsgaLemondas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public allamiVizsgaLemondasUsingPOST(body: VizsgaidopontSzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<AllamiVizsga>;
    public allamiVizsgaLemondasUsingPOST(body: VizsgaidopontSzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllamiVizsga>>;
    public allamiVizsgaLemondasUsingPOST(body: VizsgaidopontSzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllamiVizsga>>;
    public allamiVizsgaLemondasUsingPOST(body: VizsgaidopontSzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling allamiVizsgaLemondasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AllamiVizsga>('post',`${this.basePath}/api/mohosz/onlinevizsga/allamivizsga-lemondas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * gyakorloMegszakitas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gyakorloMegszakitasUsingPOST(body: VizsgaMegszakitasRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public gyakorloMegszakitasUsingPOST(body: VizsgaMegszakitasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public gyakorloMegszakitasUsingPOST(body: VizsgaMegszakitasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public gyakorloMegszakitasUsingPOST(body: VizsgaMegszakitasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling gyakorloMegszakitasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/onlinevizsga/gyakorlovizsga-megszakitas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * gyakorloValasz
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gyakorloValaszUsingPOST(body: VizsgaKerdesValaszRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgaKerdesValaszResponse>;
    public gyakorloValaszUsingPOST(body: VizsgaKerdesValaszRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgaKerdesValaszResponse>>;
    public gyakorloValaszUsingPOST(body: VizsgaKerdesValaszRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgaKerdesValaszResponse>>;
    public gyakorloValaszUsingPOST(body: VizsgaKerdesValaszRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling gyakorloValaszUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgaKerdesValaszResponse>('post',`${this.basePath}/api/mohosz/onlinevizsga/gyakorlovizsga-valasz`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * gyakorloVizsgaBefejezes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gyakorloVizsgaBefejezesUsingPOST(body: VizsgaBefejezesRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgaKerdesValaszResponse>;
    public gyakorloVizsgaBefejezesUsingPOST(body: VizsgaBefejezesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgaKerdesValaszResponse>>;
    public gyakorloVizsgaBefejezesUsingPOST(body: VizsgaBefejezesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgaKerdesValaszResponse>>;
    public gyakorloVizsgaBefejezesUsingPOST(body: VizsgaBefejezesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling gyakorloVizsgaBefejezesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgaKerdesValaszResponse>('post',`${this.basePath}/api/mohosz/onlinevizsga/gyakorlovizsga-befejezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * gyakorloVizsgaInditas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gyakorloVizsgaInditasUsingPOST(body: VizsgaInditasRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgaInditasResponse>;
    public gyakorloVizsgaInditasUsingPOST(body: VizsgaInditasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgaInditasResponse>>;
    public gyakorloVizsgaInditasUsingPOST(body: VizsgaInditasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgaInditasResponse>>;
    public gyakorloVizsgaInditasUsingPOST(body: VizsgaInditasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling gyakorloVizsgaInditasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgaInditasResponse>('post',`${this.basePath}/api/mohosz/onlinevizsga/gyakorlovizsga-inditas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kapcsolattartasModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kapcsolattartasModositasUsingPOST(body: VizsgaidopontJelentkezesRequest, observe?: 'body', reportProgress?: boolean): Observable<AllamiVizsga>;
    public kapcsolattartasModositasUsingPOST(body: VizsgaidopontJelentkezesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllamiVizsga>>;
    public kapcsolattartasModositasUsingPOST(body: VizsgaidopontJelentkezesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllamiVizsga>>;
    public kapcsolattartasModositasUsingPOST(body: VizsgaidopontJelentkezesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kapcsolattartasModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AllamiVizsga>('post',`${this.basePath}/api/mohosz/onlinevizsga/allamivizsga-kapcsolattartas-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * turistaVizsgaInditas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public turistaVizsgaInditasUsingPOST(body: VizsgaInditasRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgaInditasResponse>;
    public turistaVizsgaInditasUsingPOST(body: VizsgaInditasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgaInditasResponse>>;
    public turistaVizsgaInditasUsingPOST(body: VizsgaInditasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgaInditasResponse>>;
    public turistaVizsgaInditasUsingPOST(body: VizsgaInditasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling turistaVizsgaInditasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgaInditasResponse>('post',`${this.basePath}/api/mohosz/onlinevizsga/turistavizsga-inditas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaBefejezes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaBefejezesUsingPOST(body: VizsgaBefejezesRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgaKerdesValaszResponse>;
    public vizsgaBefejezesUsingPOST(body: VizsgaBefejezesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgaKerdesValaszResponse>>;
    public vizsgaBefejezesUsingPOST(body: VizsgaBefejezesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgaKerdesValaszResponse>>;
    public vizsgaBefejezesUsingPOST(body: VizsgaBefejezesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizsgaBefejezesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgaKerdesValaszResponse>('post',`${this.basePath}/api/mohosz/onlinevizsga/vizsga-befejezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaMegszakitas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaMegszakitasUsingPOST(body: VizsgaMegszakitasRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public vizsgaMegszakitasUsingPOST(body: VizsgaMegszakitasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public vizsgaMegszakitasUsingPOST(body: VizsgaMegszakitasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public vizsgaMegszakitasUsingPOST(body: VizsgaMegszakitasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizsgaMegszakitasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/onlinevizsga/vizsga-megszakitas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaOsszesito
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaOsszesitoUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgaOsszesito>;
    public vizsgaOsszesitoUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgaOsszesito>>;
    public vizsgaOsszesitoUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgaOsszesito>>;
    public vizsgaOsszesitoUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizsgaOsszesitoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgaOsszesito>('post',`${this.basePath}/api/mohosz/onlinevizsga/onlinevizsga-osszesito`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgaValasz
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgaValaszUsingPOST(body: VizsgaKerdesValaszRequest, observe?: 'body', reportProgress?: boolean): Observable<VizsgaKerdesValaszResponse>;
    public vizsgaValaszUsingPOST(body: VizsgaKerdesValaszRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizsgaKerdesValaszResponse>>;
    public vizsgaValaszUsingPOST(body: VizsgaKerdesValaszRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizsgaKerdesValaszResponse>>;
    public vizsgaValaszUsingPOST(body: VizsgaKerdesValaszRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizsgaValaszUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizsgaKerdesValaszResponse>('post',`${this.basePath}/api/mohosz/onlinevizsga/vizsga-valasz`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizsgabizonyitvanyNyomtatas
     * 
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizsgabizonyitvanyNyomtatasUsingGET(request: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public vizsgabizonyitvanyNyomtatasUsingGET(request: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public vizsgabizonyitvanyNyomtatasUsingGET(request: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public vizsgabizonyitvanyNyomtatasUsingGET(request: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling vizsgabizonyitvanyNyomtatasUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (request !== undefined && request !== null) {
            queryParameters = queryParameters.set('request', <any>request);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/onlinevizsga/allamivizsga-vizsgabizonyitvany-nyomtatas`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
