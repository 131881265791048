/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AlapadatRequest } from '../model/alapadatRequest';
import { CimekRequest } from '../model/cimekRequest';
import { FelhasznaloRequest } from '../model/felhasznaloRequest';
import { GondviseloFelhasznaloRequest } from '../model/gondviseloFelhasznaloRequest';
import { GondviseloMegadasRequest } from '../model/gondviseloMegadasRequest';
import { GondviseloSzemelyesAdatRequest } from '../model/gondviseloSzemelyesAdatRequest';
import { HorgaszRegisztracio } from '../model/horgaszRegisztracio';
import { LoginIdJavaslat } from '../model/loginIdJavaslat';
import { Nyilatkozat } from '../model/nyilatkozat';
import { SzemelyAttekinto } from '../model/szemelyAttekinto';
import { SzemelyRequest } from '../model/szemelyRequest';
import { SzereploRequest } from '../model/szereploRequest';
import { VeglegesitesRequest } from '../model/veglegesitesRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UgyintezoiHorgaszRegisztracioControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * alapadat
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public alapadatUsingPOST(body: AlapadatRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszRegisztracio>;
    public alapadatUsingPOST(body: AlapadatRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszRegisztracio>>;
    public alapadatUsingPOST(body: AlapadatRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszRegisztracio>>;
    public alapadatUsingPOST(body: AlapadatRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling alapadatUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszRegisztracio>('post',`${this.basePath}/api/mohosz/ugyintezoi-horgaszregisztracio/alapadat`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * cimek
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cimekUsingPOST(body: CimekRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszRegisztracio>;
    public cimekUsingPOST(body: CimekRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszRegisztracio>>;
    public cimekUsingPOST(body: CimekRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszRegisztracio>>;
    public cimekUsingPOST(body: CimekRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling cimekUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszRegisztracio>('post',`${this.basePath}/api/mohosz/ugyintezoi-horgaszregisztracio/cimek`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * felhasznalo
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public felhasznaloUsingPOST(body: FelhasznaloRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszRegisztracio>;
    public felhasznaloUsingPOST(body: FelhasznaloRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszRegisztracio>>;
    public felhasznaloUsingPOST(body: FelhasznaloRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszRegisztracio>>;
    public felhasznaloUsingPOST(body: FelhasznaloRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling felhasznaloUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszRegisztracio>('post',`${this.basePath}/api/mohosz/ugyintezoi-horgaszregisztracio/felhasznalo`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchHozzatartozoiRegList
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchHozzatartozoiRegListUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<HorgaszRegisztracio>>;
    public fetchHozzatartozoiRegListUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HorgaszRegisztracio>>>;
    public fetchHozzatartozoiRegListUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HorgaszRegisztracio>>>;
    public fetchHozzatartozoiRegListUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fetchHozzatartozoiRegListUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<HorgaszRegisztracio>>('post',`${this.basePath}/api/mohosz/ugyintezoi-horgaszregisztracio/fetchhozzatartozoireglist`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchSzemelyAttekinto
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchSzemelyAttekintoUsingPOST(body: SzereploRequest, observe?: 'body', reportProgress?: boolean): Observable<SzemelyAttekinto>;
    public fetchSzemelyAttekintoUsingPOST(body: SzereploRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SzemelyAttekinto>>;
    public fetchSzemelyAttekintoUsingPOST(body: SzereploRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SzemelyAttekinto>>;
    public fetchSzemelyAttekintoUsingPOST(body: SzereploRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fetchSzemelyAttekintoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SzemelyAttekinto>('post',`${this.basePath}/api/mohosz/ugyintezoi-horgaszregisztracio/fetchszemelyattekinto`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findHorgaszregisztracio
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findHorgaszregisztracioUsingPOST(body: SzereploRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszRegisztracio>;
    public findHorgaszregisztracioUsingPOST(body: SzereploRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszRegisztracio>>;
    public findHorgaszregisztracioUsingPOST(body: SzereploRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszRegisztracio>>;
    public findHorgaszregisztracioUsingPOST(body: SzereploRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findHorgaszregisztracioUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszRegisztracio>('post',`${this.basePath}/api/mohosz/ugyintezoi-horgaszregisztracio/findhorgaszregisztracio`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * gondviseloFelhasznalo
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gondviseloFelhasznaloUsingPOST(body: GondviseloFelhasznaloRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszRegisztracio>;
    public gondviseloFelhasznaloUsingPOST(body: GondviseloFelhasznaloRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszRegisztracio>>;
    public gondviseloFelhasznaloUsingPOST(body: GondviseloFelhasznaloRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszRegisztracio>>;
    public gondviseloFelhasznaloUsingPOST(body: GondviseloFelhasznaloRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling gondviseloFelhasznaloUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszRegisztracio>('post',`${this.basePath}/api/mohosz/ugyintezoi-horgaszregisztracio/gondviselo-felhasznalo`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * gondviseloSzemelyesAdat
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gondviseloSzemelyesAdatUsingPOST(body: GondviseloSzemelyesAdatRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszRegisztracio>;
    public gondviseloSzemelyesAdatUsingPOST(body: GondviseloSzemelyesAdatRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszRegisztracio>>;
    public gondviseloSzemelyesAdatUsingPOST(body: GondviseloSzemelyesAdatRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszRegisztracio>>;
    public gondviseloSzemelyesAdatUsingPOST(body: GondviseloSzemelyesAdatRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling gondviseloSzemelyesAdatUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszRegisztracio>('post',`${this.basePath}/api/mohosz/ugyintezoi-horgaszregisztracio/gondviselo-szemelyesadat`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * gondviselo
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gondviseloUsingPOST(body: GondviseloMegadasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszRegisztracio>;
    public gondviseloUsingPOST(body: GondviseloMegadasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszRegisztracio>>;
    public gondviseloUsingPOST(body: GondviseloMegadasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszRegisztracio>>;
    public gondviseloUsingPOST(body: GondviseloMegadasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling gondviseloUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszRegisztracio>('post',`${this.basePath}/api/mohosz/ugyintezoi-horgaszregisztracio/gondviselo`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * gondviselorolLevalasztas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gondviselorolLevalasztasUsingPOST(body: FelhasznaloRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszRegisztracio>;
    public gondviselorolLevalasztasUsingPOST(body: FelhasznaloRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszRegisztracio>>;
    public gondviselorolLevalasztasUsingPOST(body: FelhasznaloRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszRegisztracio>>;
    public gondviselorolLevalasztasUsingPOST(body: FelhasznaloRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling gondviselorolLevalasztasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszRegisztracio>('post',`${this.basePath}/api/mohosz/ugyintezoi-horgaszregisztracio/gondviselorol-levalasztas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * loginIdJavaslat
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginIdJavaslatUsingPOST(body: SzereploRequest, observe?: 'body', reportProgress?: boolean): Observable<LoginIdJavaslat>;
    public loginIdJavaslatUsingPOST(body: SzereploRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LoginIdJavaslat>>;
    public loginIdJavaslatUsingPOST(body: SzereploRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LoginIdJavaslat>>;
    public loginIdJavaslatUsingPOST(body: SzereploRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling loginIdJavaslatUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<LoginIdJavaslat>('post',`${this.basePath}/api/mohosz/ugyintezoi-horgaszregisztracio/loginidjavaslat`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * regisztracioNyilatkozat
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public regisztracioNyilatkozatUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<Nyilatkozat>;
    public regisztracioNyilatkozatUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Nyilatkozat>>;
    public regisztracioNyilatkozatUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Nyilatkozat>>;
    public regisztracioNyilatkozatUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling regisztracioNyilatkozatUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Nyilatkozat>('post',`${this.basePath}/api/mohosz/ugyintezoi-horgaszregisztracio/regisztracio-nyilatkozat`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * uploadCsekkFeladoveveny
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadCsekkFeladovevenyUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public uploadCsekkFeladovevenyUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public uploadCsekkFeladovevenyUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public uploadCsekkFeladovevenyUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling uploadCsekkFeladovevenyUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/ugyintezoi-horgaszregisztracio/upload-csekkfeladoveveny`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * veglegesitesByBoltiElado
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public veglegesitesByBoltiEladoUsingPOST(body: VeglegesitesRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszRegisztracio>;
    public veglegesitesByBoltiEladoUsingPOST(body: VeglegesitesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszRegisztracio>>;
    public veglegesitesByBoltiEladoUsingPOST(body: VeglegesitesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszRegisztracio>>;
    public veglegesitesByBoltiEladoUsingPOST(body: VeglegesitesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling veglegesitesByBoltiEladoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszRegisztracio>('post',`${this.basePath}/api/mohosz/ugyintezoi-horgaszregisztracio/veglegesites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
