/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizteruletCsoportDetails { 
    altalanosTajekoztatas?: string;
    alternativNevList?: Array<string>;
    egyediVizteruletNevek?: string;
    hasOnlineErtekesithetoTermek?: boolean;
    kepIds?: Array<number>;
    leirasKepId?: number;
    letrehozoSzervezetId?: number;
    statusz?: VizteruletCsoportDetails.StatuszEnum;
    szolgaltatasbolLetrehozva?: boolean;
    tovabbiInformaciok?: string;
}
export namespace VizteruletCsoportDetails {
    export type StatuszEnum = 'AKTIV' | 'INAKTIV';
    export const StatuszEnum = {
        AKTIV: 'AKTIV' as StatuszEnum,
        INAKTIV: 'INAKTIV' as StatuszEnum
    };
}