/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizsgaTortenetRequest { 
    szemelyId?: number;
    vizsgaTipus?: VizsgaTortenetRequest.VizsgaTipusEnum;
}
export namespace VizsgaTortenetRequest {
    export type VizsgaTipusEnum = 'TURISTA' | 'ALLAMI';
    export const VizsgaTipusEnum = {
        TURISTA: 'TURISTA' as VizsgaTipusEnum,
        ALLAMI: 'ALLAMI' as VizsgaTipusEnum
    };
}