/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { HorgaszkartyaIgenyles } from './horgaszkartyaIgenyles';

export interface HorgaszkartyaIgenylesKombinacio { 
    gyartasMuvelet?: HorgaszkartyaIgenylesKombinacio.GyartasMuveletEnum;
    horgaszkartyaTipus?: HorgaszkartyaIgenylesKombinacio.HorgaszkartyaTipusEnum;
    igenylesOkList?: Array<HorgaszkartyaIgenylesKombinacio.IgenylesOkListEnum>;
    utolsoIgenyles?: HorgaszkartyaIgenyles;
}
export namespace HorgaszkartyaIgenylesKombinacio {
    export type GyartasMuveletEnum = 'GYARTAS' | 'UJRAGYARTAS';
    export const GyartasMuveletEnum = {
        GYARTAS: 'GYARTAS' as GyartasMuveletEnum,
        UJRAGYARTAS: 'UJRAGYARTAS' as GyartasMuveletEnum
    };
    export type HorgaszkartyaTipusEnum = 'MHK' | 'VIP';
    export const HorgaszkartyaTipusEnum = {
        MHK: 'MHK' as HorgaszkartyaTipusEnum,
        VIP: 'VIP' as HorgaszkartyaTipusEnum
    };
    export type IgenylesOkListEnum = 'ELSO_GYARTAS' | 'TERMESZETES_MODON_MEGVALTOZO_ADAT' | 'KARTYA_HIANY' | 'IGAZOLT_KARTYA_HIANY' | 'ADATHIBA_A_KARTYAN_HORGASZ_HIBAJABOL' | 'ADATHIBA_A_KARTYAN_UGYFELSZOLGALAT_HIBAJABOL' | 'LEJART_KARTYA';
    export const IgenylesOkListEnum = {
        ELSOGYARTAS: 'ELSO_GYARTAS' as IgenylesOkListEnum,
        TERMESZETESMODONMEGVALTOZOADAT: 'TERMESZETES_MODON_MEGVALTOZO_ADAT' as IgenylesOkListEnum,
        KARTYAHIANY: 'KARTYA_HIANY' as IgenylesOkListEnum,
        IGAZOLTKARTYAHIANY: 'IGAZOLT_KARTYA_HIANY' as IgenylesOkListEnum,
        ADATHIBAAKARTYANHORGASZHIBAJABOL: 'ADATHIBA_A_KARTYAN_HORGASZ_HIBAJABOL' as IgenylesOkListEnum,
        ADATHIBAAKARTYANUGYFELSZOLGALATHIBAJABOL: 'ADATHIBA_A_KARTYAN_UGYFELSZOLGALAT_HIBAJABOL' as IgenylesOkListEnum,
        LEJARTKARTYA: 'LEJART_KARTYA' as IgenylesOkListEnum
    };
}