/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AllamiJegyArlistaTetel { 
    ar?: number;
    dijkategoria?: AllamiJegyArlistaTetel.DijkategoriaEnum;
    jogosultsag?: AllamiJegyArlistaTetel.JogosultsagEnum;
    korkategoria?: AllamiJegyArlistaTetel.KorkategoriaEnum;
}
export namespace AllamiJegyArlistaTetel {
    export type DijkategoriaEnum = 'ALAPDIJ' | 'EMELTDIJ';
    export const DijkategoriaEnum = {
        ALAPDIJ: 'ALAPDIJ' as DijkategoriaEnum,
        EMELTDIJ: 'EMELTDIJ' as DijkategoriaEnum
    };
    export type JogosultsagEnum = 'TELJESJOGU' | 'MENTESITETT' | 'NEMKOTELEZETT' | 'MENTESITETT_70EVFELETTI' | 'MENTESITETT_FOGYATEKKALELO' | 'MENTESITETT_GYERMEK' | 'NEMKOTELEZETT_KULFOLDI' | 'NEMKOTELEZETT_NEMMOHOSZTAG';
    export const JogosultsagEnum = {
        TELJESJOGU: 'TELJESJOGU' as JogosultsagEnum,
        MENTESITETT: 'MENTESITETT' as JogosultsagEnum,
        NEMKOTELEZETT: 'NEMKOTELEZETT' as JogosultsagEnum,
        MENTESITETT70EVFELETTI: 'MENTESITETT_70EVFELETTI' as JogosultsagEnum,
        MENTESITETTFOGYATEKKALELO: 'MENTESITETT_FOGYATEKKALELO' as JogosultsagEnum,
        MENTESITETTGYERMEK: 'MENTESITETT_GYERMEK' as JogosultsagEnum,
        NEMKOTELEZETTKULFOLDI: 'NEMKOTELEZETT_KULFOLDI' as JogosultsagEnum,
        NEMKOTELEZETTNEMMOHOSZTAG: 'NEMKOTELEZETT_NEMMOHOSZTAG' as JogosultsagEnum
    };
    export type KorkategoriaEnum = 'GYERMEK' | 'IFJUSAGI' | 'FELNOTT';
    export const KorkategoriaEnum = {
        GYERMEK: 'GYERMEK' as KorkategoriaEnum,
        IFJUSAGI: 'IFJUSAGI' as KorkategoriaEnum,
        FELNOTT: 'FELNOTT' as KorkategoriaEnum
    };
}