/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TeruletiJegyTermekModositasRequest { 
    afakulcs?: TeruletiJegyTermekModositasRequest.AfakulcsEnum;
    ar?: number;
    becenev?: string;
    botokSzama?: number;
    engedelyezettErtekesitesTipusList?: Array<TeruletiJegyTermekModositasRequest.EngedelyezettErtekesitesTipusListEnum>;
    ertekesitesKezdete?: string;
    ertekesitesVege?: string;
    teruletiJegyId?: number;
}
export namespace TeruletiJegyTermekModositasRequest {
    export type AfakulcsEnum = 'AKMAA' | 'AK27' | 'AK5';
    export const AfakulcsEnum = {
        AKMAA: 'AKMAA' as AfakulcsEnum,
        AK27: 'AK27' as AfakulcsEnum,
        AK5: 'AK5' as AfakulcsEnum
    };
    export type EngedelyezettErtekesitesTipusListEnum = 'ONLINE' | 'SAJAT' | 'KOZVETLEN' | 'VISZONT';
    export const EngedelyezettErtekesitesTipusListEnum = {
        ONLINE: 'ONLINE' as EngedelyezettErtekesitesTipusListEnum,
        SAJAT: 'SAJAT' as EngedelyezettErtekesitesTipusListEnum,
        KOZVETLEN: 'KOZVETLEN' as EngedelyezettErtekesitesTipusListEnum,
        VISZONT: 'VISZONT' as EngedelyezettErtekesitesTipusListEnum
    };
}