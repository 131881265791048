/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Halmatrix } from './halmatrix';
import { MegyePoiSzamossag } from './megyePoiSzamossag';
import { PoiTypeSzamossag } from './poiTypeSzamossag';

export interface FelfedezesTorzsadat { 
    megyePoiSzamossagList?: Array<MegyePoiSzamossag>;
    nepszeruHalmatrixList?: Array<Halmatrix>;
    poiTypeSzamossagList?: Array<PoiTypeSzamossag>;
}