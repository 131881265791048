/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HorgaszvizsgaIgazoloDokumentum { 
    horgaszvizsgaIgazolasTipus?: HorgaszvizsgaIgazoloDokumentum.HorgaszvizsgaIgazolasTipusEnum;
    horgaszvizsgaKepId?: number;
    horgaszvizsgaSzam?: string;
    torolheto?: boolean;
}
export namespace HorgaszvizsgaIgazoloDokumentum {
    export type HorgaszvizsgaIgazolasTipusEnum = 'FOGASINAPLO' | 'HORGASZVIZSGA' | 'MENTESITESIIGAZOLAS';
    export const HorgaszvizsgaIgazolasTipusEnum = {
        FOGASINAPLO: 'FOGASINAPLO' as HorgaszvizsgaIgazolasTipusEnum,
        HORGASZVIZSGA: 'HORGASZVIZSGA' as HorgaszvizsgaIgazolasTipusEnum,
        MENTESITESIIGAZOLAS: 'MENTESITESIIGAZOLAS' as HorgaszvizsgaIgazolasTipusEnum
    };
}