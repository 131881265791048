/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ErtekesitesUtaniModositasRequest } from '../model/ertekesitesUtaniModositasRequest';
import { TeruletiJegyTermek } from '../model/teruletiJegyTermek';
import { TeruletiJegyTermekFilter } from '../model/teruletiJegyTermekFilter';
import { TeruletiJegyTermekHorgaszrendModositasRequest } from '../model/teruletiJegyTermekHorgaszrendModositasRequest';
import { TeruletiJegyTermekKiegeszitoTorzsadatBundle } from '../model/teruletiJegyTermekKiegeszitoTorzsadatBundle';
import { TeruletiJegyTermekKombinacioFilter } from '../model/teruletiJegyTermekKombinacioFilter';
import { TeruletiJegyTermekLeirasModositasRequest } from '../model/teruletiJegyTermekLeirasModositasRequest';
import { TeruletiJegyTermekLetrehozasRequest } from '../model/teruletiJegyTermekLetrehozasRequest';
import { TeruletiJegyTermekMasolasRequest } from '../model/teruletiJegyTermekMasolasRequest';
import { TeruletiJegyTermekMatrix } from '../model/teruletiJegyTermekMatrix';
import { TeruletiJegyTermekModositasRequest } from '../model/teruletiJegyTermekModositasRequest';
import { TeruletiJegyTermekRequest } from '../model/teruletiJegyTermekRequest';
import { TeruletiJegyTermekVizterulet } from '../model/teruletiJegyTermekVizterulet';
import { TeruletiKiegeszitoJegyTermekBeallitasRequest } from '../model/teruletiKiegeszitoJegyTermekBeallitasRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TeruletiJegyTermekMgmtControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * countByFilter
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countByFilterUsingPOST(body: TeruletiJegyTermekFilter, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public countByFilterUsingPOST(body: TeruletiJegyTermekFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public countByFilterUsingPOST(body: TeruletiJegyTermekFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public countByFilterUsingPOST(body: TeruletiJegyTermekFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling countByFilterUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/teruletijegytermekmgmt/teruletijegytermek-szamossag`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * csvExport
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public csvExportUsingGET(filter: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public csvExportUsingGET(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public csvExportUsingGET(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public csvExportUsingGET(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling csvExportUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/teruletijegytermekmgmt/teruletijegytermek-csvexport`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ertekesitesUtaniModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ertekesitesUtaniModositasUsingPOST(body: ErtekesitesUtaniModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyTermek>;
    public ertekesitesUtaniModositasUsingPOST(body: ErtekesitesUtaniModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyTermek>>;
    public ertekesitesUtaniModositasUsingPOST(body: ErtekesitesUtaniModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyTermek>>;
    public ertekesitesUtaniModositasUsingPOST(body: ErtekesitesUtaniModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ertekesitesUtaniModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyTermek>('post',`${this.basePath}/api/mohosz/teruletijegytermekmgmt/teruletijegytermek-ertekesitesutani-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findByFilter
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findByFilterUsingPOST(body: TeruletiJegyTermekFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<TeruletiJegyTermek>>;
    public findByFilterUsingPOST(body: TeruletiJegyTermekFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TeruletiJegyTermek>>>;
    public findByFilterUsingPOST(body: TeruletiJegyTermekFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TeruletiJegyTermek>>>;
    public findByFilterUsingPOST(body: TeruletiJegyTermekFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findByFilterUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<TeruletiJegyTermek>>('post',`${this.basePath}/api/mohosz/teruletijegytermekmgmt/teruletijegytermek-listazas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * horgaszrendFeltoltes
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszrendFeltoltesUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public horgaszrendFeltoltesUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public horgaszrendFeltoltesUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public horgaszrendFeltoltesUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling horgaszrendFeltoltesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/teruletijegytermekmgmt/teruletijegytermek-horgaszrend-feltoltes`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * horgaszrendModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszrendModositasUsingPOST(body: TeruletiJegyTermekHorgaszrendModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public horgaszrendModositasUsingPOST(body: TeruletiJegyTermekHorgaszrendModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public horgaszrendModositasUsingPOST(body: TeruletiJegyTermekHorgaszrendModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public horgaszrendModositasUsingPOST(body: TeruletiJegyTermekHorgaszrendModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgaszrendModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/teruletijegytermekmgmt/teruletijegytermek-horgaszrend-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * isAuthorized
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isAuthorizedUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public isAuthorizedUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public isAuthorizedUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public isAuthorizedUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/mohosz/teruletijegytermekmgmt/isauthorized`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kiegeszitokBeallitas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kiegeszitokBeallitasUsingPOST(body: TeruletiKiegeszitoJegyTermekBeallitasRequest, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyTermek>;
    public kiegeszitokBeallitasUsingPOST(body: TeruletiKiegeszitoJegyTermekBeallitasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyTermek>>;
    public kiegeszitokBeallitasUsingPOST(body: TeruletiKiegeszitoJegyTermekBeallitasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyTermek>>;
    public kiegeszitokBeallitasUsingPOST(body: TeruletiKiegeszitoJegyTermekBeallitasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kiegeszitokBeallitasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyTermek>('post',`${this.basePath}/api/mohosz/teruletijegytermekmgmt/teruletijegytermek-kiegeszitok-beallitas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kiegeszitokTorzsadat
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kiegeszitokTorzsadatUsingPOST(body: TeruletiJegyTermekRequest, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyTermekKiegeszitoTorzsadatBundle>;
    public kiegeszitokTorzsadatUsingPOST(body: TeruletiJegyTermekRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyTermekKiegeszitoTorzsadatBundle>>;
    public kiegeszitokTorzsadatUsingPOST(body: TeruletiJegyTermekRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyTermekKiegeszitoTorzsadatBundle>>;
    public kiegeszitokTorzsadatUsingPOST(body: TeruletiJegyTermekRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kiegeszitokTorzsadatUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyTermekKiegeszitoTorzsadatBundle>('post',`${this.basePath}/api/mohosz/teruletijegytermekmgmt/teruletijegytermek-kiegeszitok-torzsadat`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * leirasModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public leirasModositasUsingPOST(body: TeruletiJegyTermekLeirasModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyTermek>;
    public leirasModositasUsingPOST(body: TeruletiJegyTermekLeirasModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyTermek>>;
    public leirasModositasUsingPOST(body: TeruletiJegyTermekLeirasModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyTermek>>;
    public leirasModositasUsingPOST(body: TeruletiJegyTermekLeirasModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling leirasModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyTermek>('post',`${this.basePath}/api/mohosz/teruletijegytermekmgmt/teruletijegytermek-leiras-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * letrehozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public letrehozasUsingPOST(body: TeruletiJegyTermekLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyTermek>;
    public letrehozasUsingPOST(body: TeruletiJegyTermekLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyTermek>>;
    public letrehozasUsingPOST(body: TeruletiJegyTermekLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyTermek>>;
    public letrehozasUsingPOST(body: TeruletiJegyTermekLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling letrehozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyTermek>('post',`${this.basePath}/api/mohosz/teruletijegytermekmgmt/teruletijegytermek-letrehozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * masolas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public masolasUsingPOST(body: TeruletiJegyTermekMasolasRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public masolasUsingPOST(body: TeruletiJegyTermekMasolasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public masolasUsingPOST(body: TeruletiJegyTermekMasolasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public masolasUsingPOST(body: TeruletiJegyTermekMasolasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling masolasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/teruletijegytermekmgmt/teruletijegytermek-masolas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * matrixFilter
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public matrixFilterUsingPOST(body: TeruletiJegyTermekKombinacioFilter, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyTermekMatrix>;
    public matrixFilterUsingPOST(body: TeruletiJegyTermekKombinacioFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyTermekMatrix>>;
    public matrixFilterUsingPOST(body: TeruletiJegyTermekKombinacioFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyTermekMatrix>>;
    public matrixFilterUsingPOST(body: TeruletiJegyTermekKombinacioFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling matrixFilterUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyTermekMatrix>('post',`${this.basePath}/api/mohosz/teruletijegytermekmgmt/teruletijegytermek-matrix-filter`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * modositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modositasUsingPOST(body: TeruletiJegyTermekModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyTermek>;
    public modositasUsingPOST(body: TeruletiJegyTermekModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyTermek>>;
    public modositasUsingPOST(body: TeruletiJegyTermekModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyTermek>>;
    public modositasUsingPOST(body: TeruletiJegyTermekModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling modositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyTermek>('post',`${this.basePath}/api/mohosz/teruletijegytermekmgmt/teruletijegytermek-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiJegyMinta
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiJegyMintaUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public teruletiJegyMintaUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public teruletiJegyMintaUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public teruletiJegyMintaUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling teruletiJegyMintaUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/teruletijegytermekmgmt/teruletijegytermek-minta`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * torles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public torlesUsingPOST(body: TeruletiJegyTermekRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public torlesUsingPOST(body: TeruletiJegyTermekRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public torlesUsingPOST(body: TeruletiJegyTermekRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public torlesUsingPOST(body: TeruletiJegyTermekRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling torlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/teruletijegytermekmgmt/teruletijegytermek-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * valaszthatoVizteruletek
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public valaszthatoVizteruletekUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<TeruletiJegyTermekVizterulet>>;
    public valaszthatoVizteruletekUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TeruletiJegyTermekVizterulet>>>;
    public valaszthatoVizteruletekUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TeruletiJegyTermekVizterulet>>>;
    public valaszthatoVizteruletekUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TeruletiJegyTermekVizterulet>>('post',`${this.basePath}/api/mohosz/teruletijegytermekmgmt/teruletijegytermek-valaszthato-vizteruletek`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
