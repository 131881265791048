/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VersenyzoiEngedely { 
    availableCommands?: Array<VersenyzoiEngedely.AvailableCommandsEnum>;
    ervenyessegKezdete?: Date;
    ervenyessegVege?: string;
    ervenyessegiEv?: number;
    korkategoria?: VersenyzoiEngedely.KorkategoriaEnum;
    statusz?: VersenyzoiEngedely.StatuszEnum;
}
export namespace VersenyzoiEngedely {
    export type AvailableCommandsEnum = 'NYOMTATAS' | 'SZTORNOZAS';
    export const AvailableCommandsEnum = {
        NYOMTATAS: 'NYOMTATAS' as AvailableCommandsEnum,
        SZTORNOZAS: 'SZTORNOZAS' as AvailableCommandsEnum
    };
    export type KorkategoriaEnum = 'FELNOTT' | 'IFJUSAGI_18_ALATTI' | 'KISKORU_14_ALATTI';
    export const KorkategoriaEnum = {
        FELNOTT: 'FELNOTT' as KorkategoriaEnum,
        IFJUSAGI18ALATTI: 'IFJUSAGI_18_ALATTI' as KorkategoriaEnum,
        KISKORU14ALATTI: 'KISKORU_14_ALATTI' as KorkategoriaEnum
    };
    export type StatuszEnum = 'LEJART' | 'ERVENYES';
    export const StatuszEnum = {
        LEJART: 'LEJART' as StatuszEnum,
        ERVENYES: 'ERVENYES' as StatuszEnum
    };
}