/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { VizteruletHalgazdalkodo } from './vizteruletHalgazdalkodo';
import { VizteruletTulajdonsag } from './vizteruletTulajdonsag';

export interface Vizterulet { 
    halgazdalkodok?: Array<VizteruletHalgazdalkodo>;
    id?: number;
    nev?: string;
    normalizedNev?: string;
    tulajdonsag?: VizteruletTulajdonsag;
    vizterkod?: string;
}