/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdatEllenorzesFeladat } from './adatEllenorzesFeladat';
import { TelefonosAdatEllenorzes } from './telefonosAdatEllenorzes';

export interface PersonalizedAdatEllenorzes { 
    adoazonositoJel?: string;
    anyjaNeve?: string;
    email?: string;
    feladatList?: Array<AdatEllenorzesFeladat>;
    horgaszAzonosito?: string;
    labels?: Array<string>;
    nev?: string;
    szemelyId?: number;
    szuletesiDatum?: string;
    szuletesiHely?: string;
    telefonosAdatEllenorzesList?: Array<TelefonosAdatEllenorzes>;
    vegrehajtva?: boolean;
}