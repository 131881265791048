/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Cim } from './cim';
import { SzemelyDetails } from './szemelyDetails';

export interface Szemely { 
    allandoLakcim?: Cim;
    anyjaNeve?: string;
    bekertTelefonszam?: string;
    details?: SzemelyDetails;
    egyesuletiTagsagIdList?: Array<number>;
    email?: string;
    felhasznalo?: boolean;
    felnott?: boolean;
    gondviseloSzemelyId?: number;
    gyermek?: boolean;
    horgasz?: boolean;
    horgaszAzonosito?: string;
    id?: number;
    keresztnev?: string;
    kezbesitesiCim?: Cim;
    kezbesitesiCimLakcimEgyezes?: boolean;
    kulfoldiAllampolgar?: boolean;
    modositva?: Date;
    nem?: Szemely.NemEnum;
    profilkepId?: number;
    szemelyContentIds?: Array<number>;
    szervezet?: boolean;
    szuletesiDatum?: string;
    szuletesiHely?: string;
    termeszetes4tId?: string;
    tipus?: Szemely.TipusEnum;
    titulus?: Szemely.TitulusEnum;
    vezeteknev?: string;
}
export namespace Szemely {
    export type NemEnum = 'NINCS_MEGADVA' | 'NO' | 'FERFI';
    export const NemEnum = {
        NINCSMEGADVA: 'NINCS_MEGADVA' as NemEnum,
        NO: 'NO' as NemEnum,
        FERFI: 'FERFI' as NemEnum
    };
    export type TipusEnum = 'SZEMELY' | 'MOHOSZ' | 'TAGEGYESULET' | 'TAGSZOVETSEG' | 'BOLT' | 'HALGAZDALKODO' | 'ALTALANOS';
    export const TipusEnum = {
        SZEMELY: 'SZEMELY' as TipusEnum,
        MOHOSZ: 'MOHOSZ' as TipusEnum,
        TAGEGYESULET: 'TAGEGYESULET' as TipusEnum,
        TAGSZOVETSEG: 'TAGSZOVETSEG' as TipusEnum,
        BOLT: 'BOLT' as TipusEnum,
        HALGAZDALKODO: 'HALGAZDALKODO' as TipusEnum,
        ALTALANOS: 'ALTALANOS' as TipusEnum
    };
    export type TitulusEnum = 'dr' | 'id' | 'ifj' | 'özv' | 'prof';
    export const TitulusEnum = {
        Dr: 'dr' as TitulusEnum,
        Id: 'id' as TitulusEnum,
        Ifj: 'ifj' as TitulusEnum,
        Zv: 'özv' as TitulusEnum,
        Prof: 'prof' as TitulusEnum
    };
}