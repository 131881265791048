/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TransactionResult { 
    email?: string;
    licenseNumber?: string;
    responseCode?: string;
    status?: TransactionResult.StatusEnum;
    textMessage?: string;
}
export namespace TransactionResult {
    export type StatusEnum = 'PENDING' | 'CANCELLED' | 'EXPIRED' | 'NOT_ACKNOWLEDGED' | 'UNKNOWN_TRANSACTION_ID' | 'ERROR' | 'ACKNOWLEDGED';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        CANCELLED: 'CANCELLED' as StatusEnum,
        EXPIRED: 'EXPIRED' as StatusEnum,
        NOTACKNOWLEDGED: 'NOT_ACKNOWLEDGED' as StatusEnum,
        UNKNOWNTRANSACTIONID: 'UNKNOWN_TRANSACTION_ID' as StatusEnum,
        ERROR: 'ERROR' as StatusEnum,
        ACKNOWLEDGED: 'ACKNOWLEDGED' as StatusEnum
    };
}