/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface EgyebTermekArazasFilter { 
    ervenyessegiEv?: number;
    termekTipus?: EgyebTermekArazasFilter.TermekTipusEnum;
}
export namespace EgyebTermekArazasFilter {
    export type TermekTipusEnum = 'HORGASZKARTYA' | 'EGYESULETITAGDIJ' | 'ALLAMIJEGY' | 'FOGASINAPLO' | 'ESZH' | 'TURISTA_ALLAMIJEGY' | 'TERULETI_JEGY' | 'TERULETI_KIEGESZITO_JEGY' | 'MAHOR_ELOFIZETES' | 'VERSENYZOI_ENGEDELY' | 'VERSENY_NEVEZES';
    export const TermekTipusEnum = {
        HORGASZKARTYA: 'HORGASZKARTYA' as TermekTipusEnum,
        EGYESULETITAGDIJ: 'EGYESULETITAGDIJ' as TermekTipusEnum,
        ALLAMIJEGY: 'ALLAMIJEGY' as TermekTipusEnum,
        FOGASINAPLO: 'FOGASINAPLO' as TermekTipusEnum,
        ESZH: 'ESZH' as TermekTipusEnum,
        TURISTAALLAMIJEGY: 'TURISTA_ALLAMIJEGY' as TermekTipusEnum,
        TERULETIJEGY: 'TERULETI_JEGY' as TermekTipusEnum,
        TERULETIKIEGESZITOJEGY: 'TERULETI_KIEGESZITO_JEGY' as TermekTipusEnum,
        MAHORELOFIZETES: 'MAHOR_ELOFIZETES' as TermekTipusEnum,
        VERSENYZOIENGEDELY: 'VERSENYZOI_ENGEDELY' as TermekTipusEnum,
        VERSENYNEVEZES: 'VERSENY_NEVEZES' as TermekTipusEnum
    };
}