/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HorgasztatasSzolgaltatasAlapadatRequest { 
    altalanosLeiras?: string;
    ellenorTelefonszam?: string;
    fenntartoEmail?: string;
    fenntartoTelefonszam?: string;
    ismertNev?: string;
    nev?: string;
    ownerSzereploId?: number;
    szolgaltatasId?: number;
    vizterkod?: string;
    vizteruletTipus?: HorgasztatasSzolgaltatasAlapadatRequest.VizteruletTipusEnum;
}
export namespace HorgasztatasSzolgaltatasAlapadatRequest {
    export type VizteruletTipusEnum = 'FOLYO' | 'VIZTAROZO' | 'PATAK' | 'CSATORNA' | 'TERMESZETES_TO' | 'MESTERSEGES_TO' | 'HOLTAG';
    export const VizteruletTipusEnum = {
        FOLYO: 'FOLYO' as VizteruletTipusEnum,
        VIZTAROZO: 'VIZTAROZO' as VizteruletTipusEnum,
        PATAK: 'PATAK' as VizteruletTipusEnum,
        CSATORNA: 'CSATORNA' as VizteruletTipusEnum,
        TERMESZETESTO: 'TERMESZETES_TO' as VizteruletTipusEnum,
        MESTERSEGESTO: 'MESTERSEGES_TO' as VizteruletTipusEnum,
        HOLTAG: 'HOLTAG' as VizteruletTipusEnum
    };
}