/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SzemelyAlapadatRequest { 
    anyjaNeve?: string;
    bekertTelefonszam?: string;
    email?: string;
    keresztnev?: string;
    kulfoldiAllampolgar?: boolean;
    nem?: SzemelyAlapadatRequest.NemEnum;
    szemelyId?: number;
    szuletesiDatum?: string;
    szuletesiHely?: string;
    titulus?: SzemelyAlapadatRequest.TitulusEnum;
    vezeteknev?: string;
}
export namespace SzemelyAlapadatRequest {
    export type NemEnum = 'NINCS_MEGADVA' | 'NO' | 'FERFI';
    export const NemEnum = {
        NINCSMEGADVA: 'NINCS_MEGADVA' as NemEnum,
        NO: 'NO' as NemEnum,
        FERFI: 'FERFI' as NemEnum
    };
    export type TitulusEnum = 'dr' | 'id' | 'ifj' | 'özv' | 'prof';
    export const TitulusEnum = {
        Dr: 'dr' as TitulusEnum,
        Id: 'id' as TitulusEnum,
        Ifj: 'ifj' as TitulusEnum,
        Zv: 'özv' as TitulusEnum,
        Prof: 'prof' as TitulusEnum
    };
}