/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { NyilatkozatDetails } from './nyilatkozatDetails';

export interface Nyilatkozat { 
    details?: NyilatkozatDetails;
    dokumentumId?: number;
    eloallitasDatum?: Date;
    ervenyessegiEv?: number;
    feltoltesDatum?: Date;
    fileNev?: string;
    id?: number;
    rendszerbenEloallitott?: boolean;
    statusz?: Nyilatkozat.StatuszEnum;
    tipus?: Nyilatkozat.TipusEnum;
    toroltDokumentumIds?: Array<number>;
}
export namespace Nyilatkozat {
    export type StatuszEnum = 'LETREHOZVA' | 'ATADVA' | 'FELTOLTVE';
    export const StatuszEnum = {
        LETREHOZVA: 'LETREHOZVA' as StatuszEnum,
        ATADVA: 'ATADVA' as StatuszEnum,
        FELTOLTVE: 'FELTOLTVE' as StatuszEnum
    };
    export type TipusEnum = 'ALTALANOS_NYILATKOZAT' | 'HORGASZ_REGISZTRACIO' | 'HIANYZO_ALLAMIJEGY' | 'ELVESZETT_FOGASINAPLO' | 'MEGSEMMISULT_FOGASINAPLO' | 'NEMVOLT_ELOTTEVOLT_ALLAMIJEGY' | 'NEMVOLT_2013ELOTTVOLT_ALLAMIJEGY' | 'NEMVOLT_ELOTTENEMVOLT_ALLAMIJEGY' | 'EGYESULETI_TAGSAG' | 'ADATELLENORZES_ADATVALTOZAS';
    export const TipusEnum = {
        ALTALANOSNYILATKOZAT: 'ALTALANOS_NYILATKOZAT' as TipusEnum,
        HORGASZREGISZTRACIO: 'HORGASZ_REGISZTRACIO' as TipusEnum,
        HIANYZOALLAMIJEGY: 'HIANYZO_ALLAMIJEGY' as TipusEnum,
        ELVESZETTFOGASINAPLO: 'ELVESZETT_FOGASINAPLO' as TipusEnum,
        MEGSEMMISULTFOGASINAPLO: 'MEGSEMMISULT_FOGASINAPLO' as TipusEnum,
        NEMVOLTELOTTEVOLTALLAMIJEGY: 'NEMVOLT_ELOTTEVOLT_ALLAMIJEGY' as TipusEnum,
        NEMVOLT2013ELOTTVOLTALLAMIJEGY: 'NEMVOLT_2013ELOTTVOLT_ALLAMIJEGY' as TipusEnum,
        NEMVOLTELOTTENEMVOLTALLAMIJEGY: 'NEMVOLT_ELOTTENEMVOLT_ALLAMIJEGY' as TipusEnum,
        EGYESULETITAGSAG: 'EGYESULETI_TAGSAG' as TipusEnum,
        ADATELLENORZESADATVALTOZAS: 'ADATELLENORZES_ADATVALTOZAS' as TipusEnum
    };
}