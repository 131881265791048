/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FogasiNaploRiportFilter { 
    csakSajat?: boolean;
    ervenyessegiEv?: number;
    ev?: number;
    filterLabels?: Array<FogasiNaploRiportFilter.FilterLabelsEnum>;
    fromDate?: string;
    honap?: number;
    limit?: number;
    offset?: number;
    sajatErtekesitoSzervezetId?: number;
    statuszList?: Array<FogasiNaploRiportFilter.StatuszListEnum>;
    toDate?: string;
    validIdoszak?: boolean;
}
export namespace FogasiNaploRiportFilter {
    export type FilterLabelsEnum = 'LEADVA' | 'ROGZITVE';
    export const FilterLabelsEnum = {
        LEADVA: 'LEADVA' as FilterLabelsEnum,
        ROGZITVE: 'ROGZITVE' as FilterLabelsEnum
    };
    export type StatuszListEnum = 'KIADOTT' | 'IDOBEN_LEADVA' | 'KESON_LEADVA' | 'BETELT' | 'VISSZAVONT' | 'ELVESZETT' | 'MEGSEMMISULT';
    export const StatuszListEnum = {
        KIADOTT: 'KIADOTT' as StatuszListEnum,
        IDOBENLEADVA: 'IDOBEN_LEADVA' as StatuszListEnum,
        KESONLEADVA: 'KESON_LEADVA' as StatuszListEnum,
        BETELT: 'BETELT' as StatuszListEnum,
        VISSZAVONT: 'VISSZAVONT' as StatuszListEnum,
        ELVESZETT: 'ELVESZETT' as StatuszListEnum,
        MEGSEMMISULT: 'MEGSEMMISULT' as StatuszListEnum
    };
}