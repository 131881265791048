/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VersenyLetrehozasRequest { 
    isCsapatverseny?: boolean;
    jelentkezesKezdeteIdopont?: string;
    jelentkezesVegeIdopont?: string;
    kezdesIdopont?: Date;
    maxCsapatletszam?: number;
    maxEgyeniJelentkezo?: number;
    maxKorhatar?: number;
    maxResztvevoCsapat?: number;
    minCsapatletszam?: number;
    minKorhatar?: number;
    nev?: string;
    nevezesiDij?: number;
    vegeIdopont?: Date;
    versenyKiiras?: string;
    versenyszabalyzatUrl?: string;
}