/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ErtekesitesOsztonzes { 
    szemelyId?: number;
    tipusList?: Array<ErtekesitesOsztonzes.TipusListEnum>;
}
export namespace ErtekesitesOsztonzes {
    export type TipusListEnum = 'MAHORELOFIZETES_MEGUJITAS' | 'MAHORELOFIZETES_FIZETES' | 'MAHORELOFIZETES_LETREHOZAS';
    export const TipusListEnum = {
        MEGUJITAS: 'MAHORELOFIZETES_MEGUJITAS' as TipusListEnum,
        FIZETES: 'MAHORELOFIZETES_FIZETES' as TipusListEnum,
        LETREHOZAS: 'MAHORELOFIZETES_LETREHOZAS' as TipusListEnum
    };
}