/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Cim } from './cim';

export interface MahorElofizetesTorzsadat { 
    ar?: number;
    idotartamList?: Array<MahorElofizetesTorzsadat.IdotartamListEnum>;
    kezbesitesTipusList?: Array<MahorElofizetesTorzsadat.KezbesitesTipusListEnum>;
    labels?: Array<string>;
    nev?: string;
    szamlazasiCim?: Cim;
}
export namespace MahorElofizetesTorzsadat {
    export type IdotartamListEnum = 'EVES' | 'FELEVES';
    export const IdotartamListEnum = {
        EVES: 'EVES' as IdotartamListEnum,
        FELEVES: 'FELEVES' as IdotartamListEnum
    };
    export type KezbesitesTipusListEnum = 'LISTAS' | 'CIMZETT';
    export const KezbesitesTipusListEnum = {
        LISTAS: 'LISTAS' as KezbesitesTipusListEnum,
        CIMZETT: 'CIMZETT' as KezbesitesTipusListEnum
    };
}