/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VersenyzoiEngedelyArReply { 
    ar?: number;
    veKorkategoria?: VersenyzoiEngedelyArReply.VeKorkategoriaEnum;
}
export namespace VersenyzoiEngedelyArReply {
    export type VeKorkategoriaEnum = 'FELNOTT' | 'IFJUSAGI_18_ALATTI' | 'KISKORU_14_ALATTI';
    export const VeKorkategoriaEnum = {
        FELNOTT: 'FELNOTT' as VeKorkategoriaEnum,
        IFJUSAGI18ALATTI: 'IFJUSAGI_18_ALATTI' as VeKorkategoriaEnum,
        KISKORU14ALATTI: 'KISKORU_14_ALATTI' as VeKorkategoriaEnum
    };
}