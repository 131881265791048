/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { NebihAllamiJegyDetails } from './nebihAllamiJegyDetails';

export interface NebihAllamiJegy { 
    anyjaNeve?: string;
    details?: NebihAllamiJegyDetails;
    hrjAzonosito?: string;
    irsz?: string;
    keresztnev?: string;
    kitoltve?: boolean;
    leadasDatuma?: string;
    leadva?: boolean;
    migralva?: boolean;
    partnerid?: string;
    statusz?: NebihAllamiJegy.StatuszEnum;
    szemelyId?: number;
    szuletesiDatum?: string;
    szuletesiHely?: string;
    titulus?: string;
    utca?: string;
    varos?: string;
    vezeteknev?: string;
    vizsgaAzonosito?: string;
    vizsgaDatum?: string;
}
export namespace NebihAllamiJegy {
    export type StatuszEnum = 'BETELT' | 'LEADOTT';
    export const StatuszEnum = {
        BETELT: 'BETELT' as StatuszEnum,
        LEADOTT: 'LEADOTT' as StatuszEnum
    };
}