/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TagdijBefizetes { 
    ervenyessegiEv?: number;
    korkategoria?: TagdijBefizetes.KorkategoriaEnum;
    tagdij?: number;
    tagdijBefizetesDatum?: Date;
    tagsagKategoria?: TagdijBefizetes.TagsagKategoriaEnum;
}
export namespace TagdijBefizetes {
    export type KorkategoriaEnum = 'GYERMEK' | 'IFJUSAGI' | 'FELNOTT';
    export const KorkategoriaEnum = {
        GYERMEK: 'GYERMEK' as KorkategoriaEnum,
        IFJUSAGI: 'IFJUSAGI' as KorkategoriaEnum,
        FELNOTT: 'FELNOTT' as KorkategoriaEnum
    };
    export type TagsagKategoriaEnum = 'IFJUSAGI' | 'FELNOTT' | 'FELNOTT_KEDVEZMENYEZETT' | 'IFJUSAGI_IHJ' | 'FELNOTT_IHJ' | 'FELNOTT_JOGFENNTARTO_IHJ' | 'MOHOSZON_KIVULI';
    export const TagsagKategoriaEnum = {
        IFJUSAGI: 'IFJUSAGI' as TagsagKategoriaEnum,
        FELNOTT: 'FELNOTT' as TagsagKategoriaEnum,
        FELNOTTKEDVEZMENYEZETT: 'FELNOTT_KEDVEZMENYEZETT' as TagsagKategoriaEnum,
        IFJUSAGIIHJ: 'IFJUSAGI_IHJ' as TagsagKategoriaEnum,
        FELNOTTIHJ: 'FELNOTT_IHJ' as TagsagKategoriaEnum,
        FELNOTTJOGFENNTARTOIHJ: 'FELNOTT_JOGFENNTARTO_IHJ' as TagsagKategoriaEnum,
        MOHOSZONKIVULI: 'MOHOSZON_KIVULI' as TagsagKategoriaEnum
    };
}