/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SzolgaltatasRegisztracioBiralat } from './szolgaltatasRegisztracioBiralat';

export interface SzolgaltatasListItem { 
    adatKitoltottseg?: number;
    availableCommands?: Array<SzolgaltatasListItem.AvailableCommandsEnum>;
    biralatList?: Array<SzolgaltatasRegisztracioBiralat>;
    id?: number;
    kepId?: number;
    megnevezes?: string;
    ownerSzereploId?: number;
    ownerSzereploNev?: string;
    statusz?: SzolgaltatasListItem.StatuszEnum;
    tipus?: SzolgaltatasListItem.TipusEnum;
}
export namespace SzolgaltatasListItem {
    export type AvailableCommandsEnum = 'REGISZTRACIO_VEGLEGESITES' | 'RESZLETEK_MEGNYITAS' | 'ELONEZET_MEGNYITAS' | 'REGISZTRACIO_MEGNYITAS' | 'ELBIRALAS' | 'MODOSITAS';
    export const AvailableCommandsEnum = {
        REGISZTRACIOVEGLEGESITES: 'REGISZTRACIO_VEGLEGESITES' as AvailableCommandsEnum,
        RESZLETEKMEGNYITAS: 'RESZLETEK_MEGNYITAS' as AvailableCommandsEnum,
        ELONEZETMEGNYITAS: 'ELONEZET_MEGNYITAS' as AvailableCommandsEnum,
        REGISZTRACIOMEGNYITAS: 'REGISZTRACIO_MEGNYITAS' as AvailableCommandsEnum,
        ELBIRALAS: 'ELBIRALAS' as AvailableCommandsEnum,
        MODOSITAS: 'MODOSITAS' as AvailableCommandsEnum
    };
    export type StatuszEnum = 'ROGZITESALATT' | 'JOVAHAGYASRAVAR' | 'AKTIV' | 'INAKTIV';
    export const StatuszEnum = {
        ROGZITESALATT: 'ROGZITESALATT' as StatuszEnum,
        JOVAHAGYASRAVAR: 'JOVAHAGYASRAVAR' as StatuszEnum,
        AKTIV: 'AKTIV' as StatuszEnum,
        INAKTIV: 'INAKTIV' as StatuszEnum
    };
    export type TipusEnum = 'HORGASZTATAS' | 'HORGASZBOLT' | 'GUIDE' | 'KIKOTO' | 'BERBEADAS' | 'HORGASZHELY' | 'SZALLAS' | 'SOLYAPALYA' | 'HORGASZTANYA';
    export const TipusEnum = {
        HORGASZTATAS: 'HORGASZTATAS' as TipusEnum,
        HORGASZBOLT: 'HORGASZBOLT' as TipusEnum,
        GUIDE: 'GUIDE' as TipusEnum,
        KIKOTO: 'KIKOTO' as TipusEnum,
        BERBEADAS: 'BERBEADAS' as TipusEnum,
        HORGASZHELY: 'HORGASZHELY' as TipusEnum,
        SZALLAS: 'SZALLAS' as TipusEnum,
        SOLYAPALYA: 'SOLYAPALYA' as TipusEnum,
        HORGASZTANYA: 'HORGASZTANYA' as TipusEnum
    };
}