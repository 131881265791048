/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TermekTetelAr { 
    ar?: number;
    nev?: TermekTetelAr.NevEnum;
    order?: number;
}
export namespace TermekTetelAr {
    export type NevEnum = 'HORGASZKARTYA' | 'EGYESULETITAGDIJ' | 'ALLAMIJEGY' | 'FOGASINAPLO' | 'ESZH' | 'TURISTA_ALLAMIJEGY' | 'TERULETI_JEGY' | 'TERULETI_KIEGESZITO_JEGY' | 'MAHOR_ELOFIZETES' | 'VERSENYZOI_ENGEDELY' | 'VERSENY_NEVEZES';
    export const NevEnum = {
        HORGASZKARTYA: 'HORGASZKARTYA' as NevEnum,
        EGYESULETITAGDIJ: 'EGYESULETITAGDIJ' as NevEnum,
        ALLAMIJEGY: 'ALLAMIJEGY' as NevEnum,
        FOGASINAPLO: 'FOGASINAPLO' as NevEnum,
        ESZH: 'ESZH' as NevEnum,
        TURISTAALLAMIJEGY: 'TURISTA_ALLAMIJEGY' as NevEnum,
        TERULETIJEGY: 'TERULETI_JEGY' as NevEnum,
        TERULETIKIEGESZITOJEGY: 'TERULETI_KIEGESZITO_JEGY' as NevEnum,
        MAHORELOFIZETES: 'MAHOR_ELOFIZETES' as NevEnum,
        VERSENYZOIENGEDELY: 'VERSENYZOI_ENGEDELY' as NevEnum,
        VERSENYNEVEZES: 'VERSENY_NEVEZES' as NevEnum
    };
}