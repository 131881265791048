/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SzabalysertesFilter { 
    ellenorSzemelyId?: number;
    fromDate?: string;
    globalSearchStr?: string;
    limit?: number;
    offset?: number;
    sorszam?: string;
    statuszok?: Array<SzabalysertesFilter.StatuszokEnum>;
    szemelyId?: number;
    szervezetId?: number;
    tipusok?: Array<SzabalysertesFilter.TipusokEnum>;
    toDate?: string;
    tsQueryString?: string;
    validDateFields?: boolean;
    vizteruletIdList?: Array<number>;
}
export namespace SzabalysertesFilter {
    export type StatuszokEnum = 'ROGZITVE' | 'FELDOLGOZAS_ALATT' | 'ELBIRALAS_ALATT' | 'LEZART' | 'HIBAS_LEZART';
    export const StatuszokEnum = {
        ROGZITVE: 'ROGZITVE' as StatuszokEnum,
        FELDOLGOZASALATT: 'FELDOLGOZAS_ALATT' as StatuszokEnum,
        ELBIRALASALATT: 'ELBIRALAS_ALATT' as StatuszokEnum,
        LEZART: 'LEZART' as StatuszokEnum,
        HIBASLEZART: 'HIBAS_LEZART' as StatuszokEnum
    };
    export type TipusokEnum = 'HELYI_HORGASZRENDI' | 'JOGSZABALYI';
    export const TipusokEnum = {
        HELYIHORGASZRENDI: 'HELYI_HORGASZRENDI' as TipusokEnum,
        JOGSZABALYI: 'JOGSZABALYI' as TipusokEnum
    };
}