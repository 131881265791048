/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FindJogcimRequest } from '../model/findJogcimRequest';
import { FindTeruletiJegyTermekRequest } from '../model/findTeruletiJegyTermekRequest';
import { FindVizteruletCsoportRequest } from '../model/findVizteruletCsoportRequest';
import { HghJegysorszamMigracioRowIdEvent } from '../model/hghJegysorszamMigracioRowIdEvent';
import { TeruletiJegyTermek } from '../model/teruletiJegyTermek';
import { TeruletiJegyTermekDetails } from '../model/teruletiJegyTermekDetails';
import { TeruletiJegyTermekDetailsRequest } from '../model/teruletiJegyTermekDetailsRequest';
import { TeruletiJegyTermekIdoszakErvenyesseg } from '../model/teruletiJegyTermekIdoszakErvenyesseg';
import { TeruletiJegyVasarlasForecast } from '../model/teruletiJegyVasarlasForecast';
import { TeruletiJegyVasarlasForecastRequest } from '../model/teruletiJegyVasarlasForecastRequest';
import { TeruletiKiegeszitoJegyVasarlasForecast } from '../model/teruletiKiegeszitoJegyVasarlasForecast';
import { TeruletiKiegeszitoJegyVasarlasForecastRequest } from '../model/teruletiKiegeszitoJegyVasarlasForecastRequest';
import { VizteruletCsoport } from '../model/vizteruletCsoport';
import { VizteruletCsoportListazasAlapadat } from '../model/vizteruletCsoportListazasAlapadat';
import { VizteruletCsoportListazasResponse } from '../model/vizteruletCsoportListazasResponse';
import { VizteruletCsoportRequest } from '../model/vizteruletCsoportRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TeruletiJegyTermekErtekesitesControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * fetchVizteruletCsoport
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchVizteruletCsoportUsingPOST(body: VizteruletCsoportRequest, observe?: 'body', reportProgress?: boolean): Observable<VizteruletCsoport>;
    public fetchVizteruletCsoportUsingPOST(body: VizteruletCsoportRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizteruletCsoport>>;
    public fetchVizteruletCsoportUsingPOST(body: VizteruletCsoportRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizteruletCsoport>>;
    public fetchVizteruletCsoportUsingPOST(body: VizteruletCsoportRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fetchVizteruletCsoportUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizteruletCsoport>('post',`${this.basePath}/api/mohosz/teruletijegytermek/ertekesites/fetch-vizteruletcsoport`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * hghJegysorszamMigracioInditas
     * 
     * @param body rowIdEvent
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hghJegysorszamMigracioInditasUsingPOST(body: HghJegysorszamMigracioRowIdEvent, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public hghJegysorszamMigracioInditasUsingPOST(body: HghJegysorszamMigracioRowIdEvent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public hghJegysorszamMigracioInditasUsingPOST(body: HghJegysorszamMigracioRowIdEvent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public hghJegysorszamMigracioInditasUsingPOST(body: HghJegysorszamMigracioRowIdEvent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling hghJegysorszamMigracioInditasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/teruletijegytermek/ertekesites/hghjegysorszam-migracio-inditas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * jogcimListazas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public jogcimListazasUsingPOST(body: FindJogcimRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public jogcimListazasUsingPOST(body: FindJogcimRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public jogcimListazasUsingPOST(body: FindJogcimRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public jogcimListazasUsingPOST(body: FindJogcimRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling jogcimListazasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<string>>('post',`${this.basePath}/api/mohosz/teruletijegytermek/ertekesites/jogcim-listazas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiJegyErvenyesseg
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiJegyErvenyessegUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyTermekIdoszakErvenyesseg>;
    public teruletiJegyErvenyessegUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyTermekIdoszakErvenyesseg>>;
    public teruletiJegyErvenyessegUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyTermekIdoszakErvenyesseg>>;
    public teruletiJegyErvenyessegUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TeruletiJegyTermekIdoszakErvenyesseg>('post',`${this.basePath}/api/mohosz/teruletijegytermek/ertekesites/teruletijegy-idoszak-ervenyesseg`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiJegyListazas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiJegyListazasUsingPOST(body: FindTeruletiJegyTermekRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<TeruletiJegyTermek>>;
    public teruletiJegyListazasUsingPOST(body: FindTeruletiJegyTermekRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TeruletiJegyTermek>>>;
    public teruletiJegyListazasUsingPOST(body: FindTeruletiJegyTermekRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TeruletiJegyTermek>>>;
    public teruletiJegyListazasUsingPOST(body: FindTeruletiJegyTermekRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teruletiJegyListazasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<TeruletiJegyTermek>>('post',`${this.basePath}/api/mohosz/teruletijegytermek/ertekesites/teruletijegy-listazas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiJegyOnlineVasarlasForecast
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiJegyOnlineVasarlasForecastUsingPOST(body: TeruletiJegyVasarlasForecastRequest, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyVasarlasForecast>;
    public teruletiJegyOnlineVasarlasForecastUsingPOST(body: TeruletiJegyVasarlasForecastRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyVasarlasForecast>>;
    public teruletiJegyOnlineVasarlasForecastUsingPOST(body: TeruletiJegyVasarlasForecastRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyVasarlasForecast>>;
    public teruletiJegyOnlineVasarlasForecastUsingPOST(body: TeruletiJegyVasarlasForecastRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teruletiJegyOnlineVasarlasForecastUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyVasarlasForecast>('post',`${this.basePath}/api/mohosz/teruletijegytermek/ertekesites/teruletijegy-vasarlas-osszesito`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiJegyTermekDetails
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiJegyTermekDetailsUsingPOST(body: TeruletiJegyTermekDetailsRequest, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyTermekDetails>;
    public teruletiJegyTermekDetailsUsingPOST(body: TeruletiJegyTermekDetailsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyTermekDetails>>;
    public teruletiJegyTermekDetailsUsingPOST(body: TeruletiJegyTermekDetailsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyTermekDetails>>;
    public teruletiJegyTermekDetailsUsingPOST(body: TeruletiJegyTermekDetailsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teruletiJegyTermekDetailsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyTermekDetails>('post',`${this.basePath}/api/mohosz/teruletijegytermek/ertekesites/teruletijegy-reszletek`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiKiegeszitoJegyOnlineVasarlasForecast
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiKiegeszitoJegyOnlineVasarlasForecastUsingPOST(body: TeruletiKiegeszitoJegyVasarlasForecastRequest, observe?: 'body', reportProgress?: boolean): Observable<TeruletiKiegeszitoJegyVasarlasForecast>;
    public teruletiKiegeszitoJegyOnlineVasarlasForecastUsingPOST(body: TeruletiKiegeszitoJegyVasarlasForecastRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiKiegeszitoJegyVasarlasForecast>>;
    public teruletiKiegeszitoJegyOnlineVasarlasForecastUsingPOST(body: TeruletiKiegeszitoJegyVasarlasForecastRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiKiegeszitoJegyVasarlasForecast>>;
    public teruletiKiegeszitoJegyOnlineVasarlasForecastUsingPOST(body: TeruletiKiegeszitoJegyVasarlasForecastRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teruletiKiegeszitoJegyOnlineVasarlasForecastUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiKiegeszitoJegyVasarlasForecast>('post',`${this.basePath}/api/mohosz/teruletijegytermek/ertekesites/teruletikiegeszitojegy-vasarlas-osszesito`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizteruletCsoportAlapadatListazas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizteruletCsoportAlapadatListazasUsingPOST(body: FindVizteruletCsoportRequest, observe?: 'body', reportProgress?: boolean): Observable<VizteruletCsoportListazasResponse>;
    public vizteruletCsoportAlapadatListazasUsingPOST(body: FindVizteruletCsoportRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VizteruletCsoportListazasResponse>>;
    public vizteruletCsoportAlapadatListazasUsingPOST(body: FindVizteruletCsoportRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VizteruletCsoportListazasResponse>>;
    public vizteruletCsoportAlapadatListazasUsingPOST(body: FindVizteruletCsoportRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizteruletCsoportAlapadatListazasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VizteruletCsoportListazasResponse>('post',`${this.basePath}/api/mohosz/teruletijegytermek/ertekesites/vizteruletcsoport-alapadat-listazas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizteruletCsoportCsakKedvencekBeallitas
     * 
     * @param body csakKedvencek
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizteruletCsoportCsakKedvencekBeallitasUsingPOST(body: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public vizteruletCsoportCsakKedvencekBeallitasUsingPOST(body: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public vizteruletCsoportCsakKedvencekBeallitasUsingPOST(body: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public vizteruletCsoportCsakKedvencekBeallitasUsingPOST(body: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizteruletCsoportCsakKedvencekBeallitasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/teruletijegytermek/ertekesites/vizteruletcsoport-csakkedvencek-beallitas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizteruletCsoportCsakKedvencekLekerdezes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizteruletCsoportCsakKedvencekLekerdezesUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public vizteruletCsoportCsakKedvencekLekerdezesUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public vizteruletCsoportCsakKedvencekLekerdezesUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public vizteruletCsoportCsakKedvencekLekerdezesUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/mohosz/teruletijegytermek/ertekesites/vizteruletcsoport-csakkedvencek-lekerdezes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizteruletCsoportKedvencBeallitas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizteruletCsoportKedvencBeallitasUsingPOST(body: VizteruletCsoportRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public vizteruletCsoportKedvencBeallitasUsingPOST(body: VizteruletCsoportRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public vizteruletCsoportKedvencBeallitasUsingPOST(body: VizteruletCsoportRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public vizteruletCsoportKedvencBeallitasUsingPOST(body: VizteruletCsoportRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizteruletCsoportKedvencBeallitasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/teruletijegytermek/ertekesites/vizteruletcsoport-kedvenc-beallitas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizteruletCsoportKedvencTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizteruletCsoportKedvencTorlesUsingPOST(body: VizteruletCsoportRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public vizteruletCsoportKedvencTorlesUsingPOST(body: VizteruletCsoportRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public vizteruletCsoportKedvencTorlesUsingPOST(body: VizteruletCsoportRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public vizteruletCsoportKedvencTorlesUsingPOST(body: VizteruletCsoportRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizteruletCsoportKedvencTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/teruletijegytermek/ertekesites/vizteruletcsoport-kedvenc-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * vizteruletCsoportListazas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizteruletCsoportListazasUsingPOST(body: FindVizteruletCsoportRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<VizteruletCsoportListazasAlapadat>>;
    public vizteruletCsoportListazasUsingPOST(body: FindVizteruletCsoportRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VizteruletCsoportListazasAlapadat>>>;
    public vizteruletCsoportListazasUsingPOST(body: FindVizteruletCsoportRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VizteruletCsoportListazasAlapadat>>>;
    public vizteruletCsoportListazasUsingPOST(body: FindVizteruletCsoportRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling vizteruletCsoportListazasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<VizteruletCsoportListazasAlapadat>>('post',`${this.basePath}/api/mohosz/teruletijegytermek/ertekesites/vizteruletcsoport-listazas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
