/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizteruletFilter { 
    globalSearchStr?: string;
    limit?: number;
    offset?: number;
    queryString?: string;
    szervezetId?: number;
    szervezetIdList?: Array<number>;
    szervezetTipus?: VizteruletFilter.SzervezetTipusEnum;
    vizterkod?: string;
}
export namespace VizteruletFilter {
    export type SzervezetTipusEnum = 'HALGAZDALKODASI_HASZNOSITO' | 'JEGYKIADASRA_JOGOSULT';
    export const SzervezetTipusEnum = {
        HALGAZDALKODASIHASZNOSITO: 'HALGAZDALKODASI_HASZNOSITO' as SzervezetTipusEnum,
        JEGYKIADASRAJOGOSULT: 'JEGYKIADASRA_JOGOSULT' as SzervezetTipusEnum
    };
}