/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizsgaztatasRiportElszamolasItem { 
    jelentkezokSzama?: number;
    kizartSzemelyekSzama?: number;
    megjelentekSzama?: number;
    nemJelentMegJelentkezokSzama?: number;
    sikeresVizsgazokSzama?: number;
    sikertelenVizsgazokSzama?: number;
    vizsgabizottsagId?: number;
    vizsgabizottsagNev?: string;
    vizsgahelyCim?: string;
    vizsgahelyId?: string;
}