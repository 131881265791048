/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { HorgasztatasSzolgaltatasAlapadatRequest } from '../model/horgasztatasSzolgaltatasAlapadatRequest';
import { HorgasztatasSzolgaltatasBundle } from '../model/horgasztatasSzolgaltatasBundle';
import { HorgasztatasSzolgaltatasElhelyezkedesRequest } from '../model/horgasztatasSzolgaltatasElhelyezkedesRequest';
import { HorgasztatasSzolgaltatasFenykepekRequest } from '../model/horgasztatasSzolgaltatasFenykepekRequest';
import { HorgasztatasSzolgaltatasHalallomanyRequest } from '../model/horgasztatasSzolgaltatasHalallomanyRequest';
import { HorgasztatasSzolgaltatasHorgaszrendRequest } from '../model/horgasztatasSzolgaltatasHorgaszrendRequest';
import { HorgasztatasSzolgaltatasJellemzokRequest } from '../model/horgasztatasSzolgaltatasJellemzokRequest';
import { HorgasztatasSzolgaltatasTerkepesInformaciokRequest } from '../model/horgasztatasSzolgaltatasTerkepesInformaciokRequest';
import { HorgasztatasSzolgaltatasTorzsadat } from '../model/horgasztatasSzolgaltatasTorzsadat';
import { HorgasztatasSzolgaltatasZarvatartasModositasRequest } from '../model/horgasztatasSzolgaltatasZarvatartasModositasRequest';
import { HorgasztatasSzolgaltatasZarvatartasRogzitesRequest } from '../model/horgasztatasSzolgaltatasZarvatartasRogzitesRequest';
import { HorgasztatasSzolgaltatasZarvatartasTorlesRequest } from '../model/horgasztatasSzolgaltatasZarvatartasTorlesRequest';
import { SzolgaltatasRequest } from '../model/szolgaltatasRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class HorgasztatasSzolgaltatasControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * alapadatRogzites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public alapadatRogzitesUsingPOST(body: HorgasztatasSzolgaltatasAlapadatRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgasztatasSzolgaltatasBundle>;
    public alapadatRogzitesUsingPOST(body: HorgasztatasSzolgaltatasAlapadatRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgasztatasSzolgaltatasBundle>>;
    public alapadatRogzitesUsingPOST(body: HorgasztatasSzolgaltatasAlapadatRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgasztatasSzolgaltatasBundle>>;
    public alapadatRogzitesUsingPOST(body: HorgasztatasSzolgaltatasAlapadatRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling alapadatRogzitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgasztatasSzolgaltatasBundle>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatas/horgasztatas/szolgaltatas-horgasztatas-alapadatrogzites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * betoltes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public betoltesUsingPOST(body: SzolgaltatasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgasztatasSzolgaltatasBundle>;
    public betoltesUsingPOST(body: SzolgaltatasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgasztatasSzolgaltatasBundle>>;
    public betoltesUsingPOST(body: SzolgaltatasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgasztatasSzolgaltatasBundle>>;
    public betoltesUsingPOST(body: SzolgaltatasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling betoltesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgasztatasSzolgaltatasBundle>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatas/horgasztatas/szolgaltatas-horgasztatas-betoltes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * elhelyezkedesRogzites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public elhelyezkedesRogzitesUsingPOST(body: HorgasztatasSzolgaltatasElhelyezkedesRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgasztatasSzolgaltatasBundle>;
    public elhelyezkedesRogzitesUsingPOST(body: HorgasztatasSzolgaltatasElhelyezkedesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgasztatasSzolgaltatasBundle>>;
    public elhelyezkedesRogzitesUsingPOST(body: HorgasztatasSzolgaltatasElhelyezkedesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgasztatasSzolgaltatasBundle>>;
    public elhelyezkedesRogzitesUsingPOST(body: HorgasztatasSzolgaltatasElhelyezkedesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling elhelyezkedesRogzitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgasztatasSzolgaltatasBundle>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatas/horgasztatas/szolgaltatas-horgasztatas-elhelyezkedes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fenykepekRogzites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fenykepekRogzitesUsingPOST(body: HorgasztatasSzolgaltatasFenykepekRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgasztatasSzolgaltatasBundle>;
    public fenykepekRogzitesUsingPOST(body: HorgasztatasSzolgaltatasFenykepekRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgasztatasSzolgaltatasBundle>>;
    public fenykepekRogzitesUsingPOST(body: HorgasztatasSzolgaltatasFenykepekRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgasztatasSzolgaltatasBundle>>;
    public fenykepekRogzitesUsingPOST(body: HorgasztatasSzolgaltatasFenykepekRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fenykepekRogzitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgasztatasSzolgaltatasBundle>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatas/horgasztatas/szolgaltatas-horgasztatas-fenykepek`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * halallomanyRogzites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public halallomanyRogzitesUsingPOST(body: HorgasztatasSzolgaltatasHalallomanyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgasztatasSzolgaltatasBundle>;
    public halallomanyRogzitesUsingPOST(body: HorgasztatasSzolgaltatasHalallomanyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgasztatasSzolgaltatasBundle>>;
    public halallomanyRogzitesUsingPOST(body: HorgasztatasSzolgaltatasHalallomanyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgasztatasSzolgaltatasBundle>>;
    public halallomanyRogzitesUsingPOST(body: HorgasztatasSzolgaltatasHalallomanyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling halallomanyRogzitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgasztatasSzolgaltatasBundle>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatas/horgasztatas/szolgaltatas-horgasztatas-halallomany`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * horgaszrendFeltoltes
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszrendFeltoltesUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public horgaszrendFeltoltesUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public horgaszrendFeltoltesUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public horgaszrendFeltoltesUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling horgaszrendFeltoltesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatas/horgasztatas/szolgaltatas-horgasztatas-horgaszrendfeltoltes`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * horgaszrendRogzites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszrendRogzitesUsingPOST(body: HorgasztatasSzolgaltatasHorgaszrendRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgasztatasSzolgaltatasBundle>;
    public horgaszrendRogzitesUsingPOST(body: HorgasztatasSzolgaltatasHorgaszrendRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgasztatasSzolgaltatasBundle>>;
    public horgaszrendRogzitesUsingPOST(body: HorgasztatasSzolgaltatasHorgaszrendRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgasztatasSzolgaltatasBundle>>;
    public horgaszrendRogzitesUsingPOST(body: HorgasztatasSzolgaltatasHorgaszrendRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgaszrendRogzitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgasztatasSzolgaltatasBundle>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatas/horgasztatas/szolgaltatas-horgasztatas-horgaszrend`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * jellemzokRogzites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public jellemzokRogzitesUsingPOST(body: HorgasztatasSzolgaltatasJellemzokRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgasztatasSzolgaltatasBundle>;
    public jellemzokRogzitesUsingPOST(body: HorgasztatasSzolgaltatasJellemzokRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgasztatasSzolgaltatasBundle>>;
    public jellemzokRogzitesUsingPOST(body: HorgasztatasSzolgaltatasJellemzokRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgasztatasSzolgaltatasBundle>>;
    public jellemzokRogzitesUsingPOST(body: HorgasztatasSzolgaltatasJellemzokRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling jellemzokRogzitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgasztatasSzolgaltatasBundle>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatas/horgasztatas/szolgaltatas-horgasztatas-jellemzok`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kepFeltoltes
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kepFeltoltesUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public kepFeltoltesUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public kepFeltoltesUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public kepFeltoltesUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling kepFeltoltesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatas/horgasztatas/szolgaltatas-horgasztatas-kepfeltoltes`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * terkepesInformaciok
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public terkepesInformaciokUsingPOST(body: HorgasztatasSzolgaltatasTerkepesInformaciokRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgasztatasSzolgaltatasBundle>;
    public terkepesInformaciokUsingPOST(body: HorgasztatasSzolgaltatasTerkepesInformaciokRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgasztatasSzolgaltatasBundle>>;
    public terkepesInformaciokUsingPOST(body: HorgasztatasSzolgaltatasTerkepesInformaciokRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgasztatasSzolgaltatasBundle>>;
    public terkepesInformaciokUsingPOST(body: HorgasztatasSzolgaltatasTerkepesInformaciokRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling terkepesInformaciokUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgasztatasSzolgaltatasBundle>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatas/horgasztatas/szolgaltatas-horgasztatas-terkepesinformaciok`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * torzsadat
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public torzsadatUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<HorgasztatasSzolgaltatasTorzsadat>;
    public torzsadatUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgasztatasSzolgaltatasTorzsadat>>;
    public torzsadatUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgasztatasSzolgaltatasTorzsadat>>;
    public torzsadatUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HorgasztatasSzolgaltatasTorzsadat>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatas/horgasztatas/szolgaltatas-horgasztatas-torzsadat`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * veglegesites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public veglegesitesUsingPOST(body: SzolgaltatasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgasztatasSzolgaltatasBundle>;
    public veglegesitesUsingPOST(body: SzolgaltatasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgasztatasSzolgaltatasBundle>>;
    public veglegesitesUsingPOST(body: SzolgaltatasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgasztatasSzolgaltatasBundle>>;
    public veglegesitesUsingPOST(body: SzolgaltatasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling veglegesitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgasztatasSzolgaltatasBundle>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatas/horgasztatas/szolgaltatas-horgasztatas-veglegesites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * zarvatartasModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zarvatartasModositasUsingPOST(body: HorgasztatasSzolgaltatasZarvatartasModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgasztatasSzolgaltatasBundle>;
    public zarvatartasModositasUsingPOST(body: HorgasztatasSzolgaltatasZarvatartasModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgasztatasSzolgaltatasBundle>>;
    public zarvatartasModositasUsingPOST(body: HorgasztatasSzolgaltatasZarvatartasModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgasztatasSzolgaltatasBundle>>;
    public zarvatartasModositasUsingPOST(body: HorgasztatasSzolgaltatasZarvatartasModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling zarvatartasModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgasztatasSzolgaltatasBundle>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatas/horgasztatas/szolgaltatas-horgasztatas-zarvatartasmodositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * zarvatartasRogzites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zarvatartasRogzitesUsingPOST(body: HorgasztatasSzolgaltatasZarvatartasRogzitesRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgasztatasSzolgaltatasBundle>;
    public zarvatartasRogzitesUsingPOST(body: HorgasztatasSzolgaltatasZarvatartasRogzitesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgasztatasSzolgaltatasBundle>>;
    public zarvatartasRogzitesUsingPOST(body: HorgasztatasSzolgaltatasZarvatartasRogzitesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgasztatasSzolgaltatasBundle>>;
    public zarvatartasRogzitesUsingPOST(body: HorgasztatasSzolgaltatasZarvatartasRogzitesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling zarvatartasRogzitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgasztatasSzolgaltatasBundle>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatas/horgasztatas/szolgaltatas-horgasztatas-zarvatartasrogzites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * zarvatartasTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zarvatartasTorlesUsingPOST(body: HorgasztatasSzolgaltatasZarvatartasTorlesRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgasztatasSzolgaltatasBundle>;
    public zarvatartasTorlesUsingPOST(body: HorgasztatasSzolgaltatasZarvatartasTorlesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgasztatasSzolgaltatasBundle>>;
    public zarvatartasTorlesUsingPOST(body: HorgasztatasSzolgaltatasZarvatartasTorlesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgasztatasSzolgaltatasBundle>>;
    public zarvatartasTorlesUsingPOST(body: HorgasztatasSzolgaltatasZarvatartasTorlesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling zarvatartasTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgasztatasSzolgaltatasBundle>('post',`${this.basePath}/api/mohosz/horgaszturizmus/szolgaltatas/horgasztatas/szolgaltatas-horgasztatas-zarvatartastorles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
