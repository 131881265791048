/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AllamiJegySztornoRequest { 
    sorszam?: string;
    tipus?: AllamiJegySztornoRequest.TipusEnum;
}
export namespace AllamiJegySztornoRequest {
    export type TipusEnum = 'VEGLEGES' | 'UJRAFELHASZNALHATO' | 'KESZLETROLELVESZETT';
    export const TipusEnum = {
        VEGLEGES: 'VEGLEGES' as TipusEnum,
        UJRAFELHASZNALHATO: 'UJRAFELHASZNALHATO' as TipusEnum,
        KESZLETROLELVESZETT: 'KESZLETROLELVESZETT' as TipusEnum
    };
}