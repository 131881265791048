/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BefizetoAzonositoCsereRequest } from '../model/befizetoAzonositoCsereRequest';
import { BontottVasarlasiElozmenyek } from '../model/bontottVasarlasiElozmenyek';
import { CsekkBefizetesElismerRequest } from '../model/csekkBefizetesElismerRequest';
import { CsekkBefizetesTorlesRequest } from '../model/csekkBefizetesTorlesRequest';
import { CsekkesFizetesElismeres } from '../model/csekkesFizetesElismeres';
import { CsekkesFizetesElismeresFilter } from '../model/csekkesFizetesElismeresFilter';
import { CsekkesFizetesSzemely } from '../model/csekkesFizetesSzemely';
import { FeladovevenyesFizetes } from '../model/feladovevenyesFizetes';
import { FizetesIdsRequest } from '../model/fizetesIdsRequest';
import { KosarIdsRequest } from '../model/kosarIdsRequest';
import { OnlineFizetesAllapotResponse } from '../model/onlineFizetesAllapotResponse';
import { RendszerenKivuliFizetesRogzitesRequest } from '../model/rendszerenKivuliFizetesRogzitesRequest';
import { SzamlaIdsRequest } from '../model/szamlaIdsRequest';
import { SzemelyCsekkesFizetesek } from '../model/szemelyCsekkesFizetesek';
import { SzemelyRequest } from '../model/szemelyRequest';
import { SzereploFizetesMozgatasRequest } from '../model/szereploFizetesMozgatasRequest';
import { SzereploFizetesSzamla } from '../model/szereploFizetesSzamla';
import { ValosBefizetoAzonositoRogzitesRequest } from '../model/valosBefizetoAzonositoRogzitesRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class FizetesMgmtControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * arvaCsekkSzemelyKereses
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public arvaCsekkSzemelyKeresesUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public arvaCsekkSzemelyKeresesUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public arvaCsekkSzemelyKeresesUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public arvaCsekkSzemelyKeresesUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling arvaCsekkSzemelyKeresesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/fizetesmgmt/arvacsekk-szemely-kereses`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * batchFizetesEllenorzes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public batchFizetesEllenorzesUsingPOST(body: FizetesIdsRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public batchFizetesEllenorzesUsingPOST(body: FizetesIdsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public batchFizetesEllenorzesUsingPOST(body: FizetesIdsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public batchFizetesEllenorzesUsingPOST(body: FizetesIdsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling batchFizetesEllenorzesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/fizetesmgmt/batch-fizetes-ellenorzes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * batchSzamlaIssuing
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public batchSzamlaIssuingUsingPOST(body: FizetesIdsRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public batchSzamlaIssuingUsingPOST(body: FizetesIdsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public batchSzamlaIssuingUsingPOST(body: FizetesIdsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public batchSzamlaIssuingUsingPOST(body: FizetesIdsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling batchSzamlaIssuingUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/fizetesmgmt/batch-szamla-issuing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * befizetoAzonositoCsere
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public befizetoAzonositoCsereUsingPOST(body: BefizetoAzonositoCsereRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public befizetoAzonositoCsereUsingPOST(body: BefizetoAzonositoCsereRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public befizetoAzonositoCsereUsingPOST(body: BefizetoAzonositoCsereRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public befizetoAzonositoCsereUsingPOST(body: BefizetoAzonositoCsereRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling befizetoAzonositoCsereUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/fizetesmgmt/befizetoazonosito-csere`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * csekkBefizetesElismer
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public csekkBefizetesElismerUsingPOST(body: CsekkBefizetesElismerRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public csekkBefizetesElismerUsingPOST(body: CsekkBefizetesElismerRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public csekkBefizetesElismerUsingPOST(body: CsekkBefizetesElismerRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public csekkBefizetesElismerUsingPOST(body: CsekkBefizetesElismerRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling csekkBefizetesElismerUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/fizetesmgmt/csekkbefizetes-elismer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * csekkBefizetesElutasitas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public csekkBefizetesElutasitasUsingPOST(body: CsekkBefizetesTorlesRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public csekkBefizetesElutasitasUsingPOST(body: CsekkBefizetesTorlesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public csekkBefizetesElutasitasUsingPOST(body: CsekkBefizetesTorlesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public csekkBefizetesElutasitasUsingPOST(body: CsekkBefizetesTorlesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling csekkBefizetesElutasitasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/fizetesmgmt/csekkbefizetes-elutasitas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * csekkBefizetesTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public csekkBefizetesTorlesUsingPOST(body: CsekkBefizetesTorlesRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public csekkBefizetesTorlesUsingPOST(body: CsekkBefizetesTorlesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public csekkBefizetesTorlesUsingPOST(body: CsekkBefizetesTorlesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public csekkBefizetesTorlesUsingPOST(body: CsekkBefizetesTorlesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling csekkBefizetesTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/fizetesmgmt/csekkbefizetes-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * csekkesFizetesHorgaszKereses
     * 
     * @param body idsRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public csekkesFizetesHorgaszKeresesUsingPOST(body: FizetesIdsRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<CsekkesFizetesElismeres>>;
    public csekkesFizetesHorgaszKeresesUsingPOST(body: FizetesIdsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CsekkesFizetesElismeres>>>;
    public csekkesFizetesHorgaszKeresesUsingPOST(body: FizetesIdsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CsekkesFizetesElismeres>>>;
    public csekkesFizetesHorgaszKeresesUsingPOST(body: FizetesIdsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling csekkesFizetesHorgaszKeresesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<CsekkesFizetesElismeres>>('post',`${this.basePath}/api/mohosz/fizetesmgmt/csekkesfizetes-horgasz-kereses`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * csekkesFizetesSzemelyRogzites
     * 
     * @param body szemely
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public csekkesFizetesSzemelyRogzitesUsingPOST(body: CsekkesFizetesSzemely, observe?: 'body', reportProgress?: boolean): Observable<CsekkesFizetesElismeres>;
    public csekkesFizetesSzemelyRogzitesUsingPOST(body: CsekkesFizetesSzemely, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CsekkesFizetesElismeres>>;
    public csekkesFizetesSzemelyRogzitesUsingPOST(body: CsekkesFizetesSzemely, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CsekkesFizetesElismeres>>;
    public csekkesFizetesSzemelyRogzitesUsingPOST(body: CsekkesFizetesSzemely, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling csekkesFizetesSzemelyRogzitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CsekkesFizetesElismeres>('post',`${this.basePath}/api/mohosz/fizetesmgmt/csekkesfizetes-szemely-rogzites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fecthAllArvaCsv
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fecthAllArvaCsvUsingGET(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public fecthAllArvaCsvUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public fecthAllArvaCsvUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public fecthAllArvaCsvUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/fizetesmgmt/fecthall-arva-csv`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchAllArva
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchAllArvaUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<CsekkesFizetesElismeres>>;
    public fetchAllArvaUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CsekkesFizetesElismeres>>>;
    public fetchAllArvaUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CsekkesFizetesElismeres>>>;
    public fetchAllArvaUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CsekkesFizetesElismeres>>('post',`${this.basePath}/api/mohosz/fizetesmgmt/fecthall-arva`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchAllCsakUgyintezoiElismeres
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchAllCsakUgyintezoiElismeresUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<CsekkesFizetesElismeres>>;
    public fetchAllCsakUgyintezoiElismeresUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CsekkesFizetesElismeres>>>;
    public fetchAllCsakUgyintezoiElismeresUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CsekkesFizetesElismeres>>>;
    public fetchAllCsakUgyintezoiElismeresUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CsekkesFizetesElismeres>>('post',`${this.basePath}/api/mohosz/fizetesmgmt/fecthall-csakugyontezoielismeres`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchAllFeladovevenyesFizetesCsvContent
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchAllFeladovevenyesFizetesCsvContentUsingGET(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public fetchAllFeladovevenyesFizetesCsvContentUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public fetchAllFeladovevenyesFizetesCsvContentUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public fetchAllFeladovevenyesFizetesCsvContentUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/fizetesmgmt/fetchall-feladovevenyesfizetes-csvcontent`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchAllFeladovevenyesFizetes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchAllFeladovevenyesFizetesUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<FeladovevenyesFizetes>>;
    public fetchAllFeladovevenyesFizetesUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FeladovevenyesFizetes>>>;
    public fetchAllFeladovevenyesFizetesUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FeladovevenyesFizetes>>>;
    public fetchAllFeladovevenyesFizetesUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FeladovevenyesFizetes>>('post',`${this.basePath}/api/mohosz/fizetesmgmt/fetchall-feladovevenyesfizetes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchAllSzemelyFizetesSzamlaList
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchAllSzemelyFizetesSzamlaListUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<SzereploFizetesSzamla>>;
    public fetchAllSzemelyFizetesSzamlaListUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SzereploFizetesSzamla>>>;
    public fetchAllSzemelyFizetesSzamlaListUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SzereploFizetesSzamla>>>;
    public fetchAllSzemelyFizetesSzamlaListUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fetchAllSzemelyFizetesSzamlaListUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<SzereploFizetesSzamla>>('post',`${this.basePath}/api/mohosz/fizetesmgmt/fetchall-szemelyfizetesszamla`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchCsvAllCsakUgyintezoiElismeres
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchCsvAllCsakUgyintezoiElismeresUsingGET(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public fetchCsvAllCsakUgyintezoiElismeresUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public fetchCsvAllCsakUgyintezoiElismeresUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public fetchCsvAllCsakUgyintezoiElismeresUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/fizetesmgmt/fecthall-csakugyontezoielismeres-csv`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchSajatBontottVasarlasiElozmenyek
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchSajatBontottVasarlasiElozmenyekUsingGET(observe?: 'body', reportProgress?: boolean): Observable<BontottVasarlasiElozmenyek>;
    public fetchSajatBontottVasarlasiElozmenyekUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BontottVasarlasiElozmenyek>>;
    public fetchSajatBontottVasarlasiElozmenyekUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BontottVasarlasiElozmenyek>>;
    public fetchSajatBontottVasarlasiElozmenyekUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BontottVasarlasiElozmenyek>('get',`${this.basePath}/api/mohosz/fizetesmgmt/fetchsajat-bontottvasarlasielozmenyek`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchSajatSzemelyFizetesSzamlaList
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchSajatSzemelyFizetesSzamlaListUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<SzereploFizetesSzamla>>;
    public fetchSajatSzemelyFizetesSzamlaListUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SzereploFizetesSzamla>>>;
    public fetchSajatSzemelyFizetesSzamlaListUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SzereploFizetesSzamla>>>;
    public fetchSajatSzemelyFizetesSzamlaListUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SzereploFizetesSzamla>>('get',`${this.basePath}/api/mohosz/fizetesmgmt/fetchsajat-szemelyfizetesszamla`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * fetchSzemelyCsekkesFizetes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchSzemelyCsekkesFizetesUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<SzemelyCsekkesFizetesek>;
    public fetchSzemelyCsekkesFizetesUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SzemelyCsekkesFizetesek>>;
    public fetchSzemelyCsekkesFizetesUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SzemelyCsekkesFizetesek>>;
    public fetchSzemelyCsekkesFizetesUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fetchSzemelyCsekkesFizetesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SzemelyCsekkesFizetesek>('post',`${this.basePath}/api/mohosz/fizetesmgmt/fetch-szemelycsekkesfizetesek`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findByFilterArva
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findByFilterArvaUsingPOST(body: CsekkesFizetesElismeresFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<CsekkesFizetesElismeres>>;
    public findByFilterArvaUsingPOST(body: CsekkesFizetesElismeresFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CsekkesFizetesElismeres>>>;
    public findByFilterArvaUsingPOST(body: CsekkesFizetesElismeresFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CsekkesFizetesElismeres>>>;
    public findByFilterArvaUsingPOST(body: CsekkesFizetesElismeresFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findByFilterArvaUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<CsekkesFizetesElismeres>>('post',`${this.basePath}/api/mohosz/fizetesmgmt/findbyfilter-arva`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kosarFix
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kosarFixUsingPOST(body: KosarIdsRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public kosarFixUsingPOST(body: KosarIdsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public kosarFixUsingPOST(body: KosarIdsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public kosarFixUsingPOST(body: KosarIdsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kosarFixUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/fizetesmgmt/kosar-fix`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * onlineStatuszLekerdezes
     * 
     * @param body fizetesId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public onlineStatuszLekerdezesUsingPOST(body: string, observe?: 'body', reportProgress?: boolean): Observable<OnlineFizetesAllapotResponse>;
    public onlineStatuszLekerdezesUsingPOST(body: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OnlineFizetesAllapotResponse>>;
    public onlineStatuszLekerdezesUsingPOST(body: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OnlineFizetesAllapotResponse>>;
    public onlineStatuszLekerdezesUsingPOST(body: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling onlineStatuszLekerdezesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OnlineFizetesAllapotResponse>('post',`${this.basePath}/api/mohosz/fizetesmgmt/fizetes-onlinestatusz-lekerdezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * postaiCsekkesFizetesElismeresFeltoltes
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postaiCsekkesFizetesElismeresFeltoltesUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public postaiCsekkesFizetesElismeresFeltoltesUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public postaiCsekkesFizetesElismeresFeltoltesUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public postaiCsekkesFizetesElismeresFeltoltesUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling postaiCsekkesFizetesElismeresFeltoltesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/fizetesmgmt/postai-csekkesfizeteselismeres-feltoltes`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * postaiCsekkesFizetesElismeresTiffIndexFeltoltes
     * 
     * @param file 
     * @param index index
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postaiCsekkesFizetesElismeresTiffIndexFeltoltesUsingPOST(file: Blob, index: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public postaiCsekkesFizetesElismeresTiffIndexFeltoltesUsingPOST(file: Blob, index: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public postaiCsekkesFizetesElismeresTiffIndexFeltoltesUsingPOST(file: Blob, index: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public postaiCsekkesFizetesElismeresTiffIndexFeltoltesUsingPOST(file: Blob, index: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling postaiCsekkesFizetesElismeresTiffIndexFeltoltesUsingPOST.');
        }

        if (index === null || index === undefined) {
            throw new Error('Required parameter index was null or undefined when calling postaiCsekkesFizetesElismeresTiffIndexFeltoltesUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (index !== undefined && index !== null) {
            queryParameters = queryParameters.set('index', <any>index);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/fizetesmgmt/postai-csekkesfizeteselismeres-tiffindex-feltoltes`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * rendszerenKivuliFizetesElismeres
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rendszerenKivuliFizetesElismeresUsingPOST(body: RendszerenKivuliFizetesRogzitesRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<SzereploFizetesSzamla>>;
    public rendszerenKivuliFizetesElismeresUsingPOST(body: RendszerenKivuliFizetesRogzitesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SzereploFizetesSzamla>>>;
    public rendszerenKivuliFizetesElismeresUsingPOST(body: RendszerenKivuliFizetesRogzitesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SzereploFizetesSzamla>>>;
    public rendszerenKivuliFizetesElismeresUsingPOST(body: RendszerenKivuliFizetesRogzitesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling rendszerenKivuliFizetesElismeresUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<SzereploFizetesSzamla>>('post',`${this.basePath}/api/mohosz/fizetesmgmt/rkbefizetes-elismer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * sajatSzamlaLetoltes
     * 
     * @param szamlaId szamlaId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sajatSzamlaLetoltesUsingGET(szamlaId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sajatSzamlaLetoltesUsingGET(szamlaId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sajatSzamlaLetoltesUsingGET(szamlaId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sajatSzamlaLetoltesUsingGET(szamlaId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (szamlaId === null || szamlaId === undefined) {
            throw new Error('Required parameter szamlaId was null or undefined when calling sajatSzamlaLetoltesUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (szamlaId !== undefined && szamlaId !== null) {
            queryParameters = queryParameters.set('szamlaId', <any>szamlaId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/fizetesmgmt/sajat-szamla-letoltes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szereploFizetesMozgatas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szereploFizetesMozgatasUsingPOST(body: SzereploFizetesMozgatasRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public szereploFizetesMozgatasUsingPOST(body: SzereploFizetesMozgatasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public szereploFizetesMozgatasUsingPOST(body: SzereploFizetesMozgatasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public szereploFizetesMozgatasUsingPOST(body: SzereploFizetesMozgatasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szereploFizetesMozgatasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/fizetesmgmt/szereplo-fizetes-mozgatas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ugyfelszolgalatiSzamlaLetoltes
     * 
     * @param szamlaId szamlaId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ugyfelszolgalatiSzamlaLetoltesUsingGET(szamlaId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public ugyfelszolgalatiSzamlaLetoltesUsingGET(szamlaId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public ugyfelszolgalatiSzamlaLetoltesUsingGET(szamlaId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public ugyfelszolgalatiSzamlaLetoltesUsingGET(szamlaId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (szamlaId === null || szamlaId === undefined) {
            throw new Error('Required parameter szamlaId was null or undefined when calling ugyfelszolgalatiSzamlaLetoltesUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (szamlaId !== undefined && szamlaId !== null) {
            queryParameters = queryParameters.set('szamlaId', <any>szamlaId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/fizetesmgmt/ugyfelszolgalati-szamla-letoltes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateSzamlaDetails
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSzamlaDetailsUsingPOST(body: SzamlaIdsRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateSzamlaDetailsUsingPOST(body: SzamlaIdsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateSzamlaDetailsUsingPOST(body: SzamlaIdsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateSzamlaDetailsUsingPOST(body: SzamlaIdsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateSzamlaDetailsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/fizetesmgmt/update-szamladetails`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * valosBefizetoAzonositoRogzites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public valosBefizetoAzonositoRogzitesUsingPOST(body: ValosBefizetoAzonositoRogzitesRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public valosBefizetoAzonositoRogzitesUsingPOST(body: ValosBefizetoAzonositoRogzitesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public valosBefizetoAzonositoRogzitesUsingPOST(body: ValosBefizetoAzonositoRogzitesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public valosBefizetoAzonositoRogzitesUsingPOST(body: ValosBefizetoAzonositoRogzitesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling valosBefizetoAzonositoRogzitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/fizetesmgmt/valos-befizetoazonosito-rogzites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
