/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizsgaztatasRiportFilter { 
    ev?: number;
    honap?: number;
    idoszak?: VizsgaztatasRiportFilter.IdoszakEnum;
    jelentkezettVizsgaStatusz?: VizsgaztatasRiportFilter.JelentkezettVizsgaStatuszEnum;
    limit?: number;
    negyedev?: VizsgaztatasRiportFilter.NegyedevEnum;
    offset?: number;
    szervezetId?: number;
    validIdoszak?: boolean;
    validOffsetLimit?: boolean;
    vizsgaStatusz?: VizsgaztatasRiportFilter.VizsgaStatuszEnum;
    vizsgabizottsagId?: number;
    vizsgahelyId?: string;
}
export namespace VizsgaztatasRiportFilter {
    export type IdoszakEnum = 'HAVI' | 'NEGYEDEVES' | 'EVES';
    export const IdoszakEnum = {
        HAVI: 'HAVI' as IdoszakEnum,
        NEGYEDEVES: 'NEGYEDEVES' as IdoszakEnum,
        EVES: 'EVES' as IdoszakEnum
    };
    export type JelentkezettVizsgaStatuszEnum = 'JELENTKEZETT' | 'FOLYAMATBAN' | 'KIZART' | 'SIKERES' | 'SIKERTELEN' | 'NEM_JELENT_MEG' | 'MEGHIUSULT';
    export const JelentkezettVizsgaStatuszEnum = {
        JELENTKEZETT: 'JELENTKEZETT' as JelentkezettVizsgaStatuszEnum,
        FOLYAMATBAN: 'FOLYAMATBAN' as JelentkezettVizsgaStatuszEnum,
        KIZART: 'KIZART' as JelentkezettVizsgaStatuszEnum,
        SIKERES: 'SIKERES' as JelentkezettVizsgaStatuszEnum,
        SIKERTELEN: 'SIKERTELEN' as JelentkezettVizsgaStatuszEnum,
        NEMJELENTMEG: 'NEM_JELENT_MEG' as JelentkezettVizsgaStatuszEnum,
        MEGHIUSULT: 'MEGHIUSULT' as JelentkezettVizsgaStatuszEnum
    };
    export type NegyedevEnum = 'ELSO' | 'MASODIK' | 'HARMADIK' | 'NEGYEDIK';
    export const NegyedevEnum = {
        ELSO: 'ELSO' as NegyedevEnum,
        MASODIK: 'MASODIK' as NegyedevEnum,
        HARMADIK: 'HARMADIK' as NegyedevEnum,
        NEGYEDIK: 'NEGYEDIK' as NegyedevEnum
    };
    export type VizsgaStatuszEnum = 'FOLYAMATBAN' | 'INDITHATO' | 'TERVEZETT' | 'LEZARULT' | 'MEGHIUSULT';
    export const VizsgaStatuszEnum = {
        FOLYAMATBAN: 'FOLYAMATBAN' as VizsgaStatuszEnum,
        INDITHATO: 'INDITHATO' as VizsgaStatuszEnum,
        TERVEZETT: 'TERVEZETT' as VizsgaStatuszEnum,
        LEZARULT: 'LEZARULT' as VizsgaStatuszEnum,
        MEGHIUSULT: 'MEGHIUSULT' as VizsgaStatuszEnum
    };
}