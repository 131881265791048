/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NyilatkozatDetails { 
    adatEllenorzesFeladatId?: number;
    allamiJegySorszam?: string;
    hianyIndikatorStatusz?: NyilatkozatDetails.HianyIndikatorStatuszEnum;
}
export namespace NyilatkozatDetails {
    export type HianyIndikatorStatuszEnum = 'KIADOTT' | 'LEADOTT' | 'BETELT' | 'MEGSEMMISULT' | 'ELVESZETT' | 'VEGLEGESEN_STORNOZOTT' | 'KIADOTT_SZTORNO_JOVAHAGYASRA_VAR' | 'KIADOTT_ELVESZETT_JOVAHAGYASRA_VAR' | 'KIADOTT_MEGSEMMISULT_JOVAHAGYASRA_VAR' | 'VISSZAVONT';
    export const HianyIndikatorStatuszEnum = {
        KIADOTT: 'KIADOTT' as HianyIndikatorStatuszEnum,
        LEADOTT: 'LEADOTT' as HianyIndikatorStatuszEnum,
        BETELT: 'BETELT' as HianyIndikatorStatuszEnum,
        MEGSEMMISULT: 'MEGSEMMISULT' as HianyIndikatorStatuszEnum,
        ELVESZETT: 'ELVESZETT' as HianyIndikatorStatuszEnum,
        VEGLEGESENSTORNOZOTT: 'VEGLEGESEN_STORNOZOTT' as HianyIndikatorStatuszEnum,
        KIADOTTSZTORNOJOVAHAGYASRAVAR: 'KIADOTT_SZTORNO_JOVAHAGYASRA_VAR' as HianyIndikatorStatuszEnum,
        KIADOTTELVESZETTJOVAHAGYASRAVAR: 'KIADOTT_ELVESZETT_JOVAHAGYASRA_VAR' as HianyIndikatorStatuszEnum,
        KIADOTTMEGSEMMISULTJOVAHAGYASRAVAR: 'KIADOTT_MEGSEMMISULT_JOVAHAGYASRA_VAR' as HianyIndikatorStatuszEnum,
        VISSZAVONT: 'VISSZAVONT' as HianyIndikatorStatuszEnum
    };
}