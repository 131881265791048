/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MahorElofizetesMigracio { 
    ajto?: string;
    arva?: boolean;
    befcim?: string;
    befirsz?: string;
    befizetesdatuma?: string;
    befizetoemailcime?: string;
    befizetoneve?: string;
    befizetotelefonszama?: string;
    beftelepules?: string;
    cim?: string;
    cimke?: string;
    elofizeteseredete?: string;
    elofizeteskezdete?: string;
    elofizeteslejar?: string;
    elofizeteslejarpostanak?: string;
    elofizetoemailcime?: string;
    elofizetoneve?: string;
    elofizetotelefonszama?: string;
    emelet?: string;
    ertesitestnemker?: string;
    fizetesimod?: string;
    gdpr?: string;
    hasonlosag?: number;
    hazszam?: string;
    horgaszkartya?: string;
    irsz?: string;
    kezbesites?: string;
    kozteruletjelleg?: string;
    kozteruletnev?: string;
    megjegyzes?: string;
    nincshorgaszazonosito?: boolean;
    partnerkod?: string;
    postaiazonosito?: string;
    postanakleadni?: string;
    szamlatker?: string;
    telepules?: string;
    tobbmint12peldanyszam?: string;
}