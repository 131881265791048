/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizteruletEltiltasFilter { 
    filterLabels?: Array<VizteruletEltiltasFilter.FilterLabelsEnum>;
    limit?: number;
    offset?: number;
    queryString?: string;
    statuszok?: Array<VizteruletEltiltasFilter.StatuszokEnum>;
    szemelyId?: number;
    tsQueryString?: string;
    unique4TId?: string;
    vizteruletIds?: Array<number>;
}
export namespace VizteruletEltiltasFilter {
    export type FilterLabelsEnum = 'HORGASZ' | 'NEM_HORGASZ' | 'ELTILTOTT';
    export const FilterLabelsEnum = {
        HORGASZ: 'HORGASZ' as FilterLabelsEnum,
        NEMHORGASZ: 'NEM_HORGASZ' as FilterLabelsEnum,
        ELTILTOTT: 'ELTILTOTT' as FilterLabelsEnum
    };
    export type StatuszokEnum = 'AKTIV' | 'INAKTIV' | 'FELOLDOTT';
    export const StatuszokEnum = {
        AKTIV: 'AKTIV' as StatuszokEnum,
        INAKTIV: 'INAKTIV' as StatuszokEnum,
        FELOLDOTT: 'FELOLDOTT' as StatuszokEnum
    };
}