/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UzenetFilterRequest { 
    feladatElvegezve?: boolean;
    feladatTipus?: UzenetFilterRequest.FeladatTipusEnum;
    feladoSzereploId?: number;
    globalSearchStr?: string;
    kezbesitve?: boolean;
    limit?: number;
    offset?: number;
    tipus?: UzenetFilterRequest.TipusEnum;
    valasz?: UzenetFilterRequest.ValaszEnum;
}
export namespace UzenetFilterRequest {
    export type FeladatTipusEnum = 'TERULETIJEGY_SZTORNO' | 'EAH_SZTORNO';
    export const FeladatTipusEnum = {
        TERULETIJEGYSZTORNO: 'TERULETIJEGY_SZTORNO' as FeladatTipusEnum,
        EAHSZTORNO: 'EAH_SZTORNO' as FeladatTipusEnum
    };
    export type TipusEnum = 'ALTALANOS' | 'SURGOS' | 'FELADAT' | 'ERTESITES';
    export const TipusEnum = {
        ALTALANOS: 'ALTALANOS' as TipusEnum,
        SURGOS: 'SURGOS' as TipusEnum,
        FELADAT: 'FELADAT' as TipusEnum,
        ERTESITES: 'ERTESITES' as TipusEnum
    };
    export type ValaszEnum = 'IGEN' | 'NEM' | 'NINCS';
    export const ValaszEnum = {
        IGEN: 'IGEN' as ValaszEnum,
        NEM: 'NEM' as ValaszEnum,
        NINCS: 'NINCS' as ValaszEnum
    };
}