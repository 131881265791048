/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { KozvetlenErtekesitoArres } from './kozvetlenErtekesitoArres';

export interface TeruletiJegyJutalek { 
    kozvetlenErtekesitoArresList?: Array<KozvetlenErtekesitoArres>;
    kozvetlenMaximum?: number;
    kozvetlenMinimum?: number;
    kozvetlenRendszerhasznalatiDij?: number;
    onlineArres?: number;
    onlineMaximum?: number;
    onlineMinimum?: number;
    sajatOfflineMaximum?: number;
    sajatOfflineMinimum?: number;
    sajatOfflineRendszerhasznalatiDij?: number;
    viszontErtekesitoArres?: number;
    viszontMaximum?: number;
    viszontMinimum?: number;
    viszontMohoszArres?: number;
}