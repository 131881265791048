/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ErtekesitesDetails } from './ertekesitesDetails';

export interface Ertekesites { 
    allamiJegyAr?: number;
    bontottJogosultsag?: Ertekesites.BontottJogosultsagEnum;
    csakFogasiNaplo?: boolean;
    details?: ErtekesitesDetails;
    dijkategoria?: Ertekesites.DijkategoriaEnum;
    ertekesitoSzemelyId?: number;
    ertekesitoSzervezetId?: number;
    eszhAr?: number;
    fogasiNaploAr?: number;
    horgaszSzemelyId?: number;
    id?: number;
    jogosultsag?: Ertekesites.JogosultsagEnum;
    korkategoria?: Ertekesites.KorkategoriaEnum;
    modositasDatuma?: Date;
    sorszam?: string;
    tipus?: Ertekesites.TipusEnum;
}
export namespace Ertekesites {
    export type BontottJogosultsagEnum = 'TELJESJOGU' | 'MENTESITETT' | 'NEMKOTELEZETT' | 'MENTESITETT_70EVFELETTI' | 'MENTESITETT_FOGYATEKKALELO' | 'MENTESITETT_GYERMEK' | 'NEMKOTELEZETT_KULFOLDI' | 'NEMKOTELEZETT_NEMMOHOSZTAG';
    export const BontottJogosultsagEnum = {
        TELJESJOGU: 'TELJESJOGU' as BontottJogosultsagEnum,
        MENTESITETT: 'MENTESITETT' as BontottJogosultsagEnum,
        NEMKOTELEZETT: 'NEMKOTELEZETT' as BontottJogosultsagEnum,
        MENTESITETT70EVFELETTI: 'MENTESITETT_70EVFELETTI' as BontottJogosultsagEnum,
        MENTESITETTFOGYATEKKALELO: 'MENTESITETT_FOGYATEKKALELO' as BontottJogosultsagEnum,
        MENTESITETTGYERMEK: 'MENTESITETT_GYERMEK' as BontottJogosultsagEnum,
        NEMKOTELEZETTKULFOLDI: 'NEMKOTELEZETT_KULFOLDI' as BontottJogosultsagEnum,
        NEMKOTELEZETTNEMMOHOSZTAG: 'NEMKOTELEZETT_NEMMOHOSZTAG' as BontottJogosultsagEnum
    };
    export type DijkategoriaEnum = 'ALAPDIJ' | 'EMELTDIJ';
    export const DijkategoriaEnum = {
        ALAPDIJ: 'ALAPDIJ' as DijkategoriaEnum,
        EMELTDIJ: 'EMELTDIJ' as DijkategoriaEnum
    };
    export type JogosultsagEnum = 'TELJESJOGU' | 'MENTESITETT' | 'NEMKOTELEZETT' | 'MENTESITETT_70EVFELETTI' | 'MENTESITETT_FOGYATEKKALELO' | 'MENTESITETT_GYERMEK' | 'NEMKOTELEZETT_KULFOLDI' | 'NEMKOTELEZETT_NEMMOHOSZTAG';
    export const JogosultsagEnum = {
        TELJESJOGU: 'TELJESJOGU' as JogosultsagEnum,
        MENTESITETT: 'MENTESITETT' as JogosultsagEnum,
        NEMKOTELEZETT: 'NEMKOTELEZETT' as JogosultsagEnum,
        MENTESITETT70EVFELETTI: 'MENTESITETT_70EVFELETTI' as JogosultsagEnum,
        MENTESITETTFOGYATEKKALELO: 'MENTESITETT_FOGYATEKKALELO' as JogosultsagEnum,
        MENTESITETTGYERMEK: 'MENTESITETT_GYERMEK' as JogosultsagEnum,
        NEMKOTELEZETTKULFOLDI: 'NEMKOTELEZETT_KULFOLDI' as JogosultsagEnum,
        NEMKOTELEZETTNEMMOHOSZTAG: 'NEMKOTELEZETT_NEMMOHOSZTAG' as JogosultsagEnum
    };
    export type KorkategoriaEnum = 'GYERMEK' | 'IFJUSAGI' | 'FELNOTT';
    export const KorkategoriaEnum = {
        GYERMEK: 'GYERMEK' as KorkategoriaEnum,
        IFJUSAGI: 'IFJUSAGI' as KorkategoriaEnum,
        FELNOTT: 'FELNOTT' as KorkategoriaEnum
    };
    export type TipusEnum = 'NORMALERTEKESITETT' | 'SZTORNOZOTT' | 'RONTOTTALLAMIJEGY' | 'KESZLETROLELVESZETT';
    export const TipusEnum = {
        NORMALERTEKESITETT: 'NORMALERTEKESITETT' as TipusEnum,
        SZTORNOZOTT: 'SZTORNOZOTT' as TipusEnum,
        RONTOTTALLAMIJEGY: 'RONTOTTALLAMIJEGY' as TipusEnum,
        KESZLETROLELVESZETT: 'KESZLETROLELVESZETT' as TipusEnum
    };
}