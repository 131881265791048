/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { EgyesuletiTagdijArlista } from '../model/egyesuletiTagdijArlista';
import { EgyesuletiTagdijArlistaFilter } from '../model/egyesuletiTagdijArlistaFilter';
import { EgyesuletiTagdijArlistaLetrehozRequest } from '../model/egyesuletiTagdijArlistaLetrehozRequest';
import { EgyesuletiTagdijArlistaModositRequest } from '../model/egyesuletiTagdijArlistaModositRequest';
import { EgyesuletiTagdijArlistaTorlesRequest } from '../model/egyesuletiTagdijArlistaTorlesRequest';
import { SzervezetAdapter } from '../model/szervezetAdapter';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EgyesuletiTagdijArlistaControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * findByFilter
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findByFilterUsingPOST(body: EgyesuletiTagdijArlistaFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<EgyesuletiTagdijArlista>>;
    public findByFilterUsingPOST(body: EgyesuletiTagdijArlistaFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EgyesuletiTagdijArlista>>>;
    public findByFilterUsingPOST(body: EgyesuletiTagdijArlistaFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EgyesuletiTagdijArlista>>>;
    public findByFilterUsingPOST(body: EgyesuletiTagdijArlistaFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findByFilterUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<EgyesuletiTagdijArlista>>('post',`${this.basePath}/api/mohosz/vasarlas/arlista/egyesuletitagdij/findbyfilter`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * letrehoz
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public letrehozUsingPOST(body: EgyesuletiTagdijArlistaLetrehozRequest, observe?: 'body', reportProgress?: boolean): Observable<EgyesuletiTagdijArlista>;
    public letrehozUsingPOST(body: EgyesuletiTagdijArlistaLetrehozRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EgyesuletiTagdijArlista>>;
    public letrehozUsingPOST(body: EgyesuletiTagdijArlistaLetrehozRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EgyesuletiTagdijArlista>>;
    public letrehozUsingPOST(body: EgyesuletiTagdijArlistaLetrehozRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling letrehozUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<EgyesuletiTagdijArlista>('post',`${this.basePath}/api/mohosz/vasarlas/arlista/egyesuletitagdij/letrehoz`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * modosit
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modositUsingPOST(body: EgyesuletiTagdijArlistaModositRequest, observe?: 'body', reportProgress?: boolean): Observable<EgyesuletiTagdijArlista>;
    public modositUsingPOST(body: EgyesuletiTagdijArlistaModositRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EgyesuletiTagdijArlista>>;
    public modositUsingPOST(body: EgyesuletiTagdijArlistaModositRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EgyesuletiTagdijArlista>>;
    public modositUsingPOST(body: EgyesuletiTagdijArlistaModositRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling modositUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<EgyesuletiTagdijArlista>('post',`${this.basePath}/api/mohosz/vasarlas/arlista/egyesuletitagdij/modosit`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * torles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public torlesUsingPOST(body: EgyesuletiTagdijArlistaTorlesRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public torlesUsingPOST(body: EgyesuletiTagdijArlistaTorlesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public torlesUsingPOST(body: EgyesuletiTagdijArlistaTorlesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public torlesUsingPOST(body: EgyesuletiTagdijArlistaTorlesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling torlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/vasarlas/arlista/egyesuletitagdij/torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * valaszthatoSzervezetek
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public valaszthatoSzervezetekUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<SzervezetAdapter>>;
    public valaszthatoSzervezetekUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SzervezetAdapter>>>;
    public valaszthatoSzervezetekUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SzervezetAdapter>>>;
    public valaszthatoSzervezetekUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SzervezetAdapter>>('post',`${this.basePath}/api/mohosz/vasarlas/arlista/egyesuletitagdij/valaszthato-szervezetek`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
