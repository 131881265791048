/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HogyanTovabb { 
    cimSzoveg?: string;
    fejlecGombFelirat?: string;
    gombFelirat?: string;
    gondviselt?: boolean;
    leiras?: string;
    menuLinkSzoveg?: string;
    szemelyId?: number;
    tipus?: HogyanTovabb.TipusEnum;
}
export namespace HogyanTovabb {
    export type TipusEnum = 'UDV' | 'SZEMELYES_ADATOK' | 'REGISZTRACIO' | 'HORGASZKARTYA_IGENYLES' | 'HORGASZKARTYA_KIFIZETES' | 'VASARLASI_ELOZMENYEK' | 'HORGASZKARTYA' | 'VIZSGA' | 'ALLAMI_TURISTA_JEGYEK' | 'TERULETI_JEGY' | 'FOGASI_NAPLO' | 'KARTYA_MEGUJITAS' | 'REGISZTRACIOS_PONT';
    export const TipusEnum = {
        UDV: 'UDV' as TipusEnum,
        SZEMELYESADATOK: 'SZEMELYES_ADATOK' as TipusEnum,
        REGISZTRACIO: 'REGISZTRACIO' as TipusEnum,
        HORGASZKARTYAIGENYLES: 'HORGASZKARTYA_IGENYLES' as TipusEnum,
        HORGASZKARTYAKIFIZETES: 'HORGASZKARTYA_KIFIZETES' as TipusEnum,
        VASARLASIELOZMENYEK: 'VASARLASI_ELOZMENYEK' as TipusEnum,
        HORGASZKARTYA: 'HORGASZKARTYA' as TipusEnum,
        VIZSGA: 'VIZSGA' as TipusEnum,
        ALLAMITURISTAJEGYEK: 'ALLAMI_TURISTA_JEGYEK' as TipusEnum,
        TERULETIJEGY: 'TERULETI_JEGY' as TipusEnum,
        FOGASINAPLO: 'FOGASI_NAPLO' as TipusEnum,
        KARTYAMEGUJITAS: 'KARTYA_MEGUJITAS' as TipusEnum,
        REGISZTRACIOSPONT: 'REGISZTRACIOS_PONT' as TipusEnum
    };
}