/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UzenetDetails { 
    cimzettValaszLehetoseg?: boolean;
    emailKikuldes?: boolean;
    feladatElvegezve?: boolean;
    feladatElvegezveDatum?: Date;
    feladatTipus?: UzenetDetails.FeladatTipusEnum;
    feladoSzereploNev?: string;
    ugyintezoNev?: string;
    ugyintezoSzemelyId?: number;
}
export namespace UzenetDetails {
    export type FeladatTipusEnum = 'TERULETIJEGY_SZTORNO' | 'EAH_SZTORNO';
    export const FeladatTipusEnum = {
        TERULETIJEGYSZTORNO: 'TERULETIJEGY_SZTORNO' as FeladatTipusEnum,
        EAHSZTORNO: 'EAH_SZTORNO' as FeladatTipusEnum
    };
}