/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TeruletiJegyRiportDetails } from './teruletiJegyRiportDetails';
import { TeruletiJegyTermek } from './teruletiJegyTermek';
import { TeruletiKiegeszitoJegyRiport } from './teruletiKiegeszitoJegyRiport';

export interface TeruletiJegyRiport { 
    details?: TeruletiJegyRiportDetails;
    ertekesitesTipus?: TeruletiJegyRiport.ErtekesitesTipusEnum;
    ertekesitoSzemelyId?: number;
    ertekesitoSzervezetId?: number;
    hghJegysorszam?: string;
    horgaszSzemelyId?: number;
    id?: number;
    sorszam?: string;
    teljesAr?: number;
    termekSzervezetId?: number;
    teruletiJegyTermek?: TeruletiJegyTermek;
    teruletiJegyTermekId?: number;
    teruletiKiegeszitoJegyList?: Array<TeruletiKiegeszitoJegyRiport>;
    tipus?: TeruletiJegyRiport.TipusEnum;
    vasarlasDatuma?: Date;
}
export namespace TeruletiJegyRiport {
    export type ErtekesitesTipusEnum = 'ONLINE' | 'SAJAT' | 'KOZVETLEN' | 'VISZONT';
    export const ErtekesitesTipusEnum = {
        ONLINE: 'ONLINE' as ErtekesitesTipusEnum,
        SAJAT: 'SAJAT' as ErtekesitesTipusEnum,
        KOZVETLEN: 'KOZVETLEN' as ErtekesitesTipusEnum,
        VISZONT: 'VISZONT' as ErtekesitesTipusEnum
    };
    export type TipusEnum = 'NORMAL' | 'SZTORNOZOTT' | 'RKSZTORNOZOTT';
    export const TipusEnum = {
        NORMAL: 'NORMAL' as TipusEnum,
        SZTORNOZOTT: 'SZTORNOZOTT' as TipusEnum,
        RKSZTORNOZOTT: 'RKSZTORNOZOTT' as TipusEnum
    };
}