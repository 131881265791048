/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AllamiJegySzervezetOsszesitesItem { 
    arres?: number;
    eladasiAr?: number;
    elszamolasiAr?: number;
    elszamoloSzervezetId?: number;
    elszamoloSzervezetNev?: string;
    ertekesitettDarabszam?: number;
    nemTovabbUtalandoAr?: number;
    osszegzesSzukseges?: boolean;
    rendszerhasznalatiDij?: number;
    sztornozottDarabszam?: number;
    termekAr?: number;
    utalandoEahAr?: number;
    utalandoEszhAr?: number;
    utalandoKeszletrolElveszettAr?: number;
}