/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PostaIntegracio } from './postaIntegracio';

export interface MahorElofizetesCiklus { 
    befizetesDatum?: Date;
    elozoCiklusUtolsoPeldanyDatum?: string;
    elsoPeldanyEv?: number;
    elsoPeldanyHonap?: number;
    ertekesitettTermekId?: string;
    fizetesTipus?: MahorElofizetesCiklus.FizetesTipusEnum;
    gyakorisag?: MahorElofizetesCiklus.GyakorisagEnum;
    id?: number;
    idotartam?: MahorElofizetesCiklus.IdotartamEnum;
    lejaratErtesitesKikuldve?: boolean;
    letrehozasDatum?: Date;
    megujithato?: boolean;
    peldanyszam?: number;
    peldanyszamRequest?: number;
    postaAzonosito?: string;
    postaIntegracioIndex?: number;
    postaIntegracioList?: Array<PostaIntegracio>;
    postaStatusz?: MahorElofizetesCiklus.PostaStatuszEnum;
    rendszerenKivuliBefizetesDatum?: string;
    rendszerenKivuliFizetesTipus?: MahorElofizetesCiklus.RendszerenKivuliFizetesTipusEnum;
    statusz?: MahorElofizetesCiklus.StatuszEnum;
    torolheto?: boolean;
    torolve?: Date;
    utolsoPeldanyEv?: number;
    utolsoPeldanyHonap?: number;
}
export namespace MahorElofizetesCiklus {
    export type FizetesTipusEnum = 'KH' | 'ERTEKESITESIPONT' | 'RK_FIZETES';
    export const FizetesTipusEnum = {
        KH: 'KH' as FizetesTipusEnum,
        ERTEKESITESIPONT: 'ERTEKESITESIPONT' as FizetesTipusEnum,
        RKFIZETES: 'RK_FIZETES' as FizetesTipusEnum
    };
    export type GyakorisagEnum = 'HAVI';
    export const GyakorisagEnum = {
        HAVI: 'HAVI' as GyakorisagEnum
    };
    export type IdotartamEnum = 'EVES' | 'FELEVES';
    export const IdotartamEnum = {
        EVES: 'EVES' as IdotartamEnum,
        FELEVES: 'FELEVES' as IdotartamEnum
    };
    export type PostaStatuszEnum = 'POSTARA_KULDENDO' | 'POSTARA_KULDVE' | 'POSTAN_BEDOLGOZOTT' | 'POSTAN_HIBAS' | 'POSTAN_TOROLVE';
    export const PostaStatuszEnum = {
        POSTARAKULDENDO: 'POSTARA_KULDENDO' as PostaStatuszEnum,
        POSTARAKULDVE: 'POSTARA_KULDVE' as PostaStatuszEnum,
        POSTANBEDOLGOZOTT: 'POSTAN_BEDOLGOZOTT' as PostaStatuszEnum,
        POSTANHIBAS: 'POSTAN_HIBAS' as PostaStatuszEnum,
        POSTANTOROLVE: 'POSTAN_TOROLVE' as PostaStatuszEnum
    };
    export type RendszerenKivuliFizetesTipusEnum = 'CSEKK' | 'ATUTALAS' | 'KESZPENZ' | 'NINCSPENZMOZGAS' | 'EGYEB';
    export const RendszerenKivuliFizetesTipusEnum = {
        CSEKK: 'CSEKK' as RendszerenKivuliFizetesTipusEnum,
        ATUTALAS: 'ATUTALAS' as RendszerenKivuliFizetesTipusEnum,
        KESZPENZ: 'KESZPENZ' as RendszerenKivuliFizetesTipusEnum,
        NINCSPENZMOZGAS: 'NINCSPENZMOZGAS' as RendszerenKivuliFizetesTipusEnum,
        EGYEB: 'EGYEB' as RendszerenKivuliFizetesTipusEnum
    };
    export type StatuszEnum = 'FIZETESRE_VAR' | 'FIZETES_FOLYAMATBAN' | 'TOROLT' | 'AKTIV' | 'LEJART';
    export const StatuszEnum = {
        FIZETESREVAR: 'FIZETESRE_VAR' as StatuszEnum,
        FIZETESFOLYAMATBAN: 'FIZETES_FOLYAMATBAN' as StatuszEnum,
        TOROLT: 'TOROLT' as StatuszEnum,
        AKTIV: 'AKTIV' as StatuszEnum,
        LEJART: 'LEJART' as StatuszEnum
    };
}