/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MahorElofizetesFilter { 
    elofizetesKodList?: Array<string>;
    elofizetesTipusList?: Array<MahorElofizetesFilter.ElofizetesTipusListEnum>;
    ertesitesUtolsoPeldanyEv?: number;
    ertesitesUtolsoPeldanyHonap?: number;
    filterLabelList?: Array<MahorElofizetesFilter.FilterLabelListEnum>;
    globalSearch?: string;
    kezbesitesTipus?: MahorElofizetesFilter.KezbesitesTipusEnum;
    lejaratErtesitesEngedelyezve?: boolean;
    limit?: number;
    offset?: number;
    peldanyEv?: number;
    peldanyHonap?: number;
    postaiStatuszList?: Array<MahorElofizetesFilter.PostaiStatuszListEnum>;
    statuszList?: Array<MahorElofizetesFilter.StatuszListEnum>;
    szereploId?: number;
    tsQueryString?: string;
    utolsoPeldanyEv?: number;
    utolsoPeldanyHonap?: number;
}
export namespace MahorElofizetesFilter {
    export type ElofizetesTipusListEnum = 'NORMAL' | 'ARVA' | 'RENDSZEREN_KIVULI';
    export const ElofizetesTipusListEnum = {
        NORMAL: 'NORMAL' as ElofizetesTipusListEnum,
        ARVA: 'ARVA' as ElofizetesTipusListEnum,
        RENDSZERENKIVULI: 'RENDSZEREN_KIVULI' as ElofizetesTipusListEnum
    };
    export type FilterLabelListEnum = 'HITELESITETT_EMAIL' | 'NEM_HITELESITETT_EMAIL';
    export const FilterLabelListEnum = {
        HITELESITETTEMAIL: 'HITELESITETT_EMAIL' as FilterLabelListEnum,
        NEMHITELESITETTEMAIL: 'NEM_HITELESITETT_EMAIL' as FilterLabelListEnum
    };
    export type KezbesitesTipusEnum = 'LISTAS' | 'CIMZETT';
    export const KezbesitesTipusEnum = {
        LISTAS: 'LISTAS' as KezbesitesTipusEnum,
        CIMZETT: 'CIMZETT' as KezbesitesTipusEnum
    };
    export type PostaiStatuszListEnum = 'POSTARA_KULDENDO' | 'POSTARA_KULDVE' | 'POSTAN_BEDOLGOZOTT' | 'POSTAN_HIBAS' | 'POSTAN_TOROLVE';
    export const PostaiStatuszListEnum = {
        POSTARAKULDENDO: 'POSTARA_KULDENDO' as PostaiStatuszListEnum,
        POSTARAKULDVE: 'POSTARA_KULDVE' as PostaiStatuszListEnum,
        POSTANBEDOLGOZOTT: 'POSTAN_BEDOLGOZOTT' as PostaiStatuszListEnum,
        POSTANHIBAS: 'POSTAN_HIBAS' as PostaiStatuszListEnum,
        POSTANTOROLVE: 'POSTAN_TOROLVE' as PostaiStatuszListEnum
    };
    export type StatuszListEnum = 'FIZETESRE_VAR' | 'FIZETES_FOLYAMATBAN' | 'TOROLT' | 'AKTIV' | 'LEJART';
    export const StatuszListEnum = {
        FIZETESREVAR: 'FIZETESRE_VAR' as StatuszListEnum,
        FIZETESFOLYAMATBAN: 'FIZETES_FOLYAMATBAN' as StatuszListEnum,
        TOROLT: 'TOROLT' as StatuszListEnum,
        AKTIV: 'AKTIV' as StatuszListEnum,
        LEJART: 'LEJART' as StatuszListEnum
    };
}