/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VersenyzoiEngedelyArlistaModositRequest { 
    ar?: number;
    id?: number;
    korkategoria?: VersenyzoiEngedelyArlistaModositRequest.KorkategoriaEnum;
}
export namespace VersenyzoiEngedelyArlistaModositRequest {
    export type KorkategoriaEnum = 'FELNOTT' | 'IFJUSAGI_18_ALATTI' | 'KISKORU_14_ALATTI';
    export const KorkategoriaEnum = {
        FELNOTT: 'FELNOTT' as KorkategoriaEnum,
        IFJUSAGI18ALATTI: 'IFJUSAGI_18_ALATTI' as KorkategoriaEnum,
        KISKORU14ALATTI: 'KISKORU_14_ALATTI' as KorkategoriaEnum
    };
}