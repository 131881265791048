/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { MahorElofizetes } from '../model/mahorElofizetes';
import { MahorElofizetesCiklusRequest } from '../model/mahorElofizetesCiklusRequest';
import { MahorElofizetesErtekesites } from '../model/mahorElofizetesErtekesites';
import { MahorElofizetesErtekesitesFilter } from '../model/mahorElofizetesErtekesitesFilter';
import { MahorElofizetesErtekesitesTorzsadatRequest } from '../model/mahorElofizetesErtekesitesTorzsadatRequest';
import { MahorElofizetesFilter } from '../model/mahorElofizetesFilter';
import { MahorElofizetesRequest } from '../model/mahorElofizetesRequest';
import { MahorIntegracio } from '../model/mahorIntegracio';
import { MahorIntegracioFilter } from '../model/mahorIntegracioFilter';
import { PostaExportRequest } from '../model/postaExportRequest';
import { SzereploKapcsolasRequest } from '../model/szereploKapcsolasRequest';
import { SzereploKeresesRequest } from '../model/szereploKeresesRequest';
import { SzereploKeresesResponse } from '../model/szereploKeresesResponse';
import { SzervezetAdapter } from '../model/szervezetAdapter';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MahorElofizetesRiportControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * betoltes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public betoltesUsingPOST(body: MahorElofizetesRequest, observe?: 'body', reportProgress?: boolean): Observable<MahorElofizetes>;
    public betoltesUsingPOST(body: MahorElofizetesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MahorElofizetes>>;
    public betoltesUsingPOST(body: MahorElofizetesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MahorElofizetes>>;
    public betoltesUsingPOST(body: MahorElofizetesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling betoltesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MahorElofizetes>('post',`${this.basePath}/api/mohosz/mahorelofizetesriport/mahorelofizetes-betoltes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ciklusTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ciklusTorlesUsingPOST(body: MahorElofizetesCiklusRequest, observe?: 'body', reportProgress?: boolean): Observable<MahorElofizetes>;
    public ciklusTorlesUsingPOST(body: MahorElofizetesCiklusRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MahorElofizetes>>;
    public ciklusTorlesUsingPOST(body: MahorElofizetesCiklusRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MahorElofizetes>>;
    public ciklusTorlesUsingPOST(body: MahorElofizetesCiklusRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ciklusTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MahorElofizetes>('post',`${this.basePath}/api/mohosz/mahorelofizetesriport/mahorelofizetes-ciklus-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * csvLetoltes
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public csvLetoltesUsingGET(filter: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public csvLetoltesUsingGET(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public csvLetoltesUsingGET(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public csvLetoltesUsingGET(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling csvLetoltesUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/mahorelofizetesriport/mahorelofizetes-csvletoltes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ertekesitesCsvLetoltes
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ertekesitesCsvLetoltesUsingGET(filter: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public ertekesitesCsvLetoltesUsingGET(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public ertekesitesCsvLetoltesUsingGET(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public ertekesitesCsvLetoltesUsingGET(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling ertekesitesCsvLetoltesUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/mahorelofizetesriport/mahorelofizetes-ertekesites-csvletoltes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ertekesitesListazas
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ertekesitesListazasUsingPOST(body: MahorElofizetesErtekesitesFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<MahorElofizetesErtekesites>>;
    public ertekesitesListazasUsingPOST(body: MahorElofizetesErtekesitesFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MahorElofizetesErtekesites>>>;
    public ertekesitesListazasUsingPOST(body: MahorElofizetesErtekesitesFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MahorElofizetesErtekesites>>>;
    public ertekesitesListazasUsingPOST(body: MahorElofizetesErtekesitesFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ertekesitesListazasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<MahorElofizetesErtekesites>>('post',`${this.basePath}/api/mohosz/mahorelofizetesriport/mahorelofizetes-ertekesites-listazas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ertekesitesSzamossag
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ertekesitesSzamossagUsingPOST(body: MahorElofizetesErtekesitesFilter, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public ertekesitesSzamossagUsingPOST(body: MahorElofizetesErtekesitesFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public ertekesitesSzamossagUsingPOST(body: MahorElofizetesErtekesitesFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public ertekesitesSzamossagUsingPOST(body: MahorElofizetesErtekesitesFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ertekesitesSzamossagUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/mahorelofizetesriport/mahorelofizetes-ertekesites-szamossag`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ertekesitesTorzsadat
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ertekesitesTorzsadatUsingPOST(body: MahorElofizetesErtekesitesTorzsadatRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<SzervezetAdapter>>;
    public ertekesitesTorzsadatUsingPOST(body: MahorElofizetesErtekesitesTorzsadatRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SzervezetAdapter>>>;
    public ertekesitesTorzsadatUsingPOST(body: MahorElofizetesErtekesitesTorzsadatRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SzervezetAdapter>>>;
    public ertekesitesTorzsadatUsingPOST(body: MahorElofizetesErtekesitesTorzsadatRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ertekesitesTorzsadatUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<SzervezetAdapter>>('post',`${this.basePath}/api/mohosz/mahorelofizetesriport/mahorelofizetes-ertekesites-torzsadat`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * integraciosAllomanyLetoltes
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public integraciosAllomanyLetoltesUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public integraciosAllomanyLetoltesUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public integraciosAllomanyLetoltesUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public integraciosAllomanyLetoltesUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling integraciosAllomanyLetoltesUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/mahorelofizetesriport/mahorelofizetes-integracio-letoltes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * integraciosAllomanyListazas
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public integraciosAllomanyListazasUsingPOST(body: MahorIntegracioFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<MahorIntegracio>>;
    public integraciosAllomanyListazasUsingPOST(body: MahorIntegracioFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MahorIntegracio>>>;
    public integraciosAllomanyListazasUsingPOST(body: MahorIntegracioFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MahorIntegracio>>>;
    public integraciosAllomanyListazasUsingPOST(body: MahorIntegracioFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling integraciosAllomanyListazasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<MahorIntegracio>>('post',`${this.basePath}/api/mohosz/mahorelofizetesriport/mahorelofizetes-integracio-listazas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * integraciosAllomanySzamossag
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public integraciosAllomanySzamossagUsingPOST(body: MahorIntegracioFilter, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public integraciosAllomanySzamossagUsingPOST(body: MahorIntegracioFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public integraciosAllomanySzamossagUsingPOST(body: MahorIntegracioFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public integraciosAllomanySzamossagUsingPOST(body: MahorIntegracioFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling integraciosAllomanySzamossagUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/mahorelofizetesriport/mahorelofizetes-integracio-szamossag`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * listazas
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listazasUsingPOST(body: MahorElofizetesFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<MahorElofizetes>>;
    public listazasUsingPOST(body: MahorElofizetesFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MahorElofizetes>>>;
    public listazasUsingPOST(body: MahorElofizetesFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MahorElofizetes>>>;
    public listazasUsingPOST(body: MahorElofizetesFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling listazasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<MahorElofizetes>>('post',`${this.basePath}/api/mohosz/mahorelofizetesriport/mahorelofizetes-listazas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * postaExport
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postaExportUsingPOST(body: PostaExportRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public postaExportUsingPOST(body: PostaExportRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public postaExportUsingPOST(body: PostaExportRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public postaExportUsingPOST(body: PostaExportRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling postaExportUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/mahorelofizetesriport/mahorelofizetes-postaexport`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * postaImport
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postaImportUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public postaImportUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public postaImportUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public postaImportUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling postaImportUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/mahorelofizetesriport/mahorelofizetes-postaimport`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * rendszerenKivuliMinosites
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rendszerenKivuliMinositesUsingPOST(body: MahorElofizetesRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public rendszerenKivuliMinositesUsingPOST(body: MahorElofizetesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public rendszerenKivuliMinositesUsingPOST(body: MahorElofizetesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public rendszerenKivuliMinositesUsingPOST(body: MahorElofizetesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling rendszerenKivuliMinositesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/mahorelofizetesriport/mahorelofizetes-rendszerenkivuli-minosites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szamossag
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szamossagUsingPOST(body: MahorElofizetesFilter, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public szamossagUsingPOST(body: MahorElofizetesFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public szamossagUsingPOST(body: MahorElofizetesFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public szamossagUsingPOST(body: MahorElofizetesFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szamossagUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/mahorelofizetesriport/mahorelofizetes-szamossag`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szereploKapcsolas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szereploKapcsolasUsingPOST(body: SzereploKapcsolasRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public szereploKapcsolasUsingPOST(body: SzereploKapcsolasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public szereploKapcsolasUsingPOST(body: SzereploKapcsolasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public szereploKapcsolasUsingPOST(body: SzereploKapcsolasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szereploKapcsolasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/mahorelofizetesriport/mahorelofizetes-szereplo-kapcsolas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szereploKereses
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szereploKeresesUsingPOST(body: SzereploKeresesRequest, observe?: 'body', reportProgress?: boolean): Observable<SzereploKeresesResponse>;
    public szereploKeresesUsingPOST(body: SzereploKeresesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SzereploKeresesResponse>>;
    public szereploKeresesUsingPOST(body: SzereploKeresesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SzereploKeresesResponse>>;
    public szereploKeresesUsingPOST(body: SzereploKeresesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szereploKeresesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SzereploKeresesResponse>('post',`${this.basePath}/api/mohosz/mahorelofizetesriport/mahorelofizetes-szereplo-kereses`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szereploLevalasztas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szereploLevalasztasUsingPOST(body: MahorElofizetesRequest, observe?: 'body', reportProgress?: boolean): Observable<MahorElofizetes>;
    public szereploLevalasztasUsingPOST(body: MahorElofizetesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MahorElofizetes>>;
    public szereploLevalasztasUsingPOST(body: MahorElofizetesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MahorElofizetes>>;
    public szereploLevalasztasUsingPOST(body: MahorElofizetesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szereploLevalasztasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MahorElofizetes>('post',`${this.basePath}/api/mohosz/mahorelofizetesriport/mahorelofizetes-szereplo-levalasztas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
