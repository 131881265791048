/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BerbeadasSzolgaltatasTorzsadat { 
    lepesVegrehajtasiSorrend?: Array<BerbeadasSzolgaltatasTorzsadat.LepesVegrehajtasiSorrendEnum>;
}
export namespace BerbeadasSzolgaltatasTorzsadat {
    export type LepesVegrehajtasiSorrendEnum = 'ALTALANOSLEIRAS' | 'ELHELYEZKEDES' | 'FENYKEPEK' | 'OSSZEGZES';
    export const LepesVegrehajtasiSorrendEnum = {
        ALTALANOSLEIRAS: 'ALTALANOSLEIRAS' as LepesVegrehajtasiSorrendEnum,
        ELHELYEZKEDES: 'ELHELYEZKEDES' as LepesVegrehajtasiSorrendEnum,
        FENYKEPEK: 'FENYKEPEK' as LepesVegrehajtasiSorrendEnum,
        OSSZEGZES: 'OSSZEGZES' as LepesVegrehajtasiSorrendEnum
    };
}