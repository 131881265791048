/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface EgyesuletTagsagKategoriaUpdateRequest { 
    ervenyessegiEv?: number;
    tagEgyesuletId?: number;
    tagsagKategoriaList?: Array<EgyesuletTagsagKategoriaUpdateRequest.TagsagKategoriaListEnum>;
}
export namespace EgyesuletTagsagKategoriaUpdateRequest {
    export type TagsagKategoriaListEnum = 'IFJUSAGI' | 'FELNOTT' | 'FELNOTT_KEDVEZMENYEZETT' | 'IFJUSAGI_IHJ' | 'FELNOTT_IHJ' | 'FELNOTT_JOGFENNTARTO_IHJ' | 'MOHOSZON_KIVULI';
    export const TagsagKategoriaListEnum = {
        IFJUSAGI: 'IFJUSAGI' as TagsagKategoriaListEnum,
        FELNOTT: 'FELNOTT' as TagsagKategoriaListEnum,
        FELNOTTKEDVEZMENYEZETT: 'FELNOTT_KEDVEZMENYEZETT' as TagsagKategoriaListEnum,
        IFJUSAGIIHJ: 'IFJUSAGI_IHJ' as TagsagKategoriaListEnum,
        FELNOTTIHJ: 'FELNOTT_IHJ' as TagsagKategoriaListEnum,
        FELNOTTJOGFENNTARTOIHJ: 'FELNOTT_JOGFENNTARTO_IHJ' as TagsagKategoriaListEnum,
        MOHOSZONKIVULI: 'MOHOSZON_KIVULI' as TagsagKategoriaListEnum
    };
}