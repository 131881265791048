/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizsgabizottsagVizsgaFilterRequest { 
    csakSajatVizsgaim?: boolean;
    globalSearch?: string;
    statusz?: VizsgabizottsagVizsgaFilterRequest.StatuszEnum;
    vizsgabizottsagId?: number;
}
export namespace VizsgabizottsagVizsgaFilterRequest {
    export type StatuszEnum = 'FOLYAMATBAN' | 'INDITHATO' | 'TERVEZETT' | 'LEZARULT' | 'MEGHIUSULT';
    export const StatuszEnum = {
        FOLYAMATBAN: 'FOLYAMATBAN' as StatuszEnum,
        INDITHATO: 'INDITHATO' as StatuszEnum,
        TERVEZETT: 'TERVEZETT' as StatuszEnum,
        LEZARULT: 'LEZARULT' as StatuszEnum,
        MEGHIUSULT: 'MEGHIUSULT' as StatuszEnum
    };
}