/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AllamiJegyVasarlasRequest { 
    ervenyessegiEv?: number;
    fogyatekkalElo?: boolean;
    hatarozatKiallitasDatuma?: string;
    hatarozatKiallitoNev?: string;
    sorszam?: number;
    szemelyId?: number;
    tagEgyesuletId?: number;
    valasztottKorkategoria?: AllamiJegyVasarlasRequest.ValasztottKorkategoriaEnum;
    valasztottTagsagKategoria?: AllamiJegyVasarlasRequest.ValasztottTagsagKategoriaEnum;
    validHatarozatszam?: boolean;
}
export namespace AllamiJegyVasarlasRequest {
    export type ValasztottKorkategoriaEnum = 'GYERMEK' | 'IFJUSAGI' | 'FELNOTT';
    export const ValasztottKorkategoriaEnum = {
        GYERMEK: 'GYERMEK' as ValasztottKorkategoriaEnum,
        IFJUSAGI: 'IFJUSAGI' as ValasztottKorkategoriaEnum,
        FELNOTT: 'FELNOTT' as ValasztottKorkategoriaEnum
    };
    export type ValasztottTagsagKategoriaEnum = 'IFJUSAGI' | 'FELNOTT' | 'FELNOTT_KEDVEZMENYEZETT' | 'IFJUSAGI_IHJ' | 'FELNOTT_IHJ' | 'FELNOTT_JOGFENNTARTO_IHJ' | 'MOHOSZON_KIVULI';
    export const ValasztottTagsagKategoriaEnum = {
        IFJUSAGI: 'IFJUSAGI' as ValasztottTagsagKategoriaEnum,
        FELNOTT: 'FELNOTT' as ValasztottTagsagKategoriaEnum,
        FELNOTTKEDVEZMENYEZETT: 'FELNOTT_KEDVEZMENYEZETT' as ValasztottTagsagKategoriaEnum,
        IFJUSAGIIHJ: 'IFJUSAGI_IHJ' as ValasztottTagsagKategoriaEnum,
        FELNOTTIHJ: 'FELNOTT_IHJ' as ValasztottTagsagKategoriaEnum,
        FELNOTTJOGFENNTARTOIHJ: 'FELNOTT_JOGFENNTARTO_IHJ' as ValasztottTagsagKategoriaEnum,
        MOHOSZONKIVULI: 'MOHOSZON_KIVULI' as ValasztottTagsagKategoriaEnum
    };
}