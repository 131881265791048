/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GPSKoordinata } from './gPSKoordinata';
import { HorgaszturizmusPoiDetails } from './horgaszturizmusPoiDetails';

export interface HorgaszturizmusPoi { 
    details?: HorgaszturizmusPoiDetails;
    id?: number;
    koordinata?: GPSKoordinata;
    megnevezes?: string;
    megyeKod?: string;
    type?: HorgaszturizmusPoi.TypeEnum;
}
export namespace HorgaszturizmusPoi {
    export type TypeEnum = 'VIZTERULET' | 'BERBEADAS';
    export const TypeEnum = {
        VIZTERULET: 'VIZTERULET' as TypeEnum,
        BERBEADAS: 'BERBEADAS' as TypeEnum
    };
}