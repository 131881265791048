/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { VersenyCsapatResztvevo } from './versenyCsapatResztvevo';
import { VersenyEgyeniResztvevo } from './versenyEgyeniResztvevo';

export interface SzemelyVerseny { 
    azonosito?: string;
    egyeniJelentkezo?: VersenyEgyeniResztvevo;
    email?: string;
    id?: number;
    isCsapatverseny?: boolean;
    jelentkezesKezdeteIdopont?: string;
    jelentkezesVegeIdopont?: string;
    jelentkezettCsapat?: VersenyCsapatResztvevo;
    kezdesIdopont?: Date;
    letszam?: number;
    maxCsapatletszam?: number;
    maxEgyeniJelentkezo?: number;
    maxKorhatar?: number;
    maxLetszam?: number;
    maxResztvevoCsapat?: number;
    minCsapatletszam?: number;
    minKorhatar?: number;
    nev?: string;
    nevezesiDij?: number;
    statusz?: SzemelyVerseny.StatuszEnum;
    szemelyAvailableCommands?: Array<SzemelyVerseny.SzemelyAvailableCommandsEnum>;
    szemelyId?: number;
    szemelyNev?: string;
    telefonszam?: string;
    vegeIdopont?: Date;
    versenyAvailableCommands?: Array<SzemelyVerseny.VersenyAvailableCommandsEnum>;
    versenyKiiras?: string;
    versenyszabalyzatUrl?: string;
}
export namespace SzemelyVerseny {
    export type StatuszEnum = 'TERVEZETT' | 'JELENTKEZES_IDO_ALATT' | 'LEZART_JELENTKEZES' | 'LEZART_VERSENY';
    export const StatuszEnum = {
        TERVEZETT: 'TERVEZETT' as StatuszEnum,
        JELENTKEZESIDOALATT: 'JELENTKEZES_IDO_ALATT' as StatuszEnum,
        LEZARTJELENTKEZES: 'LEZART_JELENTKEZES' as StatuszEnum,
        LEZARTVERSENY: 'LEZART_VERSENY' as StatuszEnum
    };
    export type SzemelyAvailableCommandsEnum = 'TORLES' | 'FIZETES' | 'FIZETES_FOLYTATAS' | 'ATHELYEZES' | 'KAPCSOLATTARTAS_MODOSITAS' | 'MENTES';
    export const SzemelyAvailableCommandsEnum = {
        TORLES: 'TORLES' as SzemelyAvailableCommandsEnum,
        FIZETES: 'FIZETES' as SzemelyAvailableCommandsEnum,
        FIZETESFOLYTATAS: 'FIZETES_FOLYTATAS' as SzemelyAvailableCommandsEnum,
        ATHELYEZES: 'ATHELYEZES' as SzemelyAvailableCommandsEnum,
        KAPCSOLATTARTASMODOSITAS: 'KAPCSOLATTARTAS_MODOSITAS' as SzemelyAvailableCommandsEnum,
        MENTES: 'MENTES' as SzemelyAvailableCommandsEnum
    };
    export type VersenyAvailableCommandsEnum = 'MEGTEKINTES' | 'MODOSITAS' | 'TORLES' | 'JELENTKEZES' | 'DIJMODOSITAS';
    export const VersenyAvailableCommandsEnum = {
        MEGTEKINTES: 'MEGTEKINTES' as VersenyAvailableCommandsEnum,
        MODOSITAS: 'MODOSITAS' as VersenyAvailableCommandsEnum,
        TORLES: 'TORLES' as VersenyAvailableCommandsEnum,
        JELENTKEZES: 'JELENTKEZES' as VersenyAvailableCommandsEnum,
        DIJMODOSITAS: 'DIJMODOSITAS' as VersenyAvailableCommandsEnum
    };
}