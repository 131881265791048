/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Feltoltes } from './feltoltes';

export interface SzerzodesErtekesitesLimit { 
    felfuggesztve?: boolean;
    feltoltesList?: Array<Feltoltes>;
    figyelmeztetesHatarertek?: number;
    hatarertekAlattiLimit?: boolean;
    limit?: number;
    megbizoSzervezetId?: number;
    megbizoSzervezetNev?: string;
    megbizottSzervezetId?: number;
    megbizottSzervezetNev?: string;
}