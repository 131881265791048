/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Horgaszmodszer { 
    horgaszmodszer?: Horgaszmodszer.HorgaszmodszerEnum;
    horgaszmodszerAltipusList?: Array<Horgaszmodszer.HorgaszmodszerAltipusListEnum>;
}
export namespace Horgaszmodszer {
    export type HorgaszmodszerEnum = 'FENEKEZOS_HORGASZAT' | 'USZOS_HORGASZAT' | 'PERGETO_HORGASZAT' | 'MULEGYEZO_HORGASZAT' | 'EGYEB_HORGASZMODSZEREK';
    export const HorgaszmodszerEnum = {
        FENEKEZOSHORGASZAT: 'FENEKEZOS_HORGASZAT' as HorgaszmodszerEnum,
        USZOSHORGASZAT: 'USZOS_HORGASZAT' as HorgaszmodszerEnum,
        PERGETOHORGASZAT: 'PERGETO_HORGASZAT' as HorgaszmodszerEnum,
        MULEGYEZOHORGASZAT: 'MULEGYEZO_HORGASZAT' as HorgaszmodszerEnum,
        EGYEBHORGASZMODSZEREK: 'EGYEB_HORGASZMODSZEREK' as HorgaszmodszerEnum
    };
    export type HorgaszmodszerAltipusListEnum = 'KLASSZIKUS_FENEKEZES' | 'BOJLIS_HORGASZAT' | 'FEEDER_BOTOS_HORGASZAT' | 'RAGADOZO_HALAS_FENEKEZOS_HORGASZAT' | 'SPICCBOTOS_HORGASZAT' | 'BOLOGNAI_BOTOS_HORGASZAT' | 'RAKOS_BOTOS_HORGASZAT' | 'MATCH_BOTOS_HORGASZAT' | 'USZOS_RAGADOZO_HALAS_HORGASZAT' | 'WOBBLEREZES' | 'JIGELES' | 'DROPSHOT' | 'VERTIKALIS' | 'MARTOGATAS_TAPOGATAS' | 'KUTTYOGTATAS' | 'MORMISKAZAS';
    export const HorgaszmodszerAltipusListEnum = {
        KLASSZIKUSFENEKEZES: 'KLASSZIKUS_FENEKEZES' as HorgaszmodszerAltipusListEnum,
        BOJLISHORGASZAT: 'BOJLIS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        FEEDERBOTOSHORGASZAT: 'FEEDER_BOTOS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        RAGADOZOHALASFENEKEZOSHORGASZAT: 'RAGADOZO_HALAS_FENEKEZOS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        SPICCBOTOSHORGASZAT: 'SPICCBOTOS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        BOLOGNAIBOTOSHORGASZAT: 'BOLOGNAI_BOTOS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        RAKOSBOTOSHORGASZAT: 'RAKOS_BOTOS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        MATCHBOTOSHORGASZAT: 'MATCH_BOTOS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        USZOSRAGADOZOHALASHORGASZAT: 'USZOS_RAGADOZO_HALAS_HORGASZAT' as HorgaszmodszerAltipusListEnum,
        WOBBLEREZES: 'WOBBLEREZES' as HorgaszmodszerAltipusListEnum,
        JIGELES: 'JIGELES' as HorgaszmodszerAltipusListEnum,
        DROPSHOT: 'DROPSHOT' as HorgaszmodszerAltipusListEnum,
        VERTIKALIS: 'VERTIKALIS' as HorgaszmodszerAltipusListEnum,
        MARTOGATASTAPOGATAS: 'MARTOGATAS_TAPOGATAS' as HorgaszmodszerAltipusListEnum,
        KUTTYOGTATAS: 'KUTTYOGTATAS' as HorgaszmodszerAltipusListEnum,
        MORMISKAZAS: 'MORMISKAZAS' as HorgaszmodszerAltipusListEnum
    };
}