/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Szemely } from './szemely';

export interface HorgaszkartyaGyartas { 
    availableCommands?: Array<HorgaszkartyaGyartas.AvailableCommandsEnum>;
    ervenyessegKezdete?: string;
    ervenyessegVege?: string;
    gyartasraKuldesDatuma?: Date;
    horgaszAzonosito?: string;
    igenylesId?: number;
    modosithato?: boolean;
    postazasDatuma?: string;
    statusz?: HorgaszkartyaGyartas.StatuszEnum;
    szemely?: Szemely;
    szemelyId?: number;
}
export namespace HorgaszkartyaGyartas {
    export type AvailableCommandsEnum = 'GYARTAS_VISSZATARTAS';
    export const AvailableCommandsEnum = {
        VISSZATARTAS: 'GYARTAS_VISSZATARTAS' as AvailableCommandsEnum
    };
    export type StatuszEnum = 'GYARTAS_JOVAHAGYASRA_VAR' | 'GYARTASRA_VAR' | 'GYARTAS_FOLYAMATBAN' | 'GYARTAS_KESZ';
    export const StatuszEnum = {
        GYARTASJOVAHAGYASRAVAR: 'GYARTAS_JOVAHAGYASRA_VAR' as StatuszEnum,
        GYARTASRAVAR: 'GYARTASRA_VAR' as StatuszEnum,
        GYARTASFOLYAMATBAN: 'GYARTAS_FOLYAMATBAN' as StatuszEnum,
        GYARTASKESZ: 'GYARTAS_KESZ' as StatuszEnum
    };
}