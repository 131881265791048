/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizsgaTortenet { 
    availableCommands?: Array<VizsgaTortenet.AvailableCommandsEnum>;
    feltettKerdesSzama?: number;
    helyesValaszokSzama?: number;
    helytelenValaszokSzama?: number;
    kezdetDatum?: Date;
    kihagyottKerdesekSzama?: number;
    sikeressegHatara?: number;
    statusz?: VizsgaTortenet.StatuszEnum;
    tipus?: VizsgaTortenet.TipusEnum;
    vegeDatum?: Date;
    vizsgaId?: number;
}
export namespace VizsgaTortenet {
    export type AvailableCommandsEnum = 'TORLES';
    export const AvailableCommandsEnum = {
        TORLES: 'TORLES' as AvailableCommandsEnum
    };
    export type StatuszEnum = 'FOLYAMATBAN' | 'MEGSZAKITOTT' | 'SIKERES' | 'SIKERTELEN' | 'KIZART' | 'MEGHIUSULT';
    export const StatuszEnum = {
        FOLYAMATBAN: 'FOLYAMATBAN' as StatuszEnum,
        MEGSZAKITOTT: 'MEGSZAKITOTT' as StatuszEnum,
        SIKERES: 'SIKERES' as StatuszEnum,
        SIKERTELEN: 'SIKERTELEN' as StatuszEnum,
        KIZART: 'KIZART' as StatuszEnum,
        MEGHIUSULT: 'MEGHIUSULT' as StatuszEnum
    };
    export type TipusEnum = 'TURISTA' | 'ALLAMI';
    export const TipusEnum = {
        TURISTA: 'TURISTA' as TipusEnum,
        ALLAMI: 'ALLAMI' as TipusEnum
    };
}