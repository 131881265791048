/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Fogas } from './fogas';

export interface FogasNyilvantartas { 
    fogasIdSequence?: number;
    fogasiNaploId?: string;
    fogasok?: Array<Fogas>;
    horgaszattalToltottNapokSzama?: number;
    rogzitesAlatt?: boolean;
    rogzitesIdopont?: Date;
    rogzitett?: boolean;
    rogzitoSzervezetId?: number;
    rogzitoUgyintezoSzemelyId?: number;
    statusz?: FogasNyilvantartas.StatuszEnum;
}
export namespace FogasNyilvantartas {
    export type StatuszEnum = 'ROGZITES_ALATT' | 'ROGZITETT';
    export const StatuszEnum = {
        ROGZITESALATT: 'ROGZITES_ALATT' as StatuszEnum,
        ROGZITETT: 'ROGZITETT' as StatuszEnum
    };
}