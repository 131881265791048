/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Versenyzo { 
    availableCommands?: Array<Versenyzo.AvailableCommandsEnum>;
    horgaszAzonosito?: string;
    jelentkezesIdopont?: Date;
    szemelyEmail?: string;
    szemelyId?: number;
    szemelyNev?: string;
    szemelyTelefonszam?: string;
    szerepkor?: Versenyzo.SzerepkorEnum;
}
export namespace Versenyzo {
    export type AvailableCommandsEnum = 'TORLES' | 'ATHELYEZES' | 'MODOSITAS';
    export const AvailableCommandsEnum = {
        TORLES: 'TORLES' as AvailableCommandsEnum,
        ATHELYEZES: 'ATHELYEZES' as AvailableCommandsEnum,
        MODOSITAS: 'MODOSITAS' as AvailableCommandsEnum
    };
    export type SzerepkorEnum = 'VERSENYZO' | 'TULAJDONOS' | 'VERSENYZO_TULAJDONOS';
    export const SzerepkorEnum = {
        VERSENYZO: 'VERSENYZO' as SzerepkorEnum,
        TULAJDONOS: 'TULAJDONOS' as SzerepkorEnum,
        VERSENYZOTULAJDONOS: 'VERSENYZO_TULAJDONOS' as SzerepkorEnum
    };
}