/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BerbeadasSzolgaltatasVegrehajtottLepes { 
    lepes?: BerbeadasSzolgaltatasVegrehajtottLepes.LepesEnum;
    teljesKitoltottseg?: boolean;
}
export namespace BerbeadasSzolgaltatasVegrehajtottLepes {
    export type LepesEnum = 'ALTALANOSLEIRAS' | 'ELHELYEZKEDES' | 'FENYKEPEK' | 'OSSZEGZES';
    export const LepesEnum = {
        ALTALANOSLEIRAS: 'ALTALANOSLEIRAS' as LepesEnum,
        ELHELYEZKEDES: 'ELHELYEZKEDES' as LepesEnum,
        FENYKEPEK: 'FENYKEPEK' as LepesEnum,
        OSSZEGZES: 'OSSZEGZES' as LepesEnum
    };
}