/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface EgyesuletiTagsagRiportFilter { 
    ervenyessegiEv?: number;
    ervenyessegiEvHonapok?: Array<number>;
    filterLabels?: Array<EgyesuletiTagsagRiportFilter.FilterLabelsEnum>;
    limit?: number;
    offset?: number;
    statuszok?: Array<EgyesuletiTagsagRiportFilter.StatuszokEnum>;
    szervezetId?: number;
    tagsagKategoriaList?: Array<EgyesuletiTagsagRiportFilter.TagsagKategoriaListEnum>;
    tsQueryString?: string;
}
export namespace EgyesuletiTagsagRiportFilter {
    export type FilterLabelsEnum = 'HITELESITETT_EMAIL' | 'FELHASZNALO_AZONOSITO' | 'GONDVISELOS_HORGASZ';
    export const FilterLabelsEnum = {
        HITELESITETTEMAIL: 'HITELESITETT_EMAIL' as FilterLabelsEnum,
        FELHASZNALOAZONOSITO: 'FELHASZNALO_AZONOSITO' as FilterLabelsEnum,
        GONDVISELOSHORGASZ: 'GONDVISELOS_HORGASZ' as FilterLabelsEnum
    };
    export type StatuszokEnum = 'ROGZITETT' | 'AKTIV' | 'TURELMI_IDOSZAK' | 'MEGSZUNT';
    export const StatuszokEnum = {
        ROGZITETT: 'ROGZITETT' as StatuszokEnum,
        AKTIV: 'AKTIV' as StatuszokEnum,
        TURELMIIDOSZAK: 'TURELMI_IDOSZAK' as StatuszokEnum,
        MEGSZUNT: 'MEGSZUNT' as StatuszokEnum
    };
    export type TagsagKategoriaListEnum = 'IFJUSAGI' | 'FELNOTT' | 'FELNOTT_KEDVEZMENYEZETT' | 'IFJUSAGI_IHJ' | 'FELNOTT_IHJ' | 'FELNOTT_JOGFENNTARTO_IHJ' | 'MOHOSZON_KIVULI';
    export const TagsagKategoriaListEnum = {
        IFJUSAGI: 'IFJUSAGI' as TagsagKategoriaListEnum,
        FELNOTT: 'FELNOTT' as TagsagKategoriaListEnum,
        FELNOTTKEDVEZMENYEZETT: 'FELNOTT_KEDVEZMENYEZETT' as TagsagKategoriaListEnum,
        IFJUSAGIIHJ: 'IFJUSAGI_IHJ' as TagsagKategoriaListEnum,
        FELNOTTIHJ: 'FELNOTT_IHJ' as TagsagKategoriaListEnum,
        FELNOTTJOGFENNTARTOIHJ: 'FELNOTT_JOGFENNTARTO_IHJ' as TagsagKategoriaListEnum,
        MOHOSZONKIVULI: 'MOHOSZON_KIVULI' as TagsagKategoriaListEnum
    };
}