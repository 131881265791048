/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OfflineHozzatartozo } from './offlineHozzatartozo';

export interface OfflineFelhasznalo { 
    availableCommands?: Array<OfflineFelhasznalo.AvailableCommandsEnum>;
    eltiltott?: boolean;
    gondviseloSzemelyId?: number;
    gondviselorolLevalaszthato?: boolean;
    horgasz?: boolean;
    horgaszAzonosito?: string;
    horgaszBugyellarisStatusz?: OfflineFelhasznalo.HorgaszBugyellarisStatuszEnum;
    hozzatartozoList?: Array<OfflineHozzatartozo>;
    keresztnev?: string;
    loginId?: string;
    szemelyId?: number;
    szuletesiDatum?: string;
    titulus?: OfflineFelhasznalo.TitulusEnum;
    vezeteknev?: string;
}
export namespace OfflineFelhasznalo {
    export type AvailableCommandsEnum = 'REGISZTRACIO_INDITAS' | 'REGISZTRACIO_FOLYTATAS';
    export const AvailableCommandsEnum = {
        INDITAS: 'REGISZTRACIO_INDITAS' as AvailableCommandsEnum,
        FOLYTATAS: 'REGISZTRACIO_FOLYTATAS' as AvailableCommandsEnum
    };
    export type HorgaszBugyellarisStatuszEnum = 'REGISZTRACIO_FOLYAMATBAN' | 'FIZETES_FOLYAMATBAN' | 'ELO' | 'ADATEGYEZTETESMIATTI_FELFUGGESZTETT_ELO' | 'FELADOVEVENYES_ELO' | 'UGYINTEZOI_JOVAHAGYASRA_VAR' | 'FELFUGGESZTVE';
    export const HorgaszBugyellarisStatuszEnum = {
        REGISZTRACIOFOLYAMATBAN: 'REGISZTRACIO_FOLYAMATBAN' as HorgaszBugyellarisStatuszEnum,
        FIZETESFOLYAMATBAN: 'FIZETES_FOLYAMATBAN' as HorgaszBugyellarisStatuszEnum,
        ELO: 'ELO' as HorgaszBugyellarisStatuszEnum,
        ADATEGYEZTETESMIATTIFELFUGGESZTETTELO: 'ADATEGYEZTETESMIATTI_FELFUGGESZTETT_ELO' as HorgaszBugyellarisStatuszEnum,
        FELADOVEVENYESELO: 'FELADOVEVENYES_ELO' as HorgaszBugyellarisStatuszEnum,
        UGYINTEZOIJOVAHAGYASRAVAR: 'UGYINTEZOI_JOVAHAGYASRA_VAR' as HorgaszBugyellarisStatuszEnum,
        FELFUGGESZTVE: 'FELFUGGESZTVE' as HorgaszBugyellarisStatuszEnum
    };
    export type TitulusEnum = 'dr' | 'id' | 'ifj' | 'özv' | 'prof';
    export const TitulusEnum = {
        Dr: 'dr' as TitulusEnum,
        Id: 'id' as TitulusEnum,
        Ifj: 'ifj' as TitulusEnum,
        Zv: 'özv' as TitulusEnum,
        Prof: 'prof' as TitulusEnum
    };
}