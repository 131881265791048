/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TeruletiJegyTermek } from './teruletiJegyTermek';
import { TeruletiKiegeszitoJegyTermek } from './teruletiKiegeszitoJegyTermek';
import { VizteruletCsoport } from './vizteruletCsoport';

export interface TeruletiJegyTermekDetails { 
    horgaszrendKepId?: number;
    megvasarolhatoKiegeszitoJegyList?: Array<TeruletiKiegeszitoJegyTermek>;
    teruletiJegyTermek?: TeruletiJegyTermek;
    vizteruletCsoport?: VizteruletCsoport;
}