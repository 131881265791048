/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TeruletiJegyTermekIdoszakErvenyesseg { 
    ervenyessegNemKotelezo?: Array<TeruletiJegyTermekIdoszakErvenyesseg.ErvenyessegNemKotelezoEnum>;
    idopontErvenyessegKotelezo?: Array<TeruletiJegyTermekIdoszakErvenyesseg.IdopontErvenyessegKotelezoEnum>;
    napErvenyessegKotelezo?: Array<TeruletiJegyTermekIdoszakErvenyesseg.NapErvenyessegKotelezoEnum>;
}
export namespace TeruletiJegyTermekIdoszakErvenyesseg {
    export type ErvenyessegNemKotelezoEnum = 'EVES' | 'EVES_RESZLET1' | 'EVES_RESZLET2' | 'FELEVES' | 'NAP10' | 'NAP7' | 'ORA72' | 'NAP3' | 'ORA48' | 'NAP2' | 'ORA24' | 'NAPI' | 'EJSZAKAI';
    export const ErvenyessegNemKotelezoEnum = {
        EVES: 'EVES' as ErvenyessegNemKotelezoEnum,
        EVESRESZLET1: 'EVES_RESZLET1' as ErvenyessegNemKotelezoEnum,
        EVESRESZLET2: 'EVES_RESZLET2' as ErvenyessegNemKotelezoEnum,
        FELEVES: 'FELEVES' as ErvenyessegNemKotelezoEnum,
        NAP10: 'NAP10' as ErvenyessegNemKotelezoEnum,
        NAP7: 'NAP7' as ErvenyessegNemKotelezoEnum,
        ORA72: 'ORA72' as ErvenyessegNemKotelezoEnum,
        NAP3: 'NAP3' as ErvenyessegNemKotelezoEnum,
        ORA48: 'ORA48' as ErvenyessegNemKotelezoEnum,
        NAP2: 'NAP2' as ErvenyessegNemKotelezoEnum,
        ORA24: 'ORA24' as ErvenyessegNemKotelezoEnum,
        NAPI: 'NAPI' as ErvenyessegNemKotelezoEnum,
        EJSZAKAI: 'EJSZAKAI' as ErvenyessegNemKotelezoEnum
    };
    export type IdopontErvenyessegKotelezoEnum = 'EVES' | 'EVES_RESZLET1' | 'EVES_RESZLET2' | 'FELEVES' | 'NAP10' | 'NAP7' | 'ORA72' | 'NAP3' | 'ORA48' | 'NAP2' | 'ORA24' | 'NAPI' | 'EJSZAKAI';
    export const IdopontErvenyessegKotelezoEnum = {
        EVES: 'EVES' as IdopontErvenyessegKotelezoEnum,
        EVESRESZLET1: 'EVES_RESZLET1' as IdopontErvenyessegKotelezoEnum,
        EVESRESZLET2: 'EVES_RESZLET2' as IdopontErvenyessegKotelezoEnum,
        FELEVES: 'FELEVES' as IdopontErvenyessegKotelezoEnum,
        NAP10: 'NAP10' as IdopontErvenyessegKotelezoEnum,
        NAP7: 'NAP7' as IdopontErvenyessegKotelezoEnum,
        ORA72: 'ORA72' as IdopontErvenyessegKotelezoEnum,
        NAP3: 'NAP3' as IdopontErvenyessegKotelezoEnum,
        ORA48: 'ORA48' as IdopontErvenyessegKotelezoEnum,
        NAP2: 'NAP2' as IdopontErvenyessegKotelezoEnum,
        ORA24: 'ORA24' as IdopontErvenyessegKotelezoEnum,
        NAPI: 'NAPI' as IdopontErvenyessegKotelezoEnum,
        EJSZAKAI: 'EJSZAKAI' as IdopontErvenyessegKotelezoEnum
    };
    export type NapErvenyessegKotelezoEnum = 'EVES' | 'EVES_RESZLET1' | 'EVES_RESZLET2' | 'FELEVES' | 'NAP10' | 'NAP7' | 'ORA72' | 'NAP3' | 'ORA48' | 'NAP2' | 'ORA24' | 'NAPI' | 'EJSZAKAI';
    export const NapErvenyessegKotelezoEnum = {
        EVES: 'EVES' as NapErvenyessegKotelezoEnum,
        EVESRESZLET1: 'EVES_RESZLET1' as NapErvenyessegKotelezoEnum,
        EVESRESZLET2: 'EVES_RESZLET2' as NapErvenyessegKotelezoEnum,
        FELEVES: 'FELEVES' as NapErvenyessegKotelezoEnum,
        NAP10: 'NAP10' as NapErvenyessegKotelezoEnum,
        NAP7: 'NAP7' as NapErvenyessegKotelezoEnum,
        ORA72: 'ORA72' as NapErvenyessegKotelezoEnum,
        NAP3: 'NAP3' as NapErvenyessegKotelezoEnum,
        ORA48: 'ORA48' as NapErvenyessegKotelezoEnum,
        NAP2: 'NAP2' as NapErvenyessegKotelezoEnum,
        ORA24: 'ORA24' as NapErvenyessegKotelezoEnum,
        NAPI: 'NAPI' as NapErvenyessegKotelezoEnum,
        EJSZAKAI: 'EJSZAKAI' as NapErvenyessegKotelezoEnum
    };
}