/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BigFishTransactionResult { 
    email?: string;
    licenseNumber?: string;
    responseCode?: string;
    status?: BigFishTransactionResult.StatusEnum;
    textMessage?: string;
}
export namespace BigFishTransactionResult {
    export type StatusEnum = 'PENDING' | 'OPEN' | 'SUCCESSFUL' | 'ERROR' | 'CANCELED' | 'TIMEOUT' | 'InactiveStore' | 'MissingParameter' | 'MissingRemoteAddress' | 'UnauthorizedAccess' | 'UnauthorizedRemoteAddress' | 'UnknownStore' | 'UnknownTransaction' | 'WrongApikey';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        OPEN: 'OPEN' as StatusEnum,
        SUCCESSFUL: 'SUCCESSFUL' as StatusEnum,
        ERROR: 'ERROR' as StatusEnum,
        CANCELED: 'CANCELED' as StatusEnum,
        TIMEOUT: 'TIMEOUT' as StatusEnum,
        InactiveStore: 'InactiveStore' as StatusEnum,
        MissingParameter: 'MissingParameter' as StatusEnum,
        MissingRemoteAddress: 'MissingRemoteAddress' as StatusEnum,
        UnauthorizedAccess: 'UnauthorizedAccess' as StatusEnum,
        UnauthorizedRemoteAddress: 'UnauthorizedRemoteAddress' as StatusEnum,
        UnknownStore: 'UnknownStore' as StatusEnum,
        UnknownTransaction: 'UnknownTransaction' as StatusEnum,
        WrongApikey: 'WrongApikey' as StatusEnum
    };
}