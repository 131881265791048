/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Idoszak } from './idoszak';

export interface JegyFilter { 
    allamiJegyTipusok?: Array<JegyFilter.AllamiJegyTipusokEnum>;
    ervenyes?: boolean;
    idoszakFilter?: boolean;
    idoszakok?: Array<Idoszak>;
    tipusFilter?: boolean;
    tipusok?: Array<JegyFilter.TipusokEnum>;
}
export namespace JegyFilter {
    export type AllamiJegyTipusokEnum = 'TURISTA_ALLAMIJEGY' | 'ALLAMIJEGY';
    export const AllamiJegyTipusokEnum = {
        TURISTAALLAMIJEGY: 'TURISTA_ALLAMIJEGY' as AllamiJegyTipusokEnum,
        ALLAMIJEGY: 'ALLAMIJEGY' as AllamiJegyTipusokEnum
    };
    export type TipusokEnum = 'TERULETI' | 'ALLAMI' | 'TURISTA_ALLAMI';
    export const TipusokEnum = {
        TERULETI: 'TERULETI' as TipusokEnum,
        ALLAMI: 'ALLAMI' as TipusokEnum,
        TURISTAALLAMI: 'TURISTA_ALLAMI' as TipusokEnum
    };
}