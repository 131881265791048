/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TagdijBefizetes } from './tagdijBefizetes';

export interface MohoszEgyesuletiTagsag { 
    availableCommands?: Array<MohoszEgyesuletiTagsag.AvailableCommandsEnum>;
    modositva?: Date;
    statusz?: MohoszEgyesuletiTagsag.StatuszEnum;
    tagEgyesuletId?: number;
    tagdijBefizetesList?: Array<TagdijBefizetes>;
    tagsagErvenyesseg?: string;
    tagsagKezdete?: string;
}
export namespace MohoszEgyesuletiTagsag {
    export type AvailableCommandsEnum = 'SZERKESZTES';
    export const AvailableCommandsEnum = {
        SZERKESZTES: 'SZERKESZTES' as AvailableCommandsEnum
    };
    export type StatuszEnum = 'ROGZITETT' | 'AKTIV' | 'TURELMI_IDOSZAK' | 'MEGSZUNT';
    export const StatuszEnum = {
        ROGZITETT: 'ROGZITETT' as StatuszEnum,
        AKTIV: 'AKTIV' as StatuszEnum,
        TURELMIIDOSZAK: 'TURELMI_IDOSZAK' as StatuszEnum,
        MEGSZUNT: 'MEGSZUNT' as StatuszEnum
    };
}