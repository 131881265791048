/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ErtekesithetoEgyesuletiTagsagRequest { 
    ervenyessegiEv?: number;
    korkategoria?: ErtekesithetoEgyesuletiTagsagRequest.KorkategoriaEnum;
    szemelyId?: number;
    tagEgyesuletId?: number;
}
export namespace ErtekesithetoEgyesuletiTagsagRequest {
    export type KorkategoriaEnum = 'GYERMEK' | 'IFJUSAGI' | 'FELNOTT';
    export const KorkategoriaEnum = {
        GYERMEK: 'GYERMEK' as KorkategoriaEnum,
        IFJUSAGI: 'IFJUSAGI' as KorkategoriaEnum,
        FELNOTT: 'FELNOTT' as KorkategoriaEnum
    };
}