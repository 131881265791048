/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface IgenylesCsekkFizetesRequest { 
    befizetoAzonosito?: string;
    csekkFeladovevenyKepId?: number;
    horgaszkartyaTipus?: IgenylesCsekkFizetesRequest.HorgaszkartyaTipusEnum;
    szemelyId?: number;
    validBefizetoAzonosito?: boolean;
}
export namespace IgenylesCsekkFizetesRequest {
    export type HorgaszkartyaTipusEnum = 'MHK' | 'VIP';
    export const HorgaszkartyaTipusEnum = {
        MHK: 'MHK' as HorgaszkartyaTipusEnum,
        VIP: 'VIP' as HorgaszkartyaTipusEnum
    };
}