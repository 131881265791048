/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Dokumentum { 
    contentId?: number;
    csatornaSet?: Array<Dokumentum.CsatornaSetEnum>;
    elerhetoCsatornaSet?: Array<Dokumentum.ElerhetoCsatornaSetEnum>;
    fileNev?: string;
    hatalyos?: string;
    id?: number;
    mappaCsatornaSet?: Array<Dokumentum.MappaCsatornaSetEnum>;
    mappaId?: number;
    megnevezes?: string;
}
export namespace Dokumentum {
    export type CsatornaSetEnum = 'ONLINE' | 'UGYINTEZO' | 'UGYFELSZOLGALAT' | 'ALTALANOS';
    export const CsatornaSetEnum = {
        ONLINE: 'ONLINE' as CsatornaSetEnum,
        UGYINTEZO: 'UGYINTEZO' as CsatornaSetEnum,
        UGYFELSZOLGALAT: 'UGYFELSZOLGALAT' as CsatornaSetEnum,
        ALTALANOS: 'ALTALANOS' as CsatornaSetEnum
    };
    export type ElerhetoCsatornaSetEnum = 'ONLINE' | 'UGYINTEZO' | 'UGYFELSZOLGALAT' | 'ALTALANOS';
    export const ElerhetoCsatornaSetEnum = {
        ONLINE: 'ONLINE' as ElerhetoCsatornaSetEnum,
        UGYINTEZO: 'UGYINTEZO' as ElerhetoCsatornaSetEnum,
        UGYFELSZOLGALAT: 'UGYFELSZOLGALAT' as ElerhetoCsatornaSetEnum,
        ALTALANOS: 'ALTALANOS' as ElerhetoCsatornaSetEnum
    };
    export type MappaCsatornaSetEnum = 'ONLINE' | 'UGYINTEZO' | 'UGYFELSZOLGALAT' | 'ALTALANOS';
    export const MappaCsatornaSetEnum = {
        ONLINE: 'ONLINE' as MappaCsatornaSetEnum,
        UGYINTEZO: 'UGYINTEZO' as MappaCsatornaSetEnum,
        UGYFELSZOLGALAT: 'UGYFELSZOLGALAT' as MappaCsatornaSetEnum,
        ALTALANOS: 'ALTALANOS' as MappaCsatornaSetEnum
    };
}