/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FeladatsorLetrehozasRequest { 
    kerdesekMegvalaszolasahozIdoLimit?: number;
    megvalaszolandoKerdesekSzama?: number;
    nev?: string;
    nyelv?: FeladatsorLetrehozasRequest.NyelvEnum;
    sikeresVizsgaHatar?: number;
    tipus?: FeladatsorLetrehozasRequest.TipusEnum;
    vizsgaMegvalaszolasahozIdoLimit?: number;
}
export namespace FeladatsorLetrehozasRequest {
    export type NyelvEnum = 'MAGYAR' | 'ANGOL' | 'NEMET';
    export const NyelvEnum = {
        MAGYAR: 'MAGYAR' as NyelvEnum,
        ANGOL: 'ANGOL' as NyelvEnum,
        NEMET: 'NEMET' as NyelvEnum
    };
    export type TipusEnum = 'TURISTA' | 'ALLAMI';
    export const TipusEnum = {
        TURISTA: 'TURISTA' as TipusEnum,
        ALLAMI: 'ALLAMI' as TipusEnum
    };
}