/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FogasiNaploLeadasRequest { 
    leadasDatuma?: string;
    leadottFogasiNaploKepIds?: Array<number>;
    osszesitesKitoltve?: boolean;
    sorszam?: string;
    statusz?: FogasiNaploLeadasRequest.StatuszEnum;
    szemelyId?: number;
    validLeadasDatum?: boolean;
    validSorszam?: boolean;
    validStatusz?: boolean;
}
export namespace FogasiNaploLeadasRequest {
    export type StatuszEnum = 'KIADOTT' | 'LEADOTT' | 'BETELT' | 'MEGSEMMISULT' | 'ELVESZETT' | 'VEGLEGESEN_STORNOZOTT' | 'KIADOTT_SZTORNO_JOVAHAGYASRA_VAR' | 'KIADOTT_ELVESZETT_JOVAHAGYASRA_VAR' | 'KIADOTT_MEGSEMMISULT_JOVAHAGYASRA_VAR' | 'VISSZAVONT';
    export const StatuszEnum = {
        KIADOTT: 'KIADOTT' as StatuszEnum,
        LEADOTT: 'LEADOTT' as StatuszEnum,
        BETELT: 'BETELT' as StatuszEnum,
        MEGSEMMISULT: 'MEGSEMMISULT' as StatuszEnum,
        ELVESZETT: 'ELVESZETT' as StatuszEnum,
        VEGLEGESENSTORNOZOTT: 'VEGLEGESEN_STORNOZOTT' as StatuszEnum,
        KIADOTTSZTORNOJOVAHAGYASRAVAR: 'KIADOTT_SZTORNO_JOVAHAGYASRA_VAR' as StatuszEnum,
        KIADOTTELVESZETTJOVAHAGYASRAVAR: 'KIADOTT_ELVESZETT_JOVAHAGYASRA_VAR' as StatuszEnum,
        KIADOTTMEGSEMMISULTJOVAHAGYASRAVAR: 'KIADOTT_MEGSEMMISULT_JOVAHAGYASRA_VAR' as StatuszEnum,
        VISSZAVONT: 'VISSZAVONT' as StatuszEnum
    };
}