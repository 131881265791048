/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface VizteruletEltiltasModositasRequest { 
    anyjaNeve?: string;
    eltiltasId?: number;
    eltiltasKezdete?: string;
    eltiltasVege?: string;
    keresztnev?: string;
    szemelyId?: number;
    szemelyesAdatKitoltve?: boolean;
    szuletesiDatum?: string;
    szuletesiHely?: string;
    validEltiltasKezdete?: boolean;
    validEltiltasVege?: boolean;
    validRequest?: boolean;
    vezeteknev?: string;
    vizteruletId?: number;
}