/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Cim } from './cim';

export interface ErtekesitesRiportSzemely { 
    allandoLakcim?: Cim;
    anyjaNeve?: string;
    keresztnev?: string;
    szuletesiDatum?: string;
    szuletesiHely?: string;
    titulus?: ErtekesitesRiportSzemely.TitulusEnum;
    vezeteknev?: string;
}
export namespace ErtekesitesRiportSzemely {
    export type TitulusEnum = 'dr' | 'id' | 'ifj' | 'özv' | 'prof';
    export const TitulusEnum = {
        Dr: 'dr' as TitulusEnum,
        Id: 'id' as TitulusEnum,
        Ifj: 'ifj' as TitulusEnum,
        Zv: 'özv' as TitulusEnum,
        Prof: 'prof' as TitulusEnum
    };
}