/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AllamiJegy } from './allamiJegy';
import { HorgaszAdatSzemely } from './horgaszAdatSzemely';
import { SzemelyEltiltas } from './szemelyEltiltas';
import { SzemelyTeruletiEltiltas } from './szemelyTeruletiEltiltas';
import { TeruletiJegy } from './teruletiJegy';
import { VersenyzoiEngedely } from './versenyzoiEngedely';

export interface EllenorzottSzemelyAdatai { 
    allamiJegyList?: Array<AllamiJegy>;
    ervenyesAllamiJegy?: AllamiJegy;
    ervenyesTeruletiJegy?: TeruletiJegy;
    ervenyesVersenyzoiEngedely?: VersenyzoiEngedely;
    horgasz?: HorgaszAdatSzemely;
    horgaszAzonosito?: string;
    keresettEgyediAzonosito?: string;
    szemelyEltiltas?: SzemelyEltiltas;
    szemelyTeruletiEltiltas?: SzemelyTeruletiEltiltas;
    szemelyTeruletiEltiltasList?: Array<SzemelyTeruletiEltiltas>;
    teruletiJegyList?: Array<TeruletiJegy>;
    versenyzoiEngedelyList?: Array<VersenyzoiEngedely>;
}