/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DuplicationCandidate } from './duplicationCandidate';
import { HorinfoKapcsolatTortenet } from './horinfoKapcsolatTortenet';

export interface NebihSzemelyEltiltasokDetails { 
    hasonloSzemelyList?: Array<DuplicationCandidate>;
    tortenetList?: Array<HorinfoKapcsolatTortenet>;
}