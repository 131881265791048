/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TeruletiJegyTermekKombinacio } from './teruletiJegyTermekKombinacio';

export interface TeruletiJegyTermekMatrix { 
    idoszakValueSet?: Array<TeruletiJegyTermekMatrix.IdoszakValueSetEnum>;
    jogcimCsoportValueSet?: Array<TeruletiJegyTermekMatrix.JogcimCsoportValueSetEnum>;
    jogcimValueSet?: Array<TeruletiJegyTermekMatrix.JogcimValueSetEnum>;
    teruletiJegyTermekKombinacioList?: Array<TeruletiJegyTermekKombinacio>;
}
export namespace TeruletiJegyTermekMatrix {
    export type IdoszakValueSetEnum = 'EVES' | 'EVES_RESZLET1' | 'EVES_RESZLET2' | 'FELEVES' | 'NAP10' | 'NAP7' | 'ORA72' | 'NAP3' | 'ORA48' | 'NAP2' | 'ORA24' | 'NAPI' | 'EJSZAKAI';
    export const IdoszakValueSetEnum = {
        EVES: 'EVES' as IdoszakValueSetEnum,
        EVESRESZLET1: 'EVES_RESZLET1' as IdoszakValueSetEnum,
        EVESRESZLET2: 'EVES_RESZLET2' as IdoszakValueSetEnum,
        FELEVES: 'FELEVES' as IdoszakValueSetEnum,
        NAP10: 'NAP10' as IdoszakValueSetEnum,
        NAP7: 'NAP7' as IdoszakValueSetEnum,
        ORA72: 'ORA72' as IdoszakValueSetEnum,
        NAP3: 'NAP3' as IdoszakValueSetEnum,
        ORA48: 'ORA48' as IdoszakValueSetEnum,
        NAP2: 'NAP2' as IdoszakValueSetEnum,
        ORA24: 'ORA24' as IdoszakValueSetEnum,
        NAPI: 'NAPI' as IdoszakValueSetEnum,
        EJSZAKAI: 'EJSZAKAI' as IdoszakValueSetEnum
    };
    export type JogcimCsoportValueSetEnum = 'ALTALANOS' | 'PARTI' | 'BOJLIS' | 'SPORTCELU';
    export const JogcimCsoportValueSetEnum = {
        ALTALANOS: 'ALTALANOS' as JogcimCsoportValueSetEnum,
        PARTI: 'PARTI' as JogcimCsoportValueSetEnum,
        BOJLIS: 'BOJLIS' as JogcimCsoportValueSetEnum,
        SPORTCELU: 'SPORTCELU' as JogcimCsoportValueSetEnum
    };
    export type JogcimValueSetEnum = 'GYERMEK' | 'GYERMEK_USZOS' | 'IFJUSAGI' | 'IFJUSAGI_EMELTARAS' | 'IFJUSAGI_TURISTA' | 'IFJUSAGI_KULFOLDI' | 'FELNOTT' | 'FELNOTT_KEDVEZMENYEZETT' | 'FELNOTT_KORKEDVEZMENYEZETT' | 'FELNOTT_KEDVEZMENYEZETT_1BOTOS' | 'FELNOTT_EMELTARAS' | 'FELNOTT_TURISTA' | 'FELNOTT_KULFOLDI' | 'FELNOTT_MENTESITETT' | 'IFJUSAGI_IHJ' | 'FELNOTT_IHJ' | 'FELNOTT_JOGFENNTARTO_IHJ' | 'TISZTSEGVISELOI' | 'SZOLGALATI' | 'BONUSZ';
    export const JogcimValueSetEnum = {
        GYERMEK: 'GYERMEK' as JogcimValueSetEnum,
        GYERMEKUSZOS: 'GYERMEK_USZOS' as JogcimValueSetEnum,
        IFJUSAGI: 'IFJUSAGI' as JogcimValueSetEnum,
        IFJUSAGIEMELTARAS: 'IFJUSAGI_EMELTARAS' as JogcimValueSetEnum,
        IFJUSAGITURISTA: 'IFJUSAGI_TURISTA' as JogcimValueSetEnum,
        IFJUSAGIKULFOLDI: 'IFJUSAGI_KULFOLDI' as JogcimValueSetEnum,
        FELNOTT: 'FELNOTT' as JogcimValueSetEnum,
        FELNOTTKEDVEZMENYEZETT: 'FELNOTT_KEDVEZMENYEZETT' as JogcimValueSetEnum,
        FELNOTTKORKEDVEZMENYEZETT: 'FELNOTT_KORKEDVEZMENYEZETT' as JogcimValueSetEnum,
        FELNOTTKEDVEZMENYEZETT1BOTOS: 'FELNOTT_KEDVEZMENYEZETT_1BOTOS' as JogcimValueSetEnum,
        FELNOTTEMELTARAS: 'FELNOTT_EMELTARAS' as JogcimValueSetEnum,
        FELNOTTTURISTA: 'FELNOTT_TURISTA' as JogcimValueSetEnum,
        FELNOTTKULFOLDI: 'FELNOTT_KULFOLDI' as JogcimValueSetEnum,
        FELNOTTMENTESITETT: 'FELNOTT_MENTESITETT' as JogcimValueSetEnum,
        IFJUSAGIIHJ: 'IFJUSAGI_IHJ' as JogcimValueSetEnum,
        FELNOTTIHJ: 'FELNOTT_IHJ' as JogcimValueSetEnum,
        FELNOTTJOGFENNTARTOIHJ: 'FELNOTT_JOGFENNTARTO_IHJ' as JogcimValueSetEnum,
        TISZTSEGVISELOI: 'TISZTSEGVISELOI' as JogcimValueSetEnum,
        SZOLGALATI: 'SZOLGALATI' as JogcimValueSetEnum,
        BONUSZ: 'BONUSZ' as JogcimValueSetEnum
    };
}