/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OnlinePenztarTermek } from './onlinePenztarTermek';

export interface OnlinePenztar { 
    dijmentesFizetes?: boolean;
    kosarId?: number;
    szereploNev?: string;
    teljesAr?: number;
    termekList?: Array<OnlinePenztarTermek>;
    valaszthatoFizetesTipusList?: Array<OnlinePenztar.ValaszthatoFizetesTipusListEnum>;
}
export namespace OnlinePenztar {
    export type ValaszthatoFizetesTipusListEnum = 'KH' | 'CSEKKES' | 'ERTEKESITESIPONT' | 'DIJMENTES' | 'SZEPKH' | 'RK' | 'OTPSZEP' | 'MKBSZEP';
    export const ValaszthatoFizetesTipusListEnum = {
        KH: 'KH' as ValaszthatoFizetesTipusListEnum,
        CSEKKES: 'CSEKKES' as ValaszthatoFizetesTipusListEnum,
        ERTEKESITESIPONT: 'ERTEKESITESIPONT' as ValaszthatoFizetesTipusListEnum,
        DIJMENTES: 'DIJMENTES' as ValaszthatoFizetesTipusListEnum,
        SZEPKH: 'SZEPKH' as ValaszthatoFizetesTipusListEnum,
        RK: 'RK' as ValaszthatoFizetesTipusListEnum,
        OTPSZEP: 'OTPSZEP' as ValaszthatoFizetesTipusListEnum,
        MKBSZEP: 'MKBSZEP' as ValaszthatoFizetesTipusListEnum
    };
}