/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FlogModositasRequest { 
    csali?: string;
    etetoanyag?: string;
    farasztasPerc?: number;
    fogasIdopont?: Date;
    fogasModszer?: string;
    fotoId?: number;
    halfajId?: number;
    hossz?: number;
    id?: number;
    publikus?: boolean;
    suly?: number;
    visszaengedett?: boolean;
    vizteruletId?: number;
}