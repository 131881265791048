/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ArresElszamolasItem } from '../model/arresElszamolasItem';
import { ArresTermekElszamolasFilter } from '../model/arresTermekElszamolasFilter';
import { ArresTermekElszamolasTorzsadat } from '../model/arresTermekElszamolasTorzsadat';
import { ElszamolohazFilter } from '../model/elszamolohazFilter';
import { SzervezetOsszesites } from '../model/szervezetOsszesites';
import { TeruletiJegyMohoszOsszesites } from '../model/teruletiJegyMohoszOsszesites';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ElszamolohazControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * arresTermekElszamolasTorzsadat
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public arresTermekElszamolasTorzsadatUsingPOST(body: ArresTermekElszamolasFilter, observe?: 'body', reportProgress?: boolean): Observable<ArresTermekElszamolasTorzsadat>;
    public arresTermekElszamolasTorzsadatUsingPOST(body: ArresTermekElszamolasFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ArresTermekElszamolasTorzsadat>>;
    public arresTermekElszamolasTorzsadatUsingPOST(body: ArresTermekElszamolasFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ArresTermekElszamolasTorzsadat>>;
    public arresTermekElszamolasTorzsadatUsingPOST(body: ArresTermekElszamolasFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling arresTermekElszamolasTorzsadatUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ArresTermekElszamolasTorzsadat>('post',`${this.basePath}/api/mohosz/elszamolohaz/arrestermek-elszamolas-torzsadat`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * arresTermekElszamolas
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public arresTermekElszamolasUsingPOST(body: ArresTermekElszamolasFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<ArresElszamolasItem>>;
    public arresTermekElszamolasUsingPOST(body: ArresTermekElszamolasFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ArresElszamolasItem>>>;
    public arresTermekElszamolasUsingPOST(body: ArresTermekElszamolasFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ArresElszamolasItem>>>;
    public arresTermekElszamolasUsingPOST(body: ArresTermekElszamolasFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling arresTermekElszamolasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<ArresElszamolasItem>>('post',`${this.basePath}/api/mohosz/elszamolohaz/arrestermek-elszamolas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * arresTermekMohoszOsszesites
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public arresTermekMohoszOsszesitesUsingPOST(body: ElszamolohazFilter, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyMohoszOsszesites>;
    public arresTermekMohoszOsszesitesUsingPOST(body: ElszamolohazFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyMohoszOsszesites>>;
    public arresTermekMohoszOsszesitesUsingPOST(body: ElszamolohazFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyMohoszOsszesites>>;
    public arresTermekMohoszOsszesitesUsingPOST(body: ElszamolohazFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling arresTermekMohoszOsszesitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyMohoszOsszesites>('post',`${this.basePath}/api/mohosz/elszamolohaz/arrestermek-mohosz-osszesites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szervezetSajatErtekesitesOsszesites
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szervezetSajatErtekesitesOsszesitesUsingPOST(body: ElszamolohazFilter, observe?: 'body', reportProgress?: boolean): Observable<SzervezetOsszesites>;
    public szervezetSajatErtekesitesOsszesitesUsingPOST(body: ElszamolohazFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SzervezetOsszesites>>;
    public szervezetSajatErtekesitesOsszesitesUsingPOST(body: ElszamolohazFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SzervezetOsszesites>>;
    public szervezetSajatErtekesitesOsszesitesUsingPOST(body: ElszamolohazFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szervezetSajatErtekesitesOsszesitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SzervezetOsszesites>('post',`${this.basePath}/api/mohosz/elszamolohaz/szervezet-sajatertekesites-osszesites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * szervezetSajatTermekOsszesites
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szervezetSajatTermekOsszesitesUsingPOST(body: ElszamolohazFilter, observe?: 'body', reportProgress?: boolean): Observable<SzervezetOsszesites>;
    public szervezetSajatTermekOsszesitesUsingPOST(body: ElszamolohazFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SzervezetOsszesites>>;
    public szervezetSajatTermekOsszesitesUsingPOST(body: ElszamolohazFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SzervezetOsszesites>>;
    public szervezetSajatTermekOsszesitesUsingPOST(body: ElszamolohazFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szervezetSajatTermekOsszesitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SzervezetOsszesites>('post',`${this.basePath}/api/mohosz/elszamolohaz/szervezet-sajattermek-osszesites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiJegyMohoszOsszesites
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiJegyMohoszOsszesitesUsingPOST(body: ElszamolohazFilter, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyMohoszOsszesites>;
    public teruletiJegyMohoszOsszesitesUsingPOST(body: ElszamolohazFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyMohoszOsszesites>>;
    public teruletiJegyMohoszOsszesitesUsingPOST(body: ElszamolohazFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyMohoszOsszesites>>;
    public teruletiJegyMohoszOsszesitesUsingPOST(body: ElszamolohazFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teruletiJegyMohoszOsszesitesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyMohoszOsszesites>('post',`${this.basePath}/api/mohosz/elszamolohaz/teruletijegy-mohosz-osszesites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
