/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PoiTypeSzamossag { 
    poiType?: PoiTypeSzamossag.PoiTypeEnum;
    szamossag?: number;
}
export namespace PoiTypeSzamossag {
    export type PoiTypeEnum = 'VIZTERULET' | 'BERBEADAS';
    export const PoiTypeEnum = {
        VIZTERULET: 'VIZTERULET' as PoiTypeEnum,
        BERBEADAS: 'BERBEADAS' as PoiTypeEnum
    };
}