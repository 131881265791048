/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GyartasIgenylesLetrehozasRequest } from '../model/gyartasIgenylesLetrehozasRequest';
import { HorgaszkartyaIgenyles } from '../model/horgaszkartyaIgenyles';
import { HorgaszkartyaIgenylesTortenet } from '../model/horgaszkartyaIgenylesTortenet';
import { HorgaszkartyaIgenylesTortenetBundle } from '../model/horgaszkartyaIgenylesTortenetBundle';
import { KosarFizetes } from '../model/kosarFizetes';
import { OnlinePenztar } from '../model/onlinePenztar';
import { SzemelyRequest } from '../model/szemelyRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SzemelyHorgaszkartyaIgenylesekControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * fetchHorgaszkartyaIgenylesTortenet
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchHorgaszkartyaIgenylesTortenetUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<HorgaszkartyaIgenylesTortenet>>;
    public fetchHorgaszkartyaIgenylesTortenetUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HorgaszkartyaIgenylesTortenet>>>;
    public fetchHorgaszkartyaIgenylesTortenetUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HorgaszkartyaIgenylesTortenet>>>;
    public fetchHorgaszkartyaIgenylesTortenetUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fetchHorgaszkartyaIgenylesTortenetUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<HorgaszkartyaIgenylesTortenet>>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenyles/fetch-horgaszkartyaigenyles-tortenet`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findFolyamatbanLevoIgenyles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findFolyamatbanLevoIgenylesUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenyles>;
    public findFolyamatbanLevoIgenylesUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenyles>>;
    public findFolyamatbanLevoIgenylesUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenyles>>;
    public findFolyamatbanLevoIgenylesUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findFolyamatbanLevoIgenylesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenyles>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenyles/find-folyamatbanlevoigenyles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * horgaszkartyaIgenylesFizetesInditas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszkartyaIgenylesFizetesInditasUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<KosarFizetes>;
    public horgaszkartyaIgenylesFizetesInditasUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KosarFizetes>>;
    public horgaszkartyaIgenylesFizetesInditasUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KosarFizetes>>;
    public horgaszkartyaIgenylesFizetesInditasUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgaszkartyaIgenylesFizetesInditasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<KosarFizetes>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenyles/horgaszkartyaigenyles-fizetesinditas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * horgaszkartyaIgenylesFizetesStatusz
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszkartyaIgenylesFizetesStatuszUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<KosarFizetes>;
    public horgaszkartyaIgenylesFizetesStatuszUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KosarFizetes>>;
    public horgaszkartyaIgenylesFizetesStatuszUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KosarFizetes>>;
    public horgaszkartyaIgenylesFizetesStatuszUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgaszkartyaIgenylesFizetesStatuszUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<KosarFizetes>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenyles/horgaszkartyaigenyles-fizetesstatusz`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * horgaszkartyaIgenylesTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszkartyaIgenylesTorlesUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenylesTortenetBundle>;
    public horgaszkartyaIgenylesTorlesUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenylesTortenetBundle>>;
    public horgaszkartyaIgenylesTorlesUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenylesTortenetBundle>>;
    public horgaszkartyaIgenylesTorlesUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgaszkartyaIgenylesTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenylesTortenetBundle>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenyles/horgaszkartyaigenyles-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * igenylesOnlineLetrehozasEllenorzes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public igenylesOnlineLetrehozasEllenorzesUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public igenylesOnlineLetrehozasEllenorzesUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public igenylesOnlineLetrehozasEllenorzesUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public igenylesOnlineLetrehozasEllenorzesUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling igenylesOnlineLetrehozasEllenorzesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenyles/horgaszkartyaigenyles-onlineletrehozas-ellenorzes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * igenylesOnlineLetrehozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public igenylesOnlineLetrehozasUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<KosarFizetes>;
    public igenylesOnlineLetrehozasUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KosarFizetes>>;
    public igenylesOnlineLetrehozasUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KosarFizetes>>;
    public igenylesOnlineLetrehozasUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling igenylesOnlineLetrehozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<KosarFizetes>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenyles/horgaszkartyaigenyles-onlineletrehozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * igenylesOnlinePenztarLetrehozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public igenylesOnlinePenztarLetrehozasUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<OnlinePenztar>;
    public igenylesOnlinePenztarLetrehozasUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OnlinePenztar>>;
    public igenylesOnlinePenztarLetrehozasUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OnlinePenztar>>;
    public igenylesOnlinePenztarLetrehozasUsingPOST(body: GyartasIgenylesLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling igenylesOnlinePenztarLetrehozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OnlinePenztar>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenyles/horgaszkartyaigenyles-onlinepenztar-letrehozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * isIgenylesFunkcioEngedelyezve
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isIgenylesFunkcioEngedelyezveUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public isIgenylesFunkcioEngedelyezveUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public isIgenylesFunkcioEngedelyezveUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public isIgenylesFunkcioEngedelyezveUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling isIgenylesFunkcioEngedelyezveUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/mohosz/horgaszkartyaigenyles/is-horgaszkartyaigenyles-funkcioengedelyezve`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
