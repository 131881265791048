/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ArresElszamolasItem { 
    arresMertek?: number;
    ertekesitettDarabszam?: number;
    maximum?: number;
    minimum?: number;
    sztornozottDarabszam?: number;
    teljesArresMertek?: number;
    teljesEladasiAr?: number;
    teljesElszamolasiAr?: number;
    termekAr?: number;
    termekId?: string;
}