/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { HorgaszkartyaIgenylesTortenetBundle } from '../model/horgaszkartyaIgenylesTortenetBundle';
import { IgenylesCsekkFizetesRequest } from '../model/igenylesCsekkFizetesRequest';
import { SzemelyRequest } from '../model/szemelyRequest';
import { TermekMozgatasRequest } from '../model/termekMozgatasRequest';
import { UjragyartasIgenylesLetrehozasRequest } from '../model/ujragyartasIgenylesLetrehozasRequest';
import { UjragyartasIgenylesModositasRequest } from '../model/ujragyartasIgenylesModositasRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UgyintezoiHorgaszkartyaIgenylesekControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * fetchHorgaszkartyaIgenylesTortenet
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchHorgaszkartyaIgenylesTortenetUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenylesTortenetBundle>;
    public fetchHorgaszkartyaIgenylesTortenetUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenylesTortenetBundle>>;
    public fetchHorgaszkartyaIgenylesTortenetUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenylesTortenetBundle>>;
    public fetchHorgaszkartyaIgenylesTortenetUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fetchHorgaszkartyaIgenylesTortenetUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenylesTortenetBundle>('post',`${this.basePath}/api/mohosz/ugyintezoihorgaszkartyaigenyles/horgaszkartyaigenyles-tortenet`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * horgaszkartyaGyartasVisszatartas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszkartyaGyartasVisszatartasUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenylesTortenetBundle>;
    public horgaszkartyaGyartasVisszatartasUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenylesTortenetBundle>>;
    public horgaszkartyaGyartasVisszatartasUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenylesTortenetBundle>>;
    public horgaszkartyaGyartasVisszatartasUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgaszkartyaGyartasVisszatartasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenylesTortenetBundle>('post',`${this.basePath}/api/mohosz/ugyintezoihorgaszkartyaigenyles/horgaszkartyagyartas-visszatartas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * horgaszkartyaIgenylesTorles
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszkartyaIgenylesTorlesUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenylesTortenetBundle>;
    public horgaszkartyaIgenylesTorlesUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenylesTortenetBundle>>;
    public horgaszkartyaIgenylesTorlesUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenylesTortenetBundle>>;
    public horgaszkartyaIgenylesTorlesUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgaszkartyaIgenylesTorlesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenylesTortenetBundle>('post',`${this.basePath}/api/mohosz/ugyintezoihorgaszkartyaigenyles/horgaszkartyaigenyles-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * horgaszkartyaOfflineVasarlas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszkartyaOfflineVasarlasUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenylesTortenetBundle>;
    public horgaszkartyaOfflineVasarlasUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenylesTortenetBundle>>;
    public horgaszkartyaOfflineVasarlasUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenylesTortenetBundle>>;
    public horgaszkartyaOfflineVasarlasUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgaszkartyaOfflineVasarlasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenylesTortenetBundle>('post',`${this.basePath}/api/mohosz/ugyintezoihorgaszkartyaigenyles/horgaszkartyaigenyles-offlinevasarlas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * igenylesFizetesreKuldes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public igenylesFizetesreKuldesUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenylesTortenetBundle>;
    public igenylesFizetesreKuldesUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenylesTortenetBundle>>;
    public igenylesFizetesreKuldesUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenylesTortenetBundle>>;
    public igenylesFizetesreKuldesUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling igenylesFizetesreKuldesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenylesTortenetBundle>('post',`${this.basePath}/api/mohosz/ugyintezoihorgaszkartyaigenyles/horgaszkartyaigenyles-fizetesrekuldes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * igenylesLetrehozasCsekkFizetes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public igenylesLetrehozasCsekkFizetesUsingPOST(body: IgenylesCsekkFizetesRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenylesTortenetBundle>;
    public igenylesLetrehozasCsekkFizetesUsingPOST(body: IgenylesCsekkFizetesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenylesTortenetBundle>>;
    public igenylesLetrehozasCsekkFizetesUsingPOST(body: IgenylesCsekkFizetesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenylesTortenetBundle>>;
    public igenylesLetrehozasCsekkFizetesUsingPOST(body: IgenylesCsekkFizetesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling igenylesLetrehozasCsekkFizetesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenylesTortenetBundle>('post',`${this.basePath}/api/mohosz/ugyintezoihorgaszkartyaigenyles/horgaszkartyaigenyles-letrehozas-csekk`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * igenylesLetrehozasEllenorzes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public igenylesLetrehozasEllenorzesUsingPOST(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public igenylesLetrehozasEllenorzesUsingPOST(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public igenylesLetrehozasEllenorzesUsingPOST(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public igenylesLetrehozasEllenorzesUsingPOST(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling igenylesLetrehozasEllenorzesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/ugyintezoihorgaszkartyaigenyles/horgaszkartyaigenyles-letrehozas-ellenorzes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * igenylesekMozgatas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public igenylesekMozgatasUsingPOST(body: TermekMozgatasRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public igenylesekMozgatasUsingPOST(body: TermekMozgatasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public igenylesekMozgatasUsingPOST(body: TermekMozgatasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public igenylesekMozgatasUsingPOST(body: TermekMozgatasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling igenylesekMozgatasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/ugyintezoihorgaszkartyaigenyles/horgaszkartyaigenyles-mozgatas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ujragyartasIgenylesLetrehozas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ujragyartasIgenylesLetrehozasUsingPOST(body: UjragyartasIgenylesLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenylesTortenetBundle>;
    public ujragyartasIgenylesLetrehozasUsingPOST(body: UjragyartasIgenylesLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenylesTortenetBundle>>;
    public ujragyartasIgenylesLetrehozasUsingPOST(body: UjragyartasIgenylesLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenylesTortenetBundle>>;
    public ujragyartasIgenylesLetrehozasUsingPOST(body: UjragyartasIgenylesLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ujragyartasIgenylesLetrehozasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenylesTortenetBundle>('post',`${this.basePath}/api/mohosz/ugyintezoihorgaszkartyaigenyles/ujragyartas-horgaszkartyaigenyles-letrehozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ujragyartasIgenylesModositasFizetesreKuldes
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ujragyartasIgenylesModositasFizetesreKuldesUsingPOST(body: UjragyartasIgenylesModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenylesTortenetBundle>;
    public ujragyartasIgenylesModositasFizetesreKuldesUsingPOST(body: UjragyartasIgenylesModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenylesTortenetBundle>>;
    public ujragyartasIgenylesModositasFizetesreKuldesUsingPOST(body: UjragyartasIgenylesModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenylesTortenetBundle>>;
    public ujragyartasIgenylesModositasFizetesreKuldesUsingPOST(body: UjragyartasIgenylesModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ujragyartasIgenylesModositasFizetesreKuldesUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenylesTortenetBundle>('post',`${this.basePath}/api/mohosz/ugyintezoihorgaszkartyaigenyles/ujragyartas-horgaszkartyaigenyles-modositas-fizetesrekuldes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ujragyartasIgenylesModositas
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ujragyartasIgenylesModositasUsingPOST(body: UjragyartasIgenylesModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgaszkartyaIgenylesTortenetBundle>;
    public ujragyartasIgenylesModositasUsingPOST(body: UjragyartasIgenylesModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszkartyaIgenylesTortenetBundle>>;
    public ujragyartasIgenylesModositasUsingPOST(body: UjragyartasIgenylesModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszkartyaIgenylesTortenetBundle>>;
    public ujragyartasIgenylesModositasUsingPOST(body: UjragyartasIgenylesModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ujragyartasIgenylesModositasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszkartyaIgenylesTortenetBundle>('post',`${this.basePath}/api/mohosz/ugyintezoihorgaszkartyaigenyles/ujragyartas-horgaszkartyaigenyles-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * uploadCsekkFeladoveveny
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadCsekkFeladovevenyUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public uploadCsekkFeladovevenyUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public uploadCsekkFeladovevenyUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public uploadCsekkFeladovevenyUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling uploadCsekkFeladovevenyUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/ugyintezoihorgaszkartyaigenyles/horgaszkartyaigenyles-upload-csekkfeladoveveny`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * uploadDokumentum
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadDokumentumUsingPOST(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public uploadDokumentumUsingPOST(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public uploadDokumentumUsingPOST(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public uploadDokumentumUsingPOST(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling uploadDokumentumUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/ugyintezoihorgaszkartyaigenyles/horgaszkartyaigenyles-upload-dokumentum`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
