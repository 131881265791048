/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Szemely4T } from './szemely4T';

export interface TeruletiJegyRiportDetails { 
    ertekesitoSzemelyLoginId?: string;
    ertekesitoSzemelyNev?: string;
    ertekesitoSzervezetNev?: string;
    horgaszAzonosito?: string;
    horgaszSzemely?: Szemely4T;
    szervezetNev?: string;
}