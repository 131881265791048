/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BigFishTransactionResult } from './bigFishTransactionResult';
import { TransactionResult } from './transactionResult';

export interface OnlineFizetesAllapotResponse { 
    allapot?: string;
    bfResult?: BigFishTransactionResult;
    fizetesId?: string;
    khResult?: TransactionResult;
    successful?: boolean;
}