/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NebihAllamiJegyLeadasRequest { 
    leadasDatuma?: string;
    osszesitesKitoltve?: boolean;
    sorszam?: string;
    statusz?: NebihAllamiJegyLeadasRequest.StatuszEnum;
}
export namespace NebihAllamiJegyLeadasRequest {
    export type StatuszEnum = 'BETELT' | 'LEADOTT';
    export const StatuszEnum = {
        BETELT: 'BETELT' as StatuszEnum,
        LEADOTT: 'LEADOTT' as StatuszEnum
    };
}