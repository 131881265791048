/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FigyelmeztetoUzenetModositasRequest { 
    cim?: string;
    id?: number;
    idoszak?: FigyelmeztetoUzenetModositasRequest.IdoszakEnum;
    jogcim?: FigyelmeztetoUzenetModositasRequest.JogcimEnum;
    jogcimCsoport?: FigyelmeztetoUzenetModositasRequest.JogcimCsoportEnum;
    szoveg?: string;
}
export namespace FigyelmeztetoUzenetModositasRequest {
    export type IdoszakEnum = 'EVES' | 'EVES_RESZLET1' | 'EVES_RESZLET2' | 'FELEVES' | 'NAP10' | 'NAP7' | 'ORA72' | 'NAP3' | 'ORA48' | 'NAP2' | 'ORA24' | 'NAPI' | 'EJSZAKAI';
    export const IdoszakEnum = {
        EVES: 'EVES' as IdoszakEnum,
        EVESRESZLET1: 'EVES_RESZLET1' as IdoszakEnum,
        EVESRESZLET2: 'EVES_RESZLET2' as IdoszakEnum,
        FELEVES: 'FELEVES' as IdoszakEnum,
        NAP10: 'NAP10' as IdoszakEnum,
        NAP7: 'NAP7' as IdoszakEnum,
        ORA72: 'ORA72' as IdoszakEnum,
        NAP3: 'NAP3' as IdoszakEnum,
        ORA48: 'ORA48' as IdoszakEnum,
        NAP2: 'NAP2' as IdoszakEnum,
        ORA24: 'ORA24' as IdoszakEnum,
        NAPI: 'NAPI' as IdoszakEnum,
        EJSZAKAI: 'EJSZAKAI' as IdoszakEnum
    };
    export type JogcimEnum = 'GYERMEK' | 'GYERMEK_USZOS' | 'IFJUSAGI' | 'IFJUSAGI_EMELTARAS' | 'IFJUSAGI_TURISTA' | 'IFJUSAGI_KULFOLDI' | 'FELNOTT' | 'FELNOTT_KEDVEZMENYEZETT' | 'FELNOTT_KORKEDVEZMENYEZETT' | 'FELNOTT_KEDVEZMENYEZETT_1BOTOS' | 'FELNOTT_EMELTARAS' | 'FELNOTT_TURISTA' | 'FELNOTT_KULFOLDI' | 'FELNOTT_MENTESITETT' | 'IFJUSAGI_IHJ' | 'FELNOTT_IHJ' | 'FELNOTT_JOGFENNTARTO_IHJ' | 'TISZTSEGVISELOI' | 'SZOLGALATI' | 'BONUSZ';
    export const JogcimEnum = {
        GYERMEK: 'GYERMEK' as JogcimEnum,
        GYERMEKUSZOS: 'GYERMEK_USZOS' as JogcimEnum,
        IFJUSAGI: 'IFJUSAGI' as JogcimEnum,
        IFJUSAGIEMELTARAS: 'IFJUSAGI_EMELTARAS' as JogcimEnum,
        IFJUSAGITURISTA: 'IFJUSAGI_TURISTA' as JogcimEnum,
        IFJUSAGIKULFOLDI: 'IFJUSAGI_KULFOLDI' as JogcimEnum,
        FELNOTT: 'FELNOTT' as JogcimEnum,
        FELNOTTKEDVEZMENYEZETT: 'FELNOTT_KEDVEZMENYEZETT' as JogcimEnum,
        FELNOTTKORKEDVEZMENYEZETT: 'FELNOTT_KORKEDVEZMENYEZETT' as JogcimEnum,
        FELNOTTKEDVEZMENYEZETT1BOTOS: 'FELNOTT_KEDVEZMENYEZETT_1BOTOS' as JogcimEnum,
        FELNOTTEMELTARAS: 'FELNOTT_EMELTARAS' as JogcimEnum,
        FELNOTTTURISTA: 'FELNOTT_TURISTA' as JogcimEnum,
        FELNOTTKULFOLDI: 'FELNOTT_KULFOLDI' as JogcimEnum,
        FELNOTTMENTESITETT: 'FELNOTT_MENTESITETT' as JogcimEnum,
        IFJUSAGIIHJ: 'IFJUSAGI_IHJ' as JogcimEnum,
        FELNOTTIHJ: 'FELNOTT_IHJ' as JogcimEnum,
        FELNOTTJOGFENNTARTOIHJ: 'FELNOTT_JOGFENNTARTO_IHJ' as JogcimEnum,
        TISZTSEGVISELOI: 'TISZTSEGVISELOI' as JogcimEnum,
        SZOLGALATI: 'SZOLGALATI' as JogcimEnum,
        BONUSZ: 'BONUSZ' as JogcimEnum
    };
    export type JogcimCsoportEnum = 'ALTALANOS' | 'PARTI' | 'BOJLIS' | 'SPORTCELU';
    export const JogcimCsoportEnum = {
        ALTALANOS: 'ALTALANOS' as JogcimCsoportEnum,
        PARTI: 'PARTI' as JogcimCsoportEnum,
        BOJLIS: 'BOJLIS' as JogcimCsoportEnum,
        SPORTCELU: 'SPORTCELU' as JogcimCsoportEnum
    };
}