/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { HibasErtekesitesBundle } from '../model/hibasErtekesitesBundle';
import { TeruletiJegyElszamolas } from '../model/teruletiJegyElszamolas';
import { TeruletiJegyElszamolasFilter } from '../model/teruletiJegyElszamolasFilter';
import { TeruletiJegyElszamolasTobbSzerepkorTorzsadat } from '../model/teruletiJegyElszamolasTobbSzerepkorTorzsadat';
import { TeruletiJegyElszamolasTorzsadat } from '../model/teruletiJegyElszamolasTorzsadat';
import { TeruletiJegyErtekesites } from '../model/teruletiJegyErtekesites';
import { TeruletiJegyErtekesitesFilter } from '../model/teruletiJegyErtekesitesFilter';
import { TeruletiJegyRiport } from '../model/teruletiJegyRiport';
import { TeruletiJegyRiportFilter } from '../model/teruletiJegyRiportFilter';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TeruletiJegyRiportControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * elszamolasKozvetlenErtekesitoJutalek
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public elszamolasKozvetlenErtekesitoJutalekUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyElszamolas>;
    public elszamolasKozvetlenErtekesitoJutalekUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyElszamolas>>;
    public elszamolasKozvetlenErtekesitoJutalekUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyElszamolas>>;
    public elszamolasKozvetlenErtekesitoJutalekUsingPOST(body: TeruletiJegyElszamolasFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling elszamolasKozvetlenErtekesitoJutalekUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyElszamolas>('post',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-kozvetlen-ertekesito-jutalek`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * elszamolasKozvetlenMohoszRendszerhasznalat
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public elszamolasKozvetlenMohoszRendszerhasznalatUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyElszamolas>;
    public elszamolasKozvetlenMohoszRendszerhasznalatUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyElszamolas>>;
    public elszamolasKozvetlenMohoszRendszerhasznalatUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyElszamolas>>;
    public elszamolasKozvetlenMohoszRendszerhasznalatUsingPOST(body: TeruletiJegyElszamolasFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling elszamolasKozvetlenMohoszRendszerhasznalatUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyElszamolas>('post',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-kozvetlen-mohosz-rendszerhasznalat`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * elszamolasKozvetlenTorzsadat
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public elszamolasKozvetlenTorzsadatUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyElszamolasTobbSzerepkorTorzsadat>;
    public elszamolasKozvetlenTorzsadatUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyElszamolasTobbSzerepkorTorzsadat>>;
    public elszamolasKozvetlenTorzsadatUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyElszamolasTobbSzerepkorTorzsadat>>;
    public elszamolasKozvetlenTorzsadatUsingPOST(body: TeruletiJegyElszamolasFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling elszamolasKozvetlenTorzsadatUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyElszamolasTobbSzerepkorTorzsadat>('post',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-kozvetlen-torzsadat`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * elszamolasOnlineTorzsadat
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public elszamolasOnlineTorzsadatUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyElszamolasTorzsadat>;
    public elszamolasOnlineTorzsadatUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyElszamolasTorzsadat>>;
    public elszamolasOnlineTorzsadatUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyElszamolasTorzsadat>>;
    public elszamolasOnlineTorzsadatUsingPOST(body: TeruletiJegyElszamolasFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling elszamolasOnlineTorzsadatUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyElszamolasTorzsadat>('post',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-online-torzsadat`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * elszamolasSajatTorzsadat
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public elszamolasSajatTorzsadatUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyElszamolasTorzsadat>;
    public elszamolasSajatTorzsadatUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyElszamolasTorzsadat>>;
    public elszamolasSajatTorzsadatUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyElszamolasTorzsadat>>;
    public elszamolasSajatTorzsadatUsingPOST(body: TeruletiJegyElszamolasFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling elszamolasSajatTorzsadatUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyElszamolasTorzsadat>('post',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-sajat-torzsadat`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * elszamolasSajat
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public elszamolasSajatUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyElszamolas>;
    public elszamolasSajatUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyElszamolas>>;
    public elszamolasSajatUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyElszamolas>>;
    public elszamolasSajatUsingPOST(body: TeruletiJegyElszamolasFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling elszamolasSajatUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyElszamolas>('post',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-sajat`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * elszamolasViszontErtekesitoJutalek
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public elszamolasViszontErtekesitoJutalekUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyElszamolas>;
    public elszamolasViszontErtekesitoJutalekUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyElszamolas>>;
    public elszamolasViszontErtekesitoJutalekUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyElszamolas>>;
    public elszamolasViszontErtekesitoJutalekUsingPOST(body: TeruletiJegyElszamolasFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling elszamolasViszontErtekesitoJutalekUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyElszamolas>('post',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-viszont-ertekesito-jutalek`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * elszamolasViszontMohoszJutalek
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public elszamolasViszontMohoszJutalekUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyElszamolas>;
    public elszamolasViszontMohoszJutalekUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyElszamolas>>;
    public elszamolasViszontMohoszJutalekUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyElszamolas>>;
    public elszamolasViszontMohoszJutalekUsingPOST(body: TeruletiJegyElszamolasFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling elszamolasViszontMohoszJutalekUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyElszamolas>('post',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-viszont-mohosz-jutalek`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * elszamolasViszontTorzsadat
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public elszamolasViszontTorzsadatUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyElszamolasTobbSzerepkorTorzsadat>;
    public elszamolasViszontTorzsadatUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyElszamolasTobbSzerepkorTorzsadat>>;
    public elszamolasViszontTorzsadatUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyElszamolasTobbSzerepkorTorzsadat>>;
    public elszamolasViszontTorzsadatUsingPOST(body: TeruletiJegyElszamolasFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling elszamolasViszontTorzsadatUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyElszamolasTobbSzerepkorTorzsadat>('post',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-viszont-torzsadat`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ertekesitesCsvLetoltes
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ertekesitesCsvLetoltesUsingGET(filter: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public ertekesitesCsvLetoltesUsingGET(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public ertekesitesCsvLetoltesUsingGET(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public ertekesitesCsvLetoltesUsingGET(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling ertekesitesCsvLetoltesUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/teruletijegyriport/teruletijegy-ertekesites-csvletoltes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ertekesitesListazas
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ertekesitesListazasUsingPOST(body: TeruletiJegyErtekesitesFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<TeruletiJegyErtekesites>>;
    public ertekesitesListazasUsingPOST(body: TeruletiJegyErtekesitesFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TeruletiJegyErtekesites>>>;
    public ertekesitesListazasUsingPOST(body: TeruletiJegyErtekesitesFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TeruletiJegyErtekesites>>>;
    public ertekesitesListazasUsingPOST(body: TeruletiJegyErtekesitesFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ertekesitesListazasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<TeruletiJegyErtekesites>>('post',`${this.basePath}/api/mohosz/teruletijegyriport/teruletijegy-ertekesites-listazas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ertekesitesSzamossag
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ertekesitesSzamossagUsingPOST(body: TeruletiJegyErtekesitesFilter, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public ertekesitesSzamossagUsingPOST(body: TeruletiJegyErtekesitesFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public ertekesitesSzamossagUsingPOST(body: TeruletiJegyErtekesitesFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public ertekesitesSzamossagUsingPOST(body: TeruletiJegyErtekesitesFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ertekesitesSzamossagUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/teruletijegyriport/teruletijegy-ertekesites-szamossag`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * hibasErtekesitesek
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hibasErtekesitesekUsingGET(observe?: 'body', reportProgress?: boolean): Observable<HibasErtekesitesBundle>;
    public hibasErtekesitesekUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HibasErtekesitesBundle>>;
    public hibasErtekesitesekUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HibasErtekesitesBundle>>;
    public hibasErtekesitesekUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HibasErtekesitesBundle>('get',`${this.basePath}/api/mohosz/teruletijegyriport/hibas-ertekesitesek`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * isTermekKibocsato
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isTermekKibocsatoUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public isTermekKibocsatoUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public isTermekKibocsatoUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public isTermekKibocsatoUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/mohosz/teruletijegyriport/riport-termekkibocsato`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kozvetlenErtekesitoJutalekCsv
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kozvetlenErtekesitoJutalekCsvUsingGET(filter: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public kozvetlenErtekesitoJutalekCsvUsingGET(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public kozvetlenErtekesitoJutalekCsvUsingGET(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public kozvetlenErtekesitoJutalekCsvUsingGET(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling kozvetlenErtekesitoJutalekCsvUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-kozvetlen-ertekesito-jutalek-csv`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kozvetlenErtekesitoJutalekPdf
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kozvetlenErtekesitoJutalekPdfUsingGET(filter: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public kozvetlenErtekesitoJutalekPdfUsingGET(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public kozvetlenErtekesitoJutalekPdfUsingGET(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public kozvetlenErtekesitoJutalekPdfUsingGET(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling kozvetlenErtekesitoJutalekPdfUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-kozvetlen-ertekesito-jutalek-pdf`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kozvetlenMohoszRendszerhasznalatCsv
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kozvetlenMohoszRendszerhasznalatCsvUsingGET(filter: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public kozvetlenMohoszRendszerhasznalatCsvUsingGET(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public kozvetlenMohoszRendszerhasznalatCsvUsingGET(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public kozvetlenMohoszRendszerhasznalatCsvUsingGET(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling kozvetlenMohoszRendszerhasznalatCsvUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-kozvetlen-mohosz-rendszerhasznalat-csv`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * kozvetlenMohoszRendszerhasznalatPdf
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kozvetlenMohoszRendszerhasznalatPdfUsingGET(filter: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public kozvetlenMohoszRendszerhasznalatPdfUsingGET(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public kozvetlenMohoszRendszerhasznalatPdfUsingGET(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public kozvetlenMohoszRendszerhasznalatPdfUsingGET(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling kozvetlenMohoszRendszerhasznalatPdfUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-kozvetlen-mohosz-rendszerhasznalat-pdf`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * onlineMohoszJutalekCsv
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public onlineMohoszJutalekCsvUsingGET(filter: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public onlineMohoszJutalekCsvUsingGET(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public onlineMohoszJutalekCsvUsingGET(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public onlineMohoszJutalekCsvUsingGET(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling onlineMohoszJutalekCsvUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-online-mohosz-jutalek-csv`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * onlinePdf
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public onlinePdfUsingGET(filter: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public onlinePdfUsingGET(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public onlinePdfUsingGET(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public onlinePdfUsingGET(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling onlinePdfUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-online-pdf`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * riportListazas
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public riportListazasUsingPOST(body: TeruletiJegyRiportFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<TeruletiJegyRiport>>;
    public riportListazasUsingPOST(body: TeruletiJegyRiportFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TeruletiJegyRiport>>>;
    public riportListazasUsingPOST(body: TeruletiJegyRiportFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TeruletiJegyRiport>>>;
    public riportListazasUsingPOST(body: TeruletiJegyRiportFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling riportListazasUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<TeruletiJegyRiport>>('post',`${this.basePath}/api/mohosz/teruletijegyriport/riport-listazas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * riportSzamossag
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public riportSzamossagUsingPOST(body: TeruletiJegyRiportFilter, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public riportSzamossagUsingPOST(body: TeruletiJegyRiportFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public riportSzamossagUsingPOST(body: TeruletiJegyRiportFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public riportSzamossagUsingPOST(body: TeruletiJegyRiportFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling riportSzamossagUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/teruletijegyriport/riport-szamossag`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * sajatMohoszRendszerhasznalatCsv
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sajatMohoszRendszerhasznalatCsvUsingGET(filter: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sajatMohoszRendszerhasznalatCsvUsingGET(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sajatMohoszRendszerhasznalatCsvUsingGET(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sajatMohoszRendszerhasznalatCsvUsingGET(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling sajatMohoszRendszerhasznalatCsvUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-sajat-mohosz-rendszerhasznalat-csv`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * sajatPdf
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sajatPdfUsingGET(filter: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sajatPdfUsingGET(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sajatPdfUsingGET(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sajatPdfUsingGET(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling sajatPdfUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-sajat-pdf`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * teruletiJegyOnlineRiport
     * 
     * @param body filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public teruletiJegyOnlineRiportUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'body', reportProgress?: boolean): Observable<TeruletiJegyElszamolas>;
    public teruletiJegyOnlineRiportUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TeruletiJegyElszamolas>>;
    public teruletiJegyOnlineRiportUsingPOST(body: TeruletiJegyElszamolasFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TeruletiJegyElszamolas>>;
    public teruletiJegyOnlineRiportUsingPOST(body: TeruletiJegyElszamolasFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling teruletiJegyOnlineRiportUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TeruletiJegyElszamolas>('post',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-online`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * viszontErtekesitoJutalekCsv
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public viszontErtekesitoJutalekCsvUsingGET(filter: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public viszontErtekesitoJutalekCsvUsingGET(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public viszontErtekesitoJutalekCsvUsingGET(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public viszontErtekesitoJutalekCsvUsingGET(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling viszontErtekesitoJutalekCsvUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-viszont-ertekesito-jutalek-csv`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * viszontErtekesitoJutalekPdf
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public viszontErtekesitoJutalekPdfUsingGET(filter: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public viszontErtekesitoJutalekPdfUsingGET(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public viszontErtekesitoJutalekPdfUsingGET(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public viszontErtekesitoJutalekPdfUsingGET(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling viszontErtekesitoJutalekPdfUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-viszont-ertekesito-jutalek-pdf`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * viszontMohoszJutalekCsv
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public viszontMohoszJutalekCsvUsingGET(filter: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public viszontMohoszJutalekCsvUsingGET(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public viszontMohoszJutalekCsvUsingGET(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public viszontMohoszJutalekCsvUsingGET(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling viszontMohoszJutalekCsvUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-viszont-mohosz-jutalek-csv`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * viszontMohoszJutalekPdf
     * 
     * @param filter filter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public viszontMohoszJutalekPdfUsingGET(filter: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public viszontMohoszJutalekPdfUsingGET(filter: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public viszontMohoszJutalekPdfUsingGET(filter: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public viszontMohoszJutalekPdfUsingGET(filter: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (filter === null || filter === undefined) {
            throw new Error('Required parameter filter was null or undefined when calling viszontMohoszJutalekPdfUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mohosz/teruletijegyriport/elszamolas-viszont-mohosz-jutalek-pdf`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
