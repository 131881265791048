/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PersonalizedJogosultsag } from './personalizedJogosultsag';

export interface MegbizoJogosultsagBundle { 
    elerhetoAuthorityList?: Array<MegbizoJogosultsagBundle.ElerhetoAuthorityListEnum>;
    jogosultsagList?: Array<PersonalizedJogosultsag>;
    kereshetFelhasznalot?: boolean;
}
export namespace MegbizoJogosultsagBundle {
    export type ElerhetoAuthorityListEnum = 'UGYFELSZOLGALATI_GODMODE' | 'SUPER_ADMIN' | 'ONLINE_HORGASZREGISZTRACIO' | 'UGYINTEZOI_HORGASZREGISZTRACIO' | 'UGYINTEZOI_JOVAHAGYAS' | 'UGYINTEZOI_FIZETESMGMT' | 'SZERVEZETI_JOGOSULTSAG_BEALLITAS' | 'UGYFELSZOLGALATI_JOVAHAGYAS' | 'UGYFELSZOLGALATI_FIZETESMGMT' | 'ADOKARTYA_UTOLAGOS_ELLENORZES' | 'UGYFELSZOLGALATI_JUTALEK' | 'UGYFELSZOLGALATI_HORGASZREGISZTRACIO' | 'UGYFELSZOLGALATI_TORZSADATMGMT' | 'UGYFELSZOLGALATI_USERMGMT' | 'UGYFELSZOLGALATI_ELTILTASMGMT' | 'ELTILTAS_FELTOLTES' | 'ERTEKESITESI_RIPORT' | 'FOGASNYILVATTARTAS_RIPORT' | 'FOGASINAPLOLEADAS_RIPORT' | 'EGYESULETITAGSAG_RIPORT' | 'TERULETIJEGY_RIPORT' | 'VIZSGABIZOTTSAG_RIPORT' | 'FORGALOM_RIPORT' | 'UZENET_MGMNT' | 'VIZTERULET_MGMT' | 'ELLENOR' | 'ELLENOR_MGMT' | 'ALLAMIJEGY_ARLISTA_MGMT' | 'ALLAMI_JEGY_ERTEKESITES' | 'ALLAMI_JEGY_ATADAS' | 'TAGDIJ_ARLISTA_MGMT' | 'TERULETI_JEGY_ERTEKESITES' | 'TERULETI_JEGY_TERMEK_MGMT' | 'VIZSGABIZOTTSAG_MGMT' | 'VIZSGABIZTOS' | 'SZERZODES_KOTES' | 'KESZLETEZO_MGMT' | 'FELADATSOR_MGMT' | 'HORGASZKARTYA_IGENYLES_MGMT' | 'DOKUMENTUMTAR_MGMT' | 'MAHOR_ELOFIZETES_MGMT' | 'VERSENY_MGMT' | 'PSYS_ADMIN' | 'TECHU' | 'SZOLGALTATASMGMT' | 'SZOLGALTATAS_FELUGYELO';
    export const ElerhetoAuthorityListEnum = {
        UGYFELSZOLGALATIGODMODE: 'UGYFELSZOLGALATI_GODMODE' as ElerhetoAuthorityListEnum,
        SUPERADMIN: 'SUPER_ADMIN' as ElerhetoAuthorityListEnum,
        ONLINEHORGASZREGISZTRACIO: 'ONLINE_HORGASZREGISZTRACIO' as ElerhetoAuthorityListEnum,
        UGYINTEZOIHORGASZREGISZTRACIO: 'UGYINTEZOI_HORGASZREGISZTRACIO' as ElerhetoAuthorityListEnum,
        UGYINTEZOIJOVAHAGYAS: 'UGYINTEZOI_JOVAHAGYAS' as ElerhetoAuthorityListEnum,
        UGYINTEZOIFIZETESMGMT: 'UGYINTEZOI_FIZETESMGMT' as ElerhetoAuthorityListEnum,
        SZERVEZETIJOGOSULTSAGBEALLITAS: 'SZERVEZETI_JOGOSULTSAG_BEALLITAS' as ElerhetoAuthorityListEnum,
        UGYFELSZOLGALATIJOVAHAGYAS: 'UGYFELSZOLGALATI_JOVAHAGYAS' as ElerhetoAuthorityListEnum,
        UGYFELSZOLGALATIFIZETESMGMT: 'UGYFELSZOLGALATI_FIZETESMGMT' as ElerhetoAuthorityListEnum,
        ADOKARTYAUTOLAGOSELLENORZES: 'ADOKARTYA_UTOLAGOS_ELLENORZES' as ElerhetoAuthorityListEnum,
        UGYFELSZOLGALATIJUTALEK: 'UGYFELSZOLGALATI_JUTALEK' as ElerhetoAuthorityListEnum,
        UGYFELSZOLGALATIHORGASZREGISZTRACIO: 'UGYFELSZOLGALATI_HORGASZREGISZTRACIO' as ElerhetoAuthorityListEnum,
        UGYFELSZOLGALATITORZSADATMGMT: 'UGYFELSZOLGALATI_TORZSADATMGMT' as ElerhetoAuthorityListEnum,
        UGYFELSZOLGALATIUSERMGMT: 'UGYFELSZOLGALATI_USERMGMT' as ElerhetoAuthorityListEnum,
        UGYFELSZOLGALATIELTILTASMGMT: 'UGYFELSZOLGALATI_ELTILTASMGMT' as ElerhetoAuthorityListEnum,
        ELTILTASFELTOLTES: 'ELTILTAS_FELTOLTES' as ElerhetoAuthorityListEnum,
        ERTEKESITESIRIPORT: 'ERTEKESITESI_RIPORT' as ElerhetoAuthorityListEnum,
        FOGASNYILVATTARTASRIPORT: 'FOGASNYILVATTARTAS_RIPORT' as ElerhetoAuthorityListEnum,
        FOGASINAPLOLEADASRIPORT: 'FOGASINAPLOLEADAS_RIPORT' as ElerhetoAuthorityListEnum,
        EGYESULETITAGSAGRIPORT: 'EGYESULETITAGSAG_RIPORT' as ElerhetoAuthorityListEnum,
        TERULETIJEGYRIPORT: 'TERULETIJEGY_RIPORT' as ElerhetoAuthorityListEnum,
        VIZSGABIZOTTSAGRIPORT: 'VIZSGABIZOTTSAG_RIPORT' as ElerhetoAuthorityListEnum,
        FORGALOMRIPORT: 'FORGALOM_RIPORT' as ElerhetoAuthorityListEnum,
        UZENETMGMNT: 'UZENET_MGMNT' as ElerhetoAuthorityListEnum,
        VIZTERULETMGMT: 'VIZTERULET_MGMT' as ElerhetoAuthorityListEnum,
        ELLENOR: 'ELLENOR' as ElerhetoAuthorityListEnum,
        ELLENORMGMT: 'ELLENOR_MGMT' as ElerhetoAuthorityListEnum,
        ALLAMIJEGYARLISTAMGMT: 'ALLAMIJEGY_ARLISTA_MGMT' as ElerhetoAuthorityListEnum,
        ALLAMIJEGYERTEKESITES: 'ALLAMI_JEGY_ERTEKESITES' as ElerhetoAuthorityListEnum,
        ALLAMIJEGYATADAS: 'ALLAMI_JEGY_ATADAS' as ElerhetoAuthorityListEnum,
        TAGDIJARLISTAMGMT: 'TAGDIJ_ARLISTA_MGMT' as ElerhetoAuthorityListEnum,
        TERULETIJEGYERTEKESITES: 'TERULETI_JEGY_ERTEKESITES' as ElerhetoAuthorityListEnum,
        TERULETIJEGYTERMEKMGMT: 'TERULETI_JEGY_TERMEK_MGMT' as ElerhetoAuthorityListEnum,
        VIZSGABIZOTTSAGMGMT: 'VIZSGABIZOTTSAG_MGMT' as ElerhetoAuthorityListEnum,
        VIZSGABIZTOS: 'VIZSGABIZTOS' as ElerhetoAuthorityListEnum,
        SZERZODESKOTES: 'SZERZODES_KOTES' as ElerhetoAuthorityListEnum,
        KESZLETEZOMGMT: 'KESZLETEZO_MGMT' as ElerhetoAuthorityListEnum,
        FELADATSORMGMT: 'FELADATSOR_MGMT' as ElerhetoAuthorityListEnum,
        HORGASZKARTYAIGENYLESMGMT: 'HORGASZKARTYA_IGENYLES_MGMT' as ElerhetoAuthorityListEnum,
        DOKUMENTUMTARMGMT: 'DOKUMENTUMTAR_MGMT' as ElerhetoAuthorityListEnum,
        MAHORELOFIZETESMGMT: 'MAHOR_ELOFIZETES_MGMT' as ElerhetoAuthorityListEnum,
        VERSENYMGMT: 'VERSENY_MGMT' as ElerhetoAuthorityListEnum,
        PSYSADMIN: 'PSYS_ADMIN' as ElerhetoAuthorityListEnum,
        TECHU: 'TECHU' as ElerhetoAuthorityListEnum,
        SZOLGALTATASMGMT: 'SZOLGALTATASMGMT' as ElerhetoAuthorityListEnum,
        SZOLGALTATASFELUGYELO: 'SZOLGALTATAS_FELUGYELO' as ElerhetoAuthorityListEnum
    };
}