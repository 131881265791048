/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GyartasIgenylesLetrehozasRequest { 
    horgaszkartyaTipus?: GyartasIgenylesLetrehozasRequest.HorgaszkartyaTipusEnum;
    szemelyId?: number;
}
export namespace GyartasIgenylesLetrehozasRequest {
    export type HorgaszkartyaTipusEnum = 'MHK' | 'VIP';
    export const HorgaszkartyaTipusEnum = {
        MHK: 'MHK' as HorgaszkartyaTipusEnum,
        VIP: 'VIP' as HorgaszkartyaTipusEnum
    };
}