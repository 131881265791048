/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface EllenorzesiTevekenysegKontextus { 
    ellenorId?: number;
    ellenorLoginId?: string;
    ellenorNev?: string;
    hosszusagiFok?: number;
    idopont?: Date;
    kapcsoltSzabalysertesSorszama?: string;
    kliensIdopont?: Date;
    szelessegiFok?: number;
    szervezetId?: number;
    szervezetNev?: string;
    vizteruletId?: number;
    vizteruletNev?: string;
    vizteruletVizterKod?: string;
}