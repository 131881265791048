/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MappaLetrehozasRequest { 
    csatornaSet?: Array<MappaLetrehozasRequest.CsatornaSetEnum>;
    megnevezes?: string;
}
export namespace MappaLetrehozasRequest {
    export type CsatornaSetEnum = 'ONLINE' | 'UGYINTEZO' | 'UGYFELSZOLGALAT' | 'ALTALANOS';
    export const CsatornaSetEnum = {
        ONLINE: 'ONLINE' as CsatornaSetEnum,
        UGYINTEZO: 'UGYINTEZO' as CsatornaSetEnum,
        UGYFELSZOLGALAT: 'UGYFELSZOLGALAT' as CsatornaSetEnum,
        ALTALANOS: 'ALTALANOS' as CsatornaSetEnum
    };
}