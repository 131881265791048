/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ErtekesitesUtaniModositasRequest { 
    becenev?: string;
    engedelyezettErtekesitesTipusList?: Array<ErtekesitesUtaniModositasRequest.EngedelyezettErtekesitesTipusListEnum>;
    ertekesitesKezdete?: string;
    ertekesitesVege?: string;
    teruletiJegyId?: number;
}
export namespace ErtekesitesUtaniModositasRequest {
    export type EngedelyezettErtekesitesTipusListEnum = 'ONLINE' | 'SAJAT' | 'KOZVETLEN' | 'VISZONT';
    export const EngedelyezettErtekesitesTipusListEnum = {
        ONLINE: 'ONLINE' as EngedelyezettErtekesitesTipusListEnum,
        SAJAT: 'SAJAT' as EngedelyezettErtekesitesTipusListEnum,
        KOZVETLEN: 'KOZVETLEN' as EngedelyezettErtekesitesTipusListEnum,
        VISZONT: 'VISZONT' as EngedelyezettErtekesitesTipusListEnum
    };
}