/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AllamiJegySzervezetOsszesitesItem } from './allamiJegySzervezetOsszesitesItem';
import { SzervezetOsszesitesItem } from './szervezetOsszesitesItem';
import { TeruletiJegySzervezetOsszesitesItem } from './teruletiJegySzervezetOsszesitesItem';

export interface SzervezetOsszesites { 
    allamiJegyItem?: AllamiJegySzervezetOsszesitesItem;
    arres?: number;
    arresRendszerhasznalatiDij?: number;
    eladasiAr?: number;
    elszamolasiAr?: number;
    horgaszkartyaItem?: SzervezetOsszesitesItem;
    kozvetlenRendszerhasznalatOsszesites?: SzervezetOsszesitesItem;
    mahorElofizetesItems?: Array<SzervezetOsszesitesItem>;
    rendszerhasznalatiDij?: number;
    teruletiJegyItems?: Array<TeruletiJegySzervezetOsszesitesItem>;
    versenyzoiEngedelyItem?: SzervezetOsszesitesItem;
}