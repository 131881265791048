/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Hozzatartozo } from './hozzatartozo';
import { Teendok } from './teendok';

export interface Felhasznalo { 
    eloHorgaszRegisztracio?: boolean;
    hasHorgaszRegisztracio?: boolean;
    hasSzemelyesAdat?: boolean;
    horgaszAzonosito?: string;
    hozzatartozoList?: Array<Hozzatartozo>;
    keresztnev?: string;
    loginId?: string;
    loginIdType?: Felhasznalo.LoginIdTypeEnum;
    profilkepId?: number;
    szemelyId?: number;
    teendok?: Teendok;
    vezeteknev?: string;
}
export namespace Felhasznalo {
    export type LoginIdTypeEnum = 'ALTALANOS' | 'EMAIL' | 'HITELESITETT_EMAIL';
    export const LoginIdTypeEnum = {
        ALTALANOS: 'ALTALANOS' as LoginIdTypeEnum,
        EMAIL: 'EMAIL' as LoginIdTypeEnum,
        HITELESITETTEMAIL: 'HITELESITETT_EMAIL' as LoginIdTypeEnum
    };
}