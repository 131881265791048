/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Szemely } from './szemely';

export interface FogasiNaploRiportDetails { 
    ertekesitesDatuma?: Date;
    ertekesitoSzemelyId?: number;
    ertekesitoSzemelyLoginId?: string;
    ertekesitoSzemelyNev?: string;
    ertekesitoSzervezetId?: number;
    ertekesitoSzervezetNev?: string;
    fogasiNaploStatusz?: FogasiNaploRiportDetails.FogasiNaploStatuszEnum;
    horgaszAzonosito?: string;
    horgaszNev?: string;
    leadasDatuma?: string;
    leadasSzemelyId?: number;
    leadasSzemelyLoginId?: string;
    leadasSzemelyNev?: string;
    leadasSzervezetId?: number;
    leadasSzervezetNev?: string;
    osszesitesKitoltve?: boolean;
    rogzitesDatuma?: string;
    rogzitesSzemelyId?: number;
    rogzitesSzemelyLoginId?: string;
    rogzitesSzemelyNev?: string;
    rogzitesSzervezetId?: number;
    rogzitesSzervezetNev?: string;
    szemely?: Szemely;
    visszavonasDatuma?: string;
}
export namespace FogasiNaploRiportDetails {
    export type FogasiNaploStatuszEnum = 'KIADOTT' | 'LEADOTT' | 'BETELT' | 'MEGSEMMISULT' | 'ELVESZETT' | 'VEGLEGESEN_STORNOZOTT' | 'KIADOTT_SZTORNO_JOVAHAGYASRA_VAR' | 'KIADOTT_ELVESZETT_JOVAHAGYASRA_VAR' | 'KIADOTT_MEGSEMMISULT_JOVAHAGYASRA_VAR' | 'VISSZAVONT';
    export const FogasiNaploStatuszEnum = {
        KIADOTT: 'KIADOTT' as FogasiNaploStatuszEnum,
        LEADOTT: 'LEADOTT' as FogasiNaploStatuszEnum,
        BETELT: 'BETELT' as FogasiNaploStatuszEnum,
        MEGSEMMISULT: 'MEGSEMMISULT' as FogasiNaploStatuszEnum,
        ELVESZETT: 'ELVESZETT' as FogasiNaploStatuszEnum,
        VEGLEGESENSTORNOZOTT: 'VEGLEGESEN_STORNOZOTT' as FogasiNaploStatuszEnum,
        KIADOTTSZTORNOJOVAHAGYASRAVAR: 'KIADOTT_SZTORNO_JOVAHAGYASRA_VAR' as FogasiNaploStatuszEnum,
        KIADOTTELVESZETTJOVAHAGYASRAVAR: 'KIADOTT_ELVESZETT_JOVAHAGYASRA_VAR' as FogasiNaploStatuszEnum,
        KIADOTTMEGSEMMISULTJOVAHAGYASRAVAR: 'KIADOTT_MEGSEMMISULT_JOVAHAGYASRA_VAR' as FogasiNaploStatuszEnum,
        VISSZAVONT: 'VISSZAVONT' as FogasiNaploStatuszEnum
    };
}