/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Valasz } from './valasz';

export interface Kerdes { 
    helyesValasz?: Valasz;
    id?: number;
    kepId?: number;
    kerdesTipus?: Kerdes.KerdesTipusEnum;
    szoveg?: string;
}
export namespace Kerdes {
    export type KerdesTipusEnum = 'RADIOGOMB' | 'CHECKBOX' | 'DATUM' | 'SZAM' | 'IGAZHAMIS';
    export const KerdesTipusEnum = {
        RADIOGOMB: 'RADIOGOMB' as KerdesTipusEnum,
        CHECKBOX: 'CHECKBOX' as KerdesTipusEnum,
        DATUM: 'DATUM' as KerdesTipusEnum,
        SZAM: 'SZAM' as KerdesTipusEnum,
        IGAZHAMIS: 'IGAZHAMIS' as KerdesTipusEnum
    };
}