/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Character } from './character';
import { SorszamIntervallum } from './sorszamIntervallum';
import { Termek } from './termek';

export interface AllamiJegyKeszletezo { 
    allamiJegyTermek?: Termek;
    atadottKeszlet?: Array<SorszamIntervallum>;
    ervenyessegiEv?: number;
    id?: number;
    keszlet?: Array<SorszamIntervallum>;
    keszletrolElveszettSztornoKeszlet?: Array<SorszamIntervallum>;
    megvasarolhatoTermek?: Termek;
    megvasaroltKeszlet?: Array<SorszamIntervallum>;
    sorozatAzonosito?: Character;
    szereploId?: number;
    sztornoJovahagyasraVarKeszlet?: Array<SorszamIntervallum>;
    veglegesSztornoKeszlet?: Array<SorszamIntervallum>;
}