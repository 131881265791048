/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ActivateUserRequest } from '../model/activateUserRequest';
import { ChangeLoginIdRequest } from '../model/changeLoginIdRequest';
import { ChangePasswordRequest } from '../model/changePasswordRequest';
import { LoginIdRequest } from '../model/loginIdRequest';
import { RegisterUserRequest } from '../model/registerUserRequest';
import { ResetPasswordByTokenRequest } from '../model/resetPasswordByTokenRequest';
import { SssUserInfo } from '../model/sssUserInfo';
import { ValidateEmailRequest } from '../model/validateEmailRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserMgmtControllerService {

    protected basePath = '//localhost:8080/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * activateUser
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateUserUsingPOST(body: ActivateUserRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public activateUserUsingPOST(body: ActivateUserRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public activateUserUsingPOST(body: ActivateUserRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public activateUserUsingPOST(body: ActivateUserRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling activateUserUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/usermgmt/activateuser`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * changeLoginId
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeLoginIdUsingPOST(body: ChangeLoginIdRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public changeLoginIdUsingPOST(body: ChangeLoginIdRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public changeLoginIdUsingPOST(body: ChangeLoginIdRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public changeLoginIdUsingPOST(body: ChangeLoginIdRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling changeLoginIdUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/usermgmt/changeloginid`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * changePassword
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changePasswordUsingPOST(body: ChangePasswordRequest, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public changePasswordUsingPOST(body: ChangePasswordRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public changePasswordUsingPOST(body: ChangePasswordRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public changePasswordUsingPOST(body: ChangePasswordRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling changePasswordUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/usermgmt/changepassword`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteSssUser
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSssUserUsingPOST(body: LoginIdRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteSssUserUsingPOST(body: LoginIdRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteSssUserUsingPOST(body: LoginIdRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteSssUserUsingPOST(body: LoginIdRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling deleteSssUserUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/usermgmt/ugyfelszolgalati-deletesssuser`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * emailValidationRequest
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public emailValidationRequestUsingPOST(body: LoginIdRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public emailValidationRequestUsingPOST(body: LoginIdRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public emailValidationRequestUsingPOST(body: LoginIdRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public emailValidationRequestUsingPOST(body: LoginIdRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling emailValidationRequestUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/usermgmt/emailvalidationrequest`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * onlineValidateEmailByToken
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public onlineValidateEmailByTokenUsingPOST(body: ValidateEmailRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public onlineValidateEmailByTokenUsingPOST(body: ValidateEmailRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public onlineValidateEmailByTokenUsingPOST(body: ValidateEmailRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public onlineValidateEmailByTokenUsingPOST(body: ValidateEmailRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling onlineValidateEmailByTokenUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/usermgmt/online-validateemail-bytoken`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * registerUser
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerUserUsingPOST(body: RegisterUserRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public registerUserUsingPOST(body: RegisterUserRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public registerUserUsingPOST(body: RegisterUserRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public registerUserUsingPOST(body: RegisterUserRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling registerUserUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/usermgmt/registeruser`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * resetPasswordByToken
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPasswordByTokenUsingPOST(body: ResetPasswordByTokenRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resetPasswordByTokenUsingPOST(body: ResetPasswordByTokenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resetPasswordByTokenUsingPOST(body: ResetPasswordByTokenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resetPasswordByTokenUsingPOST(body: ResetPasswordByTokenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling resetPasswordByTokenUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/usermgmt/resetpasswordbytoken`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * resetPasswordRequest
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPasswordRequestUsingPOST(body: LoginIdRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resetPasswordRequestUsingPOST(body: LoginIdRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resetPasswordRequestUsingPOST(body: LoginIdRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resetPasswordRequestUsingPOST(body: LoginIdRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling resetPasswordRequestUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/usermgmt/resetpasswordrequest`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ugyfelszolgalatiChangeLoginId
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ugyfelszolgalatiChangeLoginIdUsingPOST(body: ChangeLoginIdRequest, observe?: 'body', reportProgress?: boolean): Observable<SssUserInfo>;
    public ugyfelszolgalatiChangeLoginIdUsingPOST(body: ChangeLoginIdRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SssUserInfo>>;
    public ugyfelszolgalatiChangeLoginIdUsingPOST(body: ChangeLoginIdRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SssUserInfo>>;
    public ugyfelszolgalatiChangeLoginIdUsingPOST(body: ChangeLoginIdRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ugyfelszolgalatiChangeLoginIdUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SssUserInfo>('post',`${this.basePath}/api/usermgmt/ugyfelszolgalati-changeloginid`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ugyfelszolgalatiEmailValidationRequest
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ugyfelszolgalatiEmailValidationRequestUsingPOST(body: LoginIdRequest, observe?: 'body', reportProgress?: boolean): Observable<SssUserInfo>;
    public ugyfelszolgalatiEmailValidationRequestUsingPOST(body: LoginIdRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SssUserInfo>>;
    public ugyfelszolgalatiEmailValidationRequestUsingPOST(body: LoginIdRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SssUserInfo>>;
    public ugyfelszolgalatiEmailValidationRequestUsingPOST(body: LoginIdRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ugyfelszolgalatiEmailValidationRequestUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SssUserInfo>('post',`${this.basePath}/api/usermgmt/ugyfelszolgalati-emailvalidationrequest`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ugyfelszolgalatiResetPassword
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ugyfelszolgalatiResetPasswordUsingPOST(body: LoginIdRequest, observe?: 'body', reportProgress?: boolean): Observable<SssUserInfo>;
    public ugyfelszolgalatiResetPasswordUsingPOST(body: LoginIdRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SssUserInfo>>;
    public ugyfelszolgalatiResetPasswordUsingPOST(body: LoginIdRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SssUserInfo>>;
    public ugyfelszolgalatiResetPasswordUsingPOST(body: LoginIdRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling ugyfelszolgalatiResetPasswordUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SssUserInfo>('post',`${this.basePath}/api/usermgmt/ugyfelszolgalati-resetpassword`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * userInfo
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userInfoUsingPOST(body: LoginIdRequest, observe?: 'body', reportProgress?: boolean): Observable<SssUserInfo>;
    public userInfoUsingPOST(body: LoginIdRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SssUserInfo>>;
    public userInfoUsingPOST(body: LoginIdRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SssUserInfo>>;
    public userInfoUsingPOST(body: LoginIdRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling userInfoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SssUserInfo>('post',`${this.basePath}/api/usermgmt/ugyfelszolgalati-userinfo`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * userInfo
     * 
     * @param loginId loginId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userInfoUsingPOST_1(loginId: string, observe?: 'body', reportProgress?: boolean): Observable<SssUserInfo>;
    public userInfoUsingPOST_1(loginId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SssUserInfo>>;
    public userInfoUsingPOST_1(loginId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SssUserInfo>>;
    public userInfoUsingPOST_1(loginId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (loginId === null || loginId === undefined) {
            throw new Error('Required parameter loginId was null or undefined when calling userInfoUsingPOST_1.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (loginId !== undefined && loginId !== null) {
            queryParameters = queryParameters.set('loginId', <any>loginId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SssUserInfo>('post',`${this.basePath}/api/usermgmt/userinfo`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * validateEmailByToken
     * 
     * @param body request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateEmailByTokenUsingPOST(body: ValidateEmailRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public validateEmailByTokenUsingPOST(body: ValidateEmailRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public validateEmailByTokenUsingPOST(body: ValidateEmailRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public validateEmailByTokenUsingPOST(body: ValidateEmailRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling validateEmailByTokenUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/usermgmt/validateemail-bytoken`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
