/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SzerzodesHierarchiaSzervezet { 
    id?: number;
    nev?: string;
    tipus?: SzerzodesHierarchiaSzervezet.TipusEnum;
}
export namespace SzerzodesHierarchiaSzervezet {
    export type TipusEnum = 'SZEMELY' | 'MOHOSZ' | 'TAGEGYESULET' | 'TAGSZOVETSEG' | 'BOLT' | 'HALGAZDALKODO' | 'ALTALANOS';
    export const TipusEnum = {
        SZEMELY: 'SZEMELY' as TipusEnum,
        MOHOSZ: 'MOHOSZ' as TipusEnum,
        TAGEGYESULET: 'TAGEGYESULET' as TipusEnum,
        TAGSZOVETSEG: 'TAGSZOVETSEG' as TipusEnum,
        BOLT: 'BOLT' as TipusEnum,
        HALGAZDALKODO: 'HALGAZDALKODO' as TipusEnum,
        ALTALANOS: 'ALTALANOS' as TipusEnum
    };
}